import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import detailPageStyles from '../../../styles/detailPage';
import EventParticipantArray from './EventParticipantArray';
import exportEventParticipants from './ExportEvent';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

const EventParticipants = ({ event }) => {
  const detailPageClasses = useDetailPageStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const exportEvent = async (clickEvent) => {
    clickEvent.stopPropagation();
    clickEvent.preventDefault();
    exportEventParticipants(event.id);
  };

  return (
    <>
    <Container maxWidth={false} className={detailPageClasses.pageTitle}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item>
          <Typography variant="h1">
            {t('pages.events.participants')}
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="flex-end"
              spacing={matches
                ? 2
                : 4}
              alignItems="flex-end"
            >
              <Grid item>
                <Button variant="contained" onClick={exportEvent}>
                  {t('pages.events.exportParticipants')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
      <Container maxWidth={false} className={detailPageClasses.content} style={{ marginTop: 0 }}>
        <EventParticipantArray event={event} />
      </Container>
    </>
  );
};

EventParticipants.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventParticipants;
