import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  EVENT, EVENT_ACTIVITIES,
  EVENT_ACTIVITIES_REGEX,
  EVENT_PARTICIPANTS,
  EVENT_PARTICIPANTS_REGEX,
  EVENT_REGEX,
  EVENT_TIME_SLOTS,
  EVENT_TIME_SLOTS_REGEX,
} from '../../../constants/routes';
import sidebarMenuStyle from '../../../styles/sidebarMenu';
import HomeIcon from '../../icon/HomeIcon';
import { SPORTIIZ_WHITE } from '../../../constants/colors';
import DashboardIcon from '../../icon/DashboardIcon';
import ClockIcon from '../../icon/ClockIcon';
import PeopleIcon from '../../icon/PeopleIcon';

const useSidebarMenuStyles = makeStyles((theme) => (
  sidebarMenuStyle(theme)
));

const EventSidebarMenu = (props) => {
  const {
    id,
    loading,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const sidebarMenuClasses = useSidebarMenuStyles();

  function handleMenuItemClick(path) {
    if (!loading) {
      history.push(path.replace(':id', id));
    }
  }

  const dashBoardSelected = location.pathname.match(EVENT_REGEX) || [];
  const activitySelected = location.pathname.match(EVENT_ACTIVITIES_REGEX) || [];
  const timeSlotsSelected = location.pathname.match(EVENT_TIME_SLOTS_REGEX) || [];
  const participantsSelected = location.pathname.match(EVENT_PARTICIPANTS_REGEX) || [];

  return (
    <List
      component="nav"
      aria-label="main mailbox folders"
      className={sidebarMenuClasses.list}
    >
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={dashBoardSelected.length > 0}
        onClick={() => handleMenuItemClick(EVENT)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <HomeIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary="Dashboard"
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={activitySelected.length > 0}
        onClick={() => handleMenuItemClick(EVENT_ACTIVITIES)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <DashboardIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.activities.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={timeSlotsSelected.length > 0}
        onClick={() => handleMenuItemClick(EVENT_TIME_SLOTS)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <ClockIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.timeSlots.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={participantsSelected.length > 0}
        onClick={() => handleMenuItemClick(EVENT_PARTICIPANTS)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <PeopleIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.events.participants')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
    </List>
  );
};

export default EventSidebarMenu;

EventSidebarMenu.defaultProps = {
  loading: false,
};

EventSidebarMenu.propTypes = {
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};
