import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY_LIGHT,
  SPORTIIZ_WHITE,
} from '../constants/colors';
import { CARD_RADIUS, LABEL_RADIUS } from '../constants/style';

export default function activityPaperStyles() {
  return {
    paper: {
      display: 'flex',
      border: 'none',
      borderRadius: CARD_RADIUS,
      color: SPORTIIZ_WHITE,
      height: '300px',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'border-box',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      padding: '20px',
      position: 'relative',
      boxShadow: '0px -110px 50px -20px rgba(0, 0, 0, 0.2) inset',
      cursor: 'pointer',
      textAlign: 'start',
    },
    disabled: {
      filter: 'saturate(0)',
      webkitFilter: 'saturate(0)',
      mozFilter: 'saturate(0)',
      msFilter: 'saturate(0)',
      oFilter: 'saturate(0)',
      opacity: 0.5,
    },
    pictureBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      background: 'linear-gradient(0deg, #373A46 25.1%, rgba(33, 42, 75, 0.231643) 82.89%)',
      mixBlendMode: 'darken',
      opacity: 0.4,
    },
    paperTitle: {
      position: 'absolute',
      bottom: 0,
      paddingBottom: '20px',
      overflow: 'hidden',
    },
    typo: {
      color: SPORTIIZ_WHITE,
    },
    subTypo: {
      color: SPORTIIZ_WHITE,
      paddingLeft: '45px',
      height: '1.3em',
      lineHeight: '1em',
    },
    chip: {
      color: SPORTIIZ_BLUE,
      backgroundColor: SPORTIIZ_WHITE,
      borderRadius: LABEL_RADIUS,
      fontWeight: 600,
      verticalAlign: 'bottom',
      marginLeft: '45px',
    },
    moreIcon: {
      backgroundColor: SPORTIIZ_WHITE,
      padding: '0.5rem',
      '&:hover': {
        backgroundColor: SPORTIIZ_GREY_LIGHT,
      },
      iconButtonContainer: {
        position: 'absolute',
        right: '20px',
        top: '20px',
      },
    },
    disableIcon: {
      position: 'absolute',
      bottom: '20px',
      right: '20px',
    },
  };
}
