import React from 'react';
import Box from '@material-ui/core/Box';
import { useParams } from 'react-router';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import withAuthorization from '../../hoc/withAuthorization';
import Loading from '../../components/layout/Loading';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import { QUERY_AREAS, QUERY_COMPANIES, QUERY_EVENT } from '../../constants/graphQLQueries';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import detailPageStyles from '../../styles/detailPage';
import { EVENT } from '../../constants/routes';
import EditEventForm from '../../components/events/EditEventForm';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

const EditEventPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();
  const detailPageClasses = useDetailPageStyles();
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_EVENT, {
    variables: { id },
    client,
  });
  const {
    loading: areasLoading,
    error: areasError,
    data: areasData,
  } = useQuery(
    QUERY_AREAS,
    { client },
  );
  const {
    loading: companiesLoading,
    error: companiesError,
    data: companiesData,
  } = useQuery(
    QUERY_COMPANIES,
    { client },
  );
  function handleCancel() {
    history.push(EVENT.replace(':id', id));
  }
  const allLoading = loading || areasLoading || companiesLoading;
  if (error || areasError || companiesError) {
    return <ErrorServer error={error} />;
  }
  if ((!data || !data.event) && !allLoading) {
    return <Error404 />;
  }
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        <Container maxWidth={false} className={detailPageClasses.header}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1">{t('pages.events.form.editTitle')}</Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item>
                <Button
                  variant="outlined"
                  className={detailPageClasses.headerOutlinedButton}
                  onClick={handleCancel}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Container>

        <Container maxWidth={false} className={detailPageClasses.content}>
          {allLoading && <Loading />}
          {!allLoading && data.event && (
            <EditEventForm
              editedEvent={data.event}
              areas={areasData.areas}
              companies={companiesData.companies}
            />
          )}
        </Container>
      </Box>
    </div>
  );
};

export default withAuthorization()(EditEventPage);
