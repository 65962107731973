import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CodeIcon from '../../icon/CodeIcon';
import ChevronUpIcon from '../../icon/ChevronUpIcon';
import ChevronDownIcon from '../../icon/ChevronDownIcon';

const useStyles = makeStyles(() => (
  {
    icon: {
      width: '22px',
      height: '22px',
      verticalAlign: 'bottom',
    },
    iconRotate: {
      transform: 'rotate(0.25turn)',
      width: '16px',
      height: '16px',
      verticalAlign: 'text-bottom',
    },
    iconRotateContainer: {
      marginLeft: '5px',
    },
  }
));

const SortedTableCell = (props) => {
  const {
    order,
    orderBy,
    name,
    label,
    setOrder,
    setOrderBy,
    align,
  } = props;
  const classes = useStyles();

  function onClick() {
    if (orderBy !== name) {
      setOrderBy(name);
      setOrder('ASC');
    } else if (order === 'ASC') {
      setOrder('DESC');
    } else {
      setOrder('ASC');
    }
  }

  return (
    <TableCell align={align} onClick={onClick}>
      {label}
      {orderBy !== name && <span className={classes.iconRotateContainer}>
        <CodeIcon className={classes.iconRotate}/>
      </span>}
      {orderBy === name && order === 'ASC' && <ChevronUpIcon className={classes.icon} />}
      {orderBy === name && order === 'DESC' && <ChevronDownIcon className={classes.icon} />}
    </TableCell>
  );
};
SortedTableCell.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  align: PropTypes.string,
};

SortedTableCell.defaultProps = {
  align: 'left',
};
export default SortedTableCell;
