import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useDetailPageStyles } from '../../containers/company/CompanyPage/CompanyPage';

const useStyles = makeStyles((theme) => (
  {
    titleContainer: {
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        paddingTop: '1em',
        paddingBottom: '1em',
        width: '100%',
      },
    },
    title: {
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    titleWithSubtitle: {
      marginBottom: 0,
      marginTop: '30px',
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    subtitle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      padding: 0,
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        marginBottom: '5px',
      },
    },
    associationName: {
      fontStyle: 'normal',
      fontWeight: 600,
    },
  }
));

const ActivityPageContent = (props) => {
  const { activity } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const detailPageClasses = useDetailPageStyles();
  const classes = useStyles();

  const hasSubtitle = activity.subtitle !== undefined
    && activity.subtitle !== null
    && activity.subtitle !== '';

  return (
    <Container maxWidth={false} className={detailPageClasses.header}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap={'nowrap'}
      >
        <Grid item className={classes.titleContainer}>
          <Typography
            variant={
              (
                matchesXS && 'h3'
              )
              || (
                matchesMD && 'h2'
              )
              || 'h1'
            }
            className={hasSubtitle
              ? classes.titleWithSubtitle
              : classes.title}
          >
            {activity.name}
          </Typography>
          {hasSubtitle
            && <Typography
              variant="subtitle2"
              className={classes.subtitle}
            >
              {t('pages.companyActivities.supervisedBy')}
              {' '}
              <em className={classes.associationName}>{activity.association.name}</em>
            </Typography>
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActivityPageContent;

ActivityPageContent.propTypes = {
  activity: PropTypes.object.isRequired,
};
