import thunk from 'redux-thunk';
import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';

import userReducer from './user/reducers';
import infosReducers from './infos/reducers';

const reducers = combineReducers({
  user: userReducer,
  infos: infosReducers,
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
