import React from 'react';
import Container from '@material-ui/core/Container';

export default function Error404() {
  return (
    <header className="App-header">
      <Container>
        <p>404</p>
      </Container>
    </header>
  );
}
