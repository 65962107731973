import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const CodeIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Code">
      <path
        d="M8.6406,5.2314 C8.2136,4.8784 7.5846,4.9354 7.2316,5.3594 L2.2316,11.3594 C1.9266,11.7254 1.9216,12.2554 2.2206,12.6274 L7.0486,18.6274 C7.2466,18.8724 7.5366,19.0004 7.8286,19.0004 C8.0486,19.0004 8.2706,18.9274 8.4546,18.7794 C8.8856,18.4324 8.9526,17.8034 8.6076,17.3734 L4.2916,12.0104 L8.7686,6.6404 C9.1216,6.2154 9.0646,5.5854 8.6406,5.2314 M21.7793,11.373 L16.9513,5.373 C16.6043,4.943 15.9753,4.873 15.5453,5.221 C15.1143,5.567 15.0473,6.196 15.3923,6.627 L19.7083,11.989 L15.2313,17.359 C14.8783,17.784 14.9353,18.415 15.3593,18.769 C15.5473,18.924 15.7733,19 15.9993,19 C16.2863,19 16.5703,18.878 16.7683,18.641 L21.7683,12.641 C22.0733,12.274 22.0783,11.744 21.7793,11.373"
        data-name="code"
        fill={color}
      />
    </g>
  </svg>
);

CodeIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CodeIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};

export default CodeIcon;
