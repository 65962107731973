import { Switch, withStyles } from '@material-ui/core';
import { SPORTIIZ_PALE_BLUE } from '../../constants/colors';

const AntSwitch = withStyles((theme) => (
  {
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        color: theme.palette.common.white,
        transform: 'translateX(12px)',
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    thumb: {
      width: 10,
      height: 10,
      boxShadow: 'none',
      margin: '1px',
    },
    track: {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: SPORTIIZ_PALE_BLUE,
    },
    checked: {},
  }
))(Switch);

export default AntSwitch;
