import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY_EXTRA_DARK,
} from '../../constants/colors';
import Transition from '../layout/TransitionAnimation';
import QrCodeDownload from './QrCodeDownload';
import AppDialogTitle from '../layout/AppDialogTitle';

const EventDetailsPasswordDialog = (props) => {
  const {
    onClose,
    open,
    event,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={matches}
      scroll="body"
      maxWidth="sm"
      TransitionComponent={Transition}
      transitionDuration={
        matches
          ? 500
          : 750
      }
    >
      <AppDialogTitle
        title={`${t('pages.events.visibilityLocked')} ${t('pages.events.protectedByPassword')}`}
        handleClose={onClose}
      />
      <DialogContent style={{ overflowY: 'hidden' }}>
        <Grid
          container
          direction="row"
          spacing={4}
          wrap="nowrap"
          style={{ padding: '1rem 0 0' }}
        >
          <Grid item xs={12} md={7}>
            <Grid
              container
              direction="column"
              spacing={2}
              wrap="nowrap"
              style={{ marginBottom: '0.5rem' }}
            >

              <Grid item>
                <Typography variant="caption">
                  {t('pages.events.passwordDialog')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ color: SPORTIIZ_GREY_EXTRA_DARK }}>
                  {t('pages.events.password')}
                  <span style={{ color: SPORTIIZ_BLUE }}>{event.password}</span>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {t('pages.events.qrCodeHelperText1')}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {t('pages.events.qrCodeHelperText2')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <QrCodeDownload imageUrl={event.qrCodeURL} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EventDetailsPasswordDialog;

EventDetailsPasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  event: PropTypes.bool.isRequired,
};
