import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  popover: {
    border: 0,
    padding: '15px 10px',
    minWidth: '270px',
    height: 'auto',
    maxHeight: '350px',
    zIndex: 999,
  },
  menu: {
    height: 'auto',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  menuItem: {
    fontSize: '13px',
    minHeight: '44px',
  },
}));

export default function ArrayPopover(props) {
  const {
    open,
    anchorEl,
    handleClose,
    items,
  } = props;
  const classes = useStyles();
  return (
    <Popover
      className={classes.popover}
      id="list-itm-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <List component="nav" className={classes.menu}>
        {items.map((item) => (
          <ListItem
            button
            onClick={item.action}
            className={classes.menuItem}
            key={`item-${item.label}`}
          >
            {item.label}
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}

ArrayPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};
