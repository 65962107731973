import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AvatarPictureInput from '../forms/AvatarPictureInput';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import { MUTATION_ASSOCIATION } from '../../constants/graphQLQueries';
import dialogFormStyle from '../../styles/dialogForm';

const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

const INITIAL_VALUES = {
  associationId: null,
  name: '',
  nameError: false,
  nameErrorMessage: '',
  slogan: '',
  sloganError: false,
  sloganErrorMessage: '',
  areaId: null,
  areaIdError: false,
  areaIdErrorMessage: '',
  picture: null,
  pictureBlob: null,
};

export default function AssociationForm(props) {
  const {
    handleClose,
    update,
    association,
    areas,
  } = props;
  const {
    t,
    i18n,
  } = useTranslation();
  const locale = i18n.language.slice(0, 2);
  const dispatch = useDispatch();
  const dialogFormClasses = useDialogFormStyles();
  const client = useApolloClient();
  const newState = (
    association === null
  )
    ? INITIAL_VALUES
    : {
      ...INITIAL_VALUES,
      associationId: association.id,
      name: association.name,
      slogan: association.slogan,
      areaId: association.area?.id,
      pictureBlob: association.picture || null,
    };
  const [values, setValues] = useState(newState);

  const [mutationAssociation, { loading }] = useMutation(
    MUTATION_ASSOCIATION,
    {
      client,
      update,
    },
  );

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });
  };

  const handleChangeFile = (name) => (event) => {
    const blob = new Blob([event.target.files[0]], { type: 'image/png' });
    const blobUrl = URL.createObjectURL(blob);
    setValues({
      ...values,
      [name]: event.target.files[0],
      [`${name}Blob`]: blobUrl,
    });
  };

  const onSubmit = (event) => {
    let error = false;
    let newValues = { ...values };
    if (!values.name || values.name.trim() === '') {
      newValues = {
        ...newValues,
        nameError: true,
        nameErrorMessage: t('pages.associations.form.formNameRequired'),
      };
      error = true;
    }
    if (values.slogan === null || values.slogan.trim() === '') {
      newValues = {
        ...newValues,
        sloganError: true,
        sloganErrorMessage: t('pages.associations.form.formSloganRequired'),
      };
      error = true;
    }
    if (!values.areaId || values.areaId === '') {
      newValues = {
        ...newValues,
        areaIdError: true,
        areaIdErrorMessage: t('pages.associations.form.formAreaRequired'),
      };
      error = true;
    }
    setValues(newValues);
    if (!error) {
      if (event) {
        event.preventDefault();
      }
      const vars = {
        name: values.name.trim(),
        slogan: values.slogan.trim(),
      };
      let hasUpload = false;
      let successMessage = t('pages.associations.form.formAddSuccessMessage');
      if (values.picture) {
        vars.picture = values.picture;
        hasUpload = true;
      }
      if (values.areaId) {
        vars.areaId = values.areaId;
      }
      if (values.associationId) {
        vars.associationId = values.associationId;
        successMessage = t('pages.associations.form.formEditSuccessMessage');
      }
      mutationAssociation({
        variables: vars,
        context: {
          hasUpload,
        },
      }).then(() => {
        onGraphQLSuccess(successMessage);
      }).catch((error) => {
        onGraphQLError(error);
      });
    }
  };

  function onGraphQLSuccess(message) {
    handleClose();
    setValues(INITIAL_VALUES);
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <AvatarPictureInput
              pictureBlob={values.pictureBlob}
              handleChangeFile={handleChangeFile}
              id="association-picture"
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <TextField
              label={t('pages.associations.form.name')}
              id="association-name"
              type="text"
              variant="outlined"
              onChange={handleChange('name')}
              margin="normal"
              value={values.name}
              fullWidth={true}
              error={values.nameError}
              helperText={values.nameErrorMessage}
            />
            <TextField
              label={t('pages.associations.form.slogan')}
              id="association-slogan"
              type="text"
              variant="outlined"
              onChange={handleChange('slogan')}
              margin="normal"
              value={values.slogan}
              fullWidth={true}
              error={values.sloganError}
              helperText={values.sloganErrorMessage}
            />
            <FormControl
              error={values.areaIdError}
              variant="outlined"
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="association-areaId">
                {t('pages.associations.form.area')}
              </InputLabel>
              <Select
                value={values.areaId || ''}
                onChange={handleChange('areaId')}
                input={
                  <OutlinedInput
                    labelWidth={locale === 'fr'
                      ? 55
                      : 34}
                    name="area"
                    id="association-areaId"
                  />
                }
                inputProps={{
                  name: 'area',
                  id: 'association-areaId',
                }}
              >
                {areas.map((area) => (
                  <MenuItem value={area.id} key={`area-${area.id}`}>
                    {area.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="association-areaId-error-text">
                {values.areaIdErrorMessage}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          onClick={handleClose}
          disabled={loading}
          className={dialogFormClasses.button}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={dialogFormClasses.button}
          disabled={loading}
        >
          {values.associationId
            ? t('common.edit')
            : t('common.add')}
          {loading
            && <CircularProgress size={24} className={dialogFormClasses.buttonProgress} />}
        </Button>
      </DialogActions>
    </form>
  );
}

AssociationForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  association: PropTypes.object,
};

AssociationForm.defaultProps = {
  association: null,
};
