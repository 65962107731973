import { NAV_HEIGHT } from '../constants/style';
import { SPORTIIZ_WHITE } from '../constants/colors';

export default function listPageStyles(theme) {
  return ({
    box: {
      position: 'absolute',
      width: `calc(100% - ${NAV_HEIGHT})`,
      top: 0,
      left: 0,
      margin: `40px 0 0 ${NAV_HEIGHT}`,
      backgroundColor: SPORTIIZ_WHITE,
      scrollbarWidth: 'none',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: `${NAV_HEIGHT} 0 0 0`,
      },
    },
    container: {
      overflow: 'scroll',
      scrollbarWidth: 'none',
    },
    title: {
      marginTop: '8px',
      marginBottom: '15px',
    },
    addButton: {
      marginTop: '8px',
      marginBottom: '15px',
    },
    fab: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      fontSize: '2em',
    },
  });
}
