import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const ChevronDownIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Chevron down">
      <path
        d="M12,15.5 C11.744,15.5 11.488,15.402 11.293,15.207 L7.293,11.207 C6.902,10.816 6.902,10.184 7.293,9.793 C7.684,9.402 8.316,9.402 8.707,9.793 L12.012,13.098 L15.305,9.918 C15.704,9.535 16.335,9.546 16.719,9.943 C17.103,10.34 17.092,10.974 16.695,11.357 L12.695,15.219 C12.5,15.407 12.25,15.5 12,15.5"
        data-name="chevron-down"
        fill={color}
      />
    </g>
  </svg>
);
ChevronDownIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ChevronDownIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};

export default ChevronDownIcon;
