import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { auth } from '../../firebase';
import { HOME, PASSWORD_FORGET } from '../../constants/routes';
import { handleError } from '../../store/infos/actions';
import { SPORTIIZ_GREY, SPORTIIZ_RED } from '../../constants/colors';

const INITIAL_STATE = {
  email: '',
  password: '',
  showPassword: false,
};

export const useStyles = makeStyles(() => ({
  field: {
    marginTop: '10px',
  },
  button: {
    padding: '.5rem .75rem',
    margin: '20px auto 0',
  },
  buttonProgress: {
    color: SPORTIIZ_RED,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  smallLink: {
    verticalAlign: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    float: 'right',
    color: SPORTIIZ_GREY,
    cursor: 'pointer',
  },
}));

export default function SignInForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const client = useApolloClient();
  client.resetStore();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPassword = () => {
    history.push(PASSWORD_FORGET);
  };

  const submit = (event) => {
    setLoading(true);
    auth.doSignInWithEmailAndPassword(state.email, state.password)
      .then((authUser) => {
        history.push(HOME);
      }).catch((error) => {
        setState({ ...state, password: '' });
        setLoading(false);
        const handleErrorMessage = t('error.invalid_credentials');
        dispatch(handleError({ message: handleErrorMessage }));
      });
    event.preventDefault();
  };

  return (
    <form onSubmit={submit}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={9} md={7}>
          <TextField
            className={classes.field}
            fullWidth={true}
            label={t('common.email')}
            id="signin-email"
            type="text"
            onChange={handleChange('email')}
            margin="normal"
            value={state.email}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={9} md={7}>
          <TextField
            className={classes.field}
            fullWidth={true}
            id="signin-password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            label={t('common.password')}
            value={state.password}
            onChange={handleChange('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={9} md={7}>
          <Link onClick={handleForgotPassword} className={classes.smallLink} variant={'caption'}>
            {t('pages.sign_in.forgot_password')}
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant={'contained'}
            color={'primary'}
            disabled={loading}
            className={classes.button}
          >
            {t('pages.sign_in.button')}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
