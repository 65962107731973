import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_BLUE_GREY,
  SPORTIIZ_GREY_DARK,
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_RED,
} from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    chip: {
      padding: '.5em',
    },
    withLink: {
      '&:hover': {
        backgroundColor: SPORTIIZ_RED,
      },
      '&:active': {
        backgroundColor: SPORTIIZ_RED,
      },
      '&:focus': {
        backgroundColor: SPORTIIZ_RED,
      },
    },
    waiting: {
      backgroundColor: SPORTIIZ_BLUE_GREY,
    },
    requires_action: {
      backgroundColor: SPORTIIZ_BLUE_GREY,
    },
    succeeded: {
      backgroundColor: SPORTIIZ_GREY_GREEN,
    },
    failed: {
      backgroundColor: SPORTIIZ_RED,
    },
    refunded: {
      backgroundColor: SPORTIIZ_GREY_DARK,
    },
    free: {
      backgroundColor: SPORTIIZ_BLUE,
    },
  }
));
const PaymentStatusChip = ({
  status,
  stripeFailureCode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (stripeFailureCode) {
    const onChipClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (stripeFailureCode) {
        const url = `https://stripe.com/docs/error-codes#${stripeFailureCode.replace('_', '-')}`;
        window.open(url);
      }
    };
    return (
      <Tooltip title={t('paymentStatus.stripeError', { error: stripeFailureCode })}>
        <Chip
          size="small"
          className={`${classes.chip} ${classes[status]} ${classes.withLink}`}
          label={t(`paymentStatus.${status}`)}
          color="primary"
          onClick={onChipClick}
          icon={<Link />}
        />
      </Tooltip>
    );
  }
  return (
    <Chip
      size="small"
      className={`${classes.chip} ${classes[status]}`}
      label={t(`paymentStatus.${status}`)}
      color="primary"
    />
  );
};
PaymentStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  stripeFailureCode: PropTypes.string,
};
export default PaymentStatusChip;
