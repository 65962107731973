import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import ActivityCard from './ActivityCard';
import dialogFormStyle from '../../styles/dialogForm';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

const useStyles = makeStyles(() => (
  {
    gridRow: {
      width: '100%',
      margin: 'auto',
      paddingBottom: '76px',
    },
  }
));

export default function ActivityList(props) {
  const {
    activities,
    associationId,
    companyId,
    eventId,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  let spacing = 8;
  if (matchesSM) {
    spacing = 2;
  } else if (matchesMD) {
    spacing = 4;
  }

  const sortIsActive = (elem1, elem2) => {
    if (elem1.isActivated > elem2.isActivated) {
      return -1;
    }
    return 0;
  };

  return (
    <Grid container justifyContent="flex-start" spacing={spacing} className={classes.gridRow}>
      {activities
        && activities.sort(sortIsActive).map((elem) => (
          <ActivityCard
            activity={elem}
            key={elem.id}
            associationId={associationId}
            companyId={companyId}
            eventId={eventId}
          />
        ))
      }
    </Grid>
  );
}

ActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
  associationId: PropTypes.string,
  companyId: PropTypes.string,
  eventId: PropTypes.string,
};
