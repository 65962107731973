import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const PlusIcon = ({
  color,
  width,
  height,
  className,
  onClick,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height} className={className} onClick={onClick}>
    <g data-name="Layer 2">
      <path
        d="M19 11h-6V5a1 1 0 00-2 0v6H5a1 1 0 000 2h6v6a1 1 0 002 0v-6h6a1 1 0 000-2z"
        data-name="plus"
        fill={color}
      />
    </g>
  </svg>
);

PlusIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

PlusIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
  className: '',
};

export default PlusIcon;
