import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../layout/TransitionAnimation';
import CompanyForm from './CompanyForm';
import AppDialogTitle from '../layout/AppDialogTitle';

export default function CompanyFormDialog(props) {
  const {
    onClose,
    open,
    update,
    company,
    areas,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={matches}
      open={open}
      scroll="body"
      onClose={onClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches
        ? 500
        : 750}
    >
      <AppDialogTitle
        title={company === null
          ? t('pages.companies.dialogTitleAdd')
          : t('pages.companies.dialogTitleEdit')
        }
        handleClose={onClose}
      />
      <CompanyForm
        handleClose={onClose}
        update={update}
        company={company}
        areas={areas}
      />
    </Dialog>
  );
}

CompanyFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  company: PropTypes.object,
};

CompanyFormDialog.defaultProps = {
  company: null,
};
