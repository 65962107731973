import React, { useState } from 'react';
import { useParams } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import useMember from './UseMember';
import Sidebar from '../../../components/layout/sidebar/Sidebar';
import detailPageStyles from '../../../styles/detailPage';
import { getAvatarLetter, getDisplayName } from '../../../services/userService';
import ErrorServer from '../../ErrorServer';
import { Loading } from '../../../components/layout/Loading';
import withAuthorization from '../../../hoc/withAuthorization';
import CompanySubscriptionCard from '../../../components/companies/subscriptions/CompanySubscriptionCard';
import useCompany from '../../../components/hooks/UseCompany';
import { CARD_RADIUS } from '../../../constants/style';
import EmailIcon from '../../../components/icon/EmailIcon';
import { SPORTIIZ_BLUE_GREY_DARK, SPORTIIZ_GREY_GREEN } from '../../../constants/colors';
import PhoneIcon from '../../../components/icon/PhoneIcon';
import PersonIcon from '../../../components/icon/PersonIcon';
import { isNotEmpty } from '../../../services/stringService';
import CalendarIcon from '../../../components/icon/CalendarIcon';
import NumbersBlueSection from '../../../components/layout/numbersBlueSection/NumbersBlueSection';
import DashboardIcon from '../../../components/icon/DashboardIcon';
import EditIcon from '../../../components/icon/EditIcon';
import StarIcon from '../../../components/icon/StarIcon';
import MemberBookingArray from '../../../components/companies/members/MemberBookingArray/MemberBookingArray';
import CompanyMemberFormDialog
  from '../../../components/companies/members/CompanyMemberForm/CompanyMemberFormDialog';

const useStyles = makeStyles(() => (
  {
    avatar: {
      width: '180px',
      height: '180px',
    },
    paper: {
      position: 'relative',
      borderRadius: CARD_RADIUS,
      height: 'auto',
      textAlign: 'left',
      padding: '19px 31px',
      minWidth: '320px',
    },
    cardText: {
      color: SPORTIIZ_BLUE_GREY_DARK,
      fontWeight: 600,
    },
    cardTextLink: {
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'normal',
      whiteSpace: 'nowrap',
      color: 'inherit',
      verticalAlign: 'center',
    },
    cardTextIcon: {
      margin: '5px 5px 5px 0',
      verticalAlign: 'middle',
    },
    infoContainer: {
      marginBottom: '30px',
    },
    editButton: {
      position: 'absolute',
      top: 12,
      right: 12,
      display: 'block',
      padding: 0,
    },
  }
));
const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const CompanyMemberPage = () => {
  const {
    id,
    memberId,
  } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const detailPageClasses = useDetailPageStyles();
  const [open, setOpen] = useState(false);
  const {
    member,
    loading,
    error,
    memberBookings,
    bookingError,
    refetchAll,
  } = useMember(memberId, id);
  const {
    companySubscription,
  } = useCompany(id);
  if (error) {
    return <ErrorServer error={error} />;
  }
  if (bookingError) {
    return <ErrorServer error={bookingError} />;
  }
  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpen(false);
  };
  const handleOpen = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpen(true);
  };

  const memberLoaded = member && member.id === memberId;

  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.box} component="section">
        <Container maxWidth={false}>
          <Typography variant="h1">
            {getDisplayName(member)}
          </Typography>
        </Container>

        <Container maxWidth={false} className={classes.infoContainer}>
          <Grid
            container
            spacing={0}
            direction={matches ? 'column' : 'row'}
            justifyContent={loading ? 'center' : 'flex-start'}
            alignItems={matches ? 'center' : 'flex-start'}
            style={{ gap: '20px' }}
          >
            {loading && <Grid item><Loading /></Grid>}
            {!loading && <>
              <Grid item>
                {member.picture !== null
                  ? <Avatar
                    className={classes.avatar}
                    src={member.picture}
                    title="avatar"
                  />
                  : <Avatar className={classes.avatar}>
                    {getAvatarLetter(member)}
                  </Avatar>
                }
              </Grid>
              <Grid item style={{ flexGrow: 2 }}>
                <CompanySubscriptionCard
                  currentCompanySubscription={companySubscription}
                />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h5" style={{ fontWeight: '700' }}>
                    {t('pages.companyMembers.memberInformation')}
                  </Typography>
                  <IconButton
                    aria-label="open edit form dialog"
                    onClick={handleOpen}
                    className={classes.editButton}
                  >
                    <EditIcon width="20px" height="20px" />
                  </IconButton>
                  <Typography variant="subtitle1" component="p" className={classes.cardText}>
                    <PersonIcon
                      color={SPORTIIZ_BLUE_GREY_DARK}
                      className={classes.cardTextIcon}
                      width="16px"
                      height="16px"
                    />
                    {`${member.firstname}${member.firstname ? ' ' : ''}${member.lastname}`}
                    {!isNotEmpty(member.firstname) && !isNotEmpty(member.lastname) && '-'}
                  </Typography>
                  <Typography variant="subtitle1" component="p" className={classes.cardText}>
                    <CalendarIcon
                      color={SPORTIIZ_BLUE_GREY_DARK}
                      className={classes.cardTextIcon}
                      width="16px"
                      height="16px"
                    />
                    {t('Né(e) le ')}
                    {member.birthday || '-'}
                  </Typography>
                  <Typography variant="subtitle1" component="p" className={classes.cardText}>
                    <a
                      href={`mailto:${member.email}?subject=Sportiiz`}
                      className={classes.cardTextLink}
                      aria-label="email"
                    >
                      <EmailIcon
                        color={SPORTIIZ_BLUE_GREY_DARK}
                        className={classes.cardTextIcon}
                        width="16px"
                        height="16px"
                      />
                      {member.email}
                    </a>
                  </Typography>
                  <Typography variant="subtitle1" component="p" className={classes.cardText}>
                    <a
                      href={`tel:${member.phone}`}
                      className={classes.cardTextLink}
                      aria-label="phone"
                    >
                      <PhoneIcon
                        color={SPORTIIZ_BLUE_GREY_DARK}
                        className={classes.cardTextIcon}
                        width="16px"
                        height="16px"
                      />
                      {isNotEmpty(member.phone) ? member.phone : '-'}
                    </a>
                  </Typography>
                </Paper>
              </Grid>
            </>}
          </Grid>
        </Container>
        <NumbersBlueSection
          numbers={[
            {
              icon: <DashboardIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />,
              number: memberLoaded ? member.activityCount : '-',
              text: t('pages.companyMembers.activityCount', { count: member.activityCount ?? 0 }),
            },
            {
              icon: <EditIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />,
              number: memberLoaded ? member.commentCount : '-',
              text: t('pages.companyMembers.commentCount', { count: member.commentCount ?? 0 }),
            },
            {
              icon: <StarIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />,
              number: `${memberLoaded ? member.satisfactionRatingAvg : '-'}/5`,
              text: t('pages.companyMembers.satisfactionRatingAvg'),
            },
            {
              icon: <CalendarIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />,
              number: memberLoaded ? member.timeSlotCount : '-',
              text: t('pages.companyMembers.timeSlotCount', { count: member.timeSlotCount ?? 0 }),
            },
          ]}
        />
        <Box component="section" className={detailPageClasses.footerBox}>
          <Container maxWidth={false} className={detailPageClasses.footerContainer}>
            <Typography variant={'h6'}>
              {t('pages.companyMembers.lastTimeSlots')}
            </Typography>
          </Container>
          <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
            {loading && (
              <Loading />
            )}
            {!loading && (
              <MemberBookingArray bookings={memberBookings} />
            )}
          </Container>
        </Box>
      </Box>
      <CompanyMemberFormDialog
        open={open}
        handleClose={handleClose}
        member={member}
        update={refetchAll}
      />
    </div>
  );
};

export default withAuthorization()(CompanyMemberPage);
