export function getAvatarLetter(user) {
  if (user.lastname || user.firstname) {
    const toReduce = [];
    if (user.firstname) {
      toReduce.push(user.firstname.charAt(0));
    }
    if (user.lastname) {
      toReduce.push(user.lastname.charAt(0));
    }
    return toReduce.join('').toUpperCase();
  } if (user.nickname) {
    return user.nickname.charAt(0).toUpperCase();
  } if (user.email) {
    return user.email.charAt(0).toUpperCase();
  }
  return '';
}

export function getDisplayName(user, ifNoName = '') {
  const names = [];
  if (user.firstname !== null && user.firstname !== '') {
    names.push(user.firstname);
  }
  if (user.lastname !== null && user.lastname !== '') {
    names.push(user.lastname);
  }
  const fullname = (names.length > 0) ? names.join(' ') : null;
  if (fullname !== null) {
    return fullname;
  } if (user.nickname !== null && user.nickname !== '') {
    return user.nickname;
  } if (user.email !== null && user.email !== '') {
    return user.email;
  }
  return ifNoName;
}

export function validateEmail(email) {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isSuperAdmin(authUser) {
  if (!authUser || !authUser.role) {
    return false;
  }
  return authUser.role.trim() === 'super_admin';
}

export function isAssociationAdmin(authUser) {
  if (!authUser || !authUser.associationAdminIds) {
    return false;
  }
  return authUser.associationAdminIds.length > 0;
}

export function isCompanyAdmin(authUser) {
  if (!authUser || !authUser.companyAdminIds) {
    return false;
  }
  return authUser.companyAdminIds.length > 0;
}
