import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core';
import Transition from '../layout/TransitionAnimation';
import dialogFormStyle from '../../styles/dialogForm';
import RewardForm from '../forms/RewardForm';
import AppDialogTitle from '../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

export default function RewardFormDialog(props) {
  const {
    open,
    onClose,
    update,
    reward,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={matches}
      open={open}
      scroll="body"
      onClose={onClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches
        ? 500
        : 750}
    >
      <AppDialogTitle
        title={reward === null
          ? t('pages.rewards.dialogTitleAdd')
          : t('pages.rewards.dialogTitleEdit')
        }
        handleClose={onClose}
      />
      <DialogContent>
        <RewardForm handleClose={onClose} update={update} reward={reward} />
      </DialogContent>
    </Dialog>
  );
}

RewardFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  reward: PropTypes.object,
};

RewardFormDialog.defaultProps = {
  reward: null,
};
