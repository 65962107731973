import React from 'react';
import 'firebase/auth';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import 'moment-timezone';
import 'moment/locale/fr';
import './App.css';
import HomePage from './HomePage';
import Error404 from './Error404';
import SignInPage from './user/SignInPage';
import AssociationPage from './association/AssociationPage';
import AssociationsPage from './association/AssociationsPage';
import CompanyPage from './company/CompanyPage/CompanyPage';
import CompaniesPage from './company/CompaniesPage';
import PasswordForgetPage from './user/PasswordForgetPage';
import AssociationCoachesPage from './association/AssociationCoachesPage/AssociationCoachesPage';
import AssociationAdministratorsPage from './association/AssociationAdministratorsPage/AssociationAdministratorsPage';
import ActivitiesPage from './association/ActivitiesPage';
import ActivityPage from './association/ActivityPage';
import AddActivityPage from './association/AddActivityPage';
import EditActivityPage from './association/EditActivityPage';
import TimeSlotsPage from './association/AssociationTimeSlotsPage';
import CompanyMembersPage from './company/CompanyMembersPage/CompanyMembersPage';
import RewardPage from './reward/RewardsPage';
import GamesPage from './game/GamesPage';
import GamePage from './game/GamePage';
import * as routes from '../constants/routes';
import SnackBar from '../components/layout/SnackBar';
import withTheme from '../hoc/withTheme';
import withAuthentication from '../hoc/withAuthentication';
import Nav from '../components/layout/nav/Nav';
import EmailActionHandlersPage from './firebase/EmailActionHandlersPage';
import CompanyActivitiesPage from './company/CompanyActivitiesPage';
import CompanyActivityPage from './company/CompanyActivityPage';
import EventsPage from './event/EventsPage';
import IndividualsPage from './individual/IndividualsPage';
import EventDetailsPage from './event/EventDetailsPage';
import CompanyAdminsPage from './company/CompanyAdminsPage/CompanyAdminsPage';
import EditEventPage from './event/EditEventPage';
import EventParticipantsPage from './event/EventParticipantsPage';
import EventActivitiesPage from './event/EventActivitiesPage';
import EventActivityPage from './event/EventActivityPage';
import EventTimeSlotsPage from './event/EventTimeSlotsPage';
import CompanyTimeSlotsPage from './company/CompanyTimeSlotsPage';
import { SectionProvider } from '../components/contexts/SectionContext';
import CompanyMemberPage from './company/CompanyMemberPage/CompanyMemberPage';
import AssociationBookingsPage from './association/AssociationBookingsPage';

const useStyles = makeStyles(() => (
  {
    container: {
      maxWidth: '100vw',
      padding: 0,
    },
  }
));

export function App() {
  const { authUser } = useSelector((state) => state.user);
  const classes = useStyles();
  return (
    <div className="App">
      <SectionProvider>
        <SnackBar />
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
          {authUser && <Nav />}
          <Container className={classes.container}>
            <Switch>
              <Route path={routes.SIGN_IN} component={SignInPage} />
              <Route path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
              <Route
                path={routes.FIREBASE_EMAIL_ACTION_HANDLERS}
                component={EmailActionHandlersPage}
              />
              <Route path={routes.ASSOCIATION_COACHES} component={AssociationCoachesPage} />
              <Route
                path={routes.ASSOCIATION_ADMINISTRATORS}
                component={AssociationAdministratorsPage}
              />
              <Route path={routes.ASSOCIATION_ADD_ACTIVITY} component={AddActivityPage} />
              <Route path={routes.ASSOCIATION_EDIT_ACTIVITY} component={EditActivityPage} />
              <Route path={routes.ASSOCIATION_ACTIVITY} component={ActivityPage} />
              <Route path={routes.ASSOCIATION_ACTIVITIES} component={ActivitiesPage} />
              <Route path={routes.ASSOCIATION_TIME_SLOTS} component={TimeSlotsPage} />
              <Route path={routes.ASSOCIATION_BOOKINGS} component={AssociationBookingsPage} />
              <Route path={routes.ASSOCIATION} component={AssociationPage} />
              <Route exact path={routes.ASSOCIATIONS} component={AssociationsPage} />
              <Route
                path={routes.COMPANY_ADMINISTRATORS}
                component={CompanyAdminsPage}
              />
              <Route exact path={routes.COMPANY_MEMBERS} component={CompanyMembersPage} />
              <Route path={routes.COMPANY_MEMBER} component={CompanyMemberPage} />
              <Route path={routes.COMPANY_ACTIVITY} component={CompanyActivityPage} />
              <Route path={routes.COMPANY_ACTIVITIES} component={CompanyActivitiesPage} />
              <Route path={routes.COMPANY_TIMESLOTS} component={CompanyTimeSlotsPage} />
              <Route path={routes.COMPANY} component={CompanyPage} />
              <Route exact path={routes.COMPANIES} component={CompaniesPage} />
              <Route exact path={routes.EVENTS} component={EventsPage} />
              <Route exact path={routes.EVENT_ACTIVITIES} component={EventActivitiesPage} />
              <Route exact path={routes.EVENT_ACTIVITY} component={EventActivityPage} />
              <Route exact path={routes.EVENT} component={EventDetailsPage} />
              <Route exact path={routes.EVENT_EDIT} component={EditEventPage} />
              <Route exact path={routes.EVENT_PARTICIPANTS} component={EventParticipantsPage} />
              <Route exact path={routes.EVENT_TIME_SLOTS} component={EventTimeSlotsPage} />
              <Route path={routes.REWARDS} component={RewardPage} />
              <Route path={routes.GAME} component={GamePage} />
              <Route path={routes.GAMES} component={GamesPage} />
              <Route path={routes.INDIVIDUALS} component={IndividualsPage} />
              <Route exact path={routes.HOME} component={HomePage} />
              <Route component={Error404} />
            </Switch>
          </Container>
        </Box>
      </SectionProvider>
    </div>
  );
}

export default compose(
  withAuthentication(),
  withTheme,
)(App);
