import { LIST_ITEM_RADIUS, NAV_HEIGHT } from '../constants/style';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY_DARK,
  SPORTIIZ_GREY_LIGHT,
  SPORTIIZ_RED,
  SPORTIIZ_WHITE,
} from '../constants/colors';

export default function superAdminPageStyles(theme) {
  return ({
    content: {
      width: '100%',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      [theme.breakpoints.up('lg')]: {
        marginTop: '140px',
      },
    },
    boxSimple: {
      position: 'absolute',
      width: `calc(100% - ${NAV_HEIGHT})`,
      top: 0,
      left: 0,
      margin: `0 0 0 ${NAV_HEIGHT}`,
      backgroundColor: SPORTIIZ_WHITE,
      scrollbarWidth: 'none',
      overflow: 'scroll',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: `${NAV_HEIGHT} 0 0 0`,
      },
    },
    header: {
      [theme.breakpoints.up('lg')]: {
        borderBottom: `1px solid ${SPORTIIZ_GREY_LIGHT}`,
        position: 'fixed',
        backgroundColor: SPORTIIZ_WHITE,
        zIndex: 12,
        width: `calc(100% - ${NAV_HEIGHT})`,
        paddingLeft: '10vw',
        paddingRight: '10vw',
      },
    },
    fab: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      fontSize: '2em',
      zIndex: 10,
    },
    nav: {
      background: SPORTIIZ_WHITE,
      width: '100%',
      bottom: 0,
      zIndex: 98,
      textAlign: 'left',
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      display: 'inline-block',
      margin: '10px 0',
    },
    link: {
      display: 'block',
      color: SPORTIIZ_GREY_DARK,
      padding: '0 20px',
      [theme.breakpoints.down('md')]: {
        padding: '0 10px',
        letterSpacing: '0px',
      },
      '&:hover': {
        color: SPORTIIZ_BLUE,
        textDecoration: 'none',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: LIST_ITEM_RADIUS,
      },
    },
    linkActive: {
      color: SPORTIIZ_BLUE,
      position: 'relative',
      '&:after': {
        content: '\'\'',
        background: SPORTIIZ_RED,
        height: '3px',
        width: '80%',
        position: 'absolute',
        bottom: '-3px',
        left: '10%',
        borderRadius: '1.5px',
      },
    },
  });
}
