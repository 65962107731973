import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../layout/TransitionAnimation';
import AssociationForm from './AssociationForm';
import dialogFormStyle from '../../styles/dialogForm';
import AppDialogTitle from '../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

export default function AssociationFormDialog(props) {
  const {
    onClose,
    open,
    update,
    association,
    areas,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      fullScreen={matches}
      open={open}
      scroll="body"
      onClose={handleClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={
        matches
          ? 500
          : 750
      }
    >
      <AppDialogTitle
        title={association === null
          ? t('pages.associations.dialogTitleAdd')
          : t('pages.associations.dialogTitleEdit')
        }
        handleClose={handleClose}
      />
      <AssociationForm
        handleClose={handleClose}
        update={update}
        association={association}
        areas={areas}
      />
    </Dialog>
  );
}

AssociationFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  association: PropTypes.object,
};

AssociationFormDialog.defaultProps = {
  association: null,
};
