import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const CompanyIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Layer 2">
      <path
        d="M19 7h-3V5.5A2.5 2.5 0 0013.5 3h-3A2.5 2.5 0 008 5.5V7H5a3 3 0 00-3 3v8a3 3 0 003 3h14a3 3 0 003-3v-8a3 3 0 00-3-3zm-4 2v10H9V9zm-5-3.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V7h-4zM4 18v-8a1 1 0 011-1h2v10H5a1 1 0 01-1-1zm16 0a1 1 0 01-1 1h-2V9h2a1 1 0 011 1z"
        data-name="briefcase"
        fill={color}
      />
    </g>
  </svg>
);

CompanyIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CompanyIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};

export default CompanyIcon;
