import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import { SPORTIIZ_WHITE } from '../../../constants/colors';

const BlueTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: SPORTIIZ_WHITE,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    padding: '6px 12px 2px',
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
export default BlueTab;
