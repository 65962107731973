import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import SignInForm from '../../components/forms/SignInForm';
import LeftDescriptionBackground from '../../components/layout/LeftDescriptionBackground';
import logo from '../../resources/sportiiz/logoWithTitle.svg';
import anonymousPageStyles from '../../styles/anonymousPage';
import { firebase } from '../../firebase';
import { HOME } from '../../constants/routes';
import ChevronLeftIcon from '../../components/icon/ChevronLeftIcon';
import { SPORTIIZ_GREY_GREEN } from '../../constants/colors';

const useAnonymousPageStyles = makeStyles(anonymousPageStyles);

export default function SignInPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const anonymousPageClasses = useAnonymousPageStyles();
  if (!firebase.auth.currentUser) {
    return (
      <div>
        <LeftDescriptionBackground />
        <Container className={anonymousPageClasses.container} maxWidth="xs">
          <div style={{ margin: 'auto', width: '350px' }}>
            <Link
              href={'https://sportiiz.fr/'}
              className={anonymousPageClasses.link}
              variant={'subtitle2'}
            >
              <ChevronLeftIcon color={SPORTIIZ_GREY_GREEN} height={'30px'} width={'30px'} />
              {t('pages.sign_in.returnToSportiiz')}
            </Link>
          </div>
          <img
            alt="background"
            src={logo}
            className={anonymousPageClasses.logo}
            height={'80'}
          />
          <Typography variant="h3" gutterBottom>
            {t('pages.sign_in.title')}
          </Typography>
          <SignInForm />
        </Container>
      </div>);
  }
  history.push(HOME);
  return null;
}
