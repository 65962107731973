import React from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import withAuthorization from '../../hoc/withAuthorization';
import { TYPE_COMPANY, TYPE_GLOBAL } from '../../constants/gameTypes';
import Loading from '../../components/layout/Loading';
import {
  QUERY_COMPANY_GAME,
  QUERY_GLOBAL_GAME,
  QUERY_INDIVIDUAL_GAME,
} from '../../constants/graphQLQueries';
import ErrorServer from '../ErrorServer';
import superAdminPageStyles from '../../styles/superAdminPage';
import { useStyles as useGameCardStyles } from '../../components/games/GameCard';
import Error404 from '../Error404';
import CalendarIcon from '../../components/icon/CalendarIcon';
import { SPORTIIZ_GREY_GREEN } from '../../constants/colors';
import { getDayFromTimestamp } from '../../services/dateService';
import CheckCircleIcon from '../../components/icon/CheckCircleIcon';
import StepsList from '../../components/games/StepsList';

const useSuperAdminPageStyles = makeStyles((theme) => (superAdminPageStyles(theme)));
const INITIAL_VALUES = {
  start: 0,
  end: 0,
  stepsCount: 0,
  type: '',
  status: '',
};

export function GamePage() {
  const { id, type } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const { fromGame } = location;
  const superAdminPageClasses = useSuperAdminPageStyles();
  const gameClasses = useGameCardStyles();
  const client = useApolloClient();
  const [game, setGame] = React.useState(fromGame || {
    ...INITIAL_VALUES,
    id,
    title: t('pages.game.defaultTitle'),
  });
  const [isSet, setIsSet] = React.useState(false);
  const locale = getI18n().language.slice(0, 2);
  let query;
  switch (type) {
    case TYPE_GLOBAL:
      query = QUERY_GLOBAL_GAME;
      break;
    case TYPE_COMPANY:
      query = QUERY_COMPANY_GAME;
      break;
    default:
      query = QUERY_INDIVIDUAL_GAME;
      break;
  }
  const { loading, error, data } = useQuery(query, {
    variables: { id },
    client,
  });

  let content = (<Loading />);
  if (error) {
    content = (<ErrorServer error={error} />);
  } else if (
    (!data || (!data.individualGame && !data.companyGame && !data.globalGame))
    && !loading
  ) {
    content = (<Error404 />);
  } else if ((!loading && (data.individualGame || data.companyGame || data.globalGame)) && !isSet) {
    const gameFromServer = (data.individualGame || data.companyGame || data.globalGame);
    setGame(gameFromServer);
    setIsSet(true);
  } else if (!loading && isSet) {
    content = (<StepsList game={game} />);
  }

  return (
    <Box className={superAdminPageClasses.boxSimple} component="section">
      <Container maxWidth={false} className={superAdminPageClasses.header}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h1">
              {game.title}
              <Typography variant="body1" component={'span'} style={{ marginLeft: '1em' }}>
                {game.status && t(`pages.game.${game.status}`)}
              </Typography>
            </Typography>

            <Typography variant="h6" className={gameClasses.gameType}>
              {t(game.type)}
            </Typography>

            <List component="nav">
              <ListItem className={gameClasses.listItem}>
                <ListItemIcon className={gameClasses.iconContainer}>
                  <CalendarIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t('pages.games.dateFrom')}
                    {getDayFromTimestamp(locale, game.start)}
                    {t('pages.games.dateTo')}
                    {getDayFromTimestamp(locale, game.end)}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem className={gameClasses.listItem}>
                <ListItemIcon className={gameClasses.iconContainer}>
                  <CheckCircleIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t('pages.games.cardSteps', { count: game.stepsCount })}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>

          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        className={superAdminPageClasses.content}
        style={{ marginTop: '230px' }}
      >
        {content}
      </Container>
    </Box>
  );
}

export default withAuthorization()(GamePage);
