import React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { QUERY_EVENT } from '../../constants/graphQLQueries';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import { Loading } from '../../components/layout/Loading';
import detailPageStyles from '../../styles/detailPage';
import withAuthorization from '../../hoc/withAuthorization';
import EventParticipants from '../../components/events/participants/EventParticipants';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const EventParticipantsPage = () => {
  const client = useApolloClient();
  const detailPageClasses = useDetailPageStyles();
  const { id } = useParams();
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_EVENT, {
    variables: { id },
    client,
  });
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        {loading
          && (
            <Container maxWidth={false} className={detailPageClasses.content}>
              <Loading />
            </Container>
          )}
        {!loading && data && data.event && <EventParticipants event={data.event} />}
        {!loading && (!data || !data.event) && <Error404 />}
        {!loading && error && <ErrorServer error={error} />}
      </Box>
    </div>
  );
};

export default withAuthorization()(EventParticipantsPage);
