import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import PasswordForgetForm from '../../components/forms/PasswordForgetForm';
import LeftDescriptionBackground from '../../components/layout/LeftDescriptionBackground';
import anonymousPageStyles from '../../styles/anonymousPage';
import logo from '../../resources/sportiiz/logoWithTitle.svg';

const useAnonymousPageStyles = makeStyles(anonymousPageStyles);

export default function PasswordForgetPage() {
  const { t } = useTranslation();
  const anonymousPageClasses = useAnonymousPageStyles();
  return (
    <div>
      <LeftDescriptionBackground />
      <Container className={anonymousPageClasses.container} maxWidth="xs">
        <img
          alt="background"
          src={logo}
          className={anonymousPageClasses.logo}
          height={'100'}
        />
        <Typography variant="h3" gutterBottom>
          {t('pages.forgot_password.title')}
        </Typography>
        <PasswordForgetForm />
      </Container>
    </div>
  );
}

