import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SPORTIIZ_GREY_GREEN } from '../../../constants/colors';
import SignOutIcon from '../../icon/SignOutIcon';

const useStyles = makeStyles((theme) => (
  {
    logo: {
      width: '60px',
      height: '60px',
      margin: '5px',
      float: 'left',
      transform: 'rotate(90deg)',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        float: 'right',
        transform: 'rotate(0deg)',
        margin: '5px 0',
      },
    },
    avatar: {
      height: '40px',
      width: '40px',
      zIndex: 100,
      margin: '10px',
    },
    popover: {
      border: 0,
      padding: '15px 10px',
      minWidth: '270px',
      height: 'auto',
      maxHeight: '350px',
      zIndex: 999,
    },
  }
));

export default function UserIcon(props) {
  const { authUser, logOut } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open
    ? 'simple-popover'
    : undefined;

  return (
    <div className={classes.logo}>
      {authUser.picture !== null
        ? <Avatar
          className={classes.avatar}
          alt={authUser.displayName}
          src={authUser.picture}
          onClick={handleClick}
        />
        : <Avatar className={classes.avatar} onClick={handleClick}>
          {authUser.displayName}
        </Avatar>
      }

      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: matches
            ? 'bottom'
            : 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: matches
            ? 'top'
            : 'bottom',
          horizontal: 'right',
        }}
      >
        <List component="nav">
          <ListItem button onClick={logOut}>
            <ListItemIcon>
              <SignOutIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
            </ListItemIcon>
            <ListItemText>
              <Typography color={'primary'}>{t('common.sign_out')}</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}

UserIcon.propTypes = {
  authUser: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
};
