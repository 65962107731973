import {
  ERROR, ERROR_RESET, INFO, INFO_RESET,
} from './types';

// Actions creator

export function resetInfoAction() {
  return { type: INFO_RESET };
}

export function genericInfoAction(info) {
  return {
    type: INFO,
    payload: info,
  };
}

export function resetErrorAction() {
  return { type: ERROR_RESET };
}

export function genericErrorAction(error) {
  return {
    type: ERROR,
    payload: error,
  };
}

// Actions

export function resetError() {
  return (dispatch) => {
    dispatch(resetErrorAction());
  };
}

export function resetInfo() {
  return (dispatch) => {
    dispatch(resetInfoAction());
  };
}

export function dispatchInfo(info) {
  return (dispatch) => {
    dispatch(genericInfoAction(info));
  };
}

export function handleError(error, type = null) {
  return (dispatch) => {
    const event = {
      type,
      payload: 'Impossible de réaliser cette action, veuillez réessayer ultérieurement.',
    };
    if (error) {
      if (typeof error === 'string') {
        event.payload = error;
      } else if (error.response) {
        if (error.response.status === 401) {
          event.payload = 'Vous devez être authentifier pour effectuer cette action.';
        } else if (error.response.status === 403) {
          event.payload = 'Vous n\'êtes pas autorisé à effectuer cette action.';
        } else if (error.response.status === 404) {
          event.payload = 'Contenu introuvable.';
        } else if (error.response.statusText) {
          event.payload = error.response.statusText;
        } else if (error.response.data && error.response.data.error) {
          event.payload = error.response.data.error;
        } else if (error.response.data) {
          event.payload = error.response.data;
        }
      } else if (error.message) {
        event.payload = error.message;
      }
    }

    // Dispatch real event error
    if (type) {
      dispatch(event);
    }

    // Dispatch generic error
    dispatch(genericErrorAction(event.payload));
  };
}
