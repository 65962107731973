import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import CompanyRow from './CompanyRow';

const useStyles = makeStyles(() => (
  {
    table: {
      minWidth: 0,
    },
  }
));

export default function CompaniesArray(props) {
  const {
    companies,
    handleEdit,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            {t('table.company.name')}
          </TableCell>
          <Hidden xsDown>
            <TableCell align="left">
              {t('table.company.area')}
            </TableCell>
          </Hidden>
          <Hidden smDown>
            <TableCell align="left">
              {t('table.company.subscription')}
            </TableCell>
          </Hidden>
          <TableCell align="left" />
        </TableRow>
      </TableHead>
      <TableBody>
        {companies.map((company) => (
          <CompanyRow company={company} handleEdit={handleEdit} key={company.id} />
        ))}
      </TableBody>
    </Table>
  );
}

CompaniesArray.propTypes = {
  companies: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
};
