import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { QUERY_BOOKINGS } from '../../../constants/graphQLQueries';

function useTimeSlotBooking(
  timeSlotId,
  companyId = null,
  associationId = null,
  onlyValidated = false,
) {
  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_BOOKINGS, {
    variables: {
      timeSlotId,
      companyId,
      associationId,
      onlyValidated,
    },
    client,
  });
  return {
    bookings: data?.timeSlotBookings ?? [],
    loading,
    error,
  };
}

export default useTimeSlotBooking;
