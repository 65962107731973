import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconTint from 'react-icon-tint';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import { ListItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreVerticalIcon from '../icon/MoreVerticalIcon';
import { SPORTIIZ_BLUE, SPORTIIZ_RED } from '../../constants/colors';
import {
  ASSOCIATION_ACTIVITY,
  ASSOCIATION_EDIT_ACTIVITY,
  COMPANY_ACTIVITY,
  EVENT_ACTIVITY,
} from '../../constants/routes';
import activityPaperStyles from '../../styles/activityPaper';
import PrivatiizBadge from './PrivatiizBadge';
import DisableBadge from './DisableBadge';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import { MUTATION_ACTIVITY_STATUS } from '../../constants/graphQLQueries';
import dialogFormStyle from '../../styles/dialogForm';

const useActivityPaperStyles = makeStyles(() => (
  {
    ...activityPaperStyles(),
    buttonProgress: {
      color: SPORTIIZ_RED,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }
));
export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

export default function ActivityCard(props) {
  const {
    activity,
    associationId,
    companyId,
    eventId,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [activityId, setActivityId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { hasSuperAdminRole } = useSelector((state) => state.user);

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useActivityPaperStyles();
  const dialogFormClasses = useDialogFormStyles();

  const [mutationActivity, { loading }] = useMutation(
    MUTATION_ACTIVITY_STATUS,
  );

  function onDetailLinkClick(event) {
    event.stopPropagation();
    event.preventDefault();
    if (associationId) {
      history.push(
        ASSOCIATION_ACTIVITY
          .replace(':id', associationId)
          .replace(':activityId', activity.id),
      );
    } else if (companyId) {
      history.push(
        COMPANY_ACTIVITY
          .replace(':id', companyId)
          .replace(':activityId', activity.id),
      );
    } else if (eventId) {
      history.push(
        EVENT_ACTIVITY
          .replace(':id', eventId)
          .replace(':activityId', activity.id),
      );
    }
  }

  function handleCardMenuClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    setActivityId(id);
    setAnchorEl(event.currentTarget);
  }

  function handleDetailsClick(event) {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
    if (associationId) {
      history.push(
        ASSOCIATION_ACTIVITY
          .replace(':id', associationId)
          .replace(':activityId', activity.id),
      );
    }
  }

  function handleEditClick(event) {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
    if (associationId) {
      history.push(ASSOCIATION_EDIT_ACTIVITY
        .replace(':id', associationId)
        .replace(':activityId', activity.id));
    }
  }

  function handleDisableClick(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpenDialog(true);
    setAnchorEl(null);
  }

  function handleChangeActivityStatus(event) {
    event.stopPropagation();
    event.preventDefault();
    let successMessage = t('pages.activities.activationSuccess');
    if (activity.isActivated) {
      successMessage = t('pages.activities.deactivationSuccess');
    }
    mutationActivity({
      variables: {
        activityId,
        isActivated: !activity.isActivated,
      },
    }).then(() => {
      onGraphQLSuccess(successMessage);
    }).catch((error) => {
      onGraphQLError(error);
    });
  }

  function onGraphQLSuccess(message) {
    setOpenDialog(null);
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  function handleClose(event) {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
    setActivityId(null);
  }

  function handleCloseDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpenDialog(null);
    setActivityId(null);
  }

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Paper
        className={classes.paper}
        elevation={0}
        onClick={onDetailLinkClick}
      >
        <div
          className={`${classes.pictureBackground} ${classes.paper} ${!activity.isActivated
            ? classes.disabled
            : ''}`}
          style={{ backgroundImage: `url(${activity.picture})` }}
        >
          <div className={`${classes.paper} ${classes.overlay} `} />
          {hasSuperAdminRole && associationId
            && <>
              <Popover
                className={classes.popover}
                id="list-itm-menu"
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <List component="nav" className={classes.menu}>
                  <ListItem button onClick={handleDetailsClick} className={classes.menuItem}>
                    {t('common.details')}
                  </ListItem>
                  <ListItem button onClick={handleEditClick} className={classes.menuItem}>
                    {t('common.edit')}
                  </ListItem>
                  <ListItem button onClick={handleDisableClick} className={classes.menuItem}>
                    {activity.isActivated
                      ? t('common.disable')
                      : t('common.activate')}
                  </ListItem>
                </List>
              </Popover>
            </>
          }
          {activity.isPrivatiiz
            && <div style={{ position: 'absolute' }}>
              {associationId && <PrivatiizBadge
                companyName={activity.privatiizCompany.name}
                marginLeft="0px"
              />}
              {companyId && <PrivatiizBadge />}
              {eventId && <PrivatiizBadge />}
            </div>
          }
        </div>
        <Grid
          container
          spacing={0}
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item>
            <div className={classes.paperTitle}>
              <Typography
                variant={'h6'}
                component={'div'}
                className={classes.typo}
                noWrap={true}
              >
                <IconTint
                  src={activity.sport.picture}
                  color="#FFFFFF"
                  maxHeight="35"
                  maxWidth="35"
                />
                {activity.name}
              </Typography>
              {associationId
                && activity.subtitle
                && <Typography variant="subtitle1" className={classes.subTypo} noWrap={true}>
                  {activity.subtitle}
                </Typography>
              }
              {companyId
                && activity.association
                && <Typography variant="subtitle1" className={classes.subTypo} noWrap={true}>
                  {activity.association.name}
                </Typography>
              }
              {activity.isDiscovery
                && <Chip
                  size="small"
                  className={classes.chip}
                  label="Découverte"
                  color="default"
                />
              }
            </div>
          </Grid>
        </Grid>
        <div className={classes.iconButtonContainer}>
          {hasSuperAdminRole && associationId
            && <IconButton
              size="small"
              aria-label="more"
              onClick={(event) => handleCardMenuClick(event, activity.id)}
              className={classes.moreIcon}
            >
              <MoreVerticalIcon
                height="19px"
                width="19px"
                color={SPORTIIZ_BLUE}
              />
            </IconButton>
          }
        </div>
        {!activity.isActivated
          && <div className={classes.disableIcon}>
            <DisableBadge />
          </div>
        }
      </Paper>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-confirm-title"
        aria-describedby="alert-confirm-description"
      >
        <DialogTitle id="alert-confirm-title">
          {activity.isActivated
            ? t('pages.activity.disableTitle')
            : t('pages.activity.enableTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-confirm-description">
            {t('pages.activity.content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={handleCloseDialog}
            className={dialogFormClasses.button}
            variant="outlined"
            color="primary"
            disabled={loading}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="button"
            onClick={handleChangeActivityStatus}
            className={dialogFormClasses.button}
            variant="contained"
            disabled={loading}
            color="primary"
          >
            {t('common.ok')}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

ActivityCard.propTypes = {
  activity: PropTypes.object.isRequired,
  associationId: PropTypes.string,
  companyId: PropTypes.string,
  eventId: PropTypes.string,
};
