import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import { SPORTIIZ_WHITE } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    root: {
      paddingBottom: '2em',
      paddingTop: '2em',
    },
    icon: {
      padding: '0px 15px;',
    },
    textContainer: {
      display: 'contents',
    },
    text: {
      color: SPORTIIZ_WHITE,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '22px',
    },
    picture: {
      width: '45px',
    },
  }
));

export default function IconNumberBlock(props) {
  const {
    icon,
    text,
    picture,
  } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
      wrap="wrap"
    >
      <Grid item className={classes.icon} xs={5} sm={3}>
        {icon}
        {picture && <img src={picture} className={classes.picture} alt={`${text} icon`}/>}
      </Grid>
      <Grid item xs={7} sm={9}>
        <Typography variant={'subtitle1'} className={classes.text}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}

IconNumberBlock.propTypes = {
  icon: PropTypes.object,
  picture: PropTypes.string,
  text: PropTypes.string.isRequired,
};
