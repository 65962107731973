import { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { QUERY_EVENT_BOOKING } from '../../../constants/graphQLQueries';

function sortEventParticipants(participants, orderBy, order) {
  let newBookingsArray = [...participants];
  newBookingsArray = newBookingsArray.sort((a, b) => {
    let result = 0;
    if (a[orderBy] > b[orderBy]) {
      result = 1;
    }
    if (a[orderBy] < b[orderBy]) {
      result = -1;
    }
    if (order === 'DESC') {
      result *= -1;
    }
    return result;
  });
  return newBookingsArray;
}

function filterEventParticipants(participants, search, activityId) {
  return participants.filter((participant) => (
    activityId === '' || participant.activityId === activityId
  )).filter((participant) => (
    participant.activityName.toLowerCase().includes(search.trim().toLowerCase())
    || participant.activityName.toLowerCase().includes(search.trim().toLowerCase())
    || participant.userEmail.toLowerCase().includes(search.trim().toLowerCase())
    || participant.userFullname.toLowerCase().includes(search.trim().toLowerCase())
    || participant.companyName.toLowerCase().includes(search.trim().toLowerCase())
  ));
}

function useEventParticipants(eventId, filter = '', activityId = '') {
  const [fetchedParticipants, setFetchedParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('activityName');
  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(
    QUERY_EVENT_BOOKING,
    {
      client,
      variables: { id: eventId, all: true },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    setFilteredParticipants(filterEventParticipants(fetchedParticipants, filter, activityId));
  }, [fetchedParticipants, filter, activityId]);

  useEffect(() => {
    setParticipants(sortEventParticipants(filteredParticipants, orderBy, order));
  }, [order, orderBy, filteredParticipants]);

  useEffect(() => {
    if (data && data.event && data.event.activities) {
      const newBookingsArray = [];
      data.event.activities.forEach((act) => {
        act.timeSlots.forEach((ts) => {
          ts.bookings.forEach((b) => {
            let company = null;
            if (b.user.companyUsers.length > 0) {
              company = b.user.companyUsers[0].company;
            }
            newBookingsArray.push({
              bookingId: b.id,
              bookingDate: b.createdAt,
              bookingCancelledDate: b.cancelledAt,
              paymentStatus: b.paymentStatus,
              timeslotId: ts.id,
              activityId: act.id,
              activityName: act.name,
              timeSlotDate: ts.start,
              userPicture: b.user.picture,
              userEmail: b.user.email,
              userFirstname: b.user.firstname,
              userLastname: b.user.lastname,
              userFullname: `${b.user.firstname} ${b.user.lastname}`.trim(),
              companyName: company !== null ? company.name : '',
            });
          });
        });
      });
      setFetchedParticipants(newBookingsArray);
    }
  }, [loading, error, data]);

  return {
    loading,
    participants,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  };
}

export default useEventParticipants;
