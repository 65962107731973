import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { useTranslation } from 'react-i18next';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import withAuthorization from '../../hoc/withAuthorization';
import detailPageStyles from '../../styles/detailPage';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import TimeSlotFormDialog from '../../components/timeSlots/TimeSlotFormDialog';
import TimeSlotsPageContent from '../../components/timeSlots/TimeSlotsPageContent';
import {
  TimeSlotPeriodProvider,
} from '../../components/contexts/TimeSlotPeriodContext';
import PlusIcon from '../../components/icon/PlusIcon';
import { SPORTIIZ_WHITE } from '../../constants/colors';
import { Loading } from '../../components/layout/Loading';
import useAssociation from '../../components/hooks/UseAssociation';
import TimeSlotPeriodNav from '../../components/timeSlots/TimeSlotPeriodNav/TimeSlotPeriodNav';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

export function AssociationTimeSlotsPage() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const detailPageClasses = useDetailPageStyles();
  const { t } = useTranslation();
  const {
    association,
    timeSlots,
    activities,
    loading,
    error,
    refetch,
  } = useAssociation(id, 0);
  useEffect(() => {
    refetch();
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  function update() {
    refetch({
      id,
      start: 0,
    });
  }

  if (error) {
    return <ErrorServer error={error} />;
  }
  if (!association && !loading) {
    return <Error404 />;
  }

  return (
    <div>
      <TimeSlotPeriodProvider>
        <Sidebar />
        <Box className={detailPageClasses.box} component="section">
          <Container maxWidth={false}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h1">{t('pages.timeSlots.title')}</Typography>
              </Grid>
              <Hidden mdDown>
                <Grid item>
                  <Button
                    variant="contained"
                    className={detailPageClasses.addButton}
                    onClick={() => setOpen(true)}
                  >
                    {t('pages.timeSlots.addButton')}
                  </Button>
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <Fab
                  color="primary"
                  aria-label="add"
                  className={detailPageClasses.fab}
                  onClick={() => setOpen(true)}
                >
                  <PlusIcon color={SPORTIIZ_WHITE} />
                </Fab>
              </Hidden>
            </Grid>
          </Container>
          <TimeSlotPeriodNav />

          <Box component="section" className={detailPageClasses.footerBox}>
            <div className={detailPageClasses.footerContainer}></div>
            <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
              {loading && <Loading />}
              {!loading && <>
                <TimeSlotsPageContent
                  associationId={id}
                  displayButton={true}
                  timeSlots={timeSlots}
                />
              </>
              }
            </Container>
          </Box>
        </Box>
        <TimeSlotFormDialog
          open={open}
          handleClose={() => setOpen(false)}
          update={update}
          associationId={id}
          activities={activities}
        />
      </TimeSlotPeriodProvider>
    </div>
  );
}

export default withAuthorization()(AssociationTimeSlotsPage);
