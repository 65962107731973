import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Error404 from '../Error404';
import ErrorServer from '../ErrorServer';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import NumbersBlueSection from '../../components/layout/numbersBlueSection/NumbersBlueSection';
import detailPageStyles from '../../styles/detailPage';
import withAuthorization from '../../hoc/withAuthorization';
import TimeSlotsArray from '../../components/timeSlots/TimeSlotsArray';
import Loading from '../../components/layout/Loading';
import AngleCircleCharts from '../../components/layout/charts/AngleCircleCharts';
import PeopleIcon from '../../components/icon/PeopleIcon';
import { SPORTIIZ_BLUE, SPORTIIZ_RED } from '../../constants/colors';
import HistogramCharts from '../../components/layout/charts/HistogramCharts';
import useAssociation from '../../components/hooks/UseAssociation';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

export function AssociationPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const {
    association,
    timeSlots,
    activities,
    loading,
    error,
    refetch,
  } = useAssociation(id);
  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  if (error) {
    return <ErrorServer error={error} />;
  }
  if (!association && !loading) {
    return <Error404 />;
  }
  let incomeSum = [];
  if (!loading && association.monthCharts) {
    incomeSum = association.monthCharts.sort((a, b) => {
      if (a.dateYear < b.dateYear) {
        return -1;
      }
      if (a.dateYear > b.dateYear) {
        return 1;
      }
      if (a.dateMonth < b.dateMonth) {
        return -1;
      }
      return 1;
    });
  }
  const associationLoaded = association && association.id === id;
  const today = new Date().getTime() / 1000;
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.box} component="section">
        <Container maxWidth={false} className={detailPageClasses.pageTitle}>
          <Typography variant="h1">{association.name}</Typography>
        </Container>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={
              matches
                ? 2
                : 4
            }
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingBottom: '2em' }}
          >
            {!loading
              && association.satisfactionRatingPercent
              && <AngleCircleCharts
                percent={Number((
                  association.satisfactionRatingPercent
                ).toFixed(2))}
                label={t('pages.association.charts.satisfactionRatingPercent')}
                icon={<PeopleIcon color={SPORTIIZ_BLUE} />}
                color={SPORTIIZ_RED}
              />
            }
            {!loading
              && <HistogramCharts
                label={t('pages.association.charts.incomeHistogram')}
                series={incomeSum.map((elem) => elem.income)}
                color={SPORTIIZ_RED}
                categories={incomeSum.map((elem) => elem.date)}
              />
            }
          </Grid>
        </Container>
        <NumbersBlueSection
          numbers={[
            {
              number: associationLoaded ? activities.length : '-',
              text: t('pages.association.numbers.activities'),
            },
            {
              number: associationLoaded ? association.coachCount : '-',
              text: t('pages.association.numbers.coaches'),
            },
            {
              number: associationLoaded ? association.doneTimeSlotsCount : '-',
              text: t('pages.association.numbers.doneTimeSlots'),
            },
            {
              number: associationLoaded ? `${association.totalIncome} €` : '-',
              text: t('pages.association.numbers.totalIncome'),
            },
          ]}
        />
        <Box component="section" className={detailPageClasses.footerBox}>
          <Container maxWidth={false} className={detailPageClasses.footerContainer}>
            <Typography variant={'h6'}>
              {t('pages.association.FutureTimeSlots')}
            </Typography>
          </Container>
          <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
            {loading && (
              <Loading />
            )}
            {!loading && (
              <TimeSlotsArray
                associationId={id}
                timeSlots={timeSlots.filter((t) => t.start >= today)}
                withCheckbox={false}
                limit={10}
                defaultOrder={'ASC'}
                changeCallback={refetch}
              />
            )}
          </Container>
        </Box>
      </Box>
    </div>
  );
}

export default withAuthorization()(AssociationPage);
