import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { firebase } from '../firebase';
import { userLoginAction } from '../store/user/actions';

const withAuthentication = () => (Component) => {
  function WithAuthentication(props) {
    const dispatch = useDispatch();
    const [authUser, setAuthUser] = useState(null);
    firebase.auth.onAuthStateChanged((authUser) => {
      setAuthUser(authUser);
      if (authUser) {
        dispatch(userLoginAction(authUser));
      } else {
        dispatch(userLoginAction(null));
      }
    });
    return (
      <Component {...props} authUser={authUser} />
    );
  }

  return WithAuthentication;
};

export default withAuthentication;
