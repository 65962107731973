import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Transition from '../../layout/TransitionAnimation';
import dialogFormStyle from '../../../styles/dialogForm';
import CompanyMemberForm from './CompanyMemberForm';
import { useEditUser } from '../../contexts/EditUserContext';
import AppDialogTitle from '../../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

const MemberFormDialog = (props) => {
  const {
    handleClose,
    company,
    refetch,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { editUser } = useEditUser();

  const title = (
    !editUser || editUser.id === null
  )
    ? t('pages.companyMembers.dialogTitleAdd')
    : t('pages.companyMembers.dialogTitleEdit');

  return (
    <Dialog
      fullScreen={matches}
      open={editUser !== null}
      scroll="body"
      onClose={handleClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={
        matches
          ? 500
          : 750
      }
      aria-labelledby={title}
    >
      <AppDialogTitle title={title} handleClose={handleClose}/>
      <DialogContent>
        {t('pages.companyMembers.dialogContentText', { companyName: company.name })}
        <CompanyMemberForm
          handleClose={handleClose}
          companyId={company.id}
          refetch={refetch}
        />
      </DialogContent>
    </Dialog>
  );
};

MemberFormDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};

export default MemberFormDialog;
