import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { SPORTIIZ_BLUE, SPORTIIZ_WHITE } from '../../../constants/colors';

const BlueTabSection = withStyles({
  root: {
    width: '100%',
    backgroundColor: SPORTIIZ_BLUE,
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 25px 15px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 'auto',
    '& > span': {
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      backgroundColor: SPORTIIZ_WHITE,
    },
  },
  scrollButtons: {
    paddingTop: '8px',
    color: SPORTIIZ_WHITE,
  },
})((props) => <Tabs
  {...props}
  TabIndicatorProps={{ children: <span /> }}
  variant="scrollable"
  scrollButtons="on"
  indicatorColor="secondary"
/>);

export default BlueTabSection;
