import { firebase } from '../../../firebase';
import API_ROOT from '../../../constants/api';

async function exportEventParticipants(eventId) {
  const token = await firebase.auth.currentUser.getIdToken(true);
  fetch(
    `${API_ROOT}api/admin/export/event/${eventId}`,
    {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    },
  )
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });
}

export default exportEventParticipants;
