import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import {
  DEFAULT_USER,
  QUERY_COMPANY_MEMBER,
  QUERY_COMPANY_MEMBER_BOOKINGS,
} from '../../../constants/graphQLQueries';

function useMember(userId, companyId) {
  const client = useApolloClient();
  const [member, setMember] = useState(DEFAULT_USER);
  const [memberBookings, setMemberBooking] = useState([]);

  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery(
    QUERY_COMPANY_MEMBER,
    {
      variables: {
        userId,
        companyId,
      },
      client,
    },
  );
  const {
    loading: bookingLoading,
    error: bookingError,
    data: bookingData,
    refetch: bookingRefetch,
  } = useQuery(
    QUERY_COMPANY_MEMBER_BOOKINGS,
    {
      variables: {
        userId,
        id: companyId,
        limit: 10,
      },
      client,
    },
  );

  useEffect(() => {
    if (!loading && data !== null && data.companyMember !== undefined) {
      setMember(data.companyMember);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!bookingLoading
      && bookingData
      !== null
      && bookingData.companyMemberBookings
      !== undefined) {
      setMemberBooking(bookingData.companyMemberBookings);
    }
  }, [bookingData, bookingLoading]);

  const refetchAll = () => {
    refetch();
    bookingRefetch();
  };

  return {
    member,
    memberBookings,
    loading,
    error,
    bookingError,
    refetchAll,
  };
}

export default useMember;
