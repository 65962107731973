import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Transition from '../../layout/TransitionAnimation';
import CompanySubscriptionForm from './CompanySubscriptionForm';
import { QUERY_SUBSCRIPTIONS } from '../../../constants/graphQLQueries';
import ErrorServer from '../../../containers/ErrorServer';
import AppDialogTitle from '../../layout/AppDialogTitle';

export default function CompanySubscriptionFormDialog(props) {
  const {
    onClose,
    open,
    company,
    refetch,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const client = useApolloClient();

  const {
    loading: subscriptionsLoading,
    error: subscriptionsError,
    data: subscriptionsData,
  } = useQuery(
    QUERY_SUBSCRIPTIONS,
    { client },
  );

  const title = t('pages.company.subscription.dialogTitle');
  if (subscriptionsError) {
    return <ErrorServer error={subscriptionsError} />;
  }
  if (subscriptionsLoading) {
    return null;
  }
  return (
    <Dialog
      fullScreen={matches}
      open={open}
      scroll='body'
      onClose={onClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches
        ? 500
        : 750}
      aria-labelledby={title}
    >
      <AppDialogTitle title={title} handleClose={onClose}/>
      <DialogContent>
        <CompanySubscriptionForm
          handleClose={onClose}
          companyId={company.id}
          companySubscription={company.currentCompanySubscription}
          subscriptions={subscriptionsData.subscriptions}
          refetch={refetch}
        />
      </DialogContent>
    </Dialog>
  );
}

CompanySubscriptionFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
};
