import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const ChevronLeftIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Chevron Left">
      <path
        d="M13.36 17a1 1 0 01-.72-.31l-3.86-4a1 1 0 010-1.4l4-4a1 1 0 111.42 1.42L10.9 12l3.18 3.3a1 1 0 010 1.41 1 1 0 01-.72.29z"
        data-name="chevron-left"
        fill={color}
      />
    </g>
  </svg>
);

ChevronLeftIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ChevronLeftIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};

export default ChevronLeftIcon;
