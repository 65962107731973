import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SPORTIIZ_GREY } from '../../constants/colors';
import facebook from '../../resources/email/facebook.png';
import linkedin from '../../resources/email/linkedin.png';
import twitter from '../../resources/email/twitter.png';

const useStyles = makeStyles(() => (
  {
    a: {
      textDecoration: 'none',
      color: 'inherit',
    },
    copyright: {
      color: SPORTIIZ_GREY,
    },
    table: {
      width: '100%',
      padding: '10px 80px',
    },
  }
));

export default function SocialsFooter() {
  const classes = useStyles();
  const now = new Date();
  const sportiizUrl = 'https://sportiiz.fr';

  return (
    <table border="0" bgcolor="#FFFFFF" className={classes.table}>
      <tbody>
      <tr>
        <td colSpan="2">
          <hr color="#EAEAEA" size="1px" />
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <table border="0" bgcolor="#FFFFFF" className={classes.table}>
            <tbody>
            <tr align="center">
              <td width="200">
                <a
                  title="Aimez-nous sur Facebook"
                  href="https://www.facebook.com/Sportiiz-2435529713123787/?view_public_for=2435529713123787"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={facebook}
                    width="8"
                    alt="Aimez-nous sur Facebook"
                  />
                </a>
              </td>
              <td width="200">
                <a
                  title="Sifflez-nous sur Twitter"
                  href="https://twitter.com/Sportiiz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={twitter}
                    width="18"
                    alt="Sifflez-nous sur Twitter"
                  />
                </a>
              </td>
              <td width="200">
                <a
                  title="Suivez-nous sur LinkedIn"
                  href="https://www.linkedin.com/company/sportiiz/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedin}
                    width="15"
                    alt="Suivez-nous sur LinkedIn"
                  />
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <p className={classes.copyright}>
            Copyright © {now.getFullYear()} <a className={classes.a} href={sportiizUrl}>Sportiiz</a>. Tous droits réservés.
          </p>
        </td>
      </tr>
      </tbody>
    </table>
  );
}
