import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import withAuthorization from '../../hoc/withAuthorization';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import { DEFAULT_ASSOCIATION, QUERY_ASSOCIATION } from '../../constants/graphQLQueries';
import { ASSOCIATION_ADD_ACTIVITY } from '../../constants/routes';
import detailPageStyles from '../../styles/detailPage';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import ActivityList from '../../components/activities/ActivityList';
import PlusIcon from '../../components/icon/PlusIcon';
import { SPORTIIZ_WHITE } from '../../constants/colors';
import Loading from '../../components/layout/Loading';

export const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

export function ActivitiesPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_ASSOCIATION, {
    variables: { id },
    client,
  });

  function handleAdd() {
    history.push(ASSOCIATION_ADD_ACTIVITY.replace(':id', id));
  }

  if (error) {
    return <ErrorServer error={error} />;
  }
  if ((
    !data || !data.association
  ) && !loading) {
    return <Error404 />;
  }
  const association = (
    data && data.association
  )
    ? data.association
    : DEFAULT_ASSOCIATION;
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        <Container maxWidth={false} className={detailPageClasses.header}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1">{t('pages.activities.title')}</Typography>
            </Grid>
            {hasSuperAdminRole && (
              <>
                <Hidden mdDown>
                  <Grid item>
                    <Button variant="contained" onClick={handleAdd}>
                      {t('pages.activities.addButton')}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp>
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={detailPageClasses.fab}
                    onClick={handleAdd}
                  >
                    <PlusIcon color={SPORTIIZ_WHITE} />
                  </Fab>
                </Hidden>
              </>
            )}
          </Grid>
        </Container>
        <Container maxWidth={false} className={detailPageClasses.content}>
          {loading && <Loading />}
          {!loading && <ActivityList
            activities={association.activities}
            associationId={association.id}
          />}
        </Container>
      </Box>
    </div>
  );
}

export default withAuthorization()(ActivitiesPage);
