import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '../icon/CloseIcon';
import { SPORTIIZ_BLUE } from '../../constants/colors';
import dialogFormStyle from '../../styles/dialogForm';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));
const AppDialogTitle = (props) => {
  const {
    title,
    handleClose,
  } = props;
  const dialogFormClasses = useDialogFormStyles();
  return (
    <DialogTitle id="confirm-dialog-title">
      {title}
      <CloseIcon
        color={SPORTIIZ_BLUE}
        className={dialogFormClasses.closeIcon}
        onClick={handleClose}
      />
    </DialogTitle>
  );
};

AppDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default AppDialogTitle;
