import { SPORTIIZ_RED } from './colors';

export const SIDEBAR_WIDTH = '270px';
export const NAV_HEIGHT = '70px';
export const BUTTON_PROGRESS = {
  color: SPORTIIZ_RED,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
};

// border radius
export const LABEL_RADIUS = '5px';
export const LIST_ITEM_RADIUS = '8px';
export const CARD_RADIUS = '13px';
export const BACKGROUND_RADIUS = '25px';
