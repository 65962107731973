import React from 'react';
import Moment from 'react-moment';

export function getDateString(date) {
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day}/${month}/${date.getFullYear()}`;
}

export function getDateFromString(dateStr, separator = '/') {
  const parts = dateStr.split(separator);
  return new Date(
    parseInt(parts[2], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[0], 10),
  );
}
export function getBirthdayDateFromString(dateStr, separator = '-') {
  const parts = dateStr.split(separator);
  return new Date(
    parseInt(parts[0], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[2], 10),
  );
}
export function getBirthdayStringFromDate(date, separator = '-') {
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  return `${date.getFullYear()}${separator}${month}${separator}${day}`;
}

export function getDaysBetweenDates(weekDay, start, end) {
  const result = [];
  const current = new Date(start);
  current.setDate(current.getDate() + (weekDay - current.getDay() + 7) % 7);
  while (current <= end) {
    result.push(new Date(+current));
    current.setDate(current.getDate() + 7);
  }
  return result.length;
}

export function getDayFromString(locale, dateString) {
  const date = new Date(dateString);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return date.toLocaleDateString(locale, options);
}

export function getDayFromTimestamp(locale, timestamp) {
  return <Moment unix locale={locale} format={'dddd DD MMM YYYY'}>{timestamp}</Moment>;
}

export function getShortDayFromTimestamp(locale, timestamp) {
  return <Moment unix locale={locale} format={'DD/MM/YY'}>{timestamp}</Moment>;
}

export function getHourFromTimestamp(locale, timestamp) {
  let format = 'LT';
  if (locale === 'fr') {
    format = 'HH[h]mm';
  }
  return <Moment unix locale={locale} format={format}>{timestamp}</Moment>;
}

export function getDayAndHourFromTimestamp(locale, timestamp) {
  let format = 'LT';
  if (locale === 'fr') {
    format = 'HH[h]mm';
  }
  return <Moment unix locale={locale} format={`dddd DD MMM YYYY ${format}`}>{timestamp}</Moment>;
}
