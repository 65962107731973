import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableHead, TableRow, TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import MemberBookingRow from './MemberBookingRow';

const useStyles = makeStyles((theme) => (
  {
    table: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '100px',
      },
      '& tr': {
        '& th:first-child': {
          paddingLeft: '24px',
        },
        '& td:first-child': {
          paddingLeft: '24px',
        },
      },
    },
  }
));
const MemberBookingArray = (props) => {
  const { bookings } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return <Table className={classes.table}>
    <TableHead>
      <TableRow>
        <TableCell>
          {t('table.timeSlot.activity')}
        </TableCell>
        <TableCell>
          {t('table.timeSlot.date')}
        </TableCell>
        <TableCell>
          {t('table.timeSlot.hours')}
        </TableCell>
        <TableCell>
          {t('table.companyMembers.bookingStatus')}
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      { bookings.map((b) => <MemberBookingRow booking={b} key={b.id} />) }
    </TableBody>
  </Table>;
};
MemberBookingArray.propTypes = {
  bookings: PropTypes.array.isRequired,
};
export default MemberBookingArray;
