import gql from 'graphql-tag';
import {
  ACTIVITY_FRAGMENTS,
  AREA_FRAGMENTS,
  ASSOCIATION_FRAGMENTS,
  EVENT_FRAGMENTS,
  COMPANY_FRAGMENTS,
  GAME_FRAGMENTS,
  REWARD_FRAGMENTS,
  SUBSCRIPTION_FRAGMENTS,
  TIMESLOT_FRAGMENTS,
  USER_FRAGMENTS, BOOKING_FRAGMENTS,
} from './graphQLFragments';

export const DEFAULT_ASSOCIATION = {
  id: '',
  name: '',
  activities: [],
  timeSlots: [],
  coachCount: 0,
};
export const DEFAULT_ACTIVITY = {
  id: '',
  name: '',
  duration: 0,
  subtitle: null,
  description: '',
  details: '',
  price: 0,
  minimumEntrant: 1,
  maximumEntrant: 12,
  address: null,
  street: null,
  city: null,
  country: null,
  zipCode: null,
  additionalAddress: null,
  latitude: 0,
  longitude: 0,
  isDiscovery: false,
  categories: [],
  requireLicence: false,
  area: null,
  remuneration: 30,
  isPrivatiiz: false,
  isFreeBookingDisabled: false,
  isActivated: true,
  privatiizCompany: null,
};
export const DEFAULT_COMPANY = {
  id: '',
  name: '',
  companyUsers: [],
  privatiizActivities: [],
  lastTimeSlots: [],
  currentCompanySubscription: null,
};

export const DEFAULT_USER = {
  id: null,
  email: '',
  firstname: '',
  lastname: '',
  nickname: '',
  phone: '',
  picture: null,
};

/** Queries **/
export const QUERY_AREAS = gql`
    {
        areas {
            ...AreaFragment
        }
    }
    ${AREA_FRAGMENTS.area}
`;

export const QUERY_SUBSCRIPTIONS = gql`
    {
        subscriptions {
            ...SubscriptionFragment
        }
    }
    ${SUBSCRIPTION_FRAGMENTS.subscription}
`;

export const QUERY_USER = gql`
    {
        user {
            ...UserFragment
        }
    }
    ${USER_FRAGMENTS.user}
`;

export const QUERY_ASSOCIATIONS = gql`
    query Associations {
        associations {
            ...AssociationMinFragment
        }
    }
    ${ASSOCIATION_FRAGMENTS.associationMin}
`;

export const QUERY_COMPANIES = gql`
    query Companies {
        companies {
            ...CompanyMinFragment
        }
    }
    ${COMPANY_FRAGMENTS.companyMin}
`;

export const QUERY_COMPANY = gql`
    query Company($id: ID!, $dayCount: Int, $monthCount: Int, $dayCountDate: String) {
        company(id: $id) {
            ...CompanyFullFragment
        }
    }
    ${COMPANY_FRAGMENTS.companyFull}
`;

export const QUERY_COMPANY_TIMESLOTS = gql`
    query CompanyTimeSlots($id: ID!) {
        companyTimeSlots(id: $id) {
            ...TimeSlotFragment
        }
    }
    ${TIMESLOT_FRAGMENTS.timesSlot}
`;

export const QUERY_COMPANY_MEMBER = gql`
    query CompanyMember($userId: ID!, $companyId: ID!) {
        companyMember(userId: $userId, companyId: $companyId) {
            ...UserLightFragment
        }
    }
    ${USER_FRAGMENTS.userLight}
`;

export const QUERY_ASSOCIATION = gql`
    query Association($id: ID!, $start: Int, $end: Int) {
        association(id: $id) {
            id
            name
            picture
            slogan
            coachCount
            doneTimeSlotsCount
            satisfactionRatingPercent
            totalIncome
            activities {
                ...ActivityFragment
            }
            associationUsers {
                id
                user {
                    id
                    email
                    nickname
                    lastname
                    firstname
                    phone
                    picture
                }
                roles
            }
            timeSlots(start: $start, end: $end) {
                ...TimeSlotFragment,
                coach{
                    firstname
                    lastname
                }
            }

            monthCharts(monthCount: 6) {
                date
                dateMonth
                dateYear
                income
            }
        }
    }
    ${TIMESLOT_FRAGMENTS.timesSlot}
    ${ACTIVITY_FRAGMENTS.activity}
`;

// Activity detail page
export const QUERY_ADMIN_ACTIVITY = gql`
    query activity($activityId: ID!) {
        adminActivity(id: $activityId) {
            ...ActivityFragment
        }
    }
    ${ACTIVITY_FRAGMENTS.activity}
`;

// Info for Edit activity page
export const QUERY_ACTIVITY_EDIT_INFO = gql`
    query AssociationForEditActivity($activityId: ID!) {
        sports {
            id
            name
            picture
        }
        categories {
            id
            name
        }
        companies {
            id
            name
        }
        areas {
            id
            name
        }
        events {
            id
            name
        }
        adminActivity(id: $activityId) {
            ...ActivityFullFragment
        }
    }
    ${ACTIVITY_FRAGMENTS.activityFull}
`;

// Info for Add activity page
export const QUERY_ACTIVITY_ADD_INFO = gql`
    query AssociationForAddActivity {
        sports {
            id
            name
            picture
        }
        categories {
            id
            name
        }
        companies {
            id
            name
        }
        areas {
            id
            name
        }
        events {
            id
            name
        }
    }
`;

export const QUERY_REWARDS = gql`
    query Rewards {
        rewards {
            ...RewardFragment
        }
    }
    ${REWARD_FRAGMENTS.reward}
`;

export const QUERY_GAMES = gql`
    query Games {
        individualGames {
            ...SimpleIndividualGame
        }
        companyGames {
            ...SimpleCompanyGame
        }
        globalGames {
            ...SimpleGlobalGame
        }
    }
    ${GAME_FRAGMENTS.simpleGlobalGame}
    ${GAME_FRAGMENTS.simpleCompanyGame}
    ${GAME_FRAGMENTS.simpleIndividualGame}
`;

export const QUERY_GLOBAL_GAME = gql`
    query GlobalGame($id: ID!) {
        globalGame(id: $id) {
            ...GlobalGame
        }
    }
    ${GAME_FRAGMENTS.globalGame}
`;

export const QUERY_COMPANY_GAME = gql`
    query CompanyGame($id: ID!) {
        companyGame(id: $id) {
            ...CompanyGame
        }
    }
    ${GAME_FRAGMENTS.companyGame}
`;

export const QUERY_INDIVIDUAL_GAME = gql`
    query IndividualGame($id: ID!) {
        individualGame(id: $id) {
            ...IndividualGame
        }
    }
    ${GAME_FRAGMENTS.individualGame}
`;

export const QUERY_TIMESLOT = gql`
    query TimeSlotAsAdmin($id: ID!) {
        timeSlotAsAdmin(id: $id) {
            ...TimeSlotBookingsFragment
        }
    }
    ${TIMESLOT_FRAGMENTS.timesSlotBookings}
`;

export const QUERY_BOOKINGS = gql`
    query bookings($timeSlotId: ID!, $companyId: ID, $associationId: ID) {
        timeSlotBookings(timeSlotId: $timeSlotId, companyId: $companyId, associationId: $associationId) {
            ...BookingFragment
        }
    }
    ${BOOKING_FRAGMENTS.booking}
`;

export const QUERY_COMPANY_MEMBER_BOOKINGS = gql`
    query companyMemberBookings($id: ID!, $userId: ID!, $limit: Int) {
        companyMemberBookings(id: $id, userId: $userId, limit: $limit) {
            ...MemberBookingFragment
        }
    }
    ${BOOKING_FRAGMENTS.memberBooking}
`;

export const QUERY_INDIVIDUAL_USERS = gql`
    query individualUsers {
        individualUsers {
            ...UserLightFragment
        }
    }
    ${USER_FRAGMENTS.userLight}
`;

export const QUERY_EVENTS = gql`
    query events {
        events {
            ...EventFragment
        }
    }
    ${EVENT_FRAGMENTS.event}
`;

export const QUERY_EVENT_ADD_INFO = gql`
    query informationForAddEvent {
        companies {
            id
            name
        }
        areas {
            id
            name
        }
    }
`;

export const QUERY_EVENT = gql`
    query event($id: ID!) {
        event(id: $id) {
            ...EventFragment
        }
    }
    ${EVENT_FRAGMENTS.event}
`;

export const QUERY_EVENT_BOOKING = gql`
    query event($id: ID!, $all: Boolean) {
        event(id: $id) {
            ...EventBookingsFragment
        }
    }
    ${EVENT_FRAGMENTS.eventBookings}
`;

export const QUERY_ASSOCIATION_BOOKINGS = gql`
    query associationBookings($associationId: ID!) {
        associationBookings(associationId: $associationId) {
            ...MemberBookingFragment
        }
    }
    ${BOOKING_FRAGMENTS.memberBooking}
`;

/** Mutations **/

export const MUTATION_ASSOCIATION = gql`
    mutation Association($associationId: ID, $name: String!, $slogan: String!, $picture: Picture, $areaId: ID) {
        setAssociation(associationId: $associationId, name: $name, slogan: $slogan, picture: $picture, areaId: $areaId) {
            ...AssociationMinFragment
        }
    }
    ${ASSOCIATION_FRAGMENTS.associationMin}
`;

export const MUTATION_ASSOCIATION_COACH = gql`
    mutation Coach($associationId: ID!, $coachId: ID, $email: String!, $firstname: String, $lastname: String, $phone: String, $picture: Picture, $force: Boolean) {
        setCoach(associationId: $associationId, coachId: $coachId, email: $email, picture: $picture, firstname: $firstname, lastname: $lastname, phone: $phone, force: $force) {
            id
            user {
                ...CoachFragment
            }
            roles
        }
    }
    ${USER_FRAGMENTS.coach}
`;

export const MUTATION_ASSOCIATION_ADMINISTRATOR = gql`
    mutation AssociationAdministrator($associationId: ID!, $email: String!, $firstname: String, $lastname: String, $phone: String, $picture: Picture, $force: Boolean) {
        setAssociationAdministrator(associationId: $associationId, email: $email, picture: $picture, firstname: $firstname, lastname: $lastname, phone: $phone, force: $force) {
            id
            user {
                ...CoachFragment
            }
            roles
        }
    }
    ${USER_FRAGMENTS.coach}
`;

export const MUTATION_COMPANY = gql`
    mutation Company($companyId: ID, $name: String!, $slogan: String!, $picture: Picture, $areaId: ID, $defaultSubscriptionId: ID) {
        setCompany(companyId: $companyId, name: $name, slogan: $slogan, picture: $picture, areaId: $areaId, defaultSubscriptionId: $defaultSubscriptionId) {
            ...CompanyMinFragment
        }
    }
    ${COMPANY_FRAGMENTS.companyMin}
`;

export const MUTATION_COMPANY_ADMINISTRATOR = gql`
    mutation CompanyAdministrator($companyId: ID!, $email: String!, $firstname: String, $lastname: String, $phone: String, $picture: Picture, $force: Boolean, $subscriptionId: String) {
        setCompanyAdministrator(companyId: $companyId, email: $email, picture: $picture, firstname: $firstname, lastname: $lastname, phone: $phone, force: $force, subscriptionId: $subscriptionId) {
            id
            user {
                ...UserLightFragment
            }
            roles
        }
    }
    ${USER_FRAGMENTS.userLight}
`;

export const MUTATION_ACTIVITY = gql`
    mutation EditActivity($associationId: ID!, $activityId: ID, $name: String!, $subtitle: String, $picture: Picture, $sportId: ID!, $description: String!, $details: String!, $duration: Int!, $price: Float!, $minimumEntrant: Int!, $maximumEntrant: Int!, $street: String, $city: String, $country: String, $zipCode: String, $additionalAddress: String, $latitude: Float!, $longitude: Float!, $isDiscovery: Boolean!, $requireLicence: Boolean!, $categories: [ID!]!, $areaId: ID, $remuneration: Int, $privatiizCompanyId: ID, $isFreeBookingDisabled: Boolean!, $eventId: ID, $coachesIds: [ID!]!) {
        setActivity(associationId: $associationId, activityId: $activityId, name: $name, subtitle: $subtitle, picture: $picture, sportId: $sportId, description: $description, details: $details, duration: $duration, price: $price, minimumEntrant: $minimumEntrant, maximumEntrant: $maximumEntrant, street: $street, city: $city, country: $country, zipCode: $zipCode, additionalAddress: $additionalAddress, latitude: $latitude, longitude: $longitude, isDiscovery: $isDiscovery, requireLicence: $requireLicence, categories: $categories, areaId: $areaId, remuneration: $remuneration, privatiizCompanyId: $privatiizCompanyId, isFreeBookingDisabled: $isFreeBookingDisabled, eventId: $eventId, coachesIds: $coachesIds) {
            ...ActivityFragment
        }
    }
    ${ACTIVITY_FRAGMENTS.activity}
`;

export const MUTATION_ACTIVITY_STATUS = gql`
    mutation ChangeActivityStatus($activityId: ID!, $isActivated: Boolean) {
        setActivityStatus(activityId: $activityId, isActivated: $isActivated) {
            ...ActivityLightFragment
        }
    }
    ${ACTIVITY_FRAGMENTS.activityLight}
`;

export const MUTATION_TIMESLOTS = gql`
    mutation CreateTimeSlots($associationId: ID!, $activityId: ID!, $weekDay: Int!, $hour: Int!, $minute: Int!, $start: Int!, $end: Int!) {
        createTimeSlots(associationId: $associationId, activityId: $activityId, weekDay: $weekDay, start: $start, end: $end, hour: $hour, minute: $minute) {
            ...TimeSlotFragment
        }
    }
    ${TIMESLOT_FRAGMENTS.timesSlot}
`;

export const MUTATION_REMOVE_TIMESLOTS = gql`
    mutation RemoveTimeSlots($associationId: ID!, $timeSlotsIds: [ID!]!) {
        removeTimeSlots(associationId: $associationId, timeSlotsIds: $timeSlotsIds)
    }
`;

export const MUTATION_SEND_RESET_PASSWORD_MAIL = gql`
    mutation SendResetPasswordMail($email: String!) {
        sendResetPasswordMail(email: $email)
    }
`;

export const MUTATION_COMPANY_USER = gql`
    mutation SetCompanyUser($companyId: ID!, $email: String!, $subscriptionId: ID, $firstname: String!, $lastname: String!, $birthday: String, $userId: ID, $phone: String) {
        setCompanyUser(companyId: $companyId, email: $email, subscriptionId: $subscriptionId, firstname: $firstname, lastname: $lastname, birthday: $birthday, userId: $userId, phone: $phone) {
            id
            user {
                ...UserLightFragment
            }
            roles
        }
    }
    ${USER_FRAGMENTS.userLight}
`;

export const MUTATION_SET_REWARD = gql`
    mutation SetReward($rewardId: ID, $name: String!, $description: String!, $picture: Picture, $stock: Int!, $type: String!) {
        setReward(rewardId: $rewardId, name: $name, description: $description, picture: $picture, stock: $stock, type: $type) {
            ...RewardFragment
        }
    }
    ${REWARD_FRAGMENTS.reward}
`;

export const MUTATION_REMOVE_REWARD = gql`
    mutation RemoveReward($rewardId: ID!) {
        removeReward(rewardId: $rewardId)
    }
`;

export const MUTATION_SET_COMPANY_SUBSCRIPTION = gql`
    mutation SetCompanySubscription($companyId: ID!, $subscriptionId: ID, $availableSlots: Int, $end: Int) {
        setCompanySubscription(companyId: $companyId, subscriptionId: $subscriptionId, availableSlots: $availableSlots, end: $end) {
            ...CompanySubscriptionFragment
        }
    }
    ${COMPANY_FRAGMENTS.companySubscription}
`;

export const MUTATION_EVENT = gql`
    mutation SetEvent($eventId: ID, $name: String!, $description: String, $picture: Picture, $areaId: ID, $start: Int!, $end: Int!, $visibility: String!, $activitiesIds: [ID!], $companyId: ID, $onlyForCompanyUsers: Boolean) {
        setEvent(eventId: $eventId, name: $name, description: $description, picture: $picture, areaId: $areaId, start: $start, end: $end, visibility: $visibility, activitiesIds: $activitiesIds, companyId: $companyId, onlyForCompanyUsers: $onlyForCompanyUsers) {
            ...EventFragment
        }
    }
    ${EVENT_FRAGMENTS.event}
`;
export const MUTATION_CANCEL_EVENT = gql`
    mutation CancelEvent($eventId: ID!, $cancelReason: String!) {
        cancelEvent(eventId: $eventId, cancelReason: $cancelReason) {
            ...EventFragment
        }
    }
    ${EVENT_FRAGMENTS.event}
`;

export const MUTATION_SET_COMPANY_MEMBER = gql`
    mutation SetCompanyMember($companyId: ID!, $userId: ID, $firstname: String!, $lastname: String!, $birthday: String, $phone: String) {
        setCompanyMember(companyId: $companyId, userId: $userId, firstname: $firstname, lastname: $lastname, birthday: $birthday, phone: $phone) {
            ...UserLightFragment
        }
    }
    ${USER_FRAGMENTS.userLight}
`;

export const MUTATION_REMOVE_COMPANY_USER = gql`
    mutation removeCompanyUser($companyId: ID!, $userId: ID!, $role: String!) {
        removeCompanyUser(companyId: $companyId, userId: $userId, role: $role)
    }
`;

export const MUTATION_REMOVE_ASSOCIATION_USER = gql`
    mutation removeAssociationUser($associationId: ID!, $userId: ID!, $role: String!) {
        removeAssociationUser(associationId: $associationId, userId: $userId, role: $role)
    }
`;
