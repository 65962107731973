export const isNotEmpty = (value) => value !== undefined
    && value !== null
    && typeof value === 'string'
    && value.trim() !== '';

export const formatStringForTable = (value) => {
  if (value !== null && typeof value === 'object') {
    return value;
  }
  if (typeof value === 'number') {
    value = value.toString();
  }
  if (isNotEmpty(value)) {
    return value.trim();
  }
  return '-';
};
export default {
  isNotEmpty,
  formatStringForTable,
};
