import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useApolloClient } from '@apollo/react-hooks';
import NavButton from './NavButton';
import NavIcon from './NavIcon';
import UserIcon from './UserIcon';
import { SPORTIIZ_GREY_LIGHT, SPORTIIZ_WHITE } from '../../../constants/colors';
import { ASSOCIATIONS, COMPANIES, EVENTS, INDIVIDUALS } from '../../../constants/routes';
import { NAV_HEIGHT } from '../../../constants/style';
import { auth } from '../../../firebase';
import { userResetAction } from '../../../store/user/actions';
import ParametersIcon from './ParametersIcon';
import AdminIcon from '../../icon/AdminIcon';
import CalendarIcon from '../../icon/CalendarIcon';
import PersonIcon from '../../icon/PersonIcon';
import CompanyIcon from '../../icon/CompanyIcon';

const useStyles = makeStyles((theme) => (
  {
    nav: {
      background: SPORTIIZ_WHITE,
      width: '100vh',
      height: NAV_HEIGHT,
      position: 'fixed',
      left: '-50vh',
      top: '50vh',
      marginTop: '-35px',
      marginLeft: '35px',
      borderBottom: `1px solid ${SPORTIIZ_GREY_LIGHT}`,
      zIndex: 99,
      transform: 'rotate(-90deg)',
      [theme.breakpoints.down('md')]: {
        transform: 'rotate(0deg)',
        width: '100%',
        left: 0,
        margin: 0,
        top: 0,
      },
    },
    startUl: {
      float: 'right',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down('md')]: {
        float: 'left',
      },
    },
    endUl: {
      float: 'left',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down('md')]: {
        float: 'right',
      },
    },
  }
));

const signOut = () => {
  auth.doSignOut();
};

export default function Nav() {
  const {
    authUser,
    hasSuperAdminRole,
    hasAssociationAdminRole,
    hasCompanyAdminRole,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();
  const client = useApolloClient();

  const styleHide = (
    authUser === null || !authUser.isAdmin
  ) ? { display: 'none' } : {};

  function logOut() {
    dispatch(userResetAction());
    client.resetStore();
    signOut();
  }

  return (
    <nav id="NavBO" className={classes.nav} style={styleHide}>
      <NavIcon />
      {(
        hasSuperAdminRole || (
          hasAssociationAdminRole && hasCompanyAdminRole
        )
      ) && (
        <ul className={classes.startUl}>
          {(
            hasSuperAdminRole || hasAssociationAdminRole
          ) && (
            <NavButton
              label="pages.associations.title"
              icon={<AdminIcon width='2em' height='2em' />}
              link={ASSOCIATIONS}
            />
          )}
          {(
            hasSuperAdminRole || hasCompanyAdminRole
          ) && (
            <NavButton
              label="pages.companies.title"
              icon={<CompanyIcon width='2em' height='2em' />}
              link={COMPANIES}
            />
          )}
          {hasSuperAdminRole && (
            <>
              <NavButton
                label="pages.individuals.title"
                icon={<PersonIcon width='2em' height='2em' />}
                link={INDIVIDUALS}
              />
              <NavButton
                label="pages.events.title"
                icon={<CalendarIcon width='2em' height='2em' />}
                link={EVENTS}
              />
            </>
          )}
        </ul>
      )}
      <ul className={classes.endUl}>
        {authUser && <UserIcon authUser={authUser} logOut={logOut} />}
        {false && authUser && hasSuperAdminRole && <ParametersIcon />}
      </ul>
    </nav>
  );
}
