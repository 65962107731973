import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Lock from '../icon/Lock';
import { SPORTIIZ_BLUE } from '../../constants/colors';
import EventDetailsPasswordDialog from './EventDetailsPasswordDialog';

const useStyles = makeStyles((theme) => (
  {
    subtitle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      padding: 0,
      marginBottom: 0,
      marginLeft: '5px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '5px',
      },
      icon: {
        margin: '0.3rem',
      },
    },
  }
));

const LockedEventDetails = ({ event }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <Grid item>
        <Hidden xsDown>
          <Lock color={SPORTIIZ_BLUE} className={classes.icon} />
        </Hidden>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t('pages.events.visibilityLocked')}
          <span
            role="button"
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleClickOpen}
          >
            {t('pages.events.protectedByPassword')}
          </span>
        </Typography>
      </Grid>
      <EventDetailsPasswordDialog
        onClose={handleClose}
        open={open}
        event={event}
      />
    </Grid>
  );
};

export default LockedEventDetails;

LockedEventDetails.propTypes = {
  event: PropTypes.object.isRequired,
};
