import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getI18n } from 'react-i18next';
import {
  getDayAndHourFromTimestamp,
} from '../../../services/dateService';
import { formatStringForTable } from '../../../services/stringService';
import PrivatiizIcon from '../../icon/PrivatiizIcon';
import { SPORTIIZ_RED } from '../../../constants/colors';
import PaymentStatusChip from '../../timeSlots/PaymentStatusChip';
import { getDisplayName } from '../../../services/userService';

export const getCompanyNameFromBooking = (booking) => {
  let companyName = '-';
  if (
    booking.user
    && booking.user.companyUsers
    && booking.user.companyUsers.length > 0
  ) {
    companyName = booking.user.companyUsers[0].company.name;
  }
  return companyName;
};

const StyledTableCell = withStyles(() => (
  {
    root: {
      cursor: 'default',
    },
  }
))((props) => <TableCell {...props} />);
const useStyles = makeStyles(() => (
  {
    isPrivatiizIcon: {
      marginRight: '6px',
      verticalAlign: 'middle',
    },
    redText: {
      color: SPORTIIZ_RED,
    },
    maxSizeCell: {
      maxWidth: '20%',
    },
    emailLink: {
      cursor: 'pointer',
      color: 'inherit',
    },
  }
));
const AssociationBookingRow = (props) => {
  const { booking } = props;
  const classes = useStyles();
  const locale = getI18n().language.slice(0, 2);
  return (
    <TableRow id={booking.id} className={classes.row}>

      <StyledTableCell align="left">
        {getDisplayName(booking.user, '-')}
      </StyledTableCell>
      <StyledTableCell align="left">
        <a
          href={`mailto:${booking.user.email}?subject=Sportiiz`}
          aria-label="email"
          className={classes.emailLink}
        >
          {booking.user.email}
        </a>
      </StyledTableCell>
      <StyledTableCell align="left">
        {getCompanyNameFromBooking(booking)}
      </StyledTableCell>
      <StyledTableCell align="left">
        {booking.timeSlot.activity.isPrivatiiz && (
          <span className={classes.isPrivatiizIcon}>
            <PrivatiizIcon color={SPORTIIZ_RED} verticalAlign={'unset'} />
          </span>
        )}
        {formatStringForTable(booking.timeSlot.activity.name)}
      </StyledTableCell>

      <StyledTableCell align="left">
        {formatStringForTable(getDayAndHourFromTimestamp(locale, booking.timeSlot.start))}
      </StyledTableCell>

      <StyledTableCell align="left">
        {formatStringForTable(getDayAndHourFromTimestamp(locale, booking.createdAt))}
      </StyledTableCell>
      <StyledTableCell align="left">
        <span className={booking.cancelledAt !== null ? classes.redText : ''}>
          {formatStringForTable(
            booking.cancelledAt !== null
              ? getDayAndHourFromTimestamp(locale, booking.cancelledAt)
              : '',
          )}
        </span>
      </StyledTableCell>

      <StyledTableCell align="left">
        <PaymentStatusChip status={booking.paymentStatus} stripeFailureCode={booking.stripeFailureCode} />
      </StyledTableCell>

    </TableRow>
  );
};
AssociationBookingRow.propTypes = {
  booking: PropTypes.object.isRequired,
};
export default AssociationBookingRow;
