import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import LockOpen from '../icon/LockOpen';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const useStyles = makeStyles((theme) => (
  {
    subtitle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      padding: 0,
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        marginBottom: '5px',
      },
    },
    icon: {
      margin: '0.3rem',
    },
  }
));

const PublicEventDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" style={{ flexWrap: 'nowrap' }}>
      <Grid item>
        <Hidden xsDown>
          <LockOpen color={SPORTIIZ_BLUE} className={classes.icon} />
        </Hidden>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t('pages.events.visibilityPublic')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PublicEventDetails;
