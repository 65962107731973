import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import ActivityDetails from '../../components/activities/ActivityDetails';
import { DEFAULT_ACTIVITY, QUERY_EVENT } from '../../constants/graphQLQueries';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import { useDetailPageStyles } from '../company/CompanyPage/CompanyPage';
import { Loading } from '../../components/layout/Loading';
import withAuthorization from '../../hoc/withAuthorization';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import ActivityPageContent from '../../components/activities/ActivityPageContent';

const EventActivityPage = () => {
  const {
    id,
    activityId,
  } = useParams();
  const detailPageClasses = useDetailPageStyles();

  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(
    QUERY_EVENT,
    {
      variables: { id },
      client,
    },
  );

  if (error) {
    return <ErrorServer error={error} />;
  }
  if ((
    !data || !data.event
  ) && !loading) {
    return <Error404 />;
  }
  const event = (
    data && data.event
  )
    ? data.event
    : {};
  const activity = (
    event && event.activities && activityId
  )
    ? event.activities.find((act) => act.id === activityId)
    : DEFAULT_ACTIVITY;

  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        {loading
          && (
            <Container maxWidth={false} className={detailPageClasses.content}>
              <Loading />
            </Container>
          )}
        {!loading && activity !== undefined
          ? <>
            <ActivityPageContent activity={activity} />
            <ActivityDetails activity={activity} />
          </>
          : null
        }
      </Box>
    </div>
  );
};

export default withAuthorization()(EventActivityPage);
