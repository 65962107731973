import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import withAuthorization from '../hoc/withAuthorization';
import listPageStyles from '../styles/listPage';
import {
  ASSOCIATIONS,
  ASSOCIATION,
  COMPANIES,
  COMPANY,
} from '../constants/routes';

const useListPageStyles = makeStyles(listPageStyles);

export function HomePage() {
  const listPageClasses = useListPageStyles();
  const history = useHistory();

  const {
    hasSuperAdminRole,
    hasAssociationAdminRole,
    hasCompanyAdminRole,
    associationAdminIds,
    companyAdminIds,
  } = useSelector((state) => state.user);

  if (!hasSuperAdminRole) {
    if (hasAssociationAdminRole) {
      if (associationAdminIds.length === 1 && !hasCompanyAdminRole) {
        history.push(ASSOCIATION.replace(':id', associationAdminIds[0]));
      } else {
        history.push(ASSOCIATIONS);
      }
    } else if (hasCompanyAdminRole) {
      if (companyAdminIds.length === 1 && !hasAssociationAdminRole) {
        history.push(COMPANY.replace(':id', companyAdminIds[0]));
      } else {
        history.push(COMPANIES);
      }
    }
  }

  return (
    <Box className={listPageClasses.box}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h1" className={listPageClasses.title}>
              Dashboard
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default withAuthorization()(HomePage);
