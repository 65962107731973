import React from 'react';
import withAuthorization from '../../../hoc/withAuthorization';
import CompanyAdminsContent from './CompanyAdminsContent';
import { EditUserProvider } from '../../../components/contexts/EditUserContext';

const CompanyAdminsPage = () => (
  <EditUserProvider>
    <CompanyAdminsContent />
  </EditUserProvider>
);
export default withAuthorization()(CompanyAdminsPage);
