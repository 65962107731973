import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  SPORTIIZ_BLUE_GREY_DARK,
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_WHITE,
} from '../../../constants/colors';

const useStyles = makeStyles(() => (
  {
    chip: {
      width: '150px',
    },
  }
));

const BookingStatusChip = (props) => {
  const { present } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return <Chip
    label={t(`status.${present ? 'present' : 'absent'}`)}
    size="small"
    className={classes.chip}
    style={{
      backgroundColor: present ? SPORTIIZ_GREY_GREEN : SPORTIIZ_BLUE_GREY_DARK,
      color: SPORTIIZ_WHITE,
    }}
  />;
};

BookingStatusChip.propTypes = {
  present: PropTypes.bool.isRequired,
};

export default BookingStatusChip;
