import React from 'react';
import withAuthorization from '../../../hoc/withAuthorization';
import { EditUserProvider } from '../../../components/contexts/EditUserContext';
import AssociationCoachesContent from './AssociationCoachesContent';

const AssociationCoachesPage = () => (
  <EditUserProvider>
    <AssociationCoachesContent />
  </EditUserProvider>
);

export default withAuthorization()(AssociationCoachesPage);
