import gql from 'graphql-tag';

export const USER_FRAGMENTS = {
  user: gql`
      fragment UserFragment on User {
          id
          isAdmin
          email
          firstname
          lastname
          nickname
          role
          picture
          phone
          companyAdminIds
          associationAdminIds
      }
  `,
  coach: gql`
      fragment CoachFragment on Coach {
          id
          email
          firstname
          lastname
          nickname
          role
          picture
          phone
      }
  `,
  userLight: gql`
      fragment UserLightFragment on UserLight {
          id
          firebaseId
          email
          phone
          firstname
          lastname
          nickname
          picture
          birthday
          activityCount
          timeSlotCount
          commentCount
          satisfactionRatingAvg
          currentLicence {
              id
              status
              start
              end
              paymentStatus
          }
          currentUserSubscription {
              id
              subscription {
                  id
                  name
                  activityPrice
              }
              start
              end
              paymentStatus
          }
      }
  `,
};

export const COMPANY_FRAGMENTS = {
  companyMin: gql`
      fragment CompanyMinFragment on Company {
          id
          name
          slogan
          picture
          area {
              id
              name
              status
          }
          currentCompanySubscription {
              id
              start
              end
              availableSlots
              subscription {
                  id
                  name
              }
          }
      }
  `,
  companyFull: gql`
      fragment CompanyFullFragment on Company {
          id
          name
          picture
          slogan
          doneTimeSlotsCount
          satisfactionRatingPercent
          currentCompanySubscription {
              id
              start
              end
              availableSlots
              subscription {
                  id
                  name
                  billingType
                  activityPrice
                  price
              }
          }
          companyCharts {
              companyBookingCountChart(monthCount: $monthCount) {
                  date
                  dateMonth
                  dateYear
                  bookingCount
              }
              companyBookingRatesChart(dayCount: $dayCount, from: $dayCountDate) {
                  date
                  dateMonth
                  dateYear
                  dateDay
                  fullRateCount
                  reducedRateCount
              }

          }
          waitingCompanyUsers {
              id
              email
              createdAt
              firstname
              lastname
              birthday
              source
              subscription {
                  id
                  name
              }
          }
          area {
              id
              name
              status
          }
          defaultSubscription {
              id
              name
          }
          lastTimeSlots(limit: 10) {
              id
              start
              end
              bookingCount
              cancelBookingCount
              activity {
                  id
                  name
                  maximumEntrant
              }
          }
          companyUsers {
              id
              user {
                  id
                  email
                  nickname
                  lastname
                  firstname
                  picture
                  phone
                  currentLicence {
                      id
                      status
                      start
                      end
                      paymentStatus
                  }
                  currentUserSubscription {
                      id
                      subscription {
                          id
                          name
                      }
                      start
                      end
                      paymentStatus
                  }
              }
              roles
          }
          privatiizActivities {
              id
              name
              subtitle
              picture
              duration
              price
              minimumEntrant
              maximumEntrant
              address
              latitude
              longitude
              description
              details
              sport {
                  id
                  name
                  picture
              }
              area {
                  id
                  name
              }
              isDiscovery
              categories {
                  id
                  name
              }
              isPrivatiiz
              isActivated
              privatiizCompany {
                  id
                  name
              }
              association {
                  id
                  name
              }
              coaches {
                  id
                  firstname
                  lastname
              }
          }
      }
  `,
  companySubscription: gql`
      fragment CompanySubscriptionFragment on CompanySubscription {
          id
          start
          end
          availableSlots
          subscription {
              id
              name
              billingType
              activityPrice
              price
          }
      }
  `,
};

export const ASSOCIATION_FRAGMENTS = {
  associationMin: gql`
      fragment AssociationMinFragment on Association {
          id
          name
          slogan
          picture
          area {
              id
              name
              status
          }
      }
  `,
};

export const TIMESLOT_FRAGMENTS = {
  timesSlot: gql`
      fragment TimeSlotFragment on TimeSlot {
          id
          start
          end
          status
          isFull
          activity {
              id
              name
              maximumEntrant
              isPrivatiiz
              privatiizCompany {
                  id
                  name
              }
          }
          remainingPlaces
          bookingCount
          cancelBookingCount
          isPrivatiiz
      }
  `,
  timesSlotBookings: gql`
      fragment TimeSlotBookingsFragment on TimeSlot {
          id
          bookingCount
          cancelBookingCount
          bookings {
              id
              guestCount
              user {
                  id
                  email
                  firstname
                  lastname
                  phone
                  companyUsers {
                      id
                      company {
                          id
                          name
                          picture
                      }
                  }
              }
              paymentStatus
              status
              createdAt
              type
              parentBookingId
          }

      }
  `,
};

export const BOOKING_FRAGMENTS = {
  booking: gql`
      fragment BookingFragment on Booking {
          id
          guestCount
          paymentStatus
          stripeFailureCode
          status
          createdAt
          cancelledAt
          type
          parentBookingId
          user {
              id
              email
              firstname
              lastname
              phone
              companyUsers {
                  id
                  company {
                      id
                      name
                      picture
                  }
              }
          }
      }
  `,
  memberBooking: gql`
      fragment MemberBookingFragment on Booking {
          id
          guestCount
          paymentStatus
          stripeFailureCode
          status
          createdAt
          cancelledAt
          type
          parentBookingId
          presence
          user {
              id
              email
              firstname
              lastname
              companyUsers {
                  id
                  company {
                      id
                      name
                  }
              }
          }
          timeSlot {
              id
              start
              end
              cancelledAt
              status
              isPrivatiiz
              activity {
                  id
                  name
                  isPrivatiiz
              }
          }
      }
  `,
};

export const ACTIVITY_FRAGMENTS = {
  activity: gql`
      fragment ActivityFragment on Activity {
          id
          name
          subtitle
          picture
          sport {
              id
              name
              picture
          }
          description
          details
          duration
          price
          minimumEntrant
          maximumEntrant
          address
          latitude
          longitude
          isDiscovery
          requireLicence
          remuneration
          categories {
              id
              name
          }
          area {
              id
              name
          }
          association {
              id
              name
          }
          isFreeBookingDisabled
          isActivated
          isPrivatiiz
          privatiizCompany {
              id
              name
          }
          eventId
          coachesIds
          coaches {
              id
              firstname
              lastname
          }
      }
  `,
  activityLight: gql`
      fragment ActivityLightFragment on Activity {
          id
          isActivated
      }
  `,
  activityFull: gql`
      fragment ActivityFullFragment on Activity {
          id
          name
          subtitle
          picture
          sport {
              id
              name
              picture
          }
          description
          details
          duration
          price
          minimumEntrant
          maximumEntrant
          address
          street
          city
          country
          zipCode
          additionalAddress
          latitude
          longitude
          isDiscovery
          requireLicence
          remuneration
          categories {
              id
              name
          }
          area {
              id
              name
          }
          isFreeBookingDisabled
          isActivated
          isPrivatiiz
          privatiizCompany {
              id
              name
          }
          eventId
          coachesIds
          coaches {
              id
              firstname
              lastname
          }
      }
  `,
};

export const REWARD_FRAGMENTS = {
  reward: gql`
      fragment RewardFragment on Reward {
          id
          name
          description
          type
          picture
          createdAt
          stock
      }
  `,
  simpleReward: gql`
      fragment SimpleRewardFragment on Reward {
          id
          name
          type
          picture
      }
  `,
};

export const AREA_FRAGMENTS = {
  area: gql`
      fragment AreaFragment on Area {
          id
          name
          status
      }
  `,
};

export const GAME_FRAGMENTS = {
  simpleGlobalGame: gql`
      fragment SimpleGlobalGame on GlobalGame {
          id
          title
          start
          end
          stepsCount
          type
          status
      }
  `,
  globalGame: gql`
      fragment GlobalGame on GlobalGame {
          id
          title
          start
          end
          stepsCount
          steps {
              id
              number
              neededPoints
              reward {
                  id
                  name
                  description
                  type
                  picture
              }
          }
          type
          status
      }
  `,
  simpleCompanyGame: gql`
      fragment SimpleCompanyGame on CompanyGame {
          id
          title
          start
          end
          stepsCount
          type
          status
      }
  `,
  companyGame: gql`
      fragment CompanyGame on CompanyGame {
          id
          title
          start
          end
          stepsCount
          steps {
              id
              number
              neededPoints
              reward {
                  id
                  name
                  description
                  type
                  picture
              }
          }
          type
          status
      }
  `,
  simpleIndividualGame: gql`
      fragment SimpleIndividualGame on IndividualGame {
          id
          title
          start
          end
          stepsCount
          type
          status
      }
  `,
  individualGame: gql`
      fragment IndividualGame on IndividualGame {
          id
          title
          start
          end
          stepsCount
          steps {
              id
              number
              neededPoints
              reward {
                  id
                  name
                  description
                  type
                  picture
              }
          }
          type
          status
      }
  `,
};

export const SUBSCRIPTION_FRAGMENTS = {
  subscription: gql`
      fragment SubscriptionFragment on Subscription {
          id
          name
          billingType
          activityPrice
          price
      }
  `,
};

export const EVENT_FRAGMENTS = {
  event: gql`
      fragment EventFragment on Event {
          id
          name
          description
          area {
              id
              name
          }
          picture
          start
          end
          description
          visibility
          isPrivatiiz
          companyId
          password
          onlyForCompanyUsers
          qrCodeURL
          status
          activities {
              id
              name
              subtitle
              picture
              duration
              price
              minimumEntrant
              maximumEntrant
              address
              latitude
              longitude
              description
              details
              sport {
                  id
                  name
                  picture
              }
              area {
                  id
                  name
              }
              isDiscovery
              categories {
                  id
                  name
              }
              isPrivatiiz
              isActivated
              privatiizCompany {
                  id
                  name
              }
              association {
                  id
                  name
              }
              timeSlots {
                  id
                  start
                  end
                  status
                  isFull
                  activity {
                      id
                      name
                      maximumEntrant
                      isPrivatiiz
                      privatiizCompany {
                          id
                          name
                      }
                  }
                  remainingPlaces
                  bookingCount
                  cancelBookingCount
                  isPrivatiiz
              }
              coaches {
                  id
                  firstname
                  lastname
              }
          }
          timeSlotCount
          bookingCount
      }
  `,
  eventBookings: gql`
      fragment EventBookingsFragment on Event {
          id
          name
          activities {
              id
              name
              isPrivatiiz
              timeSlots {
                  id
                  start
                  end
                  status
                  isPrivatiiz
                  bookings(all: $all) {
                      id
                      user {
                          id
                          firebaseId
                          email
                          firstname
                          lastname
                          nickname
                          picture
                          companyUsers {
                              id
                              company {
                                  id
                                  name
                              }
                          }
                      }
                      status
                      type
                      guestCount
                      paymentStatus
                      createdAt
                      cancelledAt
                      presence
                      parentBookingId
                      guestBookingsIds
                      companyId
                      fullRateCount
                      reducedRateCount
                  }
              }
          }
      }
  `,
};
