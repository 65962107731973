import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { firebase } from '../../firebase';
import logo from '../../resources/sportiiz/logoWithTitle.svg';
import anonymousPageStyles from '../../styles/anonymousPage';
import Ribbon from '../../components/emailHandlers/Ribbon';
import SocialsFooter from '../../components/emailHandlers/SocialsFooter';
import ResetPasswordForm from '../../components/emailHandlers/ResetPasswordForm';
import failImage from '../../resources/stock/manFail.jpeg';
import successImage from '../../resources/stock/manSuccess.jpeg';
import waitingImage from '../../resources/stock/manWaiting.jpeg';

const useAnonymousPageStyles = makeStyles(anonymousPageStyles);
const useStyle = makeStyles(() => {
  return {
    container: {
      height: '100%',
      minHeight: '600px',
    },
    logo: {
      margin: '6vh auto',
    },
    button: {
      width: '300px',
      margin: '3em auto 1em',
    },
    image: {
      height: '300px',
      margin: '1em auto',
    },
  };
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function EmailActionHandlersPage() {
  const { auth, projectId } = firebase;
  const query = useQuery();
  const classes = useStyle();
  const anonymousPageClasses = useAnonymousPageStyles();
  const mode = query.get('mode');
  // Get the one-time code from the query parameter.
  const actionCode = query.get('oobCode');
  // (Optional) Get the continue URL from the query parameter if available.
  const sportiizUrl = 'https://sportiiz.fr';
  const continueUrl = query.get('continueUrl') || sportiizUrl;
  // (Optional) Get the language code if available.
  const lang = query.get('lang') || 'fr';

  const { t, i18n } = useTranslation();
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }
  const [env, setEnv] = useState(null);
  const [email, setEmail] = useState(null);
  const [title, setTitle] = useState(null);
  const [image, setImage] = useState(waitingImage);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  if (env === null) {
    switch (projectId) {
      case 'sportiiz-dev':
        setEnv('dev');
        break;
      case 'sportiiz-demo':
        setEnv('demo');
        break;
      default:
        setEnv('prod');
        break;
    }
  }

  // Handle the user management action.
  if (title === null) {
    switch (mode) {
      case 'resetPassword':
        setTitle(t('pages.emailHandlers.resetPasswordTitle'));
        if (actionCode) {
          auth.verifyPasswordResetCode(actionCode).then((email) => {
            setEmail(email);
          }).catch((error) => {
            console.log(error.message);
            setErrorMessage(t('pages.emailHandlers.invalidActionCode'));
            setRedirectUrl(continueUrl);
            setImage(failImage);
          });
        } else {
          setTitle(t('pages.emailHandlers.anErrorOccurred'));
          setErrorMessage(t('pages.emailHandlers.missingActionCode'));
          setRedirectUrl(continueUrl);
          setImage(failImage);
        }
        break;
      case 'recoverEmail':
        // Not implemented yet
        setTitle(t('pages.emailHandlers.recoverEmailTitle'));
        setErrorMessage(t('pages.emailHandlers.anErrorOccurred'));
        setImage(failImage);
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, lang);
        break;
      case 'verifyEmail':
        setTitle(t('pages.emailHandlers.verifyEmailTitle'));
        if (actionCode) {
          auth.applyActionCode(actionCode).then((resp) => {
            setSuccessMessage(t('pages.emailHandlers.verifyEmailSuccess'));
            setRedirectUrl(continueUrl);
            setImage(successImage);
          }).catch((error) => {
            console.log(error.message);
            setErrorMessage(t('pages.emailHandlers.invalidActionCode'));
            setRedirectUrl(continueUrl);
            setImage(failImage);
          });
        } else {
          setTitle(t('pages.emailHandlers.anErrorOccurred'));
          setErrorMessage(t('pages.emailHandlers.missingActionCode'));
          setRedirectUrl(continueUrl);
          setImage(failImage);
        }
        break;
      default:
        if (errorMessage === null) {
          setTitle('404 - Not found');
          setErrorMessage(t('pages.emailHandlers.invalidModeMessage'));
          setRedirectUrl(continueUrl);
          setImage(failImage);
        }
        break;
    }
  }

  const handleSubmit = (newPassword) => {
    auth.confirmPasswordReset(actionCode, newPassword).then((resp) => {
      setEmail(null);
      setTitle(t('pages.emailHandlers.resetPasswordTitleSuccess'));
      setSuccessMessage(t('pages.emailHandlers.resetPasswordSuccess'));
      setRedirectUrl(continueUrl);
      setImage(successImage);
    }).catch((error) => {
      setTitle(t('pages.emailHandlers.anErrorOccurred'));
      if (error.message.includes('The action code is invalid')) {
        setErrorMessage(t('pages.emailHandlers.invalidActionCode'));
      } else {
        setErrorMessage(t('pages.emailHandlers.invalidPassword'));
      }
      setEmail(null);
      setRedirectUrl(continueUrl);
      setImage(failImage);
    });
  };

  /*


    function handleRecoverEmail(auth, actionCode, lang) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      let restoredEmail = null;
      // Confirm the action code is valid.
      auth.checkActionCode(actionCode).then((info) => {
        // Get the restored email address.
        restoredEmail = info.data.email;

        // Revert to the old email.
        return auth.applyActionCode(actionCode);
      }).then(() => {
        // Account email reverted to restoredEmail

        // TODO: Display a confirmation message to the user.

        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        auth.sendPasswordResetEmail(restoredEmail).then(() => {
          // Password reset confirmation sent. Ask user to check their email.
        }).catch((error) => {
          // Error encountered while sending password reset code.
        });
      }).catch((error) => {
        // Invalid code.
      });
    }
  */

  return (
    <Box className={classes.container}>
      <Container maxWidth="lg">
        {env === 'dev' && <Ribbon text={'Dev.'} />}
        {env === 'demo' && <Ribbon text={'Demo.'} />}

        <img
          alt="Sportiiz"
          src={logo}
          className={`${anonymousPageClasses.logo} ${classes.logo}`}
          height={'80'}
        />
        {title && <Typography variant={'h2'} gutterBottom={true}>{title}</Typography>}
        {mode === 'resetPassword'
          && email
          && <ResetPasswordForm t={t} email={email} handleSubmit={handleSubmit} />
        }
        {successMessage && <Typography variant={'body1'}>{successMessage}</Typography>}
        {errorMessage && <Typography variant={'body1'} color={'error'}>{errorMessage}</Typography>}
        {redirectUrl
        && <Button variant={'contained'} href={redirectUrl} className={classes.button}>
          Retourner sur sportiiz.fr
        </Button>
        }
        <img src={image} alt={''} className={classes.image}/>
        <SocialsFooter />
      </Container>
    </Box>
  );
}
