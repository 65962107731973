import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import { getI18n } from 'react-i18next';
import { getDayFromTimestamp, getHourFromTimestamp } from '../../../../services/dateService';
import { formatStringForTable } from '../../../../services/stringService';
import PrivatiizIcon from '../../../icon/PrivatiizIcon';
import { SPORTIIZ_RED } from '../../../../constants/colors';
import BookingStatusChip from '../BookingStatusChip';

const useStyles = makeStyles(() => (
  {
    row: {
      cursor: 'unset',
    },
    isPrivatiizIcon: {
      marginRight: '6px',
      verticalAlign: 'middle',
    },
  }
));
const MemberBookingRow = (props) => {
  const { booking } = props;
  const classes = useStyles();
  const locale = getI18n().language.slice(0, 2);

  return (
    <TableRow id={booking.id} className={classes.row}>
      <TableCell align="left">
        {booking.timeSlot.activity.isPrivatiiz && (
          <span className={classes.isPrivatiizIcon}>
            <PrivatiizIcon color={SPORTIIZ_RED} verticalAlign={'unset'} />
          </span>
        )}
        {formatStringForTable(booking.timeSlot.activity.name)}
      </TableCell>
      <TableCell align="left">
        {formatStringForTable(getDayFromTimestamp(locale, booking.timeSlot.start))}
      </TableCell>
      <TableCell align="left">
        {getHourFromTimestamp(locale, booking.timeSlot.start)}
        {' - '}
        {getHourFromTimestamp(locale, booking.timeSlot.end)}
      </TableCell>
      <TableCell align="left">
        <BookingStatusChip present={booking.presence} />
      </TableCell>
    </TableRow>
  );
};
MemberBookingRow.propTypes = {
  booking: PropTypes.object.isRequired,
};
export default MemberBookingRow;
