import { SPORTIIZ_RED } from '../constants/colors';

export default function dialogFormStyle(theme) {
  return ({
    formControl: {
      width: '100%',
    },
    closeIcon: {
      position: 'absolute',
      top: '26px',
      right: '26px',
      height: '1em',
      width: '1em',
      cursor: 'pointer',
    },
    buttonContainer: {
      margin: '15px',
    },
    button: {
      padding: '1em',
      width: 'auto',
      minWidth: '120px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '150px',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '125px',
      },
    },
    preview: {
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'unset',
    },
    uploadLabel: {
      width: '100%',
      height: '100%',
    },
    uploadButton: {
      position: 'relative',
      left: 'calc(50% - 25px)',
      top: '-20px',
      height: '40px',
      width: '40px',
      padding: '5px',
      zIndex: 10,
    },
    avatar: {
      width: '200px',
      height: '200px',
    },
    buttonProgress: {
      color: SPORTIIZ_RED,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  });
}
