/* eslint-disable react/prop-types */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const SECTION_DASHBOARD = 'Dashboard';
export const SECTION_COMPANIES = 'Companies';
export const SECTION_ASSOCIATIONS = 'Associations';
export const SECTION_EVENTS = 'Events';
export const SECTION_INDIVIDUALS = 'Individuals';
export const SECTION_SUBSCRIPTIONS = 'Subscriptions';
export const SECTION_GAMES = 'Games';
export const SECTION_LOGIN = 'Login';

const SectionContext = createContext({
  section: SECTION_LOGIN,
  setSection: () => {
  },
});
export const SectionProvider = ({
  defaultPeriod = SECTION_LOGIN,
  children,
}) => {
  const [s, setS] = useState(defaultPeriod);
  const setSection = useCallback((newSection) => setS(newSection), []);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/companies')) {
      setSection(SECTION_COMPANIES);
    } else if (location.pathname.includes('/associations')) {
      setSection(SECTION_ASSOCIATIONS);
    } else if (location.pathname.includes('/events')) {
      setSection(SECTION_EVENTS);
    } else if (location.pathname.includes('/individuals')) {
      setSection(SECTION_INDIVIDUALS);
    } else if (location.pathname.includes('/subscriptions')) {
      setSection(SECTION_SUBSCRIPTIONS);
    } else if (
      location.pathname.includes('/login')
      || location.pathname.includes('/pw-forget')
      || location.pathname.includes('/pw-forget')
    ) {
      setSection(SECTION_LOGIN);
    } else if (
      location.pathname.includes('/games')
      || location.pathname.includes('/rewards')
    ) {
      setSection(SECTION_GAMES);
    } else {
      setSection(SECTION_DASHBOARD);
    }
  }, [location, setSection]);

  return <SectionContext.Provider
    value={{
      section: s,
      setSection,
    }}
  >
    {children}
  </SectionContext.Provider>;
};

export const useSection = () => {
  const value = useContext(SectionContext);
  if (value === null) {
    throw new Error(
      'You must encapsulate your components with <SectionProvider> to use this hook',
    );
  }
  return value;
};
