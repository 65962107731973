import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import { useSelector } from 'react-redux';
import withAuthorization from '../../hoc/withAuthorization';
import { SPORTIIZ_WHITE } from '../../constants/colors';
import CompaniesArray from '../../components/companies/CompaniesArray';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import PlusIcon from '../../components/icon/PlusIcon';
import listPageStyles from '../../styles/listPage';
import { QUERY_AREAS, QUERY_COMPANIES } from '../../constants/graphQLQueries';
import CompanyFormDialog from '../../components/companies/CompanyFormDialog';
import Loading from '../../components/layout/Loading';

const useListPageStyles = makeStyles((theme) => (
  listPageStyles(theme)
));

export function CompaniesPage() {
  const { t } = useTranslation();
  const listPageClasses = useListPageStyles();
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const client = useApolloClient();
  const {
    loading: companiesLoading,
    error: companiesError,
    data: companiesData,
  } = useQuery(
    QUERY_COMPANIES,
    { client },
  );
  const {
    loading: areasLoading,
    error: areasError,
    data: areasData,
  } = useQuery(
    QUERY_AREAS,
    { client },
  );

  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState(null);

  function handleEdit(company) {
    setCompany(company);
    setOpen(true);
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setCompany(null);
    setOpen(false);
  }

  function update(cache, addCompany) {
    const { companies } = cache.readQuery({ query: QUERY_COMPANIES });
    const companyIndex = companies.findIndex((company) => company.id
      === addCompany.data.setCompany.id);
    let newCache = companies;
    if (companyIndex !== undefined && companyIndex !== -1) {
      newCache[companyIndex] = addCompany.data.setCompany;
    } else {
      newCache = newCache.concat([addCompany.data.setCompany]);
    }
    cache.writeQuery({
      query: QUERY_COMPANIES,
      data: { companies: newCache },
    });
  }

  let content = (
    <Loading />
  );
  if (companiesError || areasError) {
    content = <ErrorServer error={companiesError || areasError} />;
  }
  if ((
    !companiesData || !companiesData.companies
  ) && !companiesLoading) {
    content = <Error404 />;
  } else if (!companiesLoading && !areasLoading) {
    content = <CompaniesArray companies={companiesData.companies} handleEdit={handleEdit} />;
  }

  return (
    <Box className={listPageClasses.box}>
      <Container maxWidth={'lg'}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h1" className={listPageClasses.title}>
              {t('pages.companies.title')}
            </Typography>
          </Grid>
          {hasSuperAdminRole && (
            <>
              <Hidden mdDown>
                <Grid item>
                  <Button
                    variant="contained"
                    className={listPageClasses.addButton}
                    onClick={handleClickOpen}
                  >
                    {t('pages.companies.addButton')}
                  </Button>
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <Fab
                  color="primary"
                  aria-label="add"
                  className={listPageClasses.fab}
                  onClick={handleClickOpen}
                >
                  <PlusIcon color={SPORTIIZ_WHITE} />
                </Fab>
              </Hidden>
            </>
          )}
        </Grid>
      </Container>
      <Container maxWidth={'lg'} className={listPageClasses.container}>
        {content}
      </Container>
      {hasSuperAdminRole && (
        <CompanyFormDialog
          open={open}
          onClose={handleClose}
          update={update}
          company={company}
          areas={areasData
            ? areasData.areas
            : []}
        />
      )}
    </Box>
  );
}

export default withAuthorization()(CompaniesPage);
