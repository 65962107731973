import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const LockIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Layer 2">
      <g data-name="lockOpen">
        <path
          d="M17.8091349,1.3719552 C14.2767445,1.38460917 11.4382165,4.29390997 11.4382165,7.82543118 L11.4382165,10.7893865 L2.0185088,10.7893865 C0.904123733,10.7893865 0,11.6932877 0,12.8073986 L0,20.8794471 C0,21.9935579 0.904123733,22.8974592 2.0185088,22.8974592 L16.8209067,22.8974592 C17.9352917,22.8974592 18.8394155,21.9935579 18.8394155,20.8794471 L18.8394155,12.8073986 C18.8394155,11.6932877 17.9352917,10.7893865 16.8209067,10.7893865 L14.8023979,10.7893865 L14.8023979,7.80020603 C14.8023979,6.13534603 16.1354547,4.75216689 17.8007245,4.73535012 C19.4828151,4.71853336 20.8579243,6.08489573 20.8579243,7.7623683 L20.8579243,11.1257218 C20.8579243,11.6848794 21.3078835,12.1347279 21.8671787,12.1347279 L23.2128512,12.1347279 C23.7721463,12.1347279 24.2221056,11.6848794 24.2221056,11.1257218 L24.2221056,7.7623683 C24.2221056,4.23084709 21.3415253,1.35938402 17.8091349,1.3719552 Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

LockIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LockIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};

export default LockIcon;
