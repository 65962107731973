import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MoreVerticalIcon from '../icon/MoreVerticalIcon';
import { SPORTIIZ_GREY } from '../../constants/colors';
import PropTypes from 'prop-types';
import ArrayPopover from '../array/ArrayPopover';
import { COMPANY } from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CompanyRow = ({ company, handleEdit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleDetailsClick(event) {
    setAnchorEl(null);
    event.stopPropagation();
    event.preventDefault();
    history.push(COMPANY.replace(':id', company.id));
  }

  function handleClose(event) {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }

  function handleEditClick(event) {
    setAnchorEl(null);
    event.stopPropagation();
    event.preventDefault();
    handleEdit(company);
  }

  function handleItemListMenuClick(event) {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  function handleItemListClick(id) {
    history.push(COMPANY.replace(':id', id));
  }

  return (
    <TableRow key={company.id} onClick={() => handleItemListClick(company.id)}>
      <TableCell align="left">
        {company.name}
      </TableCell>
      <Hidden xsDown>
        <TableCell align="left">
          {
            company.area
              ? company.area.name
              : t('common.notSpecified')
          }
        </TableCell>
      </Hidden>
      <Hidden smDown>
        <TableCell align="left">
          {
            (company.currentCompanySubscription
            && company.currentCompanySubscription.subscription)
              ? company.currentCompanySubscription.subscription.name
              : t('common.notSpecified')
          }
        </TableCell>
      </Hidden>
      <TableCell align="left">
        <IconButton
          aria-label="more"
          size="small"
          onClick={handleItemListMenuClick}
        >
          <MoreVerticalIcon color={SPORTIIZ_GREY} />
        </IconButton>
      </TableCell>
      <ArrayPopover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        items={[
          {
            action: handleDetailsClick,
            label: t('common.details'),
          },
          {
            action: handleEditClick,
            label: t('common.edit'),
          },
        ]}
      />
    </TableRow>
  );
};
CompanyRow.propTypes = {
  company: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};
export default CompanyRow;
