import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import { MUTATION_COMPANY_USER } from '../../../constants/graphQLQueries';
import dialogFormStyle from '../../../styles/dialogForm';
import { validateEmail } from '../../../services/userService';
import { dispatchInfo, handleError } from '../../../store/infos/actions';
import { useEditUser } from '../../contexts/EditUserContext';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
};
const INITIAL_VALUES = {
  id: null,
  memberId: null,
  companyId: null,
  email: '',
  emailError: false,
  emailErrorMessage: '',
  firstname: '',
  firstnameError: false,
  firstnameErrorMessage: '',
  lastname: '',
  lastnameError: false,
  lastnameErrorMessage: '',
  birthday: null,
  birthdayError: false,
  birthdayErrorMessage: '',
  subscriptionId: null,
  phone: null,
  phoneError: false,
  phoneErrorMessage: '',
};

const CompanyMemberForm = (props) => {
  const {
    handleClose,
    companyId,
    refetch,
  } = props;
  const {
    t,
    i18n,
  } = useTranslation();
  const locale = i18n.language.slice(0, 2);
  const dispatch = useDispatch();
  const dialogFormClasses = useDialogFormStyles();
  const client = useApolloClient();
  const { editUser } = useEditUser();
  const [values, setValues] = useState({
    ...INITIAL_VALUES,
    companyId,
  });

  useEffect(() => {
    if (editUser !== null && editUser.id !== null) {
      setValues({
        ...INITIAL_VALUES,
        companyId,
        memberId: editUser.id,
        email: editUser.email,
        firstname: editUser.firstname,
        lastname: editUser.lastname,
        phone: editUser.phone,
        pictureBlob: editUser.picture || null,

      });
    }
  }, [companyId, editUser]);

  function update(cache, user) {
    refetch();
  }

  const [mutationMember, { loading }] = useMutation(
    MUTATION_COMPANY_USER,
    {
      client,
      update,
    },
  );

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });
  };

  const handleBirthdayChange = (date) => {
    setValues({
      ...values,
      birthday: date,
      birthdayError: false,
      birthdayErrorMessage: '',
    });
  };

  const handleResetBirthdayDate = (event) => {
    event.stopPropagation();
    setValues({
      ...values,
      birthday: null,
    });
  };

  const onSubmit = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    let newValues = { ...values };
    let error = false;
    if (values.email === null || values.email === '') {
      newValues = {
        ...newValues,
        emailError: true,
        emailErrorMessage: t('pages.companyMembers.form.formEmailRequired'),
      };
      error = true;
    }
    if (values.email !== null && values.email !== '' && !validateEmail(values.email)) {
      newValues = {
        ...newValues,
        emailError: true,
        emailErrorMessage: t('pages.companyMembers.form.formInvalidEmail'),
      };
      error = true;
    }
    if (values.firstname === null || values.firstname === '') {
      newValues = {
        ...newValues,
        firstnameError: true,
        firstnameErrorMessage: t('pages.companyMembers.form.formFirstnameRequired'),
      };
      error = true;
    }
    if (values.lastname === null || values.lastname === '') {
      newValues = {
        ...newValues,
        lastnameError: true,
        lastnameErrorMessage: t('pages.companyMembers.form.formLastnameRequired'),
      };
      error = true;
    }
    if (values.birthday === '') {
      newValues = {
        ...newValues,
        birthdayError: true,
        birthdayErrorMessage: t('pages.companyMembers.form.formBirthdayRequired'),
      };
      error = true;
    }
    if (values.phone !== null && values.phone !== '' && values.phone.length < 10) {
      newValues = {
        ...newValues,
        phoneError: true,
        phoneErrorMessage: t('pages.companyMembers.form.formInvalidPhone'),
      };
      error = true;
    }
    setValues(newValues);

    if (!error) {
      if (event) {
        event.preventDefault();
      }
      const vars = {
        userId: editUser.id ?? null,
        companyId: newValues.companyId,
        email: newValues.email,
        firstname: newValues.firstname,
        lastname: newValues.lastname,
        birthday: newValues.birthday,
        subscriptionId: null,
        phone: newValues.phone,
      };
      mutationMember({
        variables: vars,
      }).then(() => {
        let successMessage = t('pages.companyMembers.form.formAddSuccessMessage');
        if (editUser.id !== null) {
          successMessage = t('pages.companyMembers.form.formEditSuccessMessage');
        }
        onGraphQLSuccess(successMessage);
      }).catch((error) => {
        onGraphQLError(error);
      });
    }
  };

  function onGraphQLSuccess(message) {
    handleClose();
    setValues(INITIAL_VALUES);
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors || error.graphQLErrors.length > 0) {
      if (error.graphQLErrors[0].code === 409) {
        dispatch(handleError(t('pages.companyMembers.form.conflictError')));
      } else {
        dispatch(handleError(error.graphQLErrors[0].message));
      }
    } else {
      dispatch(handleError(error));
    }
  }

  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const displayMonth = `${month < 10
    ? `0${month}`
    : `${month}`}`;
  const year = newDate.getFullYear();
  const minDate = `${year - 70}-${displayMonth}-${date}`;
  const maxDate = `${year - 18}-${displayMonth}-${date}`;

  return (
    <MuiPickersUtilsProvider
      utils={
        locale === 'fr'
          ? LocalizedUtils
          : DateFnsUtils} locale={localeMap[locale]
    }
    >
      <form onSubmit={onSubmit}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={7} xs={12}>
            <TextField
              label={t('pages.companyMembers.form.email')}
              id="coach-email"
              type="text"
              variant="outlined"
              onChange={handleChange('email')}
              margin="normal"
              value={values.email}
              fullWidth={true}
              error={values.emailError}
              helperText={values.emailErrorMessage}
              disabled={editUser === null || editUser.id !== null}
            />
          </Grid>
          <Grid item sm={7} xs={12}>
            <TextField
              label={t('pages.companyMembers.form.firstname')}
              id="coach-firstname"
              type="text"
              variant="outlined"
              onChange={handleChange('firstname')}
              margin="normal"
              value={values.firstname}
              fullWidth={true}
              error={values.firstnameError}
              helperText={values.firstnameErrorMessage}
            />
          </Grid>
          <Grid item sm={7} xs={12}>
            <TextField
              label={t('pages.companyMembers.form.lastname')}
              id="coach-lastname"
              type="text"
              variant="outlined"
              onChange={handleChange('lastname')}
              margin="normal"
              value={values.lastname}
              fullWidth={true}
              error={values.lastnameError}
              helperText={values.lastnameErrorMessage}
            />
          </Grid>
          <Grid item sm={7} xs={12}>
            <TextField
              label={t('pages.associationCoaches.form.phone')}
              id="coach-phone"
              pattern="^\d{10}$"
              type="text"
              variant="outlined"
              onChange={handleChange('phone')}
              margin="normal"
              value={values.phone}
              fullWidth={true}
              error={values.phoneError}
              helperText={values.phoneErrorMessage}
            />
          </Grid>
          <Grid item sm={7} xs={12}>
            <DatePicker
              id="timeSlots-birthday"
              value={values.birthday}
              error={values.birthdayError}
              inputVariant="outlined"
              fullWidth
              disableFuture
              margin="normal"
              minDate={minDate}
              maxDate={maxDate}
              placeholder="__/__/____"
              format="dd/MM/yyyy"
              label={t('pages.companyMembers.form.birthday')}
              onChange={(date) => handleBirthdayChange(date)}
              helperText={values.birthdayErrorMessage}
              showTodayButton={false}
              okLabel={t('common.ok')}
              cancelLabel={t('common.cancel')}
              readOnly={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleResetBirthdayDate}
                  >
                    <CloseIcon
                      style={{ width: '1rem' }}
                    />
                  </InputAdornment>
                ),
              }}
            >
            </DatePicker>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item className={dialogFormClasses.buttonContainer}>
            <Button
              type="button"
              variant="outlined"
              onClick={handleClose}
              className={dialogFormClasses.button}
              disabled={loading}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item className={dialogFormClasses.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={dialogFormClasses.button}
              disabled={loading}
            >
              {values.memberId
                ? t('common.edit')
                : t('common.add')}
              {loading && (
                <CircularProgress
                  size={24}
                  className={dialogFormClasses.buttonProgress}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
};

CompanyMemberForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  refetch: PropTypes.func,
};

export default CompanyMemberForm;
