import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../../../layout/TransitionAnimation';
import AppDialogTitle from '../../../layout/AppDialogTitle';
import CompanyMemberForm from './CompanyMemberForm';

const CompanyMemberFormDialog = (props) => {
  const {
    open,
    handleClose,
    member,
    update,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={matches}
      open={open}
      scroll="body"
      onClose={handleClose}
      maxWidth="sm"
      TransitionComponent={Transition}
      transitionDuration={
        matches
          ? 500
          : 750
      }
    >
      <AppDialogTitle title={t('pages.companyMembers.editMember')} handleClose={handleClose} />
      <CompanyMemberForm update={update} member={member} handleClose={handleClose} />
    </Dialog>
  );
};

CompanyMemberFormDialog.propTypes = {
  member: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

export default CompanyMemberFormDialog;
