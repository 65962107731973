import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const useStyle = makeStyles(() => {
  return {
    button: {
      margin: '2em auto',
    },
    field: {
      width: '300px',
    },
  };
});
export default function ResetPasswordForm(props) {
  const { email, t, handleSubmit } = props;
  const classes = useStyle();

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (password.length >= 6) {
      handleSubmit(password);
    } else {
      setPasswordError(t('pages.emailHandlers.minPasswordLengthError'));
    }
  };

  return (
    <form onSubmit={handleClick}>
      <p>Pour {email}</p>
      <TextField
        className={classes.field}
        id="reset-password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        label={t('pages.emailHandlers.resetPasswordInputLabel')}
        value={password}
        onChange={(event) => {setPassword(event.target.value); setPasswordError(null);}}
        error={passwordError !== null}
        helperText={passwordError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        type="button"
        variant={'contained'}
        color={'primary'}
        className={classes.button}
        onClick={handleClick}
      >
        {t('common.save')}
      </Button>
    </form>
  );
}


ResetPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};
