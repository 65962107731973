import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Reward from './Reward';
import { BUTTON_PROGRESS } from '../../constants/style';
import { MUTATION_REMOVE_REWARD, QUERY_REWARDS } from '../../constants/graphQLQueries';
import { dispatchInfo, handleError } from '../../store/infos/actions';

const useStyles = makeStyles(() => (
  {
    gridRow: {
      width: '100%',
      margin: 'auto',
      paddingBottom: '76px',
    },
    buttonProgress: BUTTON_PROGRESS,
  }
));

export default function RewardList(props) {
  const { rewards, handleClickOpen } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [reward, setReward] = useState(null);
  const open = Boolean(anchorEl);

  // TODO l'update reward ne me pas à jour le cache

  const [mutationRemoveReward, { loading: deleting }] = useMutation(MUTATION_REMOVE_REWARD,
    {
      client,
      update,
      // refetchQueries: [{
      //   query: QUERY_REWARDS
      // }],
      // awaitRefetchQueries: true
    },
  );

  function update(cache) {
    const rewards = [...cache.readQuery({ query: QUERY_REWARDS }).rewards];
    const deletedRewardId = reward.id;
    const rewardIndex = rewards.findIndex((reward) => reward.id === deletedRewardId);

    if (rewardIndex !== undefined && rewardIndex !== -1) {
      rewards.splice(rewardIndex, 1);
    }

    cache.writeQuery({
      query: QUERY_REWARDS,
      data: { rewards },
    });
  }

  function handleClose(event) {
    setAnchorEl(null);
    setReward(null);
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function handleEditClick(event, reward) {
    handleClickOpen(event, reward);
    handleClose(event);
  }

  function handleDeleteClick(event, reward) {
    mutationRemoveReward({
      variables: {
        rewardId: reward.id,
      },
    }).then(() => {
      onGraphQLSuccess(t('pages.rewards.form.formRemoveSuccessMessage'));
    }).catch((error) => {
      onGraphQLError(error);
    }).finally(() => {
      handleClose();
    });
  }

  function handleCardClick(event, reward) {
    setAnchorEl(event.currentTarget);
    setReward(reward);
    event.stopPropagation();
    event.preventDefault();
  }

  function onGraphQLSuccess(message) {
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  return (
    <Container>
      <Grid
        container
        justifyContent="flex-start"
        spacing={
          matches
            ? 2
            : 4
        }
        className={classes.gridRow}
      >
        {rewards.map((r) => <Reward
          reward={r}
          key={`reward-${r.id}`}
          handleCardClick={handleCardClick}
        />)}
      </Grid>
      {handleClickOpen
      && <Popover
        className={classes.popover}
        id=""
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" className={classes.menu}>
          <ListItem
            button
            onClick={(event) => handleEditClick(event, reward)}
            className={classes.menuItem}
          >
            {t('common.edit')}
          </ListItem>
          <ListItem
            button
            disabled={deleting}
            onClick={(event) => handleDeleteClick(event, reward)}
            className={classes.menuItem}
          >
            {t('common.delete')}
            {deleting
            && <CircularProgress size={24} className={classes.buttonProgress} />
            }
          </ListItem>
        </List>
      </Popover>
      }
    </Container>
  );
}

RewardList.propTypes = {
  rewards: PropTypes.array.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
};
