import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const TrashIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Layer 2">
      <g data-name="trash-2">
        <path
          fill={color}
          d="M21 6h-5V4.33A2.42 2.42 0 0013.5 2h-3A2.42 2.42 0 008 4.33V6H3a1 1 0 000 2h1v11a3 3 0 003 3h10a3 3 0 003-3V8h1a1 1 0 000-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 01-1 1H7a1 1 0 01-1-1V8h12z"
        />
        <path
          fill={color}
          d="M9 17a1 1 0 001-1v-4a1 1 0 00-2 0v4a1 1 0 001 1zM15 17a1 1 0 001-1v-4a1 1 0 00-2 0v4a1 1 0 001 1z"
        />
      </g>
    </g>
  </svg>
);

TrashIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

TrashIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};
export default TrashIcon;
