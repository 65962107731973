import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import Transition from '../../layout/TransitionAnimation';
import AppDialogTitle from '../../layout/AppDialogTitle';
import dialogFormStyle from '../../../styles/dialogForm';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));
const DeleteCoachDialog = (props) => {
  const {
    open,
    onClose,
    loading,
    handleRemove,
  } = props;
  const { t } = useTranslation();
  const dialogFormClasses = useDialogFormStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches
        ? 500
        : 750}
      aria-labelledby={t('pages.associationCoaches.removeDialogTitle')}
    >
      <AppDialogTitle
        title={t('pages.associationCoaches.removeDialogTitle')}
        handleClose={onClose}
      />
      <DialogContent id="delete-dialog-content">
        {t('pages.associationCoaches.removeDialogContent')}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
          className={dialogFormClasses.button}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="button"
          onClick={handleRemove}
          className={dialogFormClasses.button}
          color="secondary"
          variant="contained"
          disabled={loading}
        >
          {t('common.delete')}
          {loading && (
            <CircularProgress
              size={24}
              className={dialogFormClasses.buttonProgress}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
DeleteCoachDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
};
export default DeleteCoachDialog;
