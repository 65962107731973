import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../layout/TransitionAnimation';
import TimeSlotForm from './TimeSlotForm';
import dialogFormStyle from '../../styles/dialogForm';
import AppDialogTitle from '../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

export default function TimeSlotFormDialog(props) {
  const {
    handleClose,
    open,
    update,
    associationId,
    activities,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      fullScreen={matches}
      open={open}
      scroll='body'
      onClose={handleClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={
        matches
          ? 500
          : 750
      }
      aria-labelledby="simple-dialog-title"
    >
      <AppDialogTitle title={t('pages.timeSlots.dialogTitleAdd')} handleClose={handleClose}/>
      <TimeSlotForm
        handleClose={handleClose}
        update={update}
        associationId={associationId}
        activities={activities}
      />
    </Dialog>
  );
}

TimeSlotFormDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  associationId: PropTypes.string.isRequired,
  activities: PropTypes.array.isRequired,
};
