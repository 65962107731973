import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import listPageStyles from '../../styles/listPage';
import withAuthorization from '../../hoc/withAuthorization';
import { QUERY_AREAS, QUERY_COMPANIES, QUERY_EVENTS } from '../../constants/graphQLQueries';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import { Loading } from '../../components/layout/Loading';
import PlusIcon from '../../components/icon/PlusIcon';
import { SPORTIIZ_WHITE } from '../../constants/colors';
import EventsArray from '../../components/events/EventsArray';
import EventFormDialog from '../../components/events/EventFormDialog';

const useListPageStyles = makeStyles((theme) => (
  listPageStyles(theme)
));

const EventsPage = () => {
  const { t } = useTranslation();
  const listPageClasses = useListPageStyles();
  const client = useApolloClient();

  const [open, setOpen] = useState(false);

  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_EVENTS, { client });
  const {
    loading: areasLoading,
    error: areasError,
    data: areasData,
  } = useQuery(
    QUERY_AREAS,
    { client },
  );
  const {
    loading: companiesLoading,
    error: companiesError,
    data: companiesData,
  } = useQuery(
    QUERY_COMPANIES,
    { client },
  );

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  let content = (
    <Loading />
  );
  if (error || areasError || companiesError) {
    content = <ErrorServer error={error} />;
  }
  if ((
    !data || !data.events
  ) && !loading) {
    content = <Error404 />;
  } else if (!loading && !areasLoading && !companiesLoading) {
    content = <EventsArray events={data.events} />;
  }

  function update(cache, addEvent) {
    const { events } = cache.readQuery({ query: QUERY_EVENTS });
    const eventIndex = events.findIndex((ev) => ev.id === addEvent.data.setEvent.id);
    let newCache = events;
    if (eventIndex !== undefined && eventIndex !== -1) {
      newCache[eventIndex] = addEvent.data.setEvent;
    } else {
      newCache = newCache.concat([addEvent.data.setEvent]);
    }
    cache.writeQuery({
      query: QUERY_EVENTS,
      data: { events: newCache },
    });
  }

  return (
    <Box className={listPageClasses.box}>
      <Container maxWidth={'lg'}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h1" className={listPageClasses.title}>
              {t('pages.events.title')}
            </Typography>
          </Grid>

          <Hidden mdDown>
            <Grid item>
              <Button
                variant="contained"
                className={listPageClasses.addButton}
                onClick={handleClickOpen}
              >
                {t('pages.events.addButton')}
              </Button>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Fab
              color="primary"
              aria-label="add"
              className={listPageClasses.fab}
              onClick={handleClickOpen}
            >
              <PlusIcon color={SPORTIIZ_WHITE} />
            </Fab>
          </Hidden>

        </Grid>
      </Container>
      <Container maxWidth={'lg'} className={listPageClasses.container}>
        {content}
      </Container>
      <EventFormDialog
        onClose={handleClose}
        update={update}
        areas={areasData ? areasData.areas : []}
        companies={companiesData ? companiesData.companies : []}
        open={open}
      />
    </Box>
  );
};

export default withAuthorization()(EventsPage);
