import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useDialogFormStyles } from '../members/CompanyMemberForm';
import { dispatchInfo, handleError } from '../../../store/infos/actions';
import { MUTATION_SET_COMPANY_SUBSCRIPTION } from '../../../constants/graphQLQueries';

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const INITIAL_VALUES = {
  companyId: null,
  subscriptionId: null,
  subscriptionIdError: '',
  end: null,
  endError: '',
  availableSlots: null,
  availableSlotsError: '',
};

const CompanySubscriptionForm = (props) => {
  const {
    handleClose,
    companyId,
    companySubscription,
    subscriptions,
    refetch,
  } = props;
  const {
    t,
    i18n,
  } = useTranslation();
  const locale = i18n.language.slice(0, 2);
  const dispatch = useDispatch();
  const dialogFormClasses = useDialogFormStyles();
  const client = useApolloClient();
  const newState = {
    ...INITIAL_VALUES,
    companyId,
    subscriptionId: companySubscription?.subscription?.id || '',
    end: companySubscription !== undefined
    && companySubscription !== null
    && companySubscription.end !== undefined
      ? new Date(companySubscription.end * 1000)
      : new Date(),
    availableSlots: companySubscription?.availableSlots || 0,
  };
  const [values, setValues] = useState(newState);

  const [mutationMember, { loading }] = useMutation(
    MUTATION_SET_COMPANY_SUBSCRIPTION,
    {
      client,
    },
  );

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
      [`${name}Error`]: '',
    });
  };

  const onSubmit = (event) => {
    const newValues = { ...values };
    let error = false;

    if (values.subscriptionId !== '' && values.end < new Date()) {
      error = true;
      newValues.endError = t('pages.company.subscription.form.endMustBeInFuture');
    }
    if (values.subscriptionId !== '' && values.availableSlots < 0) {
      error = true;
      newValues.availableSlotsError = t('pages.company.subscription.form.availableSlotsMinValue');
    }

    setValues(newValues);
    if (!error) {
      if (event) {
        event.preventDefault();
      }
      submit();
    }
  };

  function submit() {
    mutationMember({
      variables: {
        companyId: values.companyId,
        subscriptionId: values.subscriptionId !== ''
          ? values.subscriptionId
          : null,
        end: values.subscriptionId !== ''
          ? Math.round(values.end.getTime() / 1000)
          : null,
        availableSlots: values.subscriptionId !== ''
          ? values.availableSlots
          : 0,
      },
    }).then(() => {
      onGraphQLSuccess(t('pages.company.subscription.form.formEditSuccessMessage'));
    }).catch((error) => {
      onGraphQLError(error);
    });
  }

  function onGraphQLSuccess(message) {
    refetch();
    handleClose();
    setValues(INITIAL_VALUES);
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error && error.graphQLErrors !== undefined && error.graphQLErrors.length > 0) {
      if (error.graphQLErrors[0].code === 409) {
        dispatch(handleError(t('pages.company.subscription.form.conflictError')));
      } else {
        dispatch(handleError(error.graphQLErrors[0].message));
      }
    } else {
      dispatch(handleError(error));
    }
  }

  const handleEndChange = (date) => {
    setValues({
      ...values,
      end: date,
      endError: '',
    });
  };

  return (
    <MuiPickersUtilsProvider
      utils={locale === 'fr'
        ? LocalizedUtils
        : DateFnsUtils}
      locale={localeMap[locale]}
    >
      <form onSubmit={onSubmit}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl
              error={values.subscriptionIdError !== ''}
              variant="outlined"
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="company-subscriptionId">
                {t('pages.company.subscription.form.subscription')}
              </InputLabel>
              <Select
                label={t('pages.company.subscription.form.subscription')}
                value={values.subscriptionId || ''}
                onChange={handleChange('subscriptionId')}
                input={
                  <OutlinedInput
                    name="subscription"
                    id="company-subscriptionId"
                    label={t('pages.company.subscription.form.subscription')}
                  />
                }
                inputProps={{
                  name: 'subscription',
                  id: 'company-subscriptionId',
                }}
              >
                <MenuItem value={''} key={'subscription-null'}>
                  {t('pages.companies.form.subscriptionDefault')}
                </MenuItem>
                {subscriptions.map((subscription) => (
                  <MenuItem value={subscription.id} key={`subscription-${subscription.id}`}>
                    {subscription.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="company-subscriptionId-error-text">
                {values.subscriptionIdError}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              id="companySubscription-end"
              value={values.end}
              error={values.endError !== ''}
              inputVariant="outlined"
              fullWidth
              disable={values.subscriptionId === ''}
              disablePast
              margin="normal"
              format="dd/MM/yyyy"
              label={t('pages.company.subscription.form.end')}
              onChange={handleEndChange}
              KeyboardButtonProps={{
                'aria-label': 'change subscription end date',
              }}
              helperText={values.endError}
              showTodayButton={false}
              okLabel={t('common.ok')}
              cancelLabel={t('common.cancel')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="companySubscription-availableSlots"
              label={t('pages.company.subscription.form.availableSlots')}
              type="number"
              variant="outlined"
              disable={values.subscriptionId === ''}
              onChange={handleChange('availableSlots')}
              margin="normal"
              value={values.availableSlots}
              fullWidth={true}
              error={values.availableSlotsError !== ''}
              helperText={values.availableSlotsError}
              inputProps={{
                min: 0,
              }}
            />
          </Grid>
        </Grid>
        <Grid container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end">
          <Grid item className={dialogFormClasses.buttonContainer}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={onSubmit}
              className={dialogFormClasses.button}
              disabled={loading}
            >
              {t('common.edit')}
              {loading
                && <CircularProgress size={24} className={dialogFormClasses.buttonProgress} />
              }
            </Button>
          </Grid>
          <Grid item className={dialogFormClasses.buttonContainer}>
            <Button
              type="button"
              variant="outlined"
              onClick={handleClose}
              className={dialogFormClasses.button}
              disabled={loading}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
};

CompanySubscriptionForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  companySubscription: PropTypes.object,
  subscriptions: PropTypes.array.isRequired,
};

export default CompanySubscriptionForm;
