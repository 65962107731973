import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  DEFAULT_ASSOCIATION,
  QUERY_EVENT,
} from '../../constants/graphQLQueries';
import { useDetailPageStyles } from '../association/ActivitiesPage';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import ActivityList from '../../components/activities/ActivityList';
import { Loading } from '../../components/layout/Loading';
import withAuthorization from '../../hoc/withAuthorization';

const EventActivitiesPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_EVENT, {
    variables: { id: id },
    client: client,
  });
  if (error) {
    return <ErrorServer error={error} />;
  }
  if ((
    !data || !data.event
  ) && !loading) {
    return <Error404 />;
  }
  const event = (
    data && data.event
  )
    ? data.event
    : DEFAULT_ASSOCIATION;
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        <Container maxWidth={false} className={detailPageClasses.header}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1">{t('pages.activities.title')}</Typography>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={false} className={detailPageClasses.content}>
          {loading && <Loading />}
          {!loading && <ActivityList
            activities={event.activities}
            eventId={event.id}
          />}
        </Container>
      </Box>
    </div>
  );
};

export default withAuthorization()(EventActivitiesPage);
