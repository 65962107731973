import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { auth } from '../../firebase';
import { dispatchInfo } from '../../store/infos/actions';
import { SIGN_IN } from '../../constants/routes';
import { useStyles } from './SignInForm';

const INITIAL_STATE = {
  email: '',
  error: null,
};

export default function PasswordForgetForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(INITIAL_STATE);

  const submit = (event) => {
    setLoading(true);
    auth.doPasswordReset(state.email)
      .then(() => {
        setState(INITIAL_STATE);
        dispatch(dispatchInfo(t('pages.forgot_password.success_message')));
        setLoading(false);
      })
      .catch((error) => {
        setState({ ...state, error });
        setLoading(false);
      });
    event.preventDefault();
  };

  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleLogin = () => {
    history.push(SIGN_IN);
  };

  return (
    <form onSubmit={submit}>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={9} md={7}>
          <TextField
            className={classes.field}
            fullWidth={true}
            label={t('common.email')}
            id="changepassword-email"
            type="text"
            onChange={handleChange('email')}
            margin="normal"
            value={state.email}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant={'contained'}
            color={'primary'}
            className={classes.button}
            disabled={loading}
          >
            {t('pages.forgot_password.button')}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            type="button"
            variant="outlined"
            onClick={handleLogin}
            className={classes.button}
          >
            {t('pages.forgot_password.signIn')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
