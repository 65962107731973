import { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { getDateFromString, getDateString } from '../../../services/dateService';
import { QUERY_COMPANY } from '../../../constants/graphQLQueries';

function useCompanyCharts(id) {
  const client = useApolloClient();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('md'));

  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const [fullRateCount, setFullRateCount] = useState([]);
  const [reducedRateCount, setReducedRateCount] = useState([]);
  const [orderedRateChart, setOrderedRateChart] = useState([]);
  const [bookingCount, setBookingCount] = useState([]);

  const [dayCount, setDayCount] = useState(matches
    ? 10
    : 30);
  const [dayCountDate, setDayCountDate] = useState(getDateString(new Date()));
  const [monthCount, setMonthCount] = useState(matchesXl
    ? 8
    : 6);
  useEffect(() => {
    setDayCount(matches
      ? 10
      : 30);
  }, [matches]);

  useEffect(() => {
    setMonthCount(matchesXl
      ? 8
      : 6);
  }, [matchesXl]);

  const {
    loading,
    error,
    data,
  } = useQuery(
    QUERY_COMPANY,
    {
      variables: {
        id,
        dayCount,
        monthCount,
        dayCountDate,
      },
      client,
    },
  );

  const nextRateCountData = useCallback(() => {
    const newDate = getDateFromString(dayCountDate);
    newDate.setDate(newDate.getDate() + (
      matches
        ? 10
        : 30
    ));
    setDayCountDate(getDateString(newDate));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dayCountDate]);
  const previousRateCountData = useCallback(() => {
    const newDate = getDateFromString(dayCountDate);
    newDate.setDate(newDate.getDate() - (
      matches
        ? 10
        : 30
    ));
    setDayCountDate(getDateString(newDate));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dayCountDate]);

  useEffect(
    () => {
      if (loading) {
        setFullRateCount([]);
        setReducedRateCount([]);
        setOrderedRateChart([]);
        setBookingCount([]);
      } else if (data.company) {
        let newBookingCount = [];
        if (
          data.company.companyCharts
          && data.company.companyCharts.companyBookingCountChart
        ) {
          newBookingCount = data.company.companyCharts.companyBookingCountChart.sort((a, b) => {
            if (a.dateYear < b.dateYear) {
              return -1;
            }
            if (a.dateYear > b.dateYear) {
              return 1;
            }
            if (a.dateMonth < b.dateMonth) {
              return -1;
            }
            return 1;
          });
        }
        setBookingCount(newBookingCount);

        let newFullRateCount = [];
        let newReducedRateCount = [];
        let newOrderedRateChart = [];
        if (
          data.company.companyCharts
          && data.company.companyCharts.companyBookingRatesChart
        ) {
          newOrderedRateChart = data.company.companyCharts.companyBookingRatesChart.sort((a, b) => {
            if (a.dateYear < b.dateYear) {
              return -1;
            }
            if (a.dateYear > b.dateYear) {
              return 1;
            }
            if (a.dateMonth < b.dateMonth) {
              return -1;
            }
            if (a.dateMonth > b.dateMonth) {
              return 1;
            }
            if (a.dateDay < b.dateDay) {
              return -1;
            }
            return 1;
          });
          newFullRateCount = newOrderedRateChart.map((orc) => orc.fullRateCount);
          newReducedRateCount = newOrderedRateChart.map((orc) => orc.reducedRateCount);
        }
        setFullRateCount(newFullRateCount);
        setReducedRateCount(newReducedRateCount);
        setOrderedRateChart(newOrderedRateChart);
        setAlreadyLoaded(true);
      }
    },
    [data, loading],
  );

  return {
    loading,
    error,
    previousRateCountData,
    nextRateCountData,
    fullRateCount,
    reducedRateCount,
    orderedRateChart,
    bookingCount,
    alreadyLoaded,
  };
}

export default useCompanyCharts;
