import React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import withAuthorization from '../../hoc/withAuthorization';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import { DEFAULT_ACTIVITY, QUERY_ADMIN_ACTIVITY } from '../../constants/graphQLQueries';
import { SPORTIIZ_WHITE } from '../../constants/colors';
import detailPageStyles from '../../styles/detailPage';
import { ASSOCIATION_EDIT_ACTIVITY } from '../../constants/routes';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import EditIcon from '../../components/icon/EditIcon';
import ActivityDetails from '../../components/activities/ActivityDetails';
import Loading from '../../components/layout/Loading';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const useStyles = makeStyles((theme) => {
  return {
    titleContainer: {
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        paddingTop: '1em',
        paddingBottom: '1em',
        width: '100%',
      },
    },
    title: {
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    titleWithSubtitle: {
      marginBottom: 0,
      marginTop: '30px',
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    subtitle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      padding: 0,
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        marginBottom: '5px',
      },
    },
  };
});

export function ActivityPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const {
    id,
    activityId,
  } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const classes = useStyles();
  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_ADMIN_ACTIVITY, {
    variables: { activityId },
    client,
  });

  function handleEdit() {
    history.push(
      ASSOCIATION_EDIT_ACTIVITY
        .replace(':id', id)
        .replace(':activityId', activityId),
    );
  }

  if (error) {
    return <ErrorServer error={error} />;
  }
  if ((
    !data || !data.adminActivity
  ) && !loading) {
    return <Error404 />;
  }
  const activity = (
    data && data.adminActivity
  )
    ? data.adminActivity
    : DEFAULT_ACTIVITY;

  const hasSubtitle = activity.subtitle !== undefined
    && activity.subtitle !== null
    && activity.subtitle !== '';
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        <Container maxWidth={false} className={detailPageClasses.header}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap={'nowrap'}
          >
            <Grid item className={classes.titleContainer}>
              <Typography
                variant={
                  (
                    matchesXS && 'h3'
                  )
                  || (
                    matchesMD && 'h2'
                  )
                  || 'h1'
                }
                className={hasSubtitle ? classes.titleWithSubtitle : classes.title}
              >
                {activity.name}
              </Typography>
              {hasSubtitle
                && <Typography variant="subtitle2" className={classes.subtitle}>
                  {activity.subtitle}
                </Typography>
              }
            </Grid>
            {hasSuperAdminRole && (
              <>
                <Hidden mdDown>
                  <Grid item>
                    <Button variant="contained" onClick={handleEdit}>
                      {t('common.edit')}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp>
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={detailPageClasses.fab}
                    onClick={handleEdit}
                  >
                    <EditIcon color={SPORTIIZ_WHITE} />
                  </Fab>
                </Hidden>
              </>
            )}
          </Grid>
        </Container>
        {loading
          && (
            <Container maxWidth={false} className={detailPageClasses.content}>
              <Loading />
            </Container>
          )}
        {!loading && <ActivityDetails activity={activity} />}
      </Box>
    </div>
  );
}

export default withAuthorization()(ActivityPage);
