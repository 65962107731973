import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { QUERY_EVENT } from '../../constants/graphQLQueries';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import TimeSlotsPageContent from '../../components/timeSlots/TimeSlotsPageContent';
import withAuthorization from '../../hoc/withAuthorization';
import { useDetailPageStyles } from '../company/CompanyPage/CompanyPage';
import { Loading } from '../../components/layout/Loading';
import {
  TimeSlotPeriodProvider,
} from '../../components/contexts/TimeSlotPeriodContext';
import TimeSlotPeriodNav from '../../components/timeSlots/TimeSlotPeriodNav/TimeSlotPeriodNav';

const getTimeSlots = (arr) => {
  let newArray = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    newArray = newArray.concat(arr[i]);
  }
  return newArray;
};

const EventTimeSlotsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const detailPageClasses = useDetailPageStyles();
  const client = useApolloClient();
  const [ts, setTs] = useState([]);
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_EVENT, {
    variables: {
      id,
    },
    client,
  });

  useEffect(() => {
    const activities = (
      data && data.event && data.event.activities
    )
      ? data.event.activities
      : [];
    setTs(getTimeSlots(activities.map((a) => a.timeSlots)));
  }, [loading, data]);

  if (error) {
    return <ErrorServer error={error} />;
  }
  if ((
    !data || !data.event
  ) && !loading) {
    return <Error404 />;
  }

  return (
    <div>
      <TimeSlotPeriodProvider>
        <Sidebar />
        <Box className={detailPageClasses.box} component="section">
          <Container maxWidth={false}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h1">{t('pages.timeSlots.title')}</Typography>
              </Grid>
            </Grid>
          </Container>
          <TimeSlotPeriodNav />

          <Box component="section" className={detailPageClasses.footerBox}>
            <div className={detailPageClasses.footerContainer}></div>

            <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
              {loading && <Loading />}
              {!loading && (
                <TimeSlotsPageContent
                  displayButton={false}
                  timeSlots={ts}
                />
              )}
            </Container>
          </Box>
        </Box>
      </TimeSlotPeriodProvider>
    </div>
  );
};

export default withAuthorization()(EventTimeSlotsPage);
