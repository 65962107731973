import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Query } from 'react-apollo';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase';
import { SIGN_IN } from '../constants/routes';
import { handleError } from '../store/infos/actions';
import { userDownloadedAction, userResetAction } from '../store/user/actions';
import ErrorServer from '../containers/ErrorServer';
import { QUERY_USER } from '../constants/graphQLQueries';
import Loading from '../components/layout/Loading';

const withAuthorization = () => (Component) => {
  function WithAuthorization(props) {
    const { authUser } = useSelector((state) => state.user);
    const client = useApolloClient();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    if (authUser) {
      return (
        <Query query={QUERY_USER}>
          {({
            loading,
            error,
            data,
          }) => {
            if (loading) {
              return <Loading />;
            }
            if (authUser.isAdmin !== undefined && authUser.isAdmin !== true) {
              dispatch(handleError({ message: t('error.forbidden_simple_user') }));
              dispatch(userResetAction());
              client.resetStore();
              auth.doSignOut();
              history.push(SIGN_IN);
              return null;
            }
            if (error) {
              return <ErrorServer error={error} />;
            }
            if (authUser.role === undefined || authUser.role === null) {
              dispatch(userDownloadedAction(data.user));
            }
            return (
              <Component {...props} user={data.user} />
            );
          }}
        </Query>
      );
    }
    return null;
  }

  return WithAuthorization;
};

export default withAuthorization;
