import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const CloseIcon = ({
  color,
  width,
  height,
  className,
  onClick,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height} className={className} onClick={onClick}>
    <g data-name="Layer 2">
      <path
        d="M13.41 12l4.3-4.29a1 1 0 10-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 000 1.42 1 1 0 001.42 0l4.29-4.3 4.29 4.3a1 1 0 001.42 0 1 1 0 000-1.42z"
        data-name="close"
        fill={color}
      />
    </g>
  </svg>
);

CloseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CloseIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
  className: '',
};

export default CloseIcon;
