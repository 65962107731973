import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const LockIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Layer 2">
      <g data-name="lock">
        <path
          d="M20.4374016,10.5971712 L19.3019904,10.5971712 L19.3019904,7.1909376 C19.3019904,3.22646016 16.0755302,0 12.1110528,0 C8.14657536,0 4.9201152,3.22646016 4.9201152,7.1909376 L4.9201152,10.5971712 L3.784704,10.5971712 C2.5310208,10.5971712 1.5138816,11.6143104 1.5138816,12.8679936 L1.5138816,21.9512832 C1.5138816,23.2049664 2.5310208,24.2221056 3.784704,24.2221056 L20.4374016,24.2221056 C21.6910848,24.2221056 22.708224,23.2049664 22.708224,21.9512832 L22.708224,12.8679936 C22.708224,11.6143104 21.6910848,10.5971712 20.4374016,10.5971712 Z M15.5172864,10.5971712 L8.7048192,10.5971712 L8.7048192,7.1909376 C8.7048192,5.31277824 10.2328934,3.784704 12.1110528,3.784704 C13.9892122,3.784704 15.5172864,5.31277824 15.5172864,7.1909376 L15.5172864,10.5971712 Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

LockIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LockIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};

export default LockIcon;
