import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../../layout/TransitionAnimation';
import AssociationCoachForm from './AssociationCoachForm';
import { useEditUser } from '../../contexts/EditUserContext';
import AppDialogTitle from '../../layout/AppDialogTitle';

const CoachFormDialog = (props) => {
  const {
    handleClose,
    refetch,
    association,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [retry, setRetry] = useState(false);
  const { editUser } = useEditUser();

  const title = (
    !editUser || editUser.id === null
  )
    ? t('pages.associationCoaches.dialogTitleAdd')
    : t('pages.associationCoaches.dialogTitleEdit');

  return (
    <Dialog
      fullScreen={matches}
      open={editUser !== null}
      scroll="body"
      onClose={handleClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches ? 500 : 750}
      aria-labelledby={title}
      keepMounted={retry}
    >
      <AppDialogTitle title={title} handleClose={handleClose} />
      <AssociationCoachForm
        handleClose={handleClose}
        refetch={refetch}
        associationId={association.id}
        retry={retry}
        setRetry={setRetry}
      />
    </Dialog>
  );
};

CoachFormDialog.propTypes = {
  refetch: PropTypes.func.isRequired,
  association: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CoachFormDialog;
