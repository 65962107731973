import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LogoIcon from '../../icon/LogoIcon';
import { HOME } from '../../../constants/routes';
import { LIST_ITEM_RADIUS } from '../../../constants/style';

const useStyles = makeStyles((theme) => (
  {
    logo: {
      width: '60px',
      height: '60px',
      margin: '5px 15px',
      float: 'right',
      transform: 'rotate(90deg)',
      [theme.breakpoints.down('md')]: {
        float: 'left',
        transform: 'rotate(0deg)',
        margin: '5px 0',
      },
    },
    link: {
      width: '60px',
      height: '60px',
      display: 'block',
      backgroundSize: '50px 50px',
      padding: '9px 0',
      '&:focus': {
        outline: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: LIST_ITEM_RADIUS,
      },
    },
  }
));

export default function NavIcon() {
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      <Link to={HOME} aria-label="Retour à l'accueil" className={classes.link}>
        <LogoIcon width="3em" height="3em" viewBox="0 0 88 133" />
      </Link>
    </div>
  );
}
