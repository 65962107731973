import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SPORTIIZ_GREY_LIGHT } from '../../constants/colors';
import downloadQrCode from '../../services/qrCodeSvgDownload';

const useStyles = makeStyles(() => (
  {
    downloadButton: {
      width: '181px',
      height: '40px',
      borderRadius: '22.5px',
      border: `1px solid ${SPORTIIZ_GREY_LIGHT}`,
      fontWeight: 600,
      fontSize: '0.8rem',
    },
  }
));

const QrCodeDownload = (props) => {
  const { imageUrl } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
      <Grid
        item
        xs={5}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            width: '181px',
            height: '181px',
          }}
        />
        <Button
          type="button"
          href={imageUrl}
          className={classes.downloadButton}
          onClick={(e) => downloadQrCode(e, imageUrl)}
          target="_self"
        >
          {t('pages.events.form.downloadSvg')}
        </Button>
      </Grid>
  );
};

export default QrCodeDownload;

QrCodeDownload.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};
