import { ENV_DEV, ENV_LOCAL, ENV_PREPROD } from './env';

let backendHost = 'https://api.sportiiz.fr/';

if (process.env.REACT_APP_ENV) {
  if (process.env.REACT_APP_ENV === ENV_LOCAL) {
    backendHost = 'https://api-master.sportiiz.fr/';
  } else if (process.env.REACT_APP_ENV === ENV_DEV) {
    backendHost = 'https://api-dev.sportiiz.fr/';
  } else if (process.env.REACT_APP_ENV === ENV_PREPROD) {
    backendHost = 'https://api-demo.sportiiz.fr/';
  }
}

const API_ROOT = `${backendHost}`;
export default API_ROOT;
