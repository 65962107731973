import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import AvatarPictureInput from '../forms/AvatarPictureInput';
import { MUTATION_COMPANY } from '../../constants/graphQLQueries';
import dialogFormStyle from '../../styles/dialogForm';

const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

const INITIAL_VALUES = {
  companyId: null,
  name: '',
  nameError: false,
  nameErrorMessage: '',
  slogan: '',
  sloganError: false,
  sloganErrorMessage: '',
  areaId: null,
  areaIdError: false,
  areaIdErrorMessage: '',
  defaultSubscriptionId: '', // unused
  picture: null,
  pictureBlob: null,
};

export default function CompanyForm(props) {
  const {
    handleClose,
    update,
    company,
    areas,
  } = props;
  const dialogFormClasses = useDialogFormStyles();
  const client = useApolloClient();
  const { t, i18n } = useTranslation();
  const locale = i18n.language.slice(0, 2);
  const dispatch = useDispatch();
  const newState = (
    company === null
  )
    ? INITIAL_VALUES
    : {
      ...INITIAL_VALUES,
      companyId: company.id,
      name: company.name,
      slogan: company.slogan,
      areaId: company.area?.id,
      defaultSubscriptionId: null,
      pictureBlob: company.picture || null,
    };
  const [values, setValues] = useState(newState);

  const [mutationCompany, { loading }] = useMutation(
    MUTATION_COMPANY,
    {
      client,
      update,
    },
  );

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });
  };

  const handleChangeFile = (name) => (event) => {
    const blob = new Blob([event.target.files[0]], { type: 'image/png' });
    const blobUrl = URL.createObjectURL(blob);
    setValues({
      ...values,
      [name]: event.target.files[0],
      [`${name}Blob`]: blobUrl,
    });
  };

  const onSubmit = (event) => {
    let error = false;
    let newValues = { ...values };
    if (!values.name || values.name.trim() === '') {
      newValues = {
        ...newValues,
        nameError: true,
        nameErrorMessage: t('pages.companies.form.formNameRequired'),
      };
      error = true;
    }
    if (values.slogan === null || values.slogan.trim() === '') {
      newValues = {
        ...newValues,
        sloganError: true,
        sloganErrorMessage: t('pages.companies.form.formSloganRequired'),
      };
      error = true;
    }
    if (!values.areaId || values.areaId === '') {
      newValues = {
        ...newValues,
        areaIdError: true,
        areaIdErrorMessage: t('pages.companies.form.formAreaRequired'),
      };
      error = true;
    }
    setValues(newValues);
    if (!error) {
      if (event) {
        event.preventDefault();
      }
      const vars = {
        name: values.name.trim(),
        slogan: values.slogan.trim(),
      };
      let hasUpload = false;
      let successMessage = t('pages.companies.form.formAddSuccessMessage');
      if (values.picture) {
        vars.picture = values.picture;
        hasUpload = true;
      }
      if (values.areaId) {
        vars.areaId = values.areaId;
      }
      if (values.companyId) {
        vars.companyId = values.companyId;
        successMessage = t('pages.companies.form.formEditSuccessMessage');
      }
      mutationCompany({
        variables: vars,
        context: {
          hasUpload,
        },
      }).then(() => {
        onGraphQLSuccess(successMessage);
      }).catch((error) => {
        onGraphQLError(error);
      });
    }
  };

  function onGraphQLSuccess(message) {
    handleClose();
    setValues(INITIAL_VALUES);
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  return (
    <form onSubmit={onSubmit}>

      <DialogContent>
      <Grid container>
        <Grid item xs={12} lg={5}>
          <AvatarPictureInput
            pictureBlob={values.pictureBlob}
            handleChangeFile={handleChangeFile}
            id="company-picture"
          />
        </Grid>
        <Grid item xs={12} lg={7}>
          <TextField
            label={t('pages.companies.form.name')}
            id="company-name"
            type="text"
            variant="outlined"
            onChange={handleChange('name')}
            margin="normal"
            value={values.name}
            fullWidth={true}
            error={values.nameError}
            helperText={values.nameErrorMessage}
          />
          <TextField
            label={t('pages.companies.form.slogan')}
            id="company-slogan"
            type="text"
            variant="outlined"
            onChange={handleChange('slogan')}
            margin="normal"
            value={values.slogan}
            fullWidth={true}
            error={values.sloganError}
            helperText={values.sloganErrorMessage}
          />
          <FormControl
            error={values.areaIdError}
            variant="outlined"
            fullWidth={true}
            margin={'normal'}
          >
            <InputLabel htmlFor="company-areaId">
              {t('pages.companies.form.area')}
            </InputLabel>
            <Select
              value={values.areaId || ''}
              onChange={handleChange('areaId')}
              input={
                <OutlinedInput
                  labelWidth={locale === 'fr'
                    ? 55
                    : 34}
                  name="area"
                  id="company-areaId"
                />
              }
              inputProps={{
                name: 'area',
                id: 'company-areaId',
              }}
            >
              {areas.map((area) => (
                <MenuItem value={area.id} key={`area-${area.id}`}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id="company-areaId-error-text">
              {values.areaIdErrorMessage}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
          <Button
            type="button"
            variant="outlined"
            onClick={handleClose}
            className={dialogFormClasses.button}
            disabled={loading}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            className={dialogFormClasses.button}
            disabled={loading}
          >
            {values.companyId
              ? t('common.edit')
              : t('common.add')}
            {loading
            && <CircularProgress size={24} className={dialogFormClasses.buttonProgress} />}
          </Button>
      </DialogActions>
    </form>
  );
}

CompanyForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  company: PropTypes.object,
};

CompanyForm.defaultProps = {
  company: null,
};
