import React from 'react';
import { useParams } from 'react-router';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { QUERY_EVENT } from '../../constants/graphQLQueries';
import Loading from '../../components/layout/Loading';
import EventDetails from '../../components/events/EventDetails';
import withAuthorization from '../../hoc/withAuthorization';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import detailPageStyles from '../../styles/detailPage';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

const EventDetailsPage = () => {
  const client = useApolloClient();
  const detailPageClasses = useDetailPageStyles();
  const { id } = useParams();
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY_EVENT, {
    variables: { id },
    client,
  });
  let content = (
    <Loading />
  );
  if (error) {
    content = (<ErrorServer error={error} />);
  }
  if ((
    !data || !data.event
  ) && !loading) {
    content = (<Error404 />);
  }
  if (data && data.event) {
    content = (
      <EventDetails event={data.event} loading={loading} />
    );
  }
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.box} component="section">
        {content}
      </Box>
    </div>
  );
};

export default withAuthorization()(EventDetailsPage);
