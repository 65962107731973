import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import superAdminPageStyles from '../../../styles/superAdminPage';

const useSuperAdminPageStyles = makeStyles((theme) => (superAdminPageStyles(theme)));

function TabMenu({ label, onClick, selected }) {

    const superAdminPageClasses = useSuperAdminPageStyles();
    
    return (
        <li className={superAdminPageClasses.li} onClick={onClick}>
            <a
                href="#app"
                className={superAdminPageClasses.link + (selected ? ` ${superAdminPageClasses.linkActive}` : '')}
            >
                {label}
            </a>
        </li>
    );
}

export default TabMenu;