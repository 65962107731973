import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import withAuthorization from '../../hoc/withAuthorization';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import { QUERY_ACTIVITY_EDIT_INFO } from '../../constants/graphQLQueries';
import detailPageStyles from '../../styles/detailPage';
import { ASSOCIATION_ACTIVITY } from '../../constants/routes';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import ActivityForm from '../../components/activities/ActivityForm';
import Loading from '../../components/layout/Loading';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

export function AddActivityPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    id,
    activityId,
  } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const client = useApolloClient();

  const {
    loading,
    error,
    data,
  } = useQuery(
    QUERY_ACTIVITY_EDIT_INFO,
    {
      variables: {
        activityId,
      },
      client,
    },
  );

  function handleCancel() {
    history.push(
      ASSOCIATION_ACTIVITY
        .replace(':id', id)
        .replace(':activityId', activityId),
    );
  }

  if (error) {
    return <ErrorServer error={error} />;
  }
  if ((
    !data || !data.adminActivity
  ) && !loading) {
    return <Error404 />;
  }
  const sports = (
    data && data.sports
  )
    ? data.sports
    : [];
  const categories = (
    data && data.categories
  )
    ? data.categories
    : [];
  const areas = (
    data && data.areas
  ) ? data.areas : [];
  const companies = (
    data && data.companies
  ) ? data.companies : [];
  const events = (
    data && data.events
  )
    ? data.events
    : [];
  const activity = (
    data && data.adminActivity
  )
    ? data.adminActivity
    : null;
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        <Container maxWidth={false} className={detailPageClasses.header}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1">{t('pages.activity.form.editTitle')}</Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item>
                <Button
                  variant="outlined"
                  className={detailPageClasses.headerOutlinedButton}
                  onClick={handleCancel}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Container>

        <Container maxWidth={false} className={detailPageClasses.content}>
          {loading && <Loading />}
          {!loading && activity && <ActivityForm
            associationId={id}
            activity={activity}
            sports={sports}
            areas={areas}
            companies={companies}
            categories={categories}
            events={events}
          />}
        </Container>
      </Box>
    </div>
  );
}

export default withAuthorization()(AddActivityPage);
