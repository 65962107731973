import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Transition from '../layout/TransitionAnimation';
import { MUTATION_CANCEL_EVENT, QUERY_EVENTS } from '../../constants/graphQLQueries';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import dialogFormStyle from '../../styles/dialogForm';

const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));
const CancelEventDialog = ({
  event,
  open,
  setOpen,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const client = useApolloClient();
  const dialogFormClasses = useDialogFormStyles();
  const dispatch = useDispatch();
  const [cancelReason, setCancelReason] = useState('');
  const [cancelReasonError, setCancelReasonError] = useState('');
  const [mutationCancelEvent, { loading }] = useMutation(
    MUTATION_CANCEL_EVENT,
    {
      client,
      update,
    },
  );
  const onCancelEvent = () => {
    if (cancelReason.trim() === '') {
      setCancelReasonError(t('pages.events.cancelReasonRequired'));
    } else {
      mutationCancelEvent({
        variables: {
          eventId: event.id,
          cancelReason,
        },
      }).then(() => {
        onGraphQLSuccess(t('pages.events.cancelEventSuccessMessage'));
      }).catch((error) => {
        onGraphQLError(error);
      });
    }
  };

  function update(cache, setEvent) {
    const { events } = cache.readQuery({ query: QUERY_EVENTS });
    const eventIndex = events.findIndex((ev) => ev.id === setEvent.data.setEvent.id);
    let newCache = events;
    if (eventIndex !== undefined && eventIndex !== -1) {
      newCache[eventIndex] = { ...events[eventIndex], ...setEvent.data.setEvent };
    } else {
      newCache = newCache.concat([setEvent.data.setEvent]);
    }
    cache.writeQuery({
      query: QUERY_EVENTS,
      data: { events: newCache },
    });
  }

  function onGraphQLSuccess(message) {
    setOpen(false);
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  const handleCancelClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={matches}
      open={open}
      scroll="body"
      onClose={handleCancelClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches
        ? 500
        : 750}
    >
      <DialogTitle id="simple-dialog-title">
        {t('pages.events.cancelDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <p>
          {t('pages.events.cancelDialogContentPart1')}
          <b>{t('pages.events.cancelDialogContentPart2', { count: event.timeSlotCount })}</b>
          {t('pages.events.cancelDialogContentPart3')}
          <b>{t('pages.events.cancelDialogContentPart4', { count: event.bookingCount })}</b>
        </p>
        {t('pages.events.cancelReasonTitle')}
        <TextField
          label={t('pages.events.cancelReason')}
          id="event-cancelReason"
          type="text"
          variant="outlined"
          onChange={(event) => {
            setCancelReason(event.target.value);
            setCancelReasonError('');
          }}
          margin="normal"
          value={cancelReason}
          fullWidth={true}
          helperText={cancelReasonError}
          error={cancelReasonError !== ''}
        />
        <p>{t('pages.events.cancelDialogContentQuestion')}</p>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={onCancelEvent}
          className={dialogFormClasses.button}
          variant="contained"
          color="secondary"
          disabled={loading}
        >
          {t('pages.events.cancelDialogCancelEventAction')}
        </Button>
        <Button
          type="button"
          onClick={handleCancelClose}
          className={dialogFormClasses.button}
          variant="outlined"
          color="primary"
          disabled={loading}
        >
          {t('common.back')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CancelEventDialog.propTypes = {
  event: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default CancelEventDialog;
