import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../layout/TransitionAnimation';
import dialogFormStyle from '../../styles/dialogForm';
import EventForm from './EventForm';
import AppDialogTitle from '../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

export default function EventFormDialog(props) {
  const {
    onClose,
    open,
    update,
    event,
    areas,
    companies,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      fullScreen={matches}
      open={open}
      scroll="body"
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: { maxWidth: '1084px' },
      }}
      TransitionComponent={Transition}
      transitionDuration={
        matches
          ? 500
          : 750
      }
    >
      <AppDialogTitle
        title={event === null
          ? t('pages.events.dialogTitleAdd')
          : t('pages.events.dialogTitleEdit')
        } handleClose={handleClose}
      />
      <EventForm
        handleClose={handleClose}
        update={update}
        event={event}
        areas={areas}
        companies={companies}
      />
    </Dialog>
  );
}

EventFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  event: PropTypes.object,
};

EventFormDialog.defaultProps = {
  event: null,
};
