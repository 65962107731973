import React, { useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import ReactQuill from 'react-quill';
import AntSwitch from '../forms/AntSwitch';
import { EVENT } from '../../constants/routes';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import LargePictureInput from '../forms/LargePictureInput';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY_EXTRA_DARK,
  SPORTIIZ_RED,
  SPORTIIZ_WHITE,
} from '../../constants/colors';
import { MUTATION_EVENT, QUERY_EVENT } from '../../constants/graphQLQueries';
import SaveIcon from '../icon/SaveIcon';
import QrCodeDownload from './QrCodeDownload';

const useStyles = makeStyles((theme) => (
  {
    fab: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      fontSize: '2em',
    },
    addButton: {
      position: 'fixed',
      top: 0,
      marginTop: 'calc(2em + 1px)',
      right: 'calc(220px + 4em)',
      zIndex: 10,
    },
    paperRow: {
      width: '100%',
      maxWidth: '400px',
      margin: '10px 0 40px',
      [theme.breakpoints.down('md')]: {
        maxWidth: 'unset',
        width: '100%',
        marginBottom: '20px',
      },
    },
    paperRowInput: {
      flexGrow: 1,
      maxWidth: '50%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'unset',
        width: '100%',
      },
    },
    buttonProgress: {
      color: SPORTIIZ_RED,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    container: {
      textAlign: 'left',
    },
  }
));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const INITIAL_VALUES = {
  eventId: null,
  name: '',
  nameError: '',
  description: '',
  descriptionError: '',
  areaId: '',
  areaIdError: '',
  visibility: 'PUBLIC',
  visibilityError: '',
  privatiizCompanyId: '',
  privatiizCompanyIdError: '',
  onlyForCompanyUsers: false,
  onlyForCompanyUsersError: '',
  picture: null,
  pictureBlob: null,
  pictureError: '',
  start: null,
  startError: '',
  end: null,
  endError: '',
};
const EditEventForm = (props) => {
  const {
    editedEvent,
    areas,
    companies,
  } = props;
  const client = useApolloClient();
  const {
    t,
    i18n,
  } = useTranslation();
  const classes = useStyles();
  const locale = i18n.language.slice(0, 2);
  const dispatch = useDispatch();
  const history = useHistory();
  const newState = (
    editedEvent === undefined || editedEvent === null
  )
    ? INITIAL_VALUES
    : {
      ...INITIAL_VALUES,
      eventId: editedEvent.id,
      name: editedEvent.name,
      description: editedEvent.description,
      activitiesIds: editedEvent.activitiesIds,
      areaId: editedEvent.area?.id ?? '',
      picture: editedEvent.picture || null,
      visibility: editedEvent.visibility,
      privatiizCompanyId: editedEvent.companyId,
      onlyForCompanyUsers: editedEvent.onlyForCompanyUsers,
      start: new Date(editedEvent.start * 1000),
      end: new Date(editedEvent.end * 1000),
    };
  const [values, setValues] = useState(newState);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [mutationEvent, { loading }] = useMutation(
    MUTATION_EVENT,
    {
      client,
      update,
    },
  );
  const handleChange = (name) => (event) => {
    let newValue = event.target.value;
    if (name === 'visibility') {
      if (values.visibility === 'PUBLIC') {
        newValue = 'LOCKED';
      } else {
        newValue = 'PUBLIC';
      }
    } else if (name === 'onlyForCompanyUsers') {
      newValue = !values.onlyForCompanyUsers;
    }
    setValues({
      ...values,
      [name]: newValue,
      [`${name}Error`]: '',
    });
  };
  const handleChangeFile = (name) => (event) => {
    const blob = new Blob([event.target.files[0]], { type: 'image/png' });
    const blobUrl = URL.createObjectURL(blob);
    setValues({
      ...values,
      [name]: event.target.files[0],
      [`${name}Blob`]: blobUrl,
      [`${name}Error`]: '',
    });
  };
  const handleStartChange = (date) => {
    setValues({
      ...values,
      start: date,
      startError: '',
    });
  };

  const handleEndChange = (date) => {
    setValues({
      ...values,
      end: date,
      endError: '',
    });
  };

  const handleQuillChange = (name) => (value) => {
    setValues({
      ...values,
      [name]: value,
      [`${name}Error`]: '',
    });
  };

  function update(cache, setEvent) {
    const newEvent = setEvent.data.setEvent;
    try {
      const eventCache = client.readQuery({
        query: QUERY_EVENT,
        variables: { id: newEvent.id },
      });
      const eventData = { ...eventCache };
      const eventIndex = eventData.association.activities.findIndex((ev) => ev.id === newEvent.id);
      eventData.association.activities[eventIndex] = newEvent;
      client.writeQuery({
        query: QUERY_EVENT,
        variables: { id: newEvent.id },
        eventData,
      });
    } catch (e) {
      console.warn('no QUERY_EVENT cache yet');
      console.warn(e);
    }
  }

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let error = false;
    let newValues = { ...values };
    if (!values.name || values.name.trim() === '') {
      newValues = {
        ...newValues,
        nameError: t('pages.events.form.formNameRequired'),
      };
      error = true;
    }
    if (!values.start || values.start === null) {
      newValues = {
        ...newValues,
        startError: t('pages.events.form.formStartRequired'),
      };
      error = true;
    }
    if (!values.end || values.end === null) {
      newValues = {
        ...newValues,
        endError: t('pages.events.form.formEndRequired'),
      };
      error = true;
    }
    if (values.start !== null && values.end !== null && values.start > values.end) {
      newValues = {
        ...newValues,
        endError: t('pages.events.form.formEndLessThanStart'),
      };
      error = true;
    }
    setValues(newValues);
    if (!error) {
      const vars = {
        eventId: editedEvent.id,
        description: values.description,
        name: values.name.trim(),
        start: Math.round(values.start.getTime() / 1000),
        end: Math.round(values.end.getTime() / 1000),
        visibility: values.visibility,
        onlyForCompanyUsers: values.onlyForCompanyUsers,
      };
      if (values.areaId !== null) {
        if (values.areaId !== '') {
          vars.areaId = values.areaId;
        } else {
          vars.areaId = null;
        }
      }
      if (values.privatiizCompanyId !== null) {
        if (values.privatiizCompanyId !== '') {
          vars.companyId = values.privatiizCompanyId;
        } else {
          vars.companyId = null;
        }
      }
      let hasUpload = false;
      if (values.pictureBlob) {
        vars.picture = values.picture;
        hasUpload = true;
      }
      mutationEvent({
        variables: vars,
        context: {
          hasUpload,
        },
      }).then(() => {
        onGraphQLSuccess(t('pages.events.form.formEditSuccessMessage'));
      }).catch((error) => {
        onGraphQLError(error);
      });
    }
  };

  function onGraphQLSuccess(message) {
    if (message) {
      dispatch(dispatchInfo(message));
    }
    history.push(EVENT.replace(':id', editedEvent.id));
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  return (
    <MuiPickersUtilsProvider
      utils={
        locale === 'fr'
          ? LocalizedUtils
          : DateFnsUtils
      }
      locale={localeMap[locale]}
    >
      <form onSubmit={onSubmit}>
        <Container maxWidth={false} className={classes.container}>
          <Grid
            container
            direction={matchesSm
              ? 'column'
              : 'row'}
            justifyContent={matchesSm
              ? 'center'
              : 'flex-start'}
            alignItems={matchesSm
              ? 'center'
              : 'flex-start'}
            wrap="nowrap"
            spacing={4}
          >
            <Grid item xs={12} md={6} lg={4} className={classes.paperRow}>
              <LargePictureInput
                defaultUrl={values.pictureBlob
                  ? ''
                  : values.picture}
                pictureBlob={values.pictureBlob}
                handleChangeFile={handleChangeFile}
                id="event-picture"
                pictureErrorMessage={values.pictureError}
              />
            </Grid>

            <Grid item className={classes.paperRowInput}>
              <TextField
                label={t('pages.events.form.name')}
                id="event-name"
                type="text"
                variant="outlined"
                onChange={handleChange('name')}
                margin="normal"
                required
                value={values.name}
                fullWidth={true}
                error={values.nameError !== ''}
                helperText={values.nameError}
              />
              <DatePicker
                id="event-start"
                value={values.start}
                error={values.startError !== ''}
                inputVariant="outlined"
                fullWidth
                required
                margin="normal"
                placeholder="__/__/____"
                format="dd/MM/yyyy"
                label={t('pages.events.form.start')}
                onChange={(date) => handleStartChange(date)}
                helperText={values.startError}
                showTodayButton={false}
                okLabel={t('common.ok')}
                cancelLabel={t('common.cancel')}
                readOnly={false}
                autoOk
              />
              <DatePicker
                id="event-start"
                value={values.end}
                error={values.endError !== ''}
                inputVariant="outlined"
                fullWidth
                required
                margin="normal"
                placeholder="__/__/____"
                format="dd/MM/yyyy"
                label={t('pages.events.form.end')}
                onChange={(date) => handleEndChange(date)}
                helperText={values.endError}
                showTodayButton={false}
                okLabel={t('common.ok')}
                cancelLabel={t('common.cancel')}
                readOnly={false}
                autoOk
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.paperRowInput}>
              <FormControl
                error={values.areaIdError !== ''}
                variant="outlined"
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="event-areaId" error={values.areaIdError !== ''}>
                  {t('pages.events.form.area')}
                </InputLabel>
                <Select
                  value={values.areaId || ''}
                  onChange={handleChange('areaId')}
                  input={
                    <OutlinedInput
                      labelWidth={locale === 'fr'
                        ? 55
                        : 34}
                      name="area"
                      id="event-areaId"
                    />
                  }
                  label={t('pages.events.form.area')}
                  inputProps={{
                    name: 'area',
                    id: 'event-areaId',
                  }}
                >
                  <MenuItem value={''} key="no-area">
                    <em>{t('pages.events.form.noArea')}</em>
                  </MenuItem>
                  {areas && areas.map((area) => (
                    <MenuItem value={area.id} key={`area-${area.id}`}>
                      {area.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="event-areaId-error-text" error={values.areaIdError !== ''}>
                  {values.areaIdError}
                </FormHelperText>
              </FormControl>

              <FormControl
                error={values.privatiizCompanyIdError !== ''}
                variant="outlined"
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel
                  htmlFor="event-privatiizCompanyId"
                  error={values.privatiizCompanyIdError !== ''}
                >
                  {t('pages.events.form.privatiizCompany')}
                </InputLabel>
                <Select
                  label={t('pages.events.form.privatiizCompany')}
                  value={values.privatiizCompanyId || ''}
                  onChange={handleChange('privatiizCompanyId')}
                  input={
                    <OutlinedInput
                      labelWidth={locale === 'fr'
                        ? 55
                        : 34}
                      name="company"
                      id="event-privatiizCompanyId"
                    />
                  }
                  inputProps={{
                    name: 'company',
                    id: 'event-privatiizCompanyId',
                  }}
                >
                  <MenuItem value="" key="no-company">
                    <em>{t('pages.events.form.noPrivatiizCompany')}</em>
                  </MenuItem>
                  {companies && companies.map((company) => (
                    <MenuItem value={company.id} key={`company-${company.id}`}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  id="event-privatiizCompanyId-error-text"
                  error={values.privatiizCompanyIdError !== ''}
                >
                  {values.privatiizCompanyIdError}
                </FormHelperText>
              </FormControl>
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    style={{
                      flexGrow: 1,
                      textAlign: 'left',
                      cursor: 'pointer',
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item style={{ marginTop: '5px' }}>
                        {t('pages.events.form.onlyForCompanyUsers')}
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {t('pages.events.form.onlyForCompanyUsersHelperText')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <AntSwitch
                      checked={values.onlyForCompanyUsers}
                      onChange={handleChange('onlyForCompanyUsers')}
                      name="onlyForCompanyUsers"
                    />
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth={false} className={classes.container}>
          <Grid
            container
            direction={matchesSm
              ? 'column'
              : 'row'}
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap="nowrap"
            spacing={4}
          >
            <Grid item xs={12} md={6}>
              <Typography variant={'h6'}>
                {t('pages.events.form.description')}
              </Typography>
              <FormControl error={values.descriptionError !== ''} style={{ width: '100%' }}>
                <ReactQuill
                  style={{ marginTop: '1em' }}
                  value={values.description}
                  onChange={handleQuillChange('description')}
                  modules={{
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                />
                <FormHelperText>{values.descriptionError !== ''}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={'h6'}>
                {t('pages.events.form.visibilityTitle')}
              </Typography>
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    style={{
                      flexGrow: 1,
                      textAlign: 'left',
                      cursor: 'pointer',
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item style={{ marginTop: '5px' }}>
                        {t('pages.events.form.visibility')}
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {'('}{t('common.optional')}{')'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <AntSwitch
                      checked={values.visibility === 'LOCKED'}
                      onChange={handleChange('visibility')}
                      name="visibility"
                    />
                  </Grid>
                </Grid>
              </Typography>
              <Typography
                variant="caption"
                style={{
                  marginTop: '1em',
                  marginBottom: '2em',
                }}
              >
                {t('pages.events.form.visibilityHelperText')}
              </Typography>
              <Grid
                container
                direction="row"
                spacing={4}
                wrap="nowrap"
                style={{
                  display: (
                    values.visibility === 'LOCKED'
                      ? 'flex'
                      : 'none'
                  ),
                }}
              >
                <Grid item xs={7}>
                  <Typography variant="h6" style={{ color: SPORTIIZ_GREY_EXTRA_DARK }}>
                    {t('pages.events.password')}
                    <span style={{ color: SPORTIIZ_BLUE }}>{editedEvent.password}</span>
                  </Typography>

                  <Typography variant="caption" style={{ marginTop: '1em' }}>
                    {t('pages.events.qrCodeHelperText1')}
                  </Typography>

                  <Typography variant="caption">
                    {t('pages.events.qrCodeHelperText2')}
                  </Typography>
                </Grid>
                <QrCodeDownload imageUrl={editedEvent.qrCodeURL} />
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Hidden mdDown>
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={onSubmit}
            disabled={loading}
          >
            {t('common.save')}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </Hidden>
        <Hidden lgUp>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            disabled={loading}
            onClick={onSubmit}
          >
            <SaveIcon color={SPORTIIZ_WHITE} />
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Fab>
        </Hidden>
      </form>
    </MuiPickersUtilsProvider>
  );
};
EditEventForm.propTypes = {
  editedEvent: PropTypes.object.isRequired,
  areas: PropTypes.array,
  companies: PropTypes.array,
};
EditEventForm.defaultProps = {
  areas: [],
  companies: [],
};

export default EditEventForm;
