import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import { getI18n, useTranslation } from 'react-i18next';
import { CARD_RADIUS } from '../../../constants/style';
import { getDayFromTimestamp } from '../../../services/dateService';
import CreditCardIcon from '../../icon/CreditCardIcon';
import PlusIcon from '../../icon/PlusIcon';
import { SPORTIIZ_GREY_GREEN, SPORTIIZ_RED } from '../../../constants/colors';
import MoreVerticalIcon from '../../icon/MoreVerticalIcon';

const useStyles = makeStyles((theme) => (
  {
    paper: {
      position: 'relative',
      borderRadius: CARD_RADIUS,
      minHeight: '260px',
      height: 'auto',
      textAlign: 'left',
      padding: '19px 31px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '240px',
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '200px',
      },
    },
    editButton: {
      position: 'absolute',
      top: '19px',
      right: '22px',
    },
  }
));

const CompanySubscriptionCard = (props) => {
  const {
    currentCompanySubscription,
    openDialog,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = getI18n().language.slice(0, 2);
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  return (
    <Paper elevation={0} className={classes.paper}>
      {hasSuperAdminRole && openDialog !== undefined && (
        <IconButton onClick={openDialog} className={classes.editButton}>
          <MoreVerticalIcon color={SPORTIIZ_GREY_GREEN} />
        </IconButton>
      )}
      <Typography variant="h5" style={{ fontWeight: '700' }}>
        {currentCompanySubscription !== undefined
        && currentCompanySubscription !== null
        && currentCompanySubscription.subscription !== null
          ? currentCompanySubscription.subscription.name
          : t('pages.company.subscription.noSubscription')
        }
      </Typography>
      {currentCompanySubscription !== undefined
        && currentCompanySubscription !== null
        && <>
          {currentCompanySubscription.end !== null
            && <Typography variant={'subtitle2'}>
              {t('pages.company.subscription.validityDate')}
              {getDayFromTimestamp(locale, currentCompanySubscription.end)}
            </Typography>
          }
          {currentCompanySubscription.subscription !== null
            && <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{
                margin: '22px 0px',
              }}
            >
              <Grid item>
                <Grid container direction="row" wrap="nowrap" spacing={2}>
                  <Grid item>
                    <CreditCardIcon color={SPORTIIZ_GREY_GREEN} width="32px" height="32px" />
                  </Grid>
                  <Grid item>
                    <Grid
                      container direction="column" justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <p style={{ margin: 0 }}>
                          <Typography component="span" variant="h5" style={{ fontWeight: '700' }}>
                            {currentCompanySubscription.subscription.price}€
                          </Typography>

                          <Typography
                            component="span"
                            variant="body1"
                            style={{ fontWeight: 600 }}
                          >
                            {' /'}
                            <span style={{ color: SPORTIIZ_RED }}>
                              {currentCompanySubscription.subscription.billingType === 'montly'
                                ? t('pages.company.subscription.month')
                                : t('pages.company.subscription.year')
                              }
                              </span>
                            {' HT'}
                          </Typography>
                        </p>
                      </Grid>
                      <Grid item>
                        <Typography variant={'subtitle2'}>
                          {t('pages.company.subscription.perEmployee')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="row" wrap="nowrap" spacing={2}>
                  <Grid item>
                    <PlusIcon color={SPORTIIZ_GREY_GREEN} width="32px" height="32px" />
                  </Grid>
                  <Grid item>
                    <Grid
                      container direction="column" justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <p style={{ margin: 0 }}>
                          <Typography component="span" variant="h5" style={{ fontWeight: '700' }}>
                            {currentCompanySubscription.subscription.activityPrice}€
                          </Typography>
                        </p>
                      </Grid>
                      <Grid item>
                        <Typography variant={'subtitle2'}>
                          {t('pages.company.subscription.perTimeSlot')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          {currentCompanySubscription.subscription !== null
            && <>
              <Typography component="div" variant={'subtitle2'} style={{ fontWeight: '600' }}>
                <span style={{ fontWeight: '900' }}>• </span>
                {t(
                  'pages.company.subscription.availableSlotText',
                  { count: currentCompanySubscription.availableSlots },
                )}
              </Typography>
              <Typography component="div" variant={'subtitle2'} style={{ fontWeight: '600' }}>
                <span style={{ fontWeight: '900' }}>• </span>
                {t('pages.company.subscription.animationText')}
              </Typography>
            </>
          }
        </>}
    </Paper>
  );
};

CompanySubscriptionCard.propTypes = {
  openDialog: PropTypes.func,
  currentCompanySubscription: PropTypes.object,
};

export default CompanySubscriptionCard;
