import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_WHITE } from '../../constants/colors';

const DisableIcon = ({
  color,
  width,
  height,
  verticalAlign,
}) => (
  <svg viewBox="0 0 17 17" width={width} height={height} style={{ verticalAlign }}>
    <g data-name="grid">
      <path
        fill={color}
        d="M8.49984 1.41675C4.604 1.41675 1.4165 4.60425 1.4165 8.50008C1.4165 12.3959 4.604 15.5834 8.49984 15.5834C12.3957 15.5834 15.5832 12.3959 15.5832 8.50008C15.5832 4.60425 12.3957 1.41675 8.49984 1.41675ZM2.83317 8.50008C2.83317 5.38341 5.38317 2.83341 8.49984 2.83341C9.77484 2.83341 10.979 3.25841 11.9707 4.03758L4.03734 11.9709C3.25817 10.9792 2.83317 9.77508 2.83317 8.50008ZM5.029 12.9626C6.02067 13.7417 7.22484 14.1667 8.49984 14.1667C11.6165 14.1667 14.1665 11.6167 14.1665 8.50008C14.1665 7.22508 13.7415 6.02091 12.9623 5.02925L5.029 12.9626Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

DisableIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  verticalAlign: PropTypes.string,
};

DisableIcon.defaultProps = {
  color: SPORTIIZ_WHITE,
  width: '1em',
  height: '1em',
  verticalAlign: 'sub',
};

export default DisableIcon;
