import {
  ENV_DEV,
  ENV_LOCAL,
  ENV_PREPROD,
  ENV_TEST,
} from './env';
// import * as firebase from 'firebase/app';

let firebaseConfig = {
  apiKey: 'AIzaSyDB9vIuVlj41K2btTOFn19smU6De6862CE',
  authDomain: 'sportiiz.firebaseapp.com',
  databaseURL: 'https://sportiiz.firebaseio.com',
  projectId: 'sportiiz',
  storageBucket: 'sportiiz.appspot.com',
  messagingSenderId: '348227081862',
  appId: '1:348227081862:web:54e474eba2b4722f2da307',
};

if (process.env.REACT_APP_ENV) {
  if (
    process.env.REACT_APP_ENV === ENV_LOCAL
    || process.env.REACT_APP_ENV === ENV_DEV
    || process.env.REACT_APP_ENV === ENV_TEST
  ) {
    firebaseConfig = {
      apiKey: 'AIzaSyCh-9UaOhMjUkgsrTYk0L9nf0zhOiGPzhQ',
      authDomain: 'sportiiz-dev.firebaseapp.com',
      databaseURL: 'https://sportiiz-dev.firebaseio.com',
      projectId: 'sportiiz-dev',
      storageBucket: 'sportiiz-dev.appspot.com',
      messagingSenderId: '262726182267',
      appId: '1:262726182267:web:76eb7361f2bb2708',
    };
  } else if (process.env.REACT_APP_ENV === ENV_PREPROD) {
    firebaseConfig = {
      apiKey: 'AIzaSyAxJwY_yi23ivJ3_0qnIbkp7PCG56AAjT0',
      authDomain: 'sportiiz-demo.firebaseapp.com',
      databaseURL: 'https://sportiiz-demo.firebaseio.com',
      projectId: 'sportiiz-demo',
      storageBucket: 'sportiiz-demo.appspot.com',
      messagingSenderId: '687952139900',
      appId: '1:687952139900:web:233f84c4f4853ecd3a1bf9',
      measurementId: 'G-L0LSTRMMDK',
    };
  }
}

// export const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
// export const firebaseAppAuth = firebaseApp.auth();
export default firebaseConfig;
