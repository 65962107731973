import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SPORTIIZ_GREY } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    text: {
      color: SPORTIIZ_GREY,
    },
  }
));

export default function HtmlText(props) {
  const { text } = props;
  const classes = useStyles();
  return (
    <div
      dangerouslySetInnerHTML={{ __html: text }}
      className={classes.text}
    />
  );
}

HtmlText.propTypes = {
  text: PropTypes.string.isRequired,
};
