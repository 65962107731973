import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function SportSelect(props) {
  const { sports, sportId, handleChange, isError, errorMessage } = props;
  const { t } = useTranslation();

  return (
    <FormControl error={isError} variant="outlined" fullWidth={true}>
      <InputLabel htmlFor="activity-sport">
        {t('pages.activity.form.sport')}
      </InputLabel>
      <Select
        value={sportId || 'empty'}
        onChange={handleChange('sportId')}
        input={
          <OutlinedInput
            labelWidth={43}
            name="sport"
            id="activity-sport"
          />
        }
      >
        <MenuItem value={'empty'} key={'sport-empty'}>
          {t('pages.activity.form.sportSelectDefault')}
        </MenuItem>
        {sports.map((sport) =>
          <MenuItem value={sport.id} key={`sport-${sport.id}`}>
            <img
              alt={'img'}
              src={sport.picture}
              height={'25px'}
              style={{
                verticalAlign: 'middle',
                marginRight: '10px',
              }}
            />
            {sport.name}
          </MenuItem>
        )}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

SportSelect.propTypes = {
  sports: PropTypes.array.isRequired,
  sportId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};