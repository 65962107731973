import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import { useTranslation } from 'react-i18next';
import backgroundImg from '../../resources/stock/signin2.jpeg';
import { SPORTIIZ_BLUE, SPORTIIZ_WHITE } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    container: {
      overflow: 'hidden',
      width: '100%',
      height: '100vh',
      padding: 0,
    },
    backgroundImageContainer: {
      height: '100vh',
      position: 'absolute',
      top: 0,
      right: '-20vw',
      zIndex: 0,
      overflow: 'hidden',
    },
    backgroundImageContainerLarge: {
      height: '100vh',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 0,
      overflow: 'hidden',
    },
    backgroundImage: {
      height: '100%',
    },
    labelGrid: {
      position: 'relative',
      bottom: '-35vh',
      padding: '6em',
    },
    blueLabel: {
      zIndex: 1,
      textAlign: 'left',
      color: SPORTIIZ_BLUE,
      fontSize: '4.5em',
      fontWeight: 800,
    },
    whiteLabel: {
      zIndex: 1,
      textAlign: 'left',
      color: SPORTIIZ_WHITE,
      fontSize: '2em',
      fontWeight: 600,
    },
  }
));

export default function LeftDescriptionBackground() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const backgroundImageContainerStyle = matches
    ? classes.backgroundImageContainer
    : classes.backgroundImageContainerLarge;
  return (
    <Hidden smDown>
      <div className={backgroundImageContainerStyle}>
        <img
          alt="background"
          src={backgroundImg}
          className={classes.backgroundImage}
        />
      </div>
      <Grid item md={5} lg={7}>
        <Container className={classes.container}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.labelGrid}
          >
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.blueLabel} component={'div'}>
                <div dangerouslySetInnerHTML={{ __html: t('sportiiz.slogan_part1') }} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.whiteLabel} component={'div'}>
                <div dangerouslySetInnerHTML={{ __html: t('sportiiz.slogan_part2') }} />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Hidden>
  );
}
