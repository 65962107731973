import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import withAuthorization from '../../hoc/withAuthorization';
import listPageStyles from '../../styles/listPage';
import ErrorServer from '../ErrorServer';
import { Loading } from '../../components/layout/Loading';
import UserList from '../../components/users/UserList';
import useIndividualUser from './UseIndividualUser';

const useListPageStyles = makeStyles((theme) => (
  listPageStyles(theme)
));

const IndividualsPage = () => {
  const { t } = useTranslation();
  const listPageClasses = useListPageStyles();
  const {
    users,
    loading,
    error,
  } = useIndividualUser();

  if (error) {
    return <ErrorServer error={error} />;
  }

  return (
    <Box className={listPageClasses.box}>
      <Container maxWidth={'lg'}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h1" className={listPageClasses.title}>
              {t('pages.individuals.title')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={'lg'} className={listPageClasses.container}>
        {loading && <Loading />}
        {!loading && (
          <UserList
            key={'individual_user_list'}
            users={users}
            emptyLabel={t('pages.companyMembers.emptyLabel')}
            hideMenu
          />
        )}
      </Container>
    </Box>
  );
};

export default withAuthorization()(IndividualsPage);
