import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useEditUser } from '../../../components/contexts/EditUserContext';
import ErrorServer from '../../ErrorServer';
import Sidebar from '../../../components/layout/sidebar/Sidebar';
import PlusIcon from '../../../components/icon/PlusIcon';
import { SPORTIIZ_WHITE } from '../../../constants/colors';
import UserList from '../../../components/users/UserList';
import WaitingUserList from '../../../components/users/WaitingUserList';
import MemberFormDialog from '../../../components/companies/members/MemberFormDialog';
import { Loading } from '../../../components/layout/Loading';
import detailPageStyles from '../../../styles/detailPage';
import useCompany from '../../../components/hooks/UseCompany';
import { DEFAULT_USER, MUTATION_REMOVE_COMPANY_USER } from '../../../constants/graphQLQueries';
import BlueTabSection from '../../../components/layout/BlueTabSection/BlueTabSection';
import BlueTab from '../../../components/layout/BlueTabSection/BlueTab';
import { dispatchInfo, handleError } from '../../../store/infos/actions';
import DeleteMemberDialog from '../../../components/companies/members/DeleteMemberDialog';

export const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const useStyles = makeStyles((theme) => (
  {
    searchZone: {
      marginTop: '2em',
      marginBottom: '3em',
      textAlign: 'left',
    },
    searchInput: {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }
));
const CompanyMembersContent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { setEditUser } = useEditUser();
  const classes = useStyles();
  const {
    loading,
    error,
    company,
    users,
    waitingCompanyUsers,
    refetch,
  } = useCompany(id);
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState('');
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);

  const [mutationRemoveCompanyUser, { loading: mutationLoading }] = useMutation(
    MUTATION_REMOVE_COMPANY_USER,
    { client },
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleClickOpen(event, user) {
    event.preventDefault();
    if (user !== undefined) {
      setEditUser(user);
    } else {
      setEditUser(DEFAULT_USER);
    }
  }

  function handleClose() {
    setEditUser(null);
  }

  function handleOpenRemoveDialog(member) {
    setMemberToRemove(member);
    setOpenRemoveDialog(true);
  }

  function handleCloseRemoveDialog() {
    setOpenRemoveDialog(false);
    setTimeout(() => setMemberToRemove(null), 1000);
  }

  function handleRemoveMember() {
    mutationRemoveCompanyUser({
      variables: {
        companyId: id,
        userId: memberToRemove.id,
        role: 'user',
      },
    }).then((result) => {
      if (result.data.removeCompanyUser) {
        refetch();
        dispatch(dispatchInfo(t('pages.companyMembers.deleteMemberSuccess')));
      } else {
        dispatch(handleError(t('pages.companyMembers.deleteMemberFail')));
      }
    }).catch((error) => {
      dispatch(handleError(error));
    }).finally(() => {
      handleCloseRemoveDialog();
    });
  }

  if (error) {
    return <ErrorServer error={error} />;
  }

  const displayedUsers = users.filter((u) => {
    if (
      search.trim() !== ''
      && !(
        (u.email ?? '').toLowerCase().includes(search.toLowerCase())
        || (u.firstname ?? '').toLowerCase().includes(search.toLowerCase())
        || (u.lastname ?? '').toLowerCase().includes(search.toLowerCase())
        || `${(u.firstname ?? '')} ${(u.lastname ?? '')}`
          .toLowerCase()
          .includes(search.toLowerCase())
        || `${(u.lastname ?? '')} ${(u.firstname ?? '')}`
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    ) {
      return false;
    }
    return true;
  });
  const displayedWaitingCompanyUsers = waitingCompanyUsers.filter((u) => {
    if (
      search.trim() !== ''
      && !(
        (u.email ?? '').toLowerCase().includes(search.toLowerCase())
        || (u.firstname ?? '').toLowerCase().includes(search.toLowerCase())
        || (u.lastname ?? '').toLowerCase().includes(search.toLowerCase())
        || `${(u.firstname ?? '')} ${(u.lastname ?? '')}`
          .toLowerCase()
          .includes(search.toLowerCase())
        || `${(u.lastname ?? '')} ${(u.firstname ?? '')}`
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    ) {
      return false;
    }
    return true;
  });

  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.box} component="section">
        <Container maxWidth={false}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1">{t('pages.companyMembers.title')}</Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item>
                <Button
                  variant="contained"
                  className={detailPageClasses.addButton}
                  onClick={handleClickOpen}
                >
                  {t('pages.companyMembers.addButton')}
                </Button>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Fab
                color="primary"
                aria-label="add"
                className={detailPageClasses.fab}
                onClick={handleClickOpen}
              >
                <PlusIcon color={SPORTIIZ_WHITE} />
              </Fab>
            </Hidden>
          </Grid>
        </Container>
        <Container maxWidth={false} className={classes.searchZone}>
          <TextField
            label={t('pages.companyMembers.search')}
            placeholder={t('pages.companyMembers.searchPlaceholder', { companyName: company.name })}
            id="member-search"
            type="text"
            variant="outlined"
            onChange={(event) => setSearch(event.target.value)}
            margin="normal"
            value={search}
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.searchInput}
          />
        </Container>
        <BlueTabSection value={value} onChange={handleChange}>
          <BlueTab label={t('pages.companyMembers.title')} />
          <BlueTab label={t('pages.companyMembers.waitingCompanyUser')} />
        </BlueTabSection>

        <Box component="section" className={detailPageClasses.footerBox}>
          <div className={detailPageClasses.footerContainer}></div>
          <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
            {loading && <Loading />}
            {!loading && value === 0 && (
              <UserList
                key={'user_list'}
                users={displayedUsers}
                emptyLabel={
                  search.trim() !== ''
                    ? t('pages.companyMembers.searchEmptyLabel')
                    : t('pages.companyMembers.emptyLabel')
                }
                openRemoveDialog={handleOpenRemoveDialog}
              />
            )}
            {!loading
              && value === 1 && <WaitingUserList
                key={'waiting_user_list'}
                waitingCompanyUsers={displayedWaitingCompanyUsers}
              />}
          </Container>
        </Box>
      </Box>
      <MemberFormDialog
        handleClose={handleClose}
        company={company}
        refetch={refetch}
      />
      <DeleteMemberDialog
        open={openRemoveDialog}
        onClose={handleCloseRemoveDialog}
        loading={mutationLoading}
        handleRemove={handleRemoveMember}
      />
    </div>
  );
};

export default CompanyMembersContent;
