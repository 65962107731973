import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const CalendarIcon = ({
  color,
  width,
  height,
  className,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height} className={className}>
    <g data-name="Layer 2">
      <g data-name="calendar">
        <rect width="24" height="24" opacity="0" fill={color} />
        <path
          d="M18 4h-1V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zM6 6h1v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h1a1 1 0 0 1 1 1v4H5V7a1 1 0 0 1 1-1zm12 14H6a1 1 0 0 1-1-1v-6h14v6a1 1 0 0 1-1 1z"
          fill={color}
        />
        <circle cx="8" cy="16" r="1" fill={color} />
        <path d="M16 15h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z" fill={color} />
      </g>
    </g>
  </svg>
);

CalendarIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

CalendarIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
  className: '',
};
export default CalendarIcon;
