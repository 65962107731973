import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const MoreVerticalIcon = ({
  color,
  width,
  height,
  className,
  onClick,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height} className={className} onClick={onClick}>
    <g data-name="Layer 2">
      <g data-name="more-vertical">
        <circle cx="12" cy="12" r="2" fill={color} />
        <circle cx="12" cy="5" r="2" fill={color} />
        <circle cx="12" cy="19" r="2" fill={color} />
      </g>
    </g>
  </svg>
);

MoreVerticalIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

MoreVerticalIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '20px',
  height: '20px',
  className: '',
};

export default MoreVerticalIcon;
