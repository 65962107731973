import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CameraIcon from '../icon/CameraIcon';
import ImageIcon from '../icon/ImageIcon';
import {
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_LIGHT_PALE_BLUE,
  SPORTIIZ_PALE_BLUE,
  SPORTIIZ_WHITE,
} from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    preview: {
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'unset',
    },
    uploadLabel: {
      width: '100%',
      height: '100%',
    },
    uploadButton: {
      position: 'relative',
      left: 'calc(50% - 25px)',
      top: '-30px',
      height: '50px',
      width: '50px',
      padding: '7px',
      zIndex: 10,
      backgroundColor: SPORTIIZ_WHITE,
      '&:hover': {
        backgroundColor: SPORTIIZ_LIGHT_PALE_BLUE,
        color: 'white',
      },
    },
    avatar: {
      width: '200px',
      height: '200px',
      backgroundColor: SPORTIIZ_LIGHT_PALE_BLUE,
      border: `1px solid ${SPORTIIZ_PALE_BLUE}`,
    },
  }
));

export default function AvatarPictureInput(props) {
  const {
    pictureBlob,
    handleChangeFile,
    id,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControl
      fullWidth={true}
      variant="standard"
      style={{ paddingRight: '40px' }}
    >
      <div className={classes.preview}>
        {pictureBlob && (
          <Avatar
            alt="img"
            src={pictureBlob}
            className={classes.avatar}
          />
        )}
        {!pictureBlob && (
          <Avatar
            className={classes.avatar}
          >
            <ImageIcon color={SPORTIIZ_PALE_BLUE} height="50px" width="50px" />
          </Avatar>
        )}
      </div>
      <input
        aria-label="picture"
        accept="image/*"
        style={{ display: 'none' }}
        id={id}
        onChange={handleChangeFile('picture')}
        type="file"
      />
      <label htmlFor={id} className={classes.uploadLabel}>
        <Fab
          component="span"
          title={t('common.uploadPicture')}
          className={classes.uploadButton}
        >
          <CameraIcon color={SPORTIIZ_GREY_GREEN} height="30" width="30"/>
        </Fab>
      </label>
    </FormControl>
  );
}

AvatarPictureInput.propTypes = {
  pictureBlob: PropTypes.any,
  handleChangeFile: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

