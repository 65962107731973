import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import {
  SPORTIIZ_GREY,
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_RED,
  SPORTIIZ_WHITE,
} from '../../constants/colors';
import SaveIcon from '../icon/SaveIcon';
import SportSelect from '../forms/SportSelect';
import CreditCardIcon from '../icon/CreditCardIcon';
import ClockIcon from '../icon/ClockIcon';
import PeopleIcon from '../icon/PeopleIcon';
import { ASSOCIATION_ACTIVITY } from '../../constants/routes';
import {
  MUTATION_ACTIVITY,
  QUERY_ACTIVITY_EDIT_INFO,
  QUERY_ASSOCIATION, QUERY_EVENT,
} from '../../constants/graphQLQueries';
import { LABEL_RADIUS } from '../../constants/style';
import LargePictureInput from '../forms/LargePictureInput';
import { getDisplayName } from '../../services/userService';
import useAssociation from '../hooks/UseAssociation';

const useStyles = makeStyles((theme) => (
  {
    fab: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      fontSize: '2em',
    },
    addButton: {
      position: 'fixed',
      top: 0,
      marginTop: 'calc(2em + 1px)',
      right: 'calc(220px + 4em)',
      zIndex: 10,
    },
    paperRow: {
      width: '100%',
      maxWidth: '400px',
      margin: '10px 0 40px',
    },
    contentRow: {
      width: '100%',
      textAlign: 'left',
    },
    contentRowPart: {
      padding: '1em 2em',
    },
    sportPart: {
      textAlign: 'left',
      padding: '1em 2em',
    },
    fullWidthDownSm: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    iconNumberRoot: {
      paddingBottom: '1em',
    },
    icon: {
      padding: '22px 15px;',
    },
    text: {
      color: SPORTIIZ_GREY,
      [theme.breakpoints.down('xs')]: {
        paddingTop: '1.2em',
        paddingLeft: '1em',
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      borderRadius: LABEL_RADIUS,
    },
    buttonProgress: {
      color: SPORTIIZ_RED,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    formControl: {
      marginTop: '8px',
      marginBottom: '8px',
    },
    hourPadding: {
      paddingRight: '8px',
    },
  }
));

const INITIAL_VALUES = {
  associationId: null,
  activityId: null,
  name: '',
  nameError: false,
  nameErrorMessage: '',
  subtitle: '',
  subtitleError: false,
  subtitleErrorMessage: '',
  isDiscovery: false,
  requireLicence: false,
  sportId: null,
  sportIdError: false,
  sportIdErrorMessage: '',
  price: 1,
  priceError: false,
  priceErrorMessage: '',
  remuneration: 30,
  remunerationError: false,
  remunerationErrorMessage: '',
  durationHour: 0,
  durationHourError: false,
  durationHourErrorMessage: '',
  durationMinutes: 0,
  durationMinutesError: false,
  durationMinutesErrorMessage: '',
  durationError: false,
  durationErrorMessage: '',
  minimumEntrant: 1,
  minimumEntrantError: false,
  minimumEntrantErrorMessage: '',
  maximumEntrant: 12,
  maximumEntrantError: false,
  maximumEntrantErrorMessage: '',
  description: null,
  picture: null,
  pictureError: false,
  pictureErrorMessage: '',
  categories: [],
  details: '',
  areaId: null,
  areaIdError: false,
  areaIdErrorMessage: '',
  country: 'fr',
  street: '',
  streetError: false,
  streetErrorMessage: '',
  city: '',
  cityError: false,
  cityErrorMessage: '',
  zipCode: '',
  zipCodeError: false,
  zipCodeErrorMessage: '',
  additionalAddress: '',
  additionalAddressError: false,
  additionalAddressErrorMessage: '',
  latitude: '45.76532',
  latitudeError: false,
  latitudeErrorMessage: '',
  longitude: '3.136046',
  longitudeError: false,
  longitudeErrorMessage: '',
  pictureBlob: null,
  isPrivatiiz: false,
  isFreeBookingDisabled: false,
  privatiizCompanyId: '',
  privatiizCompanyIdError: false,
  privatiizCompanyIdMessage: '',
  eventId: null,
  eventIdError: false,
  eventIdErrorMessage: '',
  coachesIds: [],
  coachesIdsError: false,
  coachesIdsErrorMessage: '',
};

export default function ActivityForm(props) {
  const {
    associationId,
    activity,
    sports,
    categories,
    areas,
    companies,
    events,
  } = props;
  const dispatch = useDispatch();
  const { coaches } = useAssociation(associationId);
  const {
    t,
    i18n,
  } = useTranslation();
  const locale = i18n.language.slice(0, 2);
  const history = useHistory();
  const classes = useStyles();
  const client = useApolloClient();
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const newState = (
    activity === undefined || activity === null
  )
    ? {
      ...INITIAL_VALUES,
      associationId,
    }
    : {
      ...INITIAL_VALUES,
      associationId,
      activityId: activity.id,
      name: activity.name,
      subtitle: activity.subtitle,
      isDiscovery: activity.isDiscovery,
      requireLicence: activity.requireLicence,
      sportId: activity.sport?.id,
      areaId: activity.area?.id,
      price: activity.price,
      remuneration: activity.remuneration,
      durationHour: Math.floor(activity.duration / 3600),
      durationMinutes: Math.floor(
        (
          activity.duration - (
            Math.floor(activity.duration / 3600) * 3600
          )
        ) / 60,
      ),
      minimumEntrant: activity.minimumEntrant,
      maximumEntrant: activity.maximumEntrant,
      description: activity.description,
      categories: activity.categories.map((elem) => elem.id),
      details: activity.details,
      city: activity.city,
      street: activity.street,
      additionalAddress: activity.additionalAddress,
      zipCode: activity.zipCode,
      latitude: activity.latitude,
      longitude: activity.longitude,
      pictureBlob: activity.picture || null,
      isPrivatiiz: activity.isPrivatiiz,
      isFreeBookingDisabled: activity.isFreeBookingDisabled,
      privatiizCompanyId: activity.privatiizCompany
        ? activity.privatiizCompany.id
        : null,
      eventId: activity.eventId,
      coachesIds: activity.coachesIds,
    };
  const [values, setValues] = useState(newState);

  const [mutationActivity, { loading }] = useMutation(
    MUTATION_ACTIVITY,
    {
      client,
      update,
    },
  );

  function update(cache, editActivity) {
    // TODO Find why QUERY_ASSOCIATION and QUERY_ACTIVITY_EDIT_INFO fail on edit
    const newActivity = editActivity.data.setActivity;
    try {
      const { association } = {
        ...client.readQuery({
          query: QUERY_ASSOCIATION,
          variables: { id: associationId },
        }),
      };

      const activityIndex = association.activities.findIndex((activity) => (
        activity.id === newActivity.id
      ));
      association.activities[activityIndex] = newActivity;
      client.writeQuery({
        query: QUERY_ASSOCIATION,
        variables: { id: associationId },
        data: { association },
      });
    } catch (e) {
      console.warn('no QUERY_ASSOCIATION cache yet');
      console.warn(e);
    }
    try {
      const {
        adminActivity,
        ...otherData
      } = {
        ...client.readQuery({
          query: QUERY_ACTIVITY_EDIT_INFO,
          variables: {
            id: associationId,
            activityId: newActivity.id,
          },
        }),
      };
      client.writeQuery({
        query: QUERY_ACTIVITY_EDIT_INFO,
        variables: {
          id: associationId,
          activityId: newActivity.id,
        },
        data: {
          adminActivity: newActivity,
          ...otherData,
        },
      });
    } catch (e) {
      console.warn('no QUERY_ACTIVITY_EDIT_INFO cache yet');
      console.warn(e);
    }
    try {
      if (newActivity.eventId !== null) {
        const { event } = {
          ...client.readQuery({
            query: QUERY_EVENT,
            variables: { id: newActivity.eventId },
          }),
        };
        event.activities.push(newActivity);
        client.writeQuery({
          query: QUERY_EVENT,
          variables: { id: newActivity.eventId },
          data: { event },
        });
      }
    } catch (e) {
      console.warn('no QUERY_EVENT cache yet');
      console.warn(e);
    }
  }

  const handleChange = (name) => (event) => {
    const newValue = { ...values };
    if (name.includes('price')) {
      newValue[name] = parseInt(event.target.value, 10);
    } else {
      newValue[name] = event.target.value;
    }
    newValue[`${name}Error`] = false;
    newValue[`${name}ErrorMessage`] = '';
    if (name.includes('duration')) {
      newValue.durationError = false;
      newValue.durationErrorMessage = '';
    }
    setValues(newValue);
  };

  const handleQuillChange = (name) => (value) => {
    setValues({
      ...values,
      [name]: value,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });
  };

  const handleChangeFile = (name) => (event) => {
    const blob = new Blob([event.target.files[0]], { type: 'image/png' });
    const blobUrl = URL.createObjectURL(blob);
    setValues({
      ...values,
      [name]: event.target.files[0],
      [`${name}Blob`]: blobUrl,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });
  };

  const handleChangeCheckbox = (name) => (event) => {
    if (name === 'isPrivatiiz') {
      setValues({
        ...values,
        [name]: event.target.checked,
        privatiizCompanyId: INITIAL_VALUES.privatiizCompanyId,
        privatiizCompanyIdError: INITIAL_VALUES.privatiizCompanyIdError,
        privatiizCompanyIdMessage: INITIAL_VALUES.privatiizCompanyIdMessage,
      });
    } else {
      setValues({
        ...values,
        [name]: event.target.checked,
      });
    }
  };
  const onSubmit = (event) => {
    let newValues = { ...values };
    let error = false;
    if (values.name === null || values.name.trim() === '') {
      newValues = {
        ...newValues,
        nameError: true,
        nameErrorMessage: t('pages.activity.form.formNameRequired'),
      };
      error = true;
    }
    if (values.name.length > 190) {
      newValues = {
        ...newValues,
        nameError: true,
        nameErrorMessage: t('pages.activity.form.formNameMustBeLessThan'),
      };
      error = true;
    }
    if (values.picture === null && (
      !activity || activity.picture === null
    )) {
      newValues = {
        ...newValues,
        pictureError: true,
        pictureErrorMessage: t('pages.activity.form.formPictureRequired'),
      };
      error = true;
    }
    if (values.sportId === null || values.sportId === 'empty') {
      newValues = {
        ...newValues,
        sportIdError: true,
        sportIdErrorMessage: t('pages.activity.form.formSportRequired'),
      };
      error = true;
    }

    if (parseInt(values.price, 10) === null || parseInt(values.price, 10) < 0) {
      newValues = {
        ...newValues,
        priceError: true,
        priceErrorMessage: t('pages.activity.form.formPriceIncorrect'),
      };
      error = true;
    }
    if (
      values.minimumEntrant === null
      || values.minimumEntrant === ''
      || parseInt(values.minimumEntrant, 10) < 0
    ) {
      newValues = {
        ...newValues,
        minimumEntrantError: true,
        minimumEntrantErrorMessage: t('pages.activity.form.formMinimumEntrantPositive'),
      };
      error = true;
    }
    if (
      values.maximumEntrant === null
      || values.maximumEntrant === ''
      || parseInt(values.maximumEntrant, 10) < 1
    ) {
      newValues = {
        ...newValues,
        maximumEntrantError: true,
        maximumEntrantErrorMessage: t('pages.activity.form.formMaximumEntrantPositive'),
      };
      error = true;
    } else if (parseInt(values.maximumEntrant, 10) < parseInt(values.minimumEntrant, 10)) {
      newValues = {
        ...newValues,
        maximumEntrantError: true,
        maximumEntrantErrorMessage: t('pages.activity.form.formMaximumEntrantGreaterThan'),
      };
      error = true;
    }
    if (!values.areaId || values.areaId === '') {
      newValues = {
        ...newValues,
        areaIdError: true,
        areaIdErrorMessage: t('pages.activity.form.formAreaRequired'),
      };
      error = true;
    }
    if (values.zipCode === null || values.zipCode === '') {
      newValues = {
        ...newValues,
        zipCodeError: true,
        zipCodeErrorMessage: t('pages.activity.form.formZipCodeRequired'),
      };
      error = true;
    }
    if (values.city === null || values.city === '') {
      newValues = {
        ...newValues,
        cityError: true,
        cityErrorMessage: t('pages.activity.form.formCityRequired'),
      };
      error = true;
    }
    if (values.street === null || values.street === '') {
      newValues = {
        ...newValues,
        streetError: true,
        streetErrorMessage: t('pages.activity.form.formStreetRequired'),
      };
      error = true;
    }
    if (values.latitude === null || values.latitude === '') {
      newValues = {
        ...newValues,
        latitudeError: true,
        latitudeErrorMessage: t('pages.activity.form.formLatitudeRequired'),
      };
      error = true;
    }
    if (values.longitude === null || values.longitude === '') {
      newValues = {
        ...newValues,
        longitudeError: true,
        longitudeErrorMessage: t('pages.activity.form.formLongitudeRequired'),
      };
      error = true;
    }
    if (
      values.durationHour === null
      || parseInt(values.durationHour, 10) < 0
      || parseInt(values.durationHour, 10) > 23
    ) {
      newValues = {
        ...newValues,
        durationHourError: true,
        durationHourErrorMessage: t('pages.activity.form.formDurationHourMustBeBetween'),
      };
      error = true;
    }
    if (
      values.durationMinutes === null
      || parseInt(values.durationMinutes, 10) < 0
      || parseInt(values.durationMinutes, 10) > 59
    ) {
      newValues = {
        ...newValues,
        durationMinutesError: true,
        durationMinutesErrorMessage: t('pages.activity.form.formDurationMinuteMustBeBetween'),
      };
      error = true;
    }
    if (
      (
        parseInt(values.durationHour, 10) * 3600 + parseInt(values.durationMinutes, 10) * 60
      ) < (
        15 * 60
      )
    ) {
      newValues = {
        ...newValues,
        durationError: true,
        durationErrorMessage: t('pages.activity.form.formDurationMustAtLeast'),
      };
      error = true;
    }
    if (values.description === null || values.description === '') {
      newValues = {
        ...newValues,
        descriptionError: true,
        descriptionErrorMessage: t('pages.activity.form.formDescriptionNull'),
      };
      error = true;
    }
    if (values.details === null || values.details === '') {
      newValues = {
        ...newValues,
        detailsError: true,
        detailsErrorMessage: t('pages.activity.form.formDetailsNull'),
      };
      error = true;
    }
    if (values.coachesIds.length === 0) {
      newValues = {
        ...newValues,
        coachesIdsError: true,
        coachesIdsErrorMessage: t('pages.activity.form.formCoachesRequired'),
      };
      error = true;
    }
    if (hasSuperAdminRole) {
      if (
        values.remuneration === undefined
        || values.remuneration === null
        || values.remuneration === ''
      ) {
        newValues = {
          ...newValues,
          remunerationError: true,
          remunerationErrorMessage: t('pages.activity.form.remunerationEmpty'),
        };
        error = true;
      }
      if (values.remuneration < 1) {
        newValues = {
          ...newValues,
          remunerationError: true,
          remunerationErrorMessage: t('pages.activity.form.remunerationMustAtLeast'),
        };
        error = true;
      }
      if (
        values.isPrivatiiz
        && (
          values.privatiizCompanyId === null || values.privatiizCompanyId === ''
        )
      ) {
        newValues = {
          ...newValues,
          privatiizCompanyIdError: true,
          privatiizCompanyIdErrorMessage: t('pages.activity.form.privatiizCompanyIdRequired'),
        };
        error = true;
      }
    }
    setValues(newValues);

    if (!error) {
      if (event) {
        event.preventDefault();
      }
      submit();
    }
  };

  function submit() {
    const vars = {
      associationId: values.associationId,
      name: values.name.trim(),
      subtitle: values.subtitle,
      isDiscovery: values.isDiscovery,
      isFreeBookingDisabled: values.isFreeBookingDisabled,
      requireLicence: values.requireLicence,
      sportId: values.sportId,
      areaId: values.areaId,
      price: values.price,
      duration: values.durationHour * 3600 + values.durationMinutes * 60,
      minimumEntrant: values.minimumEntrant,
      maximumEntrant: values.maximumEntrant,
      description: values.description,
      categories: values.categories,
      details: values.details,
      city: values.city.trim(),
      street: values.street.trim(),
      additionalAddress: values.additionalAddress.trim(),
      zipCode: values.zipCode.trim(),
      country: values.country,
      latitude: values.latitude,
      longitude: values.longitude,
      eventId: values.eventId !== ''
        ? values.eventId
        : null,
      coachesIds: values.coachesIds,
    };

    let hasUpload = false;
    let successMessage = t('pages.activity.form.formAddSuccessMessage');

    if (values.activityId) {
      vars.activityId = values.activityId;
      successMessage = t('pages.activity.form.formEditSuccessMessage');
    }
    if (values.picture) {
      vars.picture = values.picture;
      hasUpload = true;
    }

    if (hasSuperAdminRole) {
      if (values.remuneration) {
        vars.remuneration = values.remuneration;
      }
      if (values.isPrivatiiz) {
        vars.privatiizCompanyId = values.privatiizCompanyId;
      } else {
        vars.privatiizCompanyId = null;
      }
    }
    mutationActivity({
      variables: vars,
      context: { hasUpload },
    }).then((result) => {
      onGraphQLSuccess(successMessage, result.data.setActivity.id);
    }).catch((error) => {
      onGraphQLError(error);
    });
  }

  function onGraphQLSuccess(message, activityId) {
    if (message) {
      dispatch(dispatchInfo(message));
    }
    history.push(ASSOCIATION_ACTIVITY
      .replace(':id', values.associationId)
      .replace(':activityId', activityId));
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Container maxWidth={false}>
        <Grid
          container
          direction={matchesSm
            ? 'column'
            : 'row'}
          justifyContent={matchesSm
            ? 'center'
            : 'flex-start'}
          alignItems={matchesSm
            ? 'center'
            : 'flex-start'}
        >
          <Grid item xs={12} md={6} lg={4} className={classes.paperRow}>
            <LargePictureInput
              pictureBlob={values.pictureBlob}
              handleChangeFile={handleChangeFile}
              id="activity-picture"
              pictureErrorMessage={values.pictureErrorMessage}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4} className={classes.fullWidthDownSm}>
            <div className={classes.sportPart}>
              <SportSelect
                key={'sport-select'}
                sports={sports}
                sportId={values.sportId}
                handleChange={handleChange}
                isError={values.sportIdError}
                errorMessage={values.sportIdErrorMessage}
              />
              <TextField
                label={t('pages.activity.form.name')}
                id="activity-name"
                type="text"
                variant="outlined"
                onChange={handleChange('name')}
                margin="normal"
                value={values.name}
                fullWidth={true}
                error={values.nameError}
                helperText={values.nameErrorMessage}
              />
              <TextField
                label={t('pages.activity.form.subtitle')}
                id="activity-subtitle"
                type="text"
                variant="outlined"
                onChange={handleChange('subtitle')}
                margin="normal"
                value={values.subtitle}
                fullWidth={true}
                error={values.subtitleError}
                helperText={values.subtitleErrorMessage}
              />

              <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                <InputLabel htmlFor="activity-categories">
                  {t('pages.activity.categoriesTitle')}
                </InputLabel>
                <Select
                  multiple
                  value={values.categories}
                  onChange={handleChange('categories')}
                  input={
                    <OutlinedInput
                      id="activity-categories"
                      style={{ width: '100%' }}
                      labelWidth={80}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          size="small"
                          color="primary"
                          key={value}
                          label={categories.find((elem) => elem.id === value).name}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth={true}
                error={values.coachesIdsError}
              >
                <InputLabel
                  htmlFor="activity-coachesIds"
                  error={values.coachesIdsError}
                >
                  {t('pages.activity.form.coaches')}
                </InputLabel>
                <Select
                  multiple
                  value={values.coachesIds || []}
                  onChange={handleChange('coachesIds')}
                  error={values.coachesIdsError}
                  input={
                    <OutlinedInput
                      id="activity-coachesIds"
                      style={{ width: '100%' }}
                      labelWidth={80}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {
                        selected.map((coachId) => {
                          const coach = coaches.find((elem) => elem.id === coachId);
                          let coachDisplayName = '';
                          if (coach) {
                            coachDisplayName = getDisplayName(coach);
                          }
                          return (
                            <Chip
                              size="small"
                              color="primary"
                              key={`chip-${coachId}`}
                              label={coachDisplayName}
                              className={classes.chip}
                            />
                          );
                        })
                      }
                    </div>
                  )}
                >
                  {coaches.map((coach) => (
                    <MenuItem key={coach.id} value={coach.id}>
                      {getDisplayName(coach)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={values.coachesIdsError}>
                  {values.coachesIdsErrorMessage}
                </FormHelperText>
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth={true}
                margin={'normal'}
                error={values.eventIdError}
                disabled={!hasSuperAdminRole}
              >
                <InputLabel htmlFor="activity-eventId" error={values.eventIdError}>
                  {t('pages.activity.form.eventId')}
                </InputLabel>
                <Select
                  value={values.eventId || ''}
                  onChange={handleChange('eventId')}
                  inputProps={{
                    name: 'eventId',
                    id: 'activity-eventId',
                  }}
                >
                  <MenuItem value="" key="no-company">
                    {t('pages.activity.form.noEvent')}
                  </MenuItem>
                  {events.map((event) => (
                    <MenuItem value={event.id} key={`event-${event.id}`}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="activity-eventId-error-text" error={values.eventIdError}>
                  {values.eventIdErrorMessage}
                </FormHelperText>
              </FormControl>

            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className={classes.fullWidthDownSm}>
            <div className={classes.sportPart}>
              {hasSuperAdminRole && <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isPrivatiiz}
                      onChange={handleChangeCheckbox('isPrivatiiz')}
                      value="isPrivatiiz"
                      color="secondary"
                    />
                  }
                  label="Privatiiz"
                />
                <br />
                <FormControl
                  error={values.privatiizCompanyIdError}
                  variant="outlined"
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="activity-privatiizCompanyId">
                    {t('pages.activity.form.privatiizCompanyId')}
                  </InputLabel>
                  <Select
                    value={values.privatiizCompanyId || ''}
                    onChange={handleChange('privatiizCompanyId')}
                    disabled={!values.isPrivatiiz}
                    input={
                      <OutlinedInput
                        labelWidth={locale === 'fr'
                          ? 140
                          : 133}
                        name="privatiizCompanyId"
                        id="activity-privatiizCompanyId"
                      />
                    }
                    inputProps={{
                      name: 'privatiizCompanyId',
                      id: 'activity-privatiizCompanyId',
                    }}
                  >
                    <MenuItem value="" key="no-company">
                      {t('pages.activity.form.noPrivatiizCompany')}
                    </MenuItem>
                    {companies.map((company) => (
                      <MenuItem value={company.id} key={`company-${company.id}`}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText id="activity-privatiizCompanyId-error-text">
                    {values.privatiizCompanyIdErrorMessage}
                  </FormHelperText>
                </FormControl>
                <br />
              </>}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isDiscovery}
                    onChange={handleChangeCheckbox('isDiscovery')}
                    value="isDiscovery"
                    color="primary"
                  />
                }
                label={t('pages.activity.form.isDiscovery')}
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isFreeBookingDisabled}
                    onChange={handleChangeCheckbox('isFreeBookingDisabled')}
                    value="isFreeBookingDisabled"
                    color="primary"
                  />
                }
                label={t('pages.activity.form.isFreeBookingDisabled')}
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.requireLicence}
                    onChange={handleChangeCheckbox('requireLicence')}
                    value="requireLicence"
                    color="primary"
                  />
                }
                label={t('pages.activity.form.requireLicence')}
              />
              {hasSuperAdminRole && (
                <TextField
                  id={'activity-remuneration'}
                  label={t('pages.activity.superAdmin.CoachRemunerationtitle')}
                  type={'number'}
                  variant="outlined"
                  onChange={handleChange('remuneration')}
                  margin="normal"
                  value={values.remuneration}
                  fullWidth={true}
                  error={values.remunerationError}
                  helperText={values.remunerationErrorMessage}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0,
                  }}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.contentRow}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >

              <Grid item xs={12} sm={6} lg={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.iconNumberRoot}
                  wrap="wrap"
                >
                  <Grid item className={classes.icon} xs={2} sm={3}>
                    <CreditCardIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />
                  </Grid>
                  <Grid item xs={10} sm={9}>
                    <Grid
                      container
                      direction={
                        matchesXs
                          ? 'row'
                          : 'column'
                      }
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      wrap="wrap"
                    >
                      <Grid item xs={6} md={8}>
                        <TextField
                          id={'activity-price'}
                          type={'number'}
                          variant="outlined"
                          onChange={handleChange('price')}
                          margin="normal"
                          value={values.price}
                          fullWidth={true}
                          error={values.priceError}
                          helperText={values.priceErrorMessage}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                          inputProps={{
                            min: 0,
                          }}
                        />
                      </Grid>
                      <Grid item className={classes.text} xs={6} sm={12}>
                        <Typography variant={'inherit'}>
                          {t('pages.activity.perPerson')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.iconNumberRoot}
                  wrap="wrap"
                >
                  <Grid item className={classes.icon} xs={2} sm={3}>
                    <ClockIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />
                  </Grid>
                  <Grid item xs={10} sm={9}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      wrap="wrap"
                    >
                      <Grid item xs={4} sm={4} className={classes.hourPadding}>
                        <TextField
                          label={t('pages.activity.form.hour')}
                          id={'activity-durationHour'}
                          type={'number'}
                          variant="outlined"
                          onChange={handleChange('durationHour')}
                          margin="normal"
                          value={values.durationHour}
                          fullWidth={true}
                          error={values.durationHourError}
                          helperText={values.durationHourErrorMessage}
                          inputProps={{
                            min: 0,
                            max: 23,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} className={classes.hourPadding}>
                        <TextField
                          label={t('pages.activity.form.minute')}
                          id={'activity-durationMinutes'}
                          type={'number'}
                          variant="outlined"
                          onChange={handleChange('durationMinutes')}
                          margin="normal"
                          value={values.durationMinutes}
                          fullWidth={true}
                          error={values.durationMinutesError}
                          helperText={values.durationMinutesErrorMessage}
                          inputProps={{
                            min: 0,
                            max: 59,
                          }}
                        />
                      </Grid>
                      <Grid item className={classes.text} xs={4} sm={12}>
                        <FormControl error={values.durationError}>
                          <FormHelperText>{values.durationErrorMessage}</FormHelperText>
                        </FormControl>
                        <Typography variant={'inherit'}>
                          {t('pages.activity.activityDuration')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.iconNumberRoot}
                  wrap="wrap"
                >
                  <Grid item className={classes.icon} xs={2} sm={3}>
                    <PeopleIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />
                  </Grid>
                  <Grid item xs={10} sm={9}>
                    <Grid
                      container
                      direction={
                        matchesXs
                          ? 'row'
                          : 'column'
                      }
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      wrap="wrap"
                    >
                      <Grid item xs={6} md={8}>
                        <TextField
                          id={'activity-minimumEntrant'}
                          type={'number'}
                          variant="outlined"
                          onChange={handleChange('minimumEntrant')}
                          margin="normal"
                          value={values.minimumEntrant}
                          fullWidth={true}
                          error={values.minimumEntrantError}
                          helperText={values.minimumEntrantErrorMessage}
                          inputProps={{
                            min: 0,
                          }}
                        />
                      </Grid>
                      <Grid item className={classes.text} xs={6} sm={12}>
                        <Typography variant={'inherit'}>
                          {t('pages.activity.minimumEntrant')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.iconNumberRoot}
                  wrap="wrap"
                >
                  <Grid item className={classes.icon} xs={2} sm={3}>
                    <PeopleIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />
                  </Grid>
                  <Grid item xs={10} sm={9}>
                    <Grid
                      container
                      direction={
                        matchesXs
                          ? 'row'
                          : 'column'
                      }
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      wrap="wrap"
                    >
                      <Grid item xs={6} md={8}>
                        <TextField
                          id={'activity-maximumEntrant'}
                          type={'number'}
                          variant="outlined"
                          onChange={handleChange('maximumEntrant')}
                          margin="normal"
                          value={values.maximumEntrant}
                          fullWidth={true}
                          error={values.maximumEntrantError}
                          helperText={values.maximumEntrantErrorMessage}
                          inputProps={{
                            min: 0,
                          }}
                        />
                      </Grid>
                      <Grid item className={classes.text} xs={6} sm={12}>
                        <Typography variant={'inherit'}>
                          {t('pages.activity.maximumEntrant')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.contentRow}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item sm={12} md={6} className={classes.contentRowPart}>
                <Typography variant={'h6'}>
                  {t('pages.activity.form.area')}
                </Typography>
                <FormControl
                  error={values.areaIdError}
                  variant="outlined"
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="activity-areaId">
                    {t('pages.activity.form.area')}
                  </InputLabel>
                  <Select
                    value={values.areaId || ''}
                    onChange={handleChange('areaId')}
                    input={
                      <OutlinedInput
                        labelWidth={locale === 'fr'
                          ? 55
                          : 34}
                        name="area"
                        id="activity-areaId"
                      />
                    }
                    inputProps={{
                      name: 'area',
                      id: 'activity-areaId',
                    }}
                  >
                    {areas.map((area) => (
                      <MenuItem value={area.id} key={`area-${area.id}`}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText id="activity-areaId-error-text">
                    {values.areaIdErrorMessage}
                  </FormHelperText>
                </FormControl>
                <Typography variant={'h6'}>
                  {t('pages.activity.activitiesPlaceTitle')}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  wrap="wrap"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <TextField
                      label={t('pages.activity.form.street')}
                      id={'activity-street'}
                      variant="outlined"
                      onChange={handleChange('street')}
                      margin="normal"
                      value={values.street}
                      fullWidth={true}
                      error={values.streetError}
                      helperText={values.streetErrorMessage}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t('pages.activity.form.additionalAddress')}
                      id={'activity-additionalAddress'}
                      variant="outlined"
                      onChange={handleChange('additionalAddress')}
                      margin="normal"
                      value={values.additionalAddress}
                      fullWidth={true}
                      error={values.additionalAddressError}
                      helperText={values.additionalAddressErrorMessage}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t('pages.activity.form.zipCode')}
                      id={'activity-zipCode'}
                      variant="outlined"
                      onChange={handleChange('zipCode')}
                      margin="normal"
                      value={values.zipCode}
                      fullWidth={true}
                      error={values.zipCodeError}
                      helperText={values.zipCodeErrorMessage}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label={t('pages.activity.form.city')}
                      id={'activity-city'}
                      variant="outlined"
                      onChange={handleChange('city')}
                      margin="normal"
                      value={values.city}
                      fullWidth={true}
                      error={values.cityError}
                      helperText={values.cityErrorMessage}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={'Latitude'}
                      id={'activity-latitude'}
                      variant="outlined"
                      onChange={handleChange('latitude')}
                      margin="normal"
                      value={values.latitude}
                      fullWidth={true}
                      error={values.latitudeError}
                      helperText={values.latitudeErrorMessage}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={'Longitude'}
                      id={'activity-longitude'}
                      variant="outlined"
                      onChange={handleChange('longitude')}
                      margin="normal"
                      value={values.longitude}
                      fullWidth={true}
                      error={values.longitudeError}
                      helperText={values.longitudeErrorMessage}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={6} className={classes.contentRowPart}>
                <Typography variant={'h6'}>
                  {t('pages.activity.form.description')}
                </Typography>
                <FormControl error={values.descriptionError} style={{ width: '100%' }}>
                  <ReactQuill
                    style={{ marginTop: '1em' }}
                    value={values.description}
                    onChange={handleQuillChange('description')}
                    modules={{
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                  />
                  <FormHelperText>{values.descriptionErrorMessage}</FormHelperText>
                </FormControl>

                <Typography variant={'h6'}>
                  {t('pages.activity.dontForgetTitle')}
                </Typography>

                <FormControl error={values.detailsError} style={{ width: '100%' }}>
                  <ReactQuill
                    style={{ marginTop: '1em' }}
                    value={values.details}
                    onChange={handleQuillChange('details')}
                    modules={{
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                  />
                  <FormHelperText>{values.detailsErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Hidden mdDown>
        <Button
          variant="contained"
          className={classes.addButton}
          onClick={onSubmit}
          disabled={loading}
        >
          {t('common.save')}
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Hidden>
      <Hidden lgUp>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          disabled={loading}
          onClick={onSubmit}
        >
          <SaveIcon color={SPORTIIZ_WHITE} />
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Fab>
      </Hidden>
    </form>
  );
}

ActivityForm.propTypes = {
  associationId: PropTypes.string.isRequired,
  sports: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  activity: PropTypes.object,
  events: PropTypes.array,
};

ActivityForm.defaultProps = {
  activity: null,
  events: [],
};
