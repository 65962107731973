import React from 'react';
import PropTypes from 'prop-types';
import IconTint from 'react-icon-tint';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RoundMark from '../layout/RoundMark';
import CreditCardIcon from '../icon/CreditCardIcon';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY,
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_RED,
} from '../../constants/colors';
import ClockIcon from '../icon/ClockIcon';
import PeopleIcon from '../icon/PeopleIcon';
import HtmlText from '../layout/HtmlText';
import CategoryChip from './CategoryChip';
import PinIcon from '../icon/PinIcon';
import NumbersBlueSection from '../layout/numbersBlueSection/NumbersBlueSection';
import detailPageStyles from '../../styles/detailPage';
import activityPaperStyles from '../../styles/activityPaper';
import ActivitySuperAdminCard from './ActivitySuperAdminCard';
import PrivatiizBadge from './PrivatiizBadge';
import { getDisplayName } from '../../services/userService';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const useActivityPaperStyles = makeStyles(() => activityPaperStyles());
const useStyles = makeStyles(() => (
  {
    sportPart: {
      textAlign: 'left',
      padding: '1em',
    },
    chip: {
      paddingTop: '1em',
      fontWeight: 600,
    },
    mapLink: {
      color: 'inherit',
    },
    paperRow: {
      width: '100%',
    },
    paper: {
      height: '200px',
    },
    contentRow: {
      width: '100%',
      textAlign: 'left',
    },
    p: {
      marginTop: '1em',
      marginBottom: '1em',
    },
    privatiizI: {
      position: 'relative',
      display: 'inline-block',
      '&::after': {
        color: SPORTIIZ_RED,
        display: 'inline-block',
        content: 'attr(attribute)',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '34%',
        overflow: 'hidden',
      },
    },
    privatiizBadgeContainer: {
      display: 'inline-block',
      marginRight: '10px',
      verticalAlign: 'text-bottom',
    },
    privatiizLabel: {
      display: 'inline-block',
    },
    privatiizLabelContainer: {
      position: 'relative',
      top: '-10px',
    },
  }
));

const MAP_URL = 'https://www.google.fr/maps/place/';

export default function ActivityDetails(props) {
  const {
    activity,
    companyView,
  } = props;
  const { t } = useTranslation();
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const classes = useStyles();
  const detailPageClasses = useDetailPageStyles();
  const activityPaperClasses = useActivityPaperStyles();
  const hours = Math.floor(activity.duration / 3600);
  const minutes = Math.floor((
    activity.duration - (
      hours * 3600
    )
  ) / 60);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const hoursText = hours > 0
    ? `${hours}H`
    : '';
  const minutesText = minutes > 0
    ? `${minutes}min`
    : '';
  const activityTime = activity.duration > 0
    ? hoursText + minutesText
    : '?';
  const mapLink = `${MAP_URL}${activity.latitude},${activity.longitude}`;
  const showRemuneration = !companyView && hasSuperAdminRole;
  return (
    <div>
      <Container
        maxWidth={false}
        className={detailPageClasses.content}
        style={{
          paddingTop: '2em',
          paddingBottom: '2em',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent={matches || !showRemuneration
            ? 'flex-start'
            : 'space-between'}
          alignItems="flex-start"
        >
          <Grid item xs={12} md={6} lg={4} xl={3} className={classes.paperRow}>
            <Paper
              className={`${activityPaperClasses.paper} ${classes.paper}`}
              style={
                {
                  backgroundImage: `url(${activity.picture})`,
                  cursor: 'default',
                  boxShadow: 'none',
                }
              }
              elevation={0}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={showRemuneration
              ? 5
              : 8}
            xl={showRemuneration
              ? 6
              : 9}
          >
            <div className={classes.sportPart}>
              {activity.isPrivatiiz
                && <div className={classes.privatiizLabelContainer}>
                  <div className={classes.privatiizBadgeContainer}>
                    <PrivatiizBadge marginLeft="0" />
                  </div>
                  <Typography variant="h6" className={classes.privatiizLabel}>
                    Privat
                    <span className={classes.privatiizI} attribute="i">i</span>
                    <span
                      className={classes.privatiizI}
                      attribute="i"
                    >i</span>
                    z
                    {!companyView
                      && <> {t('pages.activity.privatiizTo')} {activity.privatiizCompany.name}</>
                    }
                  </Typography>
                </div>}
              {activity.sport && (
                <Typography>
                  <IconTint
                    src={activity.sport.picture}
                    color={SPORTIIZ_BLUE}
                    maxHeight="35"
                    maxWidth="35"
                    alt={'sport'}
                  />
                  {activity.sport.name}
                </Typography>
              )}
              {activity.categories
                && <Grid container style={{ paddingTop: '1em' }}>
                  {activity.categories.map((elem) => (
                    <Grid item xs={4} sm={3} md={3} xl={2} key={elem.id}>
                      <CategoryChip name={elem.name} />
                    </Grid>
                  ))}
                </Grid>
              }

              {activity.isDiscovery && (
                <div className={classes.chip}>
                  <span>
                    <RoundMark />
                    {t('pages.activity.form.isDiscovery')}
                  </span>
                </div>
              )
              }

              {activity.requireLicence && (
                <div className={classes.chip}>
                  <span>
                    <RoundMark />
                    {t('pages.activity.form.requireLicence')}
                  </span>
                </div>
              )
              }
            </div>
          </Grid>
          {showRemuneration
            && <Grid item xs={12} sm={12} md={12} lg={3}>

              <ActivitySuperAdminCard remuneration={activity.remuneration} />
            </Grid>
          }
        </Grid>
      </Container>
      <NumbersBlueSection
        numbers={[
          {
            number: `${activity.price} €`,
            text: t('pages.activity.perPerson'),
            icon: <CreditCardIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />,
          },
          {
            number: activityTime,
            text: t('pages.activity.activityDuration'),
            icon: <ClockIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />,
          },
          {
            number: activity.minimumEntrant,
            text: t('pages.activity.minimumEntrant'),
            icon: <PeopleIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />,
          },
          {
            number: activity.maximumEntrant,
            text: t('pages.activity.maximumEntrant'),
            icon: <PeopleIcon height={'2em'} width={'2em'} color={SPORTIIZ_GREY_GREEN} />,
          },
        ]}
      />
      <Container maxWidth={false} className={detailPageClasses.footerBox}>
        <Container maxWidth={false} className={detailPageClasses.footerContainer}>
          <Grid
            container
            direction={matches
              ? 'column'
              : 'row'}
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap={'nowrap'}
            style={{ gap: '4em' }}
          >
            <Grid
              item
              style={{
                minWidth: matches
                  ? '100%'
                  : '33%',
              }}
            >
              <div>
                <Typography variant={'h6'}>
                  {t('pages.activity.form.coaches')}
                </Typography>
                <Typography variant={'subtitle2'} component={'p'} className={classes.p}>
                  {activity.coaches && activity.coaches.length !== 0
                    ? activity.coaches.map((c) => (
                      <li key={c.id}>{getDisplayName(c)}</li>
                    ))
                    : t('common.notSpecified')}
                </Typography>
              </div>
              <div>
                <Typography variant={'h6'}>
                  {t('pages.activity.form.area')}
                </Typography>
                <Typography variant={'subtitle2'} component={'p'} className={classes.p}>
                  {activity.area
                    ? activity.area.name
                    : t('common.notSpecified')}
                </Typography>
              </div>
              <Typography variant={'h6'}>
                {t('pages.activity.activitiesPlaceTitle')}
              </Typography>
              <HtmlText text={activity.address} />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={mapLink}
                className={classes.mapLink}
              >
                <Typography variant={'inherit'}>
                  <PinIcon
                    color={SPORTIIZ_GREY}
                    width={'1.25em'}
                    height={'1.25em'}
                    style={{ verticalAlign: 'sub' }}
                  />
                  Latitude : {activity.latitude} ; Longitude : {activity.longitude}
                </Typography>
              </a>
            </Grid>

            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant={'h6'}>
                {t('pages.activity.form.description')}
              </Typography>
              <HtmlText text={activity.description} />
              <Typography variant={'h6'}>
                {t('pages.activity.dontForgetTitle')}
              </Typography>
              <HtmlText text={activity.details} />
            </Grid>

          </Grid>
        </Container>
      </Container>
    </div>
  );
}

ActivityDetails.defaultProps = {
  companyView: false,
};

ActivityDetails.propTypes = {
  activity: PropTypes.object.isRequired,
  companyView: PropTypes.bool,
};
