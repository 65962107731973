import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PaymentStatusChip from '../PaymentStatusChip';
import { SPORTIIZ_BLUE_BACKGROUND, SPORTIIZ_RED } from '../../../constants/colors';
import { formatStringForTable } from '../../../services/stringService';

const useStyles = makeStyles(() => (
  {
    withGuestRow: {
      backgroundColor: SPORTIIZ_BLUE_BACKGROUND,
    },
    guestRowFirstCell: {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        height: '100%',
        width: '12px',
        left: 0,
        top: 0,
        backgroundColor: SPORTIIZ_BLUE_BACKGROUND,
      },
    },
    redText: {
      color: SPORTIIZ_RED,
    },
  }
));

function BookingListRow(props) {
  const {
    bookings,
    booking,
  } = props;
  const d = new Date(booking.createdAt * 1000);
  const cd = booking.cancelledAt ? new Date(booking.cancelledAt * 1000) : null;
  const classes = useStyles();
  const companyName = booking.user.companyUsers[0]?.company.name;
  return (
    <>
      <TableRow className={booking.guestCount > 0 ? classes.withGuestRow : ''}>
        <TableCell align="left">
          {formatStringForTable(booking.user.firstname)}
        </TableCell>
        <TableCell align="left">
          {formatStringForTable(booking.user.lastname)}
        </TableCell>
        <TableCell align="left">
          {formatStringForTable(booking.user.email)}
        </TableCell>
        <TableCell align="left">
          {formatStringForTable(booking.user.phone)}
        </TableCell>
        <TableCell align="left">
          {formatStringForTable(companyName)}
        </TableCell>
        <TableCell align="left">
          {formatStringForTable(d.toLocaleString())}
        </TableCell>
        <TableCell align="left" className={cd !== null ? classes.redText : ''}>
          {formatStringForTable(cd !== null ? cd.toLocaleString() : '')}
        </TableCell>
        <TableCell align="left">
          <PaymentStatusChip status={booking.paymentStatus} stripeFailureCode={booking.stripeFailureCode}/>
        </TableCell>
      </TableRow>
      {booking.guestCount > 0
        && bookings.length > 0
        && bookings
          .filter((b) => b.type === 'GUEST' && booking.id === b.parentBookingId)
          .map((guest) => {
            return (
              <TableRow key={guest.id}>
                <TableCell align="left" className={classes.guestRowFirstCell}>
                  {guest.user.firstname}
                </TableCell>
                <TableCell align="left">{formatStringForTable(guest.user.lastname)}</TableCell>
                <TableCell align="left">{formatStringForTable(guest.user.email)}</TableCell>
                <TableCell align="left">{formatStringForTable(guest.user.phone)}</TableCell>
                <TableCell align="left">{formatStringForTable(companyName)}</TableCell>
                <TableCell align="left">{formatStringForTable(d.toLocaleString())}</TableCell>
                <TableCell align="left" className={cd !== null ? classes.redText : ''}>
                  {formatStringForTable(cd !== null ? cd.toLocaleString() : '')}
                </TableCell>
                <TableCell align="left">
                  <PaymentStatusChip status={booking.paymentStatus} stripeFailureCode={booking.stripeFailureCode}/>
                </TableCell>
              </TableRow>
            );
          })
          }
    </>
  );
}

BookingListRow.propTypes = {
  bookings: PropTypes.array.isRequired,
  booking: PropTypes.object.isRequired,
};

export default BookingListRow;
