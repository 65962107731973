import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SPORTIIZ_RED } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    container: {
      marginTop: '12px',
    },
    progressIcon: {
      color: SPORTIIZ_RED,
      position: 'relative',
      top: '0',
    },
    progressText: {
      position: 'relative',
      top: '0',
    },
  }
));

export function Loading() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <CircularProgress size={30} className={classes.progressIcon} />
      <Typography
        variant={'h6'}
        className={classes.progressText}
      >
        {`${t('common.loading')} ...`}
      </Typography>
    </Box>

  );
}

export default withTranslation('translations')(Loading);
