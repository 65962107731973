import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SPORTIIZ_RED, SPORTIIZ_RED_DARK, SPORTIIZ_WHITE } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    ribbonWrapper: {
      position: 'relative',
      zIndex: 1,
      margin: '1.5em auto',
    },
    ribbon: {
      fontSize: '20px!important',
      width: '50%',
      position: 'relative',
      background: SPORTIIZ_RED,
      color: SPORTIIZ_WHITE,
      textAlign: 'center',
      padding: '1em 2em',
      margin: '1em auto 2em',
      '&::after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        bottom: '-1em',
        border: '1.5em solid #d74545',
        zIndex: '-1',
        right: '-2em',
        borderLeftWidth: '1.5em',
        borderRightColor: 'transparent',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        bottom: '-1em',
        border: '1.5em solid #d74545',
        zIndex: '-1',
        left: '-2em',
        borderRightWidth: '1.5em',
        borderLeftColor: 'transparent',
      },
    },
    ribbonInner: {
      '&::after': {
        right: 0,
        borderWidth: '1em 1em 0 0',
        content: '""',
        position: 'absolute',
        display: 'block',
        borderStyle: 'solid',
        borderColor: `${SPORTIIZ_RED_DARK} transparent transparent`,
        bottom: '-1em',
      },
      '&::before': {
        left: 0,
        borderWidth: '1em 0 0 1em',
        content: '""',
        position: 'absolute',
        display: 'block',
        borderStyle: 'solid',
        borderColor: `${SPORTIIZ_RED_DARK} transparent transparent`,
        bottom: '-1em',
      },
    },
  }
));

export default function Ribbon(props) {
  const { text } = props;
  const classes = useStyles();

  return (
    <div className={classes.ribbonWrapper}>
      <h3 className={classes.ribbon}>
        <strong className={classes.ribbonInner}>{text}</strong>
      </h3>
    </div>
  );
}

Ribbon.propTypes = {
  text: PropTypes.string.isRequired,
};
