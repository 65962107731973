import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableHead, TableRow, TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import SortedTableCell from '../../layout/table/SortedTableCell';
import AssociationBookingRow, { getCompanyNameFromBooking } from './AssociationBookingRow';

const useStyles = makeStyles((theme) => (
  {
    table: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '100px',
      },
      '& tr': {
        '& th:nth-child(-n+2)': {
          paddingLeft: '24px',
          width: 'auto',
          maxWidth: '250px',
          [theme.breakpoints.down('md')]: {
            maxWidth: '150px',
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100px',
          },
        },
        '& td:nth-child(-n+2)': {
          paddingLeft: '24px',
          width: 'auto',
          maxWidth: '250px',
          textOverflow: 'ellipsis',
          overflowX: 'hidden',
          [theme.breakpoints.down('md')]: {
            maxWidth: '150px',
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100px',
          },
        },
      },
    },
  }
));

const AssociationBookingArray = (props) => {
  const {
    bookings,
    defaultOrder,
    defaultOrderBy,
  } = props;

  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [displayedBookings, setDisplayedBookings] = useState([]);
  const classes = useStyles();
  const { t } = useTranslation();
  useEffect(() => {
    if (defaultOrder !== order) {
      setOrder(defaultOrder);
    }
    // eslint-disable-next-line
  }, [defaultOrder]);
  const sortBookings = useCallback(
    (orderBy, order) => {
      if (orderBy === 'timeSlot') {
        return [...bookings].sort((a, b) => {
          if (order === 'ASC') {
            return a.timeSlot.start - b.timeSlot.start;
          }
          return b.timeSlot.start - a.timeSlot.start;
        });
      }
      if (orderBy === 'createdAt') {
        return [...bookings].sort((a, b) => {
          if (order === 'ASC') {
            return a.createdAt - b.createdAt;
          }
          return b.createdAt - a.createdAt;
        });
      }
      if (orderBy === 'activity') {
        return [...bookings].sort((a, b) => {
          if (order === 'ASC') {
            // eslint-disable-next-line no-nested-ternary
            return (
              a.timeSlot.activity.name > b.timeSlot.activity.name
            )
              ? 1
              : (
                a.timeSlot.activity.name < b.timeSlot.activity.name
              )
                ? -1
                : 0;
          }
          // eslint-disable-next-line no-nested-ternary
          return (
            a.timeSlot.activity.name < b.timeSlot.activity.name
          )
            ? 1
            : (
              a.timeSlot.activity.name > b.timeSlot.activity.name
            )
              ? -1
              : 0;
        });
      }
      if (orderBy === 'company') {
        return [...bookings].sort((a, b) => {
          const ca = getCompanyNameFromBooking(a);
          const cb = getCompanyNameFromBooking(b);
          if (order === 'ASC') {
            // eslint-disable-next-line no-nested-ternary
            return (
              ca > cb
            )
              ? 1
              : (
                ca < cb
              )
                ? -1
                : 0;
          }
          // eslint-disable-next-line no-nested-ternary
          return (
            ca < cb
          )
            ? 1
            : (
              ca > cb
            )
              ? -1
              : 0;
        });
      }
      return bookings;
    },
    [bookings],
  );

  useEffect(() => {
    setDisplayedBookings(sortBookings(orderBy, order));
  }, [sortBookings, order, orderBy]);

  return <Table className={classes.table}>
    <TableHead>
      <TableRow>
        <TableCell>
          {t('table.associationBookings.identity')}
        </TableCell>
        <TableCell>
          {t('table.associationBookings.email')}
        </TableCell>
        <SortedTableCell
          align="left"
          label={t('table.associationBookings.company')}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          order={order}
          name={'company'}
        />
        <SortedTableCell
          align="left"
          label={t('table.associationBookings.activity')}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          order={order}
          name={'activity'}
        />
        <SortedTableCell
          align="left"
          label={t('table.associationBookings.timeSlot')}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          order={order}
          name={'timeSlot'}
        />
        <SortedTableCell
          align="left"
          label={t('table.associationBookings.bookingDate')}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          order={order}
          name={'createdAt'}
        />
        <TableCell>
          {t('table.associationBookings.cancelDate')}
        </TableCell>
        <TableCell>
          {t('table.associationBookings.paymentStatus')}
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {displayedBookings.map((b) => <AssociationBookingRow booking={b} key={b.id} />)}
    </TableBody>
  </Table>;
};
AssociationBookingArray.propTypes = {
  bookings: PropTypes.array.isRequired,
  defaultOrder: PropTypes.string,
  defaultOrderBy: PropTypes.string,
};
AssociationBookingArray.defaultProps = {
  defaultOrder: 'DESC',
  defaultOrderBy: 'timeSlot',
};
export default AssociationBookingArray;
