import React from 'react';
import Chart from 'react-apexcharts';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { CARD_RADIUS } from '../../../constants/style';
import {
  SPORTIIZ_BLUE_GRADIANT_DARK,
  SPORTIIZ_GREY,
  SPORTIIZ_WHITE,
} from '../../../constants/colors';

const useStyles = makeStyles((theme) => (
  {
    paper: {
      position: 'relative',
      borderRadius: CARD_RADIUS,
      height: '260px',
      [theme.breakpoints.down('sm')]: {
        height: '240px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '200px',
      },
    },
    chart: {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    label: {
      position: 'absolute',
      top: '80%',
      width: '100%',
    },
    actionContainer: {
      position: 'absolute',
      top: '-3px',
      right: '30px',
      width: 'auto',
      zIndex: 10,
    },
  }
));

export default function HistogramCharts(props) {
  const {
    series,
    label,
    color,
    secondaryColor,
    categories,
    secondarySeries,
    secondaryLabel,
    fullWidth,
    movePrevious,
    moveNext,
    title,
  } = props;

  const classes = useStyles();

  const chartHeight = '100%';

  const chartOptions = {
    chart: {
      height: chartHeight,
      type: 'bar',
      offsetY: -5,
      stacked: true,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -2,
      style: {
        fontSize: '12px',
        colors: [SPORTIIZ_WHITE],
      },
    },
    xaxis: {
      categories,
      position: 'bottom',
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: [SPORTIIZ_BLUE_GRADIANT_DARK],
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: [SPORTIIZ_BLUE_GRADIANT_DARK],
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: title ?? label,
      align: 'center',
      offsetY: 4,
      floating: true,
      style: {
        color: SPORTIIZ_GREY,
      },
    },
    colors: [color, secondaryColor],
  };

  const chartSeries = [
    {
      data: series,
      name: label,
    },
  ];
  if (secondarySeries) {
    chartSeries.push(
      {
        data: secondarySeries,
        name: secondaryLabel ?? '',
      },
    );
  }

  return (

    <Grid
      item
      xl={fullWidth
        ? 12
        : 4}
      md={fullWidth
        ? 12
        : 6}
      xs={12}
    >
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.chart}>
          <Grid
            container
            direction="row"
            className={classes.actionContainer}
            alignItems="center"
          >
            {movePrevious !== undefined && (
              <Grid item>
                <IconButton aria-label="delete" size="small" onClick={movePrevious}>
                  <ChevronLeft />
                </IconButton>
              </Grid>
            )}
            {moveNext && (
              <Grid item>
                <IconButton aria-label="delete" size="small" onClick={moveNext}>
                  <ChevronRight />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={chartHeight}
          />
        </div>
      </Paper>
    </Grid>
  );
}

HistogramCharts.propTypes = {
  label: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  secondaryLabel: PropTypes.string,
  secondarySeries: PropTypes.array,
  secondaryColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  movePrevious: PropTypes.func,
  moveNext: PropTypes.func,
  title: PropTypes.string,
};

HistogramCharts.defaultProps = {
  fullWidth: false,
  enabledZoom: false,
};
