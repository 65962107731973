import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Transition from '../../layout/TransitionAnimation';
import dialogFormStyle from '../../../styles/dialogForm';
import { dispatchInfo, handleError } from '../../../store/infos/actions';
import { MUTATION_SEND_RESET_PASSWORD_MAIL } from '../../../constants/graphQLQueries';
import AppDialogTitle from '../../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

// this component is used in multiple place
// TODO move it into a new folder and use specials translations
export default function ResetPasswordConfirmDialog(props) {
  const {
    open,
    user,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dialogFormClasses = useDialogFormStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const client = useApolloClient();
  const [mutationResetPassword, { loading: mutationLoading }] = useMutation(
    MUTATION_SEND_RESET_PASSWORD_MAIL,
    { client },
  );

  if (!user) {
    return null;
  }

  function handleValid() {
    mutationResetPassword({
      variables: { email: user.email },
    }).then(() => {
      dispatch(dispatchInfo(t('pages.associationCoaches.resetPasswordSuccess')));
    }).catch((error) => {
      dispatch(handleError(error));
    }).finally(() => {
      onClose();
    });
  }

  const title = t(
    'pages.associationCoaches.resetPasswordConfirmMessage',
    { email: user.email },
  );

  return (
    <Dialog
      open={open}
      fullScreen={matches}
      scroll="body"
      onClose={onClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches ? 500 : 750}
      aria-labelledby={title}
    >
      <AppDialogTitle title={t('pages.associationCoaches.resetPassword')} handleClose={onClose} />
      <DialogContent id="confirm-dialog-title">
        {title}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
          className={dialogFormClasses.button}
          color="primary"
          variant={'outlined'}
          disabled={mutationLoading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="button"
          onClick={handleValid}
          className={dialogFormClasses.button}
          color="primary"
          variant={'contained'}
          disabled={mutationLoading}
        >
          {t('pages.forgot_password.button')}
          {mutationLoading && (
            <CircularProgress
              size={24}
              className={dialogFormClasses.buttonProgress}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResetPasswordConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object,
};
