import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    roundMark: {
      width: '12px',
      height: '12px',
      borderRadius: '25px',
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '10px',
    },
    roundMarkMarginRight: {
      width: '12px',
      height: '12px',
      borderRadius: '25px',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: '10px',
    },
  }
));

export default function RoundMark(props) {
  const { color, onlyMarginRight } = props;
  const classes = useStyles();
  return (
    <div
      style={{ backgroundColor: color }}
      className={onlyMarginRight ? classes.roundMarkMarginRight : classes.roundMark }
    />
  );
}

RoundMark.propTypes = {
  color: PropTypes.string,
  onlyMarginRight: PropTypes.bool,
};

RoundMark.defaultProps = {
  color: SPORTIIZ_BLUE,
  onlyMarginRight: false,
};
