import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import CameraIcon from '../icon/CameraIcon';
import {
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_LIGHT_PALE_BLUE,
  SPORTIIZ_PALE_BLUE,
  SPORTIIZ_WHITE,
} from '../../constants/colors';
import { CARD_RADIUS } from '../../constants/style';

const useStyles = makeStyles((theme) => (
  {
    uploadLabel: {
      width: '100%',
      height: '30px',
    },
    uploadButton: {
      position: 'absolute',
      left: 'calc(50% - 25px)',
      bottom: '10px',
      height: '50px',
      width: '50px',
      padding: '7px',
      zIndex: 10,
      backgroundColor: SPORTIIZ_WHITE,
      '&:hover': {
        backgroundColor: SPORTIIZ_LIGHT_PALE_BLUE,
      },
    },
    paper: {
      display: 'flex',
      borderRadius: CARD_RADIUS,
      color: SPORTIIZ_WHITE,
      height: '250px',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'border-box',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      padding: '10px',
      position: 'relative',
      backgroundColor: SPORTIIZ_LIGHT_PALE_BLUE,
      border: `1px solid ${SPORTIIZ_PALE_BLUE}`,
      cursor: 'pointer',
      textAlign: 'start',
    },
    formControl: {
      minWidth: '350px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
        minWidth: 'unset',
      },
    },
  }
));

const LargePictureInput = (props) => {
  const {
    defaultUrl,
    pictureBlob,
    handleChangeFile,
    pictureErrorMessage,
    id,
    rootStyle,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControl
      fullWidth={true}
      error={pictureErrorMessage !== ''}
      className={classes.formControl}
      style={rootStyle}
    >
      <Paper
        className={classes.paper}
        style={{
          backgroundImage: `url(${pictureBlob ?? defaultUrl})`,
          cursor: 'default',
        }}
        elevation={0}
      />
      <input
        aria-label="picture"
        accept="image/*"
        style={{ display: 'none' }}
        id={id}
        onChange={handleChangeFile('picture')}
        type="file"
      />
      <label htmlFor={id} className={classes.uploadLabel}>
        <Fab
          component="span"
          title={t('common.uploadPicture')}
          className={classes.uploadButton}
        >
          <CameraIcon color={SPORTIIZ_GREY_GREEN} height="30" width="30" />
        </Fab>
      </label>
      <FormHelperText id="large-picture-error-text" error={pictureErrorMessage !== ''}>
        {pictureErrorMessage}
      </FormHelperText>
    </FormControl>
  );
};

LargePictureInput.propTypes = {
  pictureBlob: PropTypes.any,
  handleChangeFile: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  pictureErrorMessage: PropTypes.string,
  defaultUrl: PropTypes.string,
  rootStyle: PropTypes.object,
};

LargePictureInput.defaultProps = {
  pictureErrorMessage: '',
  rootStyle: {},
};

export default LargePictureInput;
