import React from 'react';
import PropTypes from 'prop-types';
import TextClamp from 'react-string-clamp';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import MessageIcon from '../icon/MessageIcon';
import { SPORTIIZ_BLUE, SPORTIIZ_GREY_GREEN, SPORTIIZ_PALE_BLUE } from '../../constants/colors';
import RewardIcon from '../icon/RewardIcon';
import { TYPE_PHYSICAL } from '../../constants/rewardTypes';
import MoreVerticalIcon from '../icon/MoreVerticalIcon';
import { CARD_RADIUS } from '../../constants/style';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    border: 'none',
    borderRadius: CARD_RADIUS,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.01), 0px 8px 10px 1px rgba(0,0,0,0.01), 0px 3px 14px 2px rgba(0,0,0,0.1)',
      '& $action': {
        display: 'block',
      },
    },
  },
  details: {
    display: 'flex',
    width: '100%',
  },
  content: {
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 100,
    textAlign: 'left',
    padding: '16px 0',
    width: '1%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
      margin: 0,
      width: 'calc(100% - 140px)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
      margin: 0,
      width: 'calc(100% - 110px)',
    },
  },
  avatar: {
    display: 'flex',
    border: '1px solid ' + SPORTIIZ_PALE_BLUE,
    width: 110,
    height: 110,
    margin: 19,
    [theme.breakpoints.down('md')]: {
      width: 80,
      height: 80,
      margin: 12,
    },
    [theme.breakpoints.down('sm')]: {
      width: 70,
      height: 70,
      margin: 10,
    },
    [theme.breakpoints.down('xs')]: {
      width: 60,
      height: 60,
      margin: 10,
    },
  },
  withPadding: {
    padding: '0 2rem',
  },
  iconContainer: {
    minWidth: '32px',
  },
  description: {
    fontWeight: 600,
    color: SPORTIIZ_BLUE,
  },
  action: {
    flexBasis: 'auto',
    alignSelf: 'flex-start',
    display: 'none',
    position: 'relative',
    top: '10px',
    right: '10px',
    padding: '5px',
    width: '2.5em',
    height: '2.5em',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  actions: {
    width: '35px',
    display: 'block',
  },
}));

export default function Reward(props) {
  const { reward, handleCardClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12} lg={6}>
      <Card className={classes.card}>
        <div className={classes.details}>
          {reward.picture !== null ?
            <Avatar
              className={classes.avatar}
              src={reward.picture}
              title="picture"
            />
            :
            <Avatar className={classes.avatar}>
              {reward.name.charAt(0)}
            </Avatar>
          }
          <CardContent className={classes.content}>
            <Typography variant="h6">
              {reward.name}
            </Typography>
            <List component="nav">
              {reward.type === TYPE_PHYSICAL &&
              <ListItem>
                <ListItemIcon className={classes.iconContainer}>
                  <RewardIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {t('pages.rewards.stock', { 'count': reward.stock })}
                  </Typography>
                </ListItemText>
              </ListItem>
              }
              <ListItem style={{ paddingBottom: 0 }}>
                <ListItemIcon className={classes.iconContainer}>
                  <MessageIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.description}>
                    {t('pages.rewards.description')}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem style={{ paddingTop: 0 }}>
                <ListItemText>
                  <Typography variant="body1" className={classes.withPadding} component={'div'}>
                    <TextClamp text={reward.description} lines={3} />
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
          <div className={classes.actions}>
            {handleCardClick &&
            <MoreVerticalIcon
              className={classes.action}
              color={SPORTIIZ_GREY_GREEN}
              onClick={(event) => handleCardClick(event, reward)}
            />
            }
          </div>
        </div>
      </Card>
    </Grid>
  );
}

Reward.propTypes = {
  reward: PropTypes.object.isRequired,
  handleCardClick: PropTypes.func.isRequired,
};
