import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { SPORTIIZ_BLUE, SPORTIIZ_WHITE } from '../../constants/colors';
import { LABEL_RADIUS } from '../../constants/style';
import DisableIcon from '../icon/DisableIcon';

const useStyles = makeStyles(() => (
  {
    blueChip: {
      color: SPORTIIZ_WHITE,
      backgroundColor: SPORTIIZ_BLUE,
      borderRadius: LABEL_RADIUS,
      verticalAlign: 'bottom',
      boxShadow: `0px 1px 13px -6px ${SPORTIIZ_BLUE};`,
      padding: '4px 14px 5px 10px',
    },
    text: {
      display: 'inline-block',
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      marginLeft: '0.5rem',
      fontWeight: 700,
    },
  }
));

const DisableBadge = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Paper className={classes.blueChip}>
      <DisableIcon color={SPORTIIZ_WHITE} width="15px" height="15px" />
      {matches && <div className={classes.text}>
        {t('common.disabled')}
      </div>}
    </Paper>
  );
};

export default DisableBadge;
