import React, { useState } from 'react';
import PropTypes from 'prop-types';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import { MUTATION_EVENT } from '../../constants/graphQLQueries';
import formInSmallDialogStyle from '../../styles/formInSmallDialog';
import LargePictureInput from '../forms/LargePictureInput';
import AntSwitch from '../forms/AntSwitch';
import { EVENT } from '../../constants/routes';

const useFormInSmallDialogStyles = makeStyles((theme) => formInSmallDialogStyle(theme));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const useStyles = makeStyles((theme) => (
  {
    container: {
      minWidth: '600px',
      widht: 'auto',
    },
    formGroup: {
      width: '400px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }
));

const INITIAL_VALUES = {
  eventId: null,
  name: '',
  nameError: '',
  areaId: '',
  areaIdError: '',
  description: '',
  descriptionError: '',
  visibility: 'PUBLIC',
  visibilityError: '',
  privatiizCompanyId: '',
  privatiizCompanyIdError: '',
  onlyForCompanyUsers: false,
  onlyForCompanyUsersError: '',
  picture: null,
  pictureBlob: null,
  pictureError: '',
  start: null,
  startError: '',
  end: null,
  endError: '',
};

export default function EventForm(props) {
  const {
    handleClose,
    update,
    event,
    areas,
    companies,
  } = props;
  const classes = useStyles();
  const {
    t,
    i18n,
  } = useTranslation();
  const history = useHistory();
  const locale = i18n.language.slice(0, 2);
  const dispatch = useDispatch();
  const formInSmallDialogClasses = useFormInSmallDialogStyles();
  const client = useApolloClient();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const newState = (
    event === null
  )
    ? INITIAL_VALUES
    : {
      ...INITIAL_VALUES,
      eventId: event.id,
      name: event.name,
      description: event.description,
      areaId: event.area?.id ?? '',
      pictureBlob: event.picture || null,
    };
  const [values, setValues] = useState(newState);

  const [mutationEvent, { loading }] = useMutation(
    MUTATION_EVENT,
    {
      client,
      update,
    },
  );

  const handleChange = (name) => (event) => {
    let newValue = event.target.value;
    if (name === 'visibility') {
      if (values.visibility === 'PUBLIC') {
        newValue = 'LOCKED';
      } else {
        newValue = 'PUBLIC';
      }
    } else if (name === 'onlyForCompanyUsers') {
      newValue = !values.onlyForCompanyUsers;
    }
    setValues({
      ...values,
      [name]: newValue,
      [`${name}Error`]: '',
    });
  };

  const handleChangeFile = (name) => (event) => {
    const blob = new Blob([event.target.files[0]], { type: 'image/png' });
    const blobUrl = URL.createObjectURL(blob);
    setValues({
      ...values,
      [name]: event.target.files[0],
      [`${name}Blob`]: blobUrl,
      [`${name}Error`]: '',
    });
  };

  const handleStartChange = (date) => {
    setValues({
      ...values,
      start: date,
      startError: '',
    });
  };

  const handleEndChange = (date) => {
    setValues({
      ...values,
      end: date,
      endError: '',
    });
  };
  const handleQuillChange = (name) => (value) => {
    setValues({
      ...values,
      [name]: value,
      [`${name}Error`]: '',
    });
  };
  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let error = false;
    let newValues = { ...values };
    if (!values.name || values.name.trim() === '') {
      newValues = {
        ...newValues,
        nameError: t('pages.events.form.formNameRequired'),
      };
      error = true;
    }
    if (!values.start || values.start === null) {
      newValues = {
        ...newValues,
        startError: t('pages.events.form.formStartRequired'),
      };
      error = true;
    }
    if (!values.end || values.end === null) {
      newValues = {
        ...newValues,
        endError: t('pages.events.form.formEndRequired'),
      };
      error = true;
    }
    if (values.start !== null && values.end !== null && values.start > values.end) {
      newValues = {
        ...newValues,
        endError: t('pages.events.form.formEndLessThanStart'),
      };
      error = true;
    }
    if (values.picture === null) {
      newValues = {
        ...newValues,
        pictureError: t('pages.events.form.formPictureRequired'),
      };
      error = true;
    }
    setValues(newValues);
    if (!error) {
      const date = values.start;
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      const vars = {
        name: values.name.trim(),
        description: values.description.trim(),
        start: date.getTime() / 1000,
        end: values.end.getTime() / 1000,
        visibility: values.visibility,
        onlyForCompanyUsers: values.onlyForCompanyUsers,
      };
      let hasUpload = false;
      let successMessage = t('pages.events.form.formAddSuccessMessage');
      if (values.picture) {
        vars.picture = values.picture;
        hasUpload = true;
      }
      if (values.areaId !== null) {
        if (values.areaId !== '') {
          vars.areaId = values.areaId;
        } else {
          vars.areaId = null;
        }
      }
      if (values.privatiizCompanyId !== null) {
        if (values.privatiizCompanyId !== '') {
          vars.companyId = values.privatiizCompanyId;
        } else {
          vars.companyId = null;
        }
      }
      if (values.eventId) {
        vars.eventId = values.eventId;
        successMessage = t('pages.events.form.formEditSuccessMessage');
      }
      mutationEvent({
        variables: vars,
        context: {
          hasUpload,
        },
      }).then((result) => {
        onGraphQLSuccess(successMessage, result.data.setEvent.id);
      }).catch((error) => {
        onGraphQLError(error);
      });
    }
  };

  function onGraphQLSuccess(message, id) {
    handleClose();
    setValues(INITIAL_VALUES);
    if (message) {
      dispatch(dispatchInfo(message));
    }
    history.push(EVENT.replace(':id', id));
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const displayMonth = `${month < 10
    ? `0${month}`
    : month}`;
  const year = newDate.getFullYear();
  const minDate = `${year}-${displayMonth}-${date}`;
  const maxDate = '2037-12-31';
  return (
    <MuiPickersUtilsProvider
      utils={locale === 'fr'
        ? LocalizedUtils
        : DateFnsUtils
      }
      locale={localeMap[locale]}
    >
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Grid
            container
            className={classes.container}
            spacing={4}
            wrap={matchesSm
              ? 'wrap'
              : 'nowrap'}
          >
            <Grid item xs={12} md={3}>
              <LargePictureInput
                pictureBlob={values.pictureBlob}
                handleChangeFile={handleChangeFile}
                id="event-picture"
                pictureErrorMessage={values.pictureError}
                rootStyle={{ minWidth: 'unset' }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.formGroup}>
              <TextField
                label={t('pages.events.form.name')}
                id="event-name"
                type="text"
                variant="outlined"
                onChange={handleChange('name')}
                margin="normal"
                required
                value={values.name}
                fullWidth={true}
                error={values.nameError !== ''}
                helperText={values.nameError}
              />
              <FormControl
                error={values.areaIdError !== ''}
                variant="outlined"
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="event-areaId" error={values.areaIdError !== ''}>
                  {t('pages.events.form.area')}
                </InputLabel>
                <Select
                  value={values.areaId || ''}
                  onChange={handleChange('areaId')}
                  input={
                    <OutlinedInput
                      labelWidth={locale === 'fr'
                        ? 55
                        : 34}
                      name="area"
                      id="event-areaId"
                    />
                  }
                  label={t('pages.events.form.area')}
                  inputProps={{
                    name: 'area',
                    id: 'event-areaId',
                  }}
                >
                  <MenuItem value={''} key="no-area">
                    <em>{t('pages.events.form.noArea')}</em>
                  </MenuItem>
                  {areas && areas.map((area) => (
                    <MenuItem value={area.id} key={`area-${area.id}`}>
                      {area.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="event-areaId-error-text" error={values.areaIdError !== ''}>
                  {values.areaIdError}
                </FormHelperText>
              </FormControl>
              <FormControl
                error={values.privatiizCompanyIdError !== ''}
                variant="outlined"
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel
                  htmlFor="event-privatiizCompanyId"
                  error={values.privatiizCompanyIdError !== ''}
                >
                  {t('pages.events.form.privatiizCompany')}
                </InputLabel>
                <Select
                  label={t('pages.events.form.privatiizCompany')}
                  value={values.privatiizCompanyId || ''}
                  onChange={handleChange('privatiizCompanyId')}
                  input={
                    <OutlinedInput
                      labelWidth={locale === 'fr'
                        ? 55
                        : 34}
                      name="company"
                      id="event-privatiizCompanyId"
                    />
                  }
                  inputProps={{
                    name: 'company',
                    id: 'event-privatiizCompanyId',
                  }}
                >
                  <MenuItem value="" key="no-company">
                    <em>{t('pages.events.form.noPrivatiizCompany')}</em>
                  </MenuItem>
                  {companies && companies.map((company) => (
                    <MenuItem value={company.id} key={`company-${company.id}`}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  id="event-privatiizCompanyId-error-text"
                  error={values.privatiizCompanyIdError !== ''}
                >
                  {values.privatiizCompanyIdError}
                </FormHelperText>
              </FormControl>
              <Grid container spacing={2} direction="row" wrap="nowrap">
                <Grid item xs={6}>
                  <DatePicker
                    id="event-start"
                    value={values.start}
                    error={values.startError !== ''}
                    inputVariant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholder="__/__/____"
                    format="dd/MM/yyyy"
                    label={t('pages.events.form.start')}
                    onChange={(date) => handleStartChange(date)}
                    helperText={values.startError}
                    showTodayButton={false}
                    okLabel={t('common.ok')}
                    cancelLabel={t('common.cancel')}
                    readOnly={false}
                    autoOk
                  >
                  </DatePicker>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    id="event-start"
                    value={values.end}
                    error={values.endError !== ''}
                    inputVariant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholder="__/__/____"
                    format="dd/MM/yyyy"
                    label={t('pages.events.form.end')}
                    onChange={(date) => handleEndChange(date)}
                    helperText={values.endError}
                    showTodayButton={false}
                    okLabel={t('common.ok')}
                    cancelLabel={t('common.cancel')}
                    readOnly={false}
                    autoOk
                  >
                  </DatePicker>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} className={classes.formGroup}>
              <FormControl
                error={values.descriptionError !== ''}
                style={{
                  width: '100%',
                  position: 'relative',
                  marginBottom: '1em',
                }}
              >
                <ReactQuill
                  placeholder={t('pages.events.form.description')}
                  style={{ marginTop: '1em' }}
                  value={values.description}
                  onChange={handleQuillChange('description')}
                  modules={{
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                />
                <FormHelperText>{values.descriptionError !== ''}</FormHelperText>
              </FormControl>
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    style={{
                      flexGrow: 1,
                      textAlign: 'left',
                      cursor: 'pointer',
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item style={{ marginTop: '5px' }}>
                        {t('pages.events.form.visibility')}
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {'('}{t('common.optional')}{')'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <AntSwitch
                      checked={values.visibility === 'LOCKED'}
                      onChange={handleChange('visibility')}
                      name="visibility"
                    />
                  </Grid>
                </Grid>
              </Typography>
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    style={{
                      flexGrow: 1,
                      textAlign: 'left',
                      cursor: 'pointer',
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item style={{ marginTop: '5px' }}>
                        {t('pages.events.form.onlyForCompanyUsers')}
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {t('pages.events.form.onlyForCompanyUsersHelperText')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <AntSwitch
                      checked={values.onlyForCompanyUsers}
                      onChange={handleChange('onlyForCompanyUsers')}
                      name="onlyForCompanyUsers"
                    />
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            onClick={handleClose}
            disabled={loading}
            className={formInSmallDialogClasses.button}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            className={formInSmallDialogClasses.button}
            disabled={loading}
          >
            {values.eventId
              ? t('common.edit')
              : t('common.add')}
            {loading && (
              <CircularProgress
                size={24}
                className={formInSmallDialogClasses.buttonProgress}
              />
            )}
          </Button>
        </DialogActions>
      </form>
    </MuiPickersUtilsProvider>
  );
}

EventForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  event: PropTypes.object,
};

EventForm.defaultProps = {
  event: null,
};
