import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import ErrorServer from '../ErrorServer';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import ActivityList from '../../components/activities/ActivityList';
import { Loading } from '../../components/layout/Loading';
import detailPageStyles from '../../styles/detailPage';
import useCompany from '../../components/hooks/UseCompany';

export const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const CompanyActivitiesPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const {
    company,
    loading,
    error,
  } = useCompany(id);

  if (error) {
    return <ErrorServer error={error} />;
  }
  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        <Container maxWidth={false} className={detailPageClasses.header}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1">{t('pages.companyActivities.title')}</Typography>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={false} className={detailPageClasses.content}>
          {loading && <Loading />}
          {!loading && <ActivityList
            activities={company.privatiizActivities}
            companyId={company.id}
          />}
        </Container>
      </Box>
    </div>
  );
};

export default CompanyActivitiesPage;
