import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY_DARK,
  SPORTIIZ_WHITE,
} from '../../../constants/colors';

const useStyles = makeStyles((theme) => (
  {
    root: {
      lineHeight: 1.4,
      backgroundColor: SPORTIIZ_BLUE,
      paddingBottom: '2em',
      paddingTop: '2em',
      display: 'block',
      position: 'relative',
    },
    container: {
      textAlign: 'left',
      maxWidth: '1280px',
      width: '100%',
      paddingRight: '15px',
      paddingLeft: '15px',
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '1140px',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '960px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '720px',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '540px',
      },
    },
    textBox: {
      marginTop: 0,
      marginBottom: '1rem',
    },
    number: {
      color: SPORTIIZ_WHITE,
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.5',
        fontSize: '2em',
      },
      [theme.breakpoints.down('xs')]: {
        lineHeight: '1.8',
        fontSize: '1.75em',
      },
    },
    text: {
      color: SPORTIIZ_GREY_DARK,
      textAlign: 'left',
    },
    iconContainer: {
      textAlign: 'right',
      height: '46px',
      paddingRight: '5px',
      paddingTop: '9px',
      [theme.breakpoints.down('sm')]: {
        height: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '35px',
      },
    },
    textContainer: {
      textAlign: 'left',
      paddingLeft: '5px',
    },
  }
));

export default function NumbersBlueSection(props) {
  const { numbers } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        {numbers.map((number) => (
          <Grid item key={number.text} xs={6} md={3}>
            <Grid container>
              <Grid
                item
                xs={4}
                sm={5}
                className={classes.iconContainer}
              >
                {number.icon}
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.textContainer}
              >
                <Typography variant="h1" component="span" className={classes.number}>
                  {number.number}
                </Typography>
              </Grid>
              <Grid item sm={5} xs={4}/>
              <Grid
                item
                xs={7}
                className={classes.textContainer}
              >
                <Typography variant="subtitle1" className={classes.text}>
                  {number.text}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

NumbersBlueSection.propTypes = {
  numbers: PropTypes.array.isRequired,
};
