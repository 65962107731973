import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import AvatarPictureInput from './AvatarPictureInput';
import { TYPE_FREE_TIME_SLOT, TYPE_PHYSICAL } from '../../constants/rewardTypes';
import { MUTATION_SET_REWARD } from '../../constants/graphQLQueries';
import formInSmallDialogStyle from '../../styles/formInSmallDialog';
import { dispatchInfo, handleError } from '../../store/infos/actions';

const useFormInSmallDialogStyles = makeStyles((theme) => formInSmallDialogStyle(theme));

const INITIAL_VALUES = {
  rewardId: null,
  type: TYPE_PHYSICAL,
  name: '',
  nameError: false,
  nameErrorMessage: '',
  stock: 0,
  stockError: false,
  stockErrorMessage: '',
  description: '',
  descriptionError: false,
  descriptionErrorMessage: '',
  picture: null,
  pictureBlob: null,
};

export default function RewardForm(props) {
  const { handleClose, reward, update } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formInSmallDialogClasses = useFormInSmallDialogStyles();
  const client = useApolloClient();

  const newState = (reward === null)
    ? INITIAL_VALUES
    : {
      ...INITIAL_VALUES,
      rewardId: reward.id,
      type: reward.type,
      name: reward.name,
      description: reward.description,
      stock: reward.stock,
      pictureBlob: reward.picture || null,
    };

  const [values, setValues] = useState(newState);

  const [mutationReward, { loading }] = useMutation(MUTATION_SET_REWARD,
    {
      client,
      update,
    });

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });
  };

  function handleRealMenuClick() {
    if (values.rewardId) {
      dispatch(handleError(t('pages.rewards.form.editTypeOnEdit')));
    } else {
      setValues({ ...values, type: TYPE_PHYSICAL });
    }
  }

  function handleVirtualMenuClick() {
    if (values.rewardId) {
      dispatch(handleError(t('pages.rewards.form.editTypeOnEdit')));
    } else {
      setValues({ ...values, type: TYPE_FREE_TIME_SLOT, stock: 0 });
    }
  }

  const handleChangeFile = (name) => (event) => {
    const blob = new Blob([event.target.files[0]], { type: 'image/png' });
    const blobUrl = URL.createObjectURL(blob);
    setValues({ ...values, [name]: event.target.files[0], [`${name}Blob`]: blobUrl });
  };

  const onSubmit = (event) => {
    let error = false;
    let newValues = { ...values };
    if (values.name === null || values.name.trim() === '') {
      newValues = {
        ...newValues,
        nameError: true,
        nameErrorMessage: t('pages.rewards.form.formNameRequired'),
      };
      error = true;
    }
    if (values.description === null || values.description.trim() === '') {
      newValues = {
        ...newValues,
        descriptionError: true,
        descriptionErrorMessage: t('pages.rewards.form.formDescriptionRequired'),
      };
      error = true;
    }
    if (values.stock < 0) {
      newValues = {
        ...newValues,
        stockError: true,
        stockErrorMessage: t('pages.rewards.form.formMinStock'),
      };
      error = true;
    }
    setValues(newValues);
    if (!error) {
      if (event) {
        event.preventDefault();
      }

      const vars = {
        name: values.name.trim(),
        description: values.description.trim(),
        stock: values.stock,
        type: values.type,
      };

      let hasUpload = false;
      let successMessage = t('pages.rewards.form.formAddSuccessMessage');

      if (values.rewardId) {
        vars.rewardId = values.rewardId;
        successMessage = t('pages.rewards.form.formEditSuccessMessage');
      }

      if (values.picture) {
        vars.picture = values.picture;
        hasUpload = true;
      }

      mutationReward({
        variables: vars,
        context: {
          hasUpload,
        },
      }).then(() => {
        onGraphQLSuccess(successMessage);
      }).catch((error) => {
        onGraphQLError(error);
      });
    }
  };

  function onGraphQLSuccess(message) {
    handleClose();
    setValues(INITIAL_VALUES);
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (
      error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <nav className={formInSmallDialogClasses.nav}>
            <ul className={formInSmallDialogClasses.ul}>
              <li className={formInSmallDialogClasses.li} onClick={handleRealMenuClick}>
                <a
                  href="#app"
                  className={formInSmallDialogClasses.link + (values.type === TYPE_PHYSICAL
                    ? ` ${formInSmallDialogClasses.linkActive}`
                    : ''
                  )}
                >
                  {t('pages.rewards.realMenuTitle')}
                </a>
              </li>
              <li className={formInSmallDialogClasses.li} onClick={handleVirtualMenuClick}>
                <a href="#app"
                   className={formInSmallDialogClasses.link + (values.type !== TYPE_PHYSICAL
                     ? ` ${formInSmallDialogClasses.linkActive}`
                     : ''
                   )}
                >
                  {t('pages.rewards.virtualMenuTitle')}
                </a>
              </li>
            </ul>
          </nav>
        </Grid>
        <Grid item xs={12} lg={5}>
          <AvatarPictureInput
            pictureBlob={values.pictureBlob}
            handleChangeFile={handleChangeFile}
            id="reward-picture"
          />
        </Grid>
        <Grid item xs={12} lg={7}>
          <TextField
            label={t('pages.rewards.form.name')}
            id="reward-name"
            type="text"
            variant="outlined"
            onChange={handleChange('name')}
            margin="normal"
            value={values.name}
            fullWidth={true}
            error={values.nameError}
            helperText={values.nameErrorMessage}
          />
          {values.type === TYPE_PHYSICAL
          && <TextField
            label={t('pages.rewards.form.stock')}
            id="reward-stock"
            type={'number'}
            variant="outlined"
            onChange={handleChange('stock')}
            margin="normal"
            value={values.stock}
            fullWidth={true}
            error={values.stockError}
            helperText={values.stockErrorMessage}
            inputProps={{
              min: 0,
            }}
          />}
          <TextField
            label={t('pages.rewards.form.description')}
            id="reward-description"
            multiline
            rows="3"
            variant="outlined"
            onChange={handleChange('description')}
            margin="normal"
            value={values.description}
            fullWidth={true}
            error={values.descriptionError}
            helperText={values.descriptionErrorMessage}
            inputProps={{
              min: 0,
              style: {
                fontSize: '14px',
                height: 'auto',
              },
            }}
            InputProps={{
              style: {
                fontSize: '14px',
                padding: '.375rem .75rem',
                minHeight: '42px',
                height: 'auto',
              },
            }}
          />
        </Grid>
        <Grid container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end">
          <Grid item className={formInSmallDialogClasses.buttonContainer}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={onSubmit}
              className={formInSmallDialogClasses.button}
              disabled={loading}
            >
              {values.rewardId ? t('common.edit') : t('common.add')}
              {loading
              && <CircularProgress
                size={24}
                className={formInSmallDialogClasses.buttonProgress}
              />}
            </Button>
          </Grid>
          <Grid item className={formInSmallDialogClasses.buttonContainer}>
            <Button
              type="button"
              variant="outlined"
              onClick={handleClose}
              disabled={loading}
              className={formInSmallDialogClasses.button}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

RewardForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  reward: PropTypes.object,
};
