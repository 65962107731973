import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_BLUE_GREY_DARK, SPORTIIZ_RED,
} from '../../constants/colors';
import { CARD_RADIUS } from '../../constants/style';
import { getAvatarLetter, getDisplayName } from '../../services/userService';
import EmailIcon from '../icon/EmailIcon';
import PhoneIcon from '../icon/PhoneIcon';
import MoreVerticalIcon from '../icon/MoreVerticalIcon';
import CreditCardIcon from '../icon/CreditCardIcon';
import ResetPasswordConfirmDialog from '../associations/coaches/ResetPasswordConfirmDialog';
import { SECTION_COMPANIES, SECTION_INDIVIDUALS, useSection } from '../contexts/SectionContext';
import { useEditUser } from '../contexts/EditUserContext';
import useCompany from '../hooks/UseCompany';
import { COMPANY_ADMINISTRATORS, COMPANY_MEMBER } from '../../constants/routes';

const useStyles = makeStyles(() => (
  {
    card: {
      position: 'relative',
      textAlign: 'left',
      border: 'none',
      borderRadius: CARD_RADIUS,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.01), 0px 8px 10px 1px rgba(0, 0, 0, 0.01), 0px 3px 14px 2px rgba(0, 0, 0, 0.1)',
      },
      padding: '20px 17px',
      display: 'flex',
      gap: '18px',
    },
    clickable: {
      cursor: 'pointer',
    },
    avatar: {
      width: '89px',
      height: '89px',
    },
    textContainer: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    cardTitle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    cardText: {
      color: SPORTIIZ_BLUE_GREY_DARK,
      fontWeight: 600,
    },
    cardTextLink: {
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'normal',
      whiteSpace: 'nowrap',
      color: 'inherit',
      verticalAlign: 'center',
    },
    cardTextSpan: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'normal',
      whiteSpace: 'nowrap',
      color: SPORTIIZ_BLUE,
      verticalAlign: 'center',
    },
    cardTextIcon: {
      margin: '5px 5px 5px 0',
      verticalAlign: 'top',
    },
    action: {
      position: 'absolute',
      top: '14px',
      right: '14px',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
    deleteItem: {
      color: SPORTIIZ_RED,
    },
  }
));

export default function UserCard(props) {
  const {
    user,
    hideMenu,
    openRemoveDialog,
  } = props;
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const isCompanyAdminPage = useRouteMatch(COMPANY_ADMINISTRATORS);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const { section } = useSection();
  const { companySubscription } = useCompany(id);
  const isCompany = section === SECTION_COMPANIES;
  const isIndividual = section === SECTION_INDIVIDUALS;
  const { setEditUser } = useEditUser();

  function handleClose(event) {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }

  function handleRemove(event) {
    event.stopPropagation();
    event.preventDefault();
    if (openRemoveDialog !== undefined) {
      setAnchorEl(null);
      openRemoveDialog(user);
    }
  }

  function handleOpen(event) {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.target);
  }

  function handleConfirmOpen(event) {
    event.stopPropagation();
    event.preventDefault();
    setConfirmOpen(true);
  }

  function handleConfirmClose(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setConfirmOpen(false);
  }

  function handleResetPasswordClick(event) {
    handleConfirmOpen(event);
    handleClose(event);
  }

  function handleEdit(event) {
    event.stopPropagation();
    event.preventDefault();
    if (setEditUser !== null) {
      setEditUser(user);
    }
    handleClose(event);
  }

  function handleClick(event) {
    event.stopPropagation();
    event.preventDefault();
    if (section === SECTION_COMPANIES) {
      history.push(
        COMPANY_MEMBER.replace(':id', id).replace(':memberId', user.id),
      );
    }
  }

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Paper
        className={`${classes.card} ${isCompany
          ? classes.clickable
          : ''}`}
        onClick={handleClick}
      >
        {user.picture !== null
          ? <Avatar
            className={classes.avatar}
            src={user.picture}
            title="avatar"
          />
          : <Avatar className={classes.avatar}>
            {getAvatarLetter(user)}
          </Avatar>
        }
        <div className={classes.textContainer}>
          <Typography component="h6" variant="h6" className={classes.cardTitle}>
            {getDisplayName(user)}
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.cardText}>
            <a
              href={`mailto:${user.email}?subject=Sportiiz`}
              className={classes.cardTextLink}
              aria-label="email"
            >
              <EmailIcon
                color={SPORTIIZ_BLUE_GREY_DARK}
                className={classes.cardTextIcon}
                width="16px"
                height="16px"
              />
              {user.email}
            </a>
          </Typography>

          {user.phone
            && (
              <Typography variant="subtitle1" component="p" className={classes.cardText}>
                <a
                  href={`tel:${user.phone}`}
                  className={classes.cardTextLink}
                  aria-label="phone"
                >
                  <PhoneIcon
                    color={SPORTIIZ_BLUE_GREY_DARK}
                    className={classes.cardTextIcon}
                    width="16px"
                    height="16px"
                  />
                  {user.phone}
                </a>
              </Typography>
            )
          }
          {(
            isCompany && !isCompanyAdminPage
          ) || isIndividual
            ? (
              <Typography variant="subtitle1" component="p" className={classes.cardText}>
                <span
                  className={classes.cardTextSpan}
                  aria-label="subscription"
                >
                  <CreditCardIcon
                    className={classes.cardTextIcon}
                    width="16px"
                    height="16px"
                  />
                  {isCompany && (
                    companySubscription && companySubscription.subscription
                      ? `${companySubscription.subscription.name} (${companySubscription.subscription.activityPrice}€ / séance)`
                      : t('pages.company.subscription.noSubscription')
                  )}
                  {isIndividual && (
                    user.currentUserSubscription && user.currentUserSubscription.subscription
                      ? `${user.currentUserSubscription.subscription.name} (${user.currentUserSubscription.subscription.activityPrice}€ / séance)`
                      : t('pages.company.subscription.noSubscription')
                  )}
                </span>
              </Typography>
            )
            : ''}
        </div>
        {hideMenu === false
          && (
            <>
              <MoreVerticalIcon
                className={classes.action}
                color={SPORTIIZ_BLUE}
                onClick={handleOpen}
              />
              <Popover
                className={classes.popover}
                id={`popovermenu-${user.id}`}
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <List component="nav" className={classes.menu}>
                  <ListItem
                    button
                    onClick={handleEdit}
                  >
                    {t('common.edit')}
                  </ListItem>
                  {hasSuperAdminRole && (
                    <ListItem
                      button
                      onClick={handleResetPasswordClick}
                    >
                      {t('pages.associationCoaches.resetPassword')}
                    </ListItem>
                  )}
                  {openRemoveDialog && (
                    <ListItem
                      button
                      onClick={handleRemove}
                      className={classes.deleteItem}
                    >
                      {t('common.delete')}
                    </ListItem>
                  )}
                </List>
              </Popover>
              <ResetPasswordConfirmDialog
                open={confirmOpen}
                onClose={handleConfirmClose}
                user={user}
              />
            </>
          )}
      </Paper>
    </Grid>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  hideMenu: PropTypes.bool,
  openRemoveDialog: PropTypes.func,
};

UserCard.defaultProps = {
  hideMenu: false,
};
