import { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  DEFAULT_ASSOCIATION,
  QUERY_ASSOCIATION,
} from '../../constants/graphQLQueries';

function sortTimeSlots(a, b) {
  if (a.start > b.start) {
    return 1;
  }
  if (a.start < b.start) {
    return -1;
  }
  return 0;
}
function useAssociation(id, start = null) {
  const [association, setAssociation] = useState(DEFAULT_ASSOCIATION);
  const [coaches, setCoaches] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [activities, setActivities] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const client = useApolloClient();
  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery(
    QUERY_ASSOCIATION,
    {
      variables: { id, start },
      client,
    },
  );

  useEffect(() => {
    if (data && data.association) {
      setAssociation(data.association);
      if (data.association.associationUsers) {
        setCoaches(
          data.association.associationUsers.filter((au) => au.roles.includes('coach'))
            .map((au) => au.user),
        );
        setAdmins(
          data.association.associationUsers.filter((au) => au.roles.includes('admin'))
            .map((au) => au.user),
        );
      }
      if (data.association.activities) {
        setActivities(data.association.activities);
      }
      if (data.association.timeSlots) {
        setTimeSlots(data.association.timeSlots.sort(sortTimeSlots));
      }
    }
  }, [data, loading]);

  return {
    loading,
    error,
    refetch,
    association,
    coaches,
    admins,
    activities,
    timeSlots,
  };
}

export default useAssociation;
