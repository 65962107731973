import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getI18n, useTranslation } from 'react-i18next';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { MUTATION_TIMESLOTS } from '../../constants/graphQLQueries';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import dialogFormStyle from '../../styles/dialogForm';
import { getDaysBetweenDates } from '../../services/dateService';
import PrivatiizIcon from '../icon/PrivatiizIcon';
import { SPORTIIZ_GREY, SPORTIIZ_RED } from '../../constants/colors';

const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const INITIAL_VALUES = {
  activityId: null,
  activityIdError: false,
  activityIdErrorMessage: '',
  weekDay: 1,
  weekDayError: false,
  weekDayErrorMessage: '',
  hour: 12,
  hourError: false,
  hourErrorMessage: '',
  minute: 0,
  minuteError: false,
  minuteErrorMessage: '',
  startError: false,
  startErrorMessage: '',
  endError: false,
  endErrorMessage: '',
};

export default function TimeSlotForm(props) {
  const {
    associationId,
    activities,
    update,
    handleClose,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialogFormClasses = useDialogFormStyles();
  const client = useApolloClient();
  const locale = getI18n().language.slice(0, 2);
  const startDate = new Date();
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  // must be next August month
  const endDate = new Date(startDate.getTime());
  endDate.setMonth(7);
  if (startDate.getMonth() >= 7) {
    endDate.setFullYear(startDate.getFullYear() + 1);
  }
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  let newState = INITIAL_VALUES;
  if (activities.length === 1) {
    newState = {
      ...newState,
      activityId: activities[0].id,
    };
  }

  const [values, setValues] = useState(newState);

  const [timeSlotsCreatedNumber, setTimeSlotsCreatedNumber] = useState(
    getDaysBetweenDates(values.weekDay, selectedStartDate, selectedEndDate),
  );

  const [mutationTimeSlot, { loading }] = useMutation(
    MUTATION_TIMESLOTS,
    {
      client,
      update,
    },
  );

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });

    if (!Number.isNaN(selectedStartDate.getTime()) && !Number.isNaN(selectedEndDate.getTime())) {
      setTimeSlotsCreatedNumber(
        getDaysBetweenDates(values.weekDay, selectedStartDate, selectedEndDate),
      );
    }
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setValues({
      ...values,
      startError: false,
      startErrorMessage: '',
    });
    if (!Number.isNaN(date.getTime())) {
      setTimeSlotsCreatedNumber(
        getDaysBetweenDates(values.weekDay, date, selectedEndDate),
      );
    }
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setValues({
      ...values,
      endError: false,
      endErrorMessage: '',
    });
    if (!Number.isNaN(date.getTime())) {
      setTimeSlotsCreatedNumber(
        getDaysBetweenDates(values.weekDay, selectedStartDate, date),
      );
    }
  };

  const onSubmit = (event) => {
    let newValues = { ...values };
    let error = false;
    if (values.activityId === null || values.activityId === '') {
      newValues = {
        ...newValues,
        activityIdError: true,
        activityIdErrorMessage: t('pages.timeSlots.form.formInvalidActivityId'),
      };
      error = true;
    }
    if (values.hour < 6 || values.hour > 23) {
      newValues = {
        ...newValues,
        hourError: true,
        hourErrorMessage: t('pages.timeSlots.form.formInvalidHour'),
      };
      error = true;
    }
    if (values.minute < 0 || values.minute > 59) {
      newValues = {
        ...newValues,
        minuteError: true,
        minuteErrorMessage: t('pages.timeSlots.form.formInvalidMinute'),
      };
      error = true;
    }
    if (Number.isNaN(selectedStartDate.getTime())) {
      newValues = {
        ...newValues,
        startError: true,
        startErrorMessage: t('pages.timeSlots.form.formInvalidDate'),
      };
      error = true;
    } else if (selectedStartDate < startDate) {
      newValues = {
        ...newValues,
        startError: true,
        startErrorMessage: t('pages.timeSlots.form.formMinStartDate'),
      };
      error = true;
    }
    if (Number.isNaN(selectedEndDate.getTime())) {
      newValues = {
        ...newValues,
        endError: true,
        endErrorMessage: t('pages.timeSlots.form.formInvalidDate'),
      };
      error = true;
    } else if (selectedEndDate < selectedStartDate) {
      newValues = {
        ...newValues,
        endError: true,
        endErrorMessage: t('pages.timeSlots.form.formEndDateLessThanStartDate'),
      };
      error = true;
    }
    setValues(newValues);
    if (!error) {
      if (event) {
        event.preventDefault();
      }
      if (timeSlotsCreatedNumber > 0) {
        submit();
      } else {
        handleClose();
      }
    }
  };

  function submit() {
    const vars = {
      associationId,
      activityId: values.activityId,
      weekDay: values.weekDay,
      hour: values.hour,
      minute: values.minute,
      start: selectedStartDate.getTime() / 1000,
      end: selectedEndDate.getTime() / 1000,
    };
    mutationTimeSlot({
      variables: vars,
    }).then((result) => {
      onGraphQLSuccess(t(
        'pages.timeSlots.form.formAddSuccessMessage',
        { count: result.data.createTimeSlots.length },
      ));
    }).catch((error) => {
      onGraphQLError(error);
    });
  }

  function onGraphQLSuccess(message) {
    if (message) {
      dispatch(dispatchInfo(message));
    }
    handleClose();
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  return (
    <MuiPickersUtilsProvider
      utils={locale === 'fr'
        ? LocalizedUtils
        : DateFnsUtils}
      locale={localeMap[locale]}
    >
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <FormControl
                className={dialogFormClasses.formControl}
                error={values.activityIdError}
              >
                <InputLabel
                  htmlFor="timeSlots-activityId"
                >{t('pages.timeSlots.form.activity')}
                </InputLabel>
                <Select
                  value={values.activityId || ''}
                  onChange={handleChange('activityId')}
                  inputProps={{
                    name: 'activity',
                    id: 'timeSlots-activityId',
                  }}
                  style={{ width: '100%' }}
                >
                  {activities.map((act) => (
                    <MenuItem
                      value={act.id}
                      key={`activity-${act.id}`}
                      style={{ paddingLeft: '6px' }}
                    >
                      {act.isPrivatiiz
                        && <span
                          style={{
                            marginRight: '6px',
                            verticalAlign: 'text-top',
                          }}
                        >
                        <PrivatiizIcon
                          color={values.activityId === act.id
                            ? SPORTIIZ_RED
                            : SPORTIIZ_GREY}
                        />
                    </span>}
                      {act.name}
                      {act.isPrivatiiz
                        && act.privatiizCompany
                        && <> {t('pages.activity.privatiizTo')} {act.privatiizCompany.name}</>
                      }
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="timeSlots-activityId-error-text">
                  {values.activityIdErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl
                className={dialogFormClasses.formControl}
                error={values.weekDayError}
              >
                <InputLabel htmlFor="timeSlots-weekDay">
                  {t('pages.timeSlots.form.weekDay')}
                </InputLabel>
                <Select
                  value={values.weekDay}
                  onChange={handleChange('weekDay')}
                  inputProps={{
                    name: 'weekDay',
                    id: 'timeSlots-weekDay',
                  }}
                  style={{ width: '100%' }}
                >
                  {[1, 2, 3, 4, 5].map((dayNumber) => (
                    <MenuItem value={dayNumber} key={`day-${dayNumber}`}>
                      {t(`pages.timeSlots.form.days.${dayNumber}`)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="timeSlots-weekDay-error-text">
                  {values.weekDayErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <TextField
                label={t('pages.timeSlots.form.hour')}
                id="timeSlots-hour"
                type="number"
                variant="outlined"
                onChange={handleChange('hour')}
                margin="normal"
                value={values.hour}
                fullWidth={true}
                error={values.hourError}
                helperText={values.hourErrorMessage}
                inputProps={{
                  min: 6,
                  max: 23,
                }}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <TextField
                label={t('pages.timeSlots.form.minute')}
                id="timeSlots-minute"
                type="number"
                variant="outlined"
                onChange={handleChange('minute')}
                margin="normal"
                value={values.minute}
                fullWidth={true}
                error={values.minuteError}
                helperText={values.minuteErrorMessage}
                inputProps={{
                  min: 0,
                  max: 59,
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DatePicker
                error={values.startError}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                fullWidth
                id="timeSlots-startDate"
                label={t('pages.timeSlots.form.startDate')}
                value={selectedStartDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
                helperText={values.startErrorMessage}
                okLabel={t('common.ok')}
                cancelLabel={t('common.cancel')}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DatePicker
                error={values.endError}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                fullWidth
                id="timeSlots-endDate"
                label={t('pages.timeSlots.form.endDate')}
                value={selectedEndDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
                helperText={values.endErrorMessage}
                okLabel={t('common.ok')}
                cancelLabel={t('common.cancel')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            onClick={handleClose}
            className={dialogFormClasses.button}
            disabled={loading}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            className={dialogFormClasses.button}
            disabled={loading}
          >
            {t('pages.timeSlots.form.add', { count: timeSlotsCreatedNumber || 0 })}
            {loading && (
              <CircularProgress size={24} className={dialogFormClasses.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </form>
    </MuiPickersUtilsProvider>
  );
}

TimeSlotForm.propTypes = {
  associationId: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  activities: PropTypes.array,
};

TimeSlotForm.defaultProps = {
  activities: [],
};
