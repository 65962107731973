import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import Transition from '../layout/TransitionAnimation';
import { useDialogFormStyles } from '../associations/coaches/ResetPasswordConfirmDialog';
import AppDialogTitle from '../layout/AppDialogTitle';

const TimeSlotDeleteConfirmationDialog = (props) => {
  const {
    open,
    onClose,
    handleValid,
    loading,
  } = props;
  const dialogFormClasses = useDialogFormStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      fullScreen={matches}
      scroll="body"
      onClose={onClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches
        ? 500
        : 750}
      aria-labelledby={t('pages.timeSlots.dialogTitleConfirmDeleteText')}
    >
      <AppDialogTitle
        title={t('pages.timeSlots.dialogTitleConfirmDeleteTitle')}
        handleClose={onClose}
      />
      <DialogContent id="confirm-dialog-title">
        {t('pages.timeSlots.dialogTitleConfirmDeleteText')}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={dialogFormClasses.button}
          color="primary"
          variant={'outlined'}
          disabled={loading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={handleValid}
          className={dialogFormClasses.button}
          color="primary"
          variant={'contained'}
          disabled={loading}
        >
          {t('common.delete')}
          {loading && (
            <CircularProgress
              size={24}
              className={dialogFormClasses.buttonProgress}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TimeSlotDeleteConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleValid: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TimeSlotDeleteConfirmationDialog;
