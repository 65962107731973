import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { SPORTIIZ_WHITE } from '../../../constants/colors';
import DashboardIcon from '../../icon/DashboardIcon';
import {
  COMPANY,
  COMPANY_REGEX,
  COMPANY_ADMINISTRATORS,
  COMPANY_ADMINISTRATORS_REGEX,
  COMPANY_ACTIVITIES,
  COMPANY_ACTIVITIES_REGEX,
  COMPANY_MEMBERS,
  COMPANY_MEMBER_REGEX,
  COMPANY_TIMESLOTS,
  COMPANY_TIMESLOTS_REGEX,
} from '../../../constants/routes';
import sidebarMenuStyle from '../../../styles/sidebarMenu';
import PeopleIcon from '../../icon/PeopleIcon';
import HomeIcon from '../../icon/HomeIcon';
import AdminIcon from '../../icon/AdminIcon';
import CalendarIcon from '../../icon/CalendarIcon';

const useSidebarMenuStyles = makeStyles((theme) => (
  sidebarMenuStyle(theme)
));

export default function CompanySidebarMenu(props) {
  const {
    id,
    loading,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const sidebarMenuClasses = useSidebarMenuStyles();

  function handleMenuItemClick(path) {
    if (!loading) {
      history.push(path.replace(':id', id));
    }
  }

  const dashBoardSelected = location.pathname.match(COMPANY_REGEX) || [];
  const memberSelected = location.pathname.match(COMPANY_MEMBER_REGEX) || [];
  const activitySelected = location.pathname.match(COMPANY_ACTIVITIES_REGEX) || [];
  const companySelected = location.pathname.match(COMPANY_ADMINISTRATORS_REGEX) || [];
  const timeslotSelected = location.pathname.match(COMPANY_TIMESLOTS_REGEX) || [];

  return (
    <List
      component="nav"
      aria-label="main mailbox folders"
      className={sidebarMenuClasses.list}
    >
      <ListItem
        button
        className={sidebarMenuClasses.listItem}
        selected={dashBoardSelected.length > 0}
        onClick={() => handleMenuItemClick(COMPANY)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <HomeIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary="Dashboard"
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={activitySelected.length > 0}
        onClick={() => handleMenuItemClick(COMPANY_ACTIVITIES)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <DashboardIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.companyActivities.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={companySelected.length > 0}
        onClick={() => handleMenuItemClick(COMPANY_ADMINISTRATORS)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <AdminIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.companyAdministrators.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        button
        className={sidebarMenuClasses.listItem}
        selected={memberSelected.length > 0}
        onClick={() => handleMenuItemClick(COMPANY_MEMBERS)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <PeopleIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.companyMembers.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>

      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={timeslotSelected.length > 0}
        onClick={() => handleMenuItemClick(COMPANY_TIMESLOTS)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <CalendarIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.companyTimeSlots.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
    </List>
  );
}

CompanySidebarMenu.defaultProps = {
  loading: false,
};

CompanySidebarMenu.propTypes = {
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};
