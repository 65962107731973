import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import PlusIcon from '../../icon/PlusIcon';
import CoachesIcon from '../../icon/CoachesIcon';
import { SPORTIIZ_BLUE, SPORTIIZ_GREY_GREEN, SPORTIIZ_RED } from '../../../constants/colors';
import SubscriptionIcon from '../../icon/SubscriptionIcon';
import RewardIcon from '../../icon/RewardIcon';
import { GAMES, REWARDS, SUBSCRIPTIONS } from '../../../constants/routes';
import useReactRouter from 'use-react-router';

const useStyles = makeStyles((theme) => (
  {
    logo: {
      width: '60px',
      height: '60px',
      margin: '5px',
      float: 'left',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        float: 'right',
        margin: '5px 0',
      },
      [theme.breakpoints.down('sm')]: {
        width: '50px',
      },
    },
    icon: {
      zIndex: 100,
      margin: '18px',
      [theme.breakpoints.down('sm')]: {
        margin: '18px 8px',
      },
    },
    popover: {
      border: 0,
      padding: '15px 10px',
      minWidth: '270px',
      height: 'auto',
      maxHeight: '350px',
      zIndex: 999,
    },
    linkActive: {
      color: SPORTIIZ_BLUE,
      position: 'relative',
      '&:after': {
        content: '\'\'',
        background: SPORTIIZ_RED,
        height: '3px',
        width: '24px',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        marginLeft: '-12px',
        borderRadius: '1.5px',
      },
    },
  }
));

export default function ParametersIcon() {
  const { t } = useTranslation();
  const history = useHistory();
  const { location } = useReactRouter();
  const isActive = location.pathname.startsWith(REWARDS)
    || location.pathname.startsWith(GAMES)
    || location.pathname.startsWith(SUBSCRIPTIONS);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function goToRewards() {
    history.push(REWARDS);
    handleClose();
  }

  function goToGames() {
    history.push(GAMES);
    handleClose();
  }

  function goToSubscriptions() {
    history.push(SUBSCRIPTIONS);
    handleClose();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open
    ? 'simple-popover'
    : undefined;

  return (
    <div className={classes.logo
    + (
      isActive
        ? ` ${classes.linkActive}`
        : ''
    )}
    >
      <PlusIcon
        className={classes.icon}
        width="1.75em"
        height="1.75em"
        style={{ verticalAlign: 'middle' }}
        onClick={handleClick}
      />
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: matches
            ? 'bottom'
            : 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: matches
            ? 'top'
            : 'bottom',
          horizontal: 'right',
        }}
      >
        <List component="nav">
          <ListItem button disabled={true} onClick={goToSubscriptions}>
            <ListItemIcon>
              <SubscriptionIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t('pages.subscriptions.title')}</Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button onClick={goToGames}>
            <ListItemIcon>
              <CoachesIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t('pages.games.title')}</Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button onClick={goToRewards}>
            <ListItemIcon>
              <RewardIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t('pages.rewards.title')}</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}
