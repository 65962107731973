import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { CARD_RADIUS } from '../../constants/style';

const useStyles = makeStyles(() => (
  {
    card: {
      display: 'flex',
      border: 'none',
      borderRadius: CARD_RADIUS,
      height: '200px',
      width: '100%',
      padding: '10px',
      position: 'relative',
      textAlign: 'start',
    },
    content: {
      width: '100%',
    },
    divider: {
      margin: '5px 0',
    },
  }
));
export default function ActivitySuperAdminCard(props) {
  const { remuneration } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h3">
          {t('pages.activity.superAdmin.CoachRemunerationtitle')}
        </Typography>
        <Divider className={classes.divider} />
        <Typography variant={'subtitle1'}>
          {remuneration}{t('pages.activity.superAdmin.CoachRemuneration')}
        </Typography>
      </CardContent>
    </Card>
  );
}

ActivitySuperAdminCard.propTypes = {
  remuneration: PropTypes.number.isRequired,
};
