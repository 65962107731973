import React from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { getDayFromTimestamp } from '../../services/dateService';
import { SPORTIIZ_GREY_GREEN } from '../../constants/colors';
import CalendarIcon from '../icon/CalendarIcon';
import CheckCircleIcon from '../icon/CheckCircleIcon';
import { GAME } from '../../constants/routes';
import { CARD_RADIUS } from '../../constants/style';

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    border: 'none',
    borderRadius: CARD_RADIUS,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.01), 0px 8px 10px 1px rgba(0,0,0,0.01), 0px 3px 14px 2px rgba(0,0,0,0.1)',
      '& $action': {
        display: 'block',
      },
    },
  },
  gameTitle: {
    fontWeight: 600,
  },
  gameType: {
    fontWeight: 500,
    fontSize: '1.15em',
    marginTop: '10px',
    textAlign: 'left',
  },
  content: {
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 100,
    textAlign: 'left',
    padding: '24px',
    width: '1%',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: 'calc(100% - 140px)',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width: 'calc(100% - 110px)',
    },
  },
  iconContainer: {
    minWidth: '32px',
  },
  listItem: {
    paddingLeft: 0,
  },
}));

export default function GameCard(props) {
  const { game } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const locale = getI18n().language.slice(0, 2);

  const handleCardClick = () => {
    history.push({
      pathname: GAME.replace(':id', game.id).replace(':type', game.type),
      fromGame: game,
    });
  };

  return (
    <Grid item xs={12} lg={6}>
      <Card className={classes.card} onClick={handleCardClick}>
        <CardContent className={classes.content}>
          <Typography variant="h5" className={classes.gameTitle}>
            {game.title}
          </Typography>
          <Typography variant="h6" className={classes.gameType}>
            {t(game.type)}
          </Typography>
          <List component="nav">
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.iconContainer}>
                <CalendarIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  {t('pages.games.dateFrom')}
                  {getDayFromTimestamp(locale, game.start)}
                  {t('pages.games.dateTo')}
                  {getDayFromTimestamp(locale, game.end)}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.iconContainer}>
                <CheckCircleIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  {t('pages.games.cardSteps', { count: game.stepsCount })}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
}
