import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Loading } from '../layout/Loading';
import AssociationBookingArray from './AssociationBookingArray/AssociationBookingArray';
import { QUERY_ASSOCIATION_BOOKINGS } from '../../constants/graphQLQueries';
import detailPageStyles from '../../styles/detailPage';
import Error404 from '../../containers/Error404';
import { useTimeSlotPeriod } from '../contexts/TimeSlotPeriodContext';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const AssociationBookingsContent = () => {
  const detailPageClasses = useDetailPageStyles();
  const { id } = useParams();
  const client = useApolloClient();
  const { period } = useTimeSlotPeriod();
  const {
    loading,
    error,
    data,
  } = useQuery(
    QUERY_ASSOCIATION_BOOKINGS,
    {
      variables: { associationId: id },
      client,
    },
  );

  const [displayedFutureBookings, setDisplayedFutureBookings] = useState([]);
  const [displayedPastBookings, setDisplayedPastBookings] = useState([]);

  useEffect(
    () => {
      if (!loading
        && data.associationBookings
        !== undefined
        && data.associationBookings.length
        > 0) {
        const todayDate = new Date().getTime() / 1000;
        setDisplayedPastBookings(
          data.associationBookings.filter((b) => b.timeSlot.end < todayDate),
        );
        setDisplayedFutureBookings(
          data.associationBookings.filter((b) => b.timeSlot.end >= todayDate),
        );
      }
    },
    [loading, data],
  );

  let displayedBookings = [];
  if (!loading && data) {
    if (period === 'Past') {
      displayedBookings = displayedPastBookings;
    } else if (period === 'Future') {
      displayedBookings = displayedFutureBookings;
    } else if (data.associationBookings !== undefined && data.associationBookings.length > 0) {
      displayedBookings = data.associationBookings;
    }
  }

  return (
    <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
      {error && <Error404 />}
      {loading && <Loading />}
      {!loading && <AssociationBookingArray bookings={displayedBookings} />}
    </Container>
  );
};

export default AssociationBookingsContent;
