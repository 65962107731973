import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { GAME_CURRENT, GAME_NEXT, GAME_PASSED } from '../../constants/gameTypes';
import superAdminPageStyles from '../../styles/superAdminPage';
import { QUERY_GAMES } from '../../constants/graphQLQueries';
import withAuthorization from '../../hoc/withAuthorization';
import Loading from '../../components/layout/Loading';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import GamesList from '../../components/games/GamesList';
import { STATUS_ENDED, STATUS_NOT_STARTED, STATUS_STARTED } from '../../constants/gameStatus';
import NavTab from '../../components/layout/navTab/NavTab';
import TabMenu from '../../components/layout/navTab/TabMenu';

const useSuperAdminPageStyles = makeStyles((theme) => (superAdminPageStyles(theme)));

export function GamesPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const superAdminPageClasses = useSuperAdminPageStyles();
  const client = useApolloClient();

  const [subMenuSelected, setSubMenuSelected] = React.useState(GAME_CURRENT);
  const [currentGames, setCurrentGames] = React.useState([]);
  const [nextGames, setNextGames] = React.useState([]);
  const [passedGames, setPassedGames] = React.useState([]);
  const [currentGamesCount, setCurrentGamesCount] = React.useState(0);
  const [nextGamesCount, setNextGamesCount] = React.useState(0);
  const [passedGamesCount, setPassedGamesCount] = React.useState(0);
  const [isSet, setIsSet] = React.useState(false);

  const { loading, error, data } = useQuery(QUERY_GAMES, { client });

  function handleCurrentMenuClick() {
    setSubMenuSelected(GAME_CURRENT);
  }

  function handlePassedMenuClick() {
    setSubMenuSelected(GAME_PASSED);
  }

  function handleNextMenuClick() {
    setSubMenuSelected(GAME_NEXT);
  }

  let content = (<Loading />);

  if (error) {
    content = (<ErrorServer error={error} />);
  }
  if ((!data || !data.individualGames) && !loading) {
    content = (<Error404 />);
  } else if (!loading && !isSet) {
    const allGames = data.individualGames.concat(data.companyGames).concat(data.globalGames);

    const newCurrentGames = allGames.filter((r) => r.status === STATUS_STARTED);
    setCurrentGames(newCurrentGames);
    const newNextGames = allGames.filter((r) => r.status === STATUS_NOT_STARTED);
    setNextGames(newNextGames);
    const newPassedGames = allGames.filter((r) => r.status === STATUS_ENDED);
    setPassedGames(newPassedGames);

    setCurrentGamesCount(newCurrentGames.length);
    setNextGamesCount(newNextGames.length);
    setPassedGamesCount(newPassedGames.length);

    setIsSet(true);
  } else if (!loading && isSet) {
    content = (<div>
      <Box component="div" display={subMenuSelected === GAME_CURRENT ? 'block' : 'none'}>
        {
          currentGamesCount > 0
          && <GamesList games={currentGames} history={history} />
        }
        {
          currentGamesCount < 1
          && <Typography variant={'h6'}>{t('pages.games.noCurrentGame')}</Typography>
        }
      </Box>
      <Box component="div" display={subMenuSelected === GAME_NEXT ? 'block' : 'none'}>
        {
          nextGamesCount > 0
          && <GamesList games={nextGames} history={history} />
        }
        {
          nextGamesCount < 1
          && <Typography variant={'h6'}>{t('pages.games.noNextGame')}</Typography>
        }
      </Box>
      <Box component="div" display={subMenuSelected === GAME_PASSED ? 'block' : 'none'}>
        {
          passedGamesCount > 0
          && <GamesList games={passedGames} history={history} />
        }
        {
          passedGamesCount < 1
          && <Typography variant={'h6'}>{t('pages.games.noPassedGame')}</Typography>
        }
      </Box>
    </div>);
  }

  return (
    <Box className={superAdminPageClasses.boxSimple} component="section">
      <Container maxWidth={false} className={superAdminPageClasses.header}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h1">
              {t('pages.games.title')}
            </Typography>
          </Grid>
        </Grid>

        <NavTab superAdminPageClasses={superAdminPageClasses}>
          <TabMenu
            selected={subMenuSelected === GAME_CURRENT}
            label={`${t('pages.games.currentMenuTitle')} ${'(' + currentGamesCount + ')'}`}
            onClick={handleCurrentMenuClick}
             />
          <TabMenu
            selected={subMenuSelected === GAME_NEXT}
            label={`${t('pages.games.nextMenuTitle')} ${'(' + nextGamesCount + ')'}`}
            onClick={handleNextMenuClick}
             />
          <TabMenu
            selected={subMenuSelected === GAME_PASSED}
            label={`${t('pages.games.passedMenuTitle')} ${'(' + passedGamesCount + ')'}`}
            onClick={handlePassedMenuClick}
             />
        </NavTab>

      </Container>
      <Container maxWidth={false} className={superAdminPageClasses.content}>
        {content}
      </Container>
    </Box>
  );
}

export default withAuthorization()(GamesPage);
