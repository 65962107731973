import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const ChevronUpIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Chevron up">
      <path
        d="M16,14.5 C15.744,14.5 15.488,14.402 15.293,14.207 L11.988,10.902 L8.695,14.082 C8.297,14.466 7.665,14.456 7.281,14.057 C6.897,13.66 6.908,13.026 7.305,12.643 L11.305,8.781 C11.698,8.402 12.322,8.407 12.707,8.793 L16.707,12.793 C17.098,13.184 17.098,13.816 16.707,14.207 C16.512,14.402 16.256,14.5 16,14.5"
        data-name="chevron-up"
        fill={color}
      />
    </g>
  </svg>
);

ChevronUpIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ChevronUpIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};
export default ChevronUpIcon;
