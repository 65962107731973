import React from 'react';
import withAuthorization from '../../../hoc/withAuthorization';
import { EditUserProvider } from '../../../components/contexts/EditUserContext';
import CompanyMembersContent from './CompanyMembersContent';

const CompanyMembersPage = () => (
  <EditUserProvider>
    <CompanyMembersContent />
  </EditUserProvider>
);

export default withAuthorization()(CompanyMembersPage);
