import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SPORTIIZ_BLUE, SPORTIIZ_GREY_DARK, SPORTIIZ_RED } from '../../../constants/colors';
import { LIST_ITEM_RADIUS } from '../../../constants/style';

const useStyles = makeStyles((theme) => (
  {
    li: {
      display: 'inline-block',
      height: '50px',
      lineHeight: '50px',
      margin: '10px 0',
      float: 'right',
      [theme.breakpoints.down('md')]: {
        float: 'left',
        width: 'auto',
        marginRight: '5px',
      },
    },
    link: {
      display: 'block',
      textTransform: 'uppercase',
      color: SPORTIIZ_GREY_DARK,
      padding: '20px',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '2.7px',
      fontFeatureSettings: '\'tnum\' on, \'lnum\' on',
      '&:hover': {
        color: SPORTIIZ_BLUE,
        textDecoration: 'none',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: LIST_ITEM_RADIUS,
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
        padding: '14px',
        minWidth: '50px',
        fontSize: '8px',
        lineHeight: '10px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    linkActive: {
      color: SPORTIIZ_BLUE,
      position: 'relative',
      '&:after': {
        content: '\'\'',
        background: SPORTIIZ_RED,
        height: '3px',
        width: '55px',
        position: 'absolute',
        bottom: 0,
        left: 'calc((100% - 55px) / 2)',
        marginLeft: '0px',
        borderRadius: '1.5px',
        [theme.breakpoints.down('sm')]: {
          width: '25px',
          left: 'calc((100% - 25px) / 2)',
        },
      },
    },
  }
));

export default function NavButton(props) {
  const { link, label, icon } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { location } = useReactRouter();
  const isActive = location.pathname.startsWith(link);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <li className={classes.li}>
      <Link to={link} className={classes.link + (
        isActive
          ? ` ${classes.linkActive}`
          : ''
      )}>
        {matchesMd && icon}
        {!matchesSm && (typeof label === 'string'
          ? t(label)
          : label)
        }
      </Link>
    </li>
  );
}

NavButton.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};
