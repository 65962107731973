import { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { QUERY_EVENT } from '../../../constants/graphQLQueries';

function useEventActivities(eventId) {
  const [activities, setActivities] = useState([]);
  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(
    QUERY_EVENT,
    {
      client,
      variables: { id: eventId },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (data && data.event && data.event.activities) {
      setActivities(data.event.activities);
    }
  }, [loading, error, data]);

  return {
    loading,
    activities,
  };
}

export default useEventActivities;
