import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GameCard from './GameCard';

const useStyles = makeStyles(() => ({
  gridRow: {
    width: '100%',
    margin: 'auto',
    paddingBottom: '76px',
  },
}));

export default function GamesList(props) {
  const { games } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container>
      <Grid
        container
        justifyContent="flex-start"
        spacing={matches ? 2 : 4}
        className={classes.gridRow}
      >
        {games.map(game => <GameCard game={game} key={'game-' + game.id} />)}
      </Grid>
    </Container>
  );
}

GamesList.propTypes = {
  games: PropTypes.array.isRequired,
};
