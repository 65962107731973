import { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { QUERY_COMPANY_TIMESLOTS } from '../../constants/graphQLQueries';

function useCompanyTimeSlots(companyId) {
  const [timeSlots, setTimeSlots] = useState([]);

  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(
    QUERY_COMPANY_TIMESLOTS,
    {
      client,
      variables: { id: companyId },
    },
  );
  useEffect(() => {
    if (data && data.companyTimeSlots) {
      setTimeSlots(data.companyTimeSlots);
    }
  }, [loading, error, data]);

  return {
    loading,
    timeSlots,
  };
}
export default useCompanyTimeSlots;
