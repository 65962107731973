import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_BLUE_GRADIANT_DARK,
  SPORTIIZ_BLUE_GRADIANT_LIGHT,
  SPORTIIZ_GREY, SPORTIIZ_GREY_EXTRA_DARK,
  SPORTIIZ_GREY_LIGHT,
  SPORTIIZ_HOVER_BACKGROUND,
  SPORTIIZ_PALE_BLUE,
  SPORTIIZ_RED,
  SPORTIIZ_RED_DARK,
  SPORTIIZ_RED_LIGHT,
  SPORTIIZ_SELECTED_BACKGROUND,
  SPORTIIZ_WHITE,
} from '../constants/colors';
// A theme with custom primary and secondary color.
// It'departments optional.
export const theme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    useNextVariants: true,
    h1: {
      fontSize: '28px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: SPORTIIZ_BLUE,
    },
    h3: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '1.5em',
      lineHeight: '1.5em',
      fontWeight: 700,
      color: SPORTIIZ_BLUE,
    },
    subtitle1: {
      fontSize: '14px',
    },
    subtitle2: {
      color: SPORTIIZ_GREY,
      paddingBottom: '3px',
    },
    caption: {
      color: SPORTIIZ_GREY_EXTRA_DARK,
      fontSize: '13px',
      lineHeight: '22px',
      position: 'relative',
      display: 'block',
    },
    button: {
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '16px',
      textAlign: 'center',
      letterSpacing: '0.857143px',
      textTransform: 'uppercase',
      fontFeatureSettings: '\'tnum\' on, \'lnum\' on',
    },
  },
  palette: {
    background: {
      default: SPORTIIZ_WHITE,
    },
    primary: {
      light: SPORTIIZ_BLUE_GRADIANT_LIGHT,
      main: SPORTIIZ_BLUE,
      dark: SPORTIIZ_BLUE_GRADIANT_DARK,
      contrastText: SPORTIIZ_WHITE,
    },
    secondary: {
      light: SPORTIIZ_RED_LIGHT,
      main: SPORTIIZ_RED,
      dark: SPORTIIZ_RED_DARK,
      contrastText: SPORTIIZ_WHITE,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        backgroundColor: SPORTIIZ_WHITE,
        display: 'flex',
        width: '220px',
        padding: '4px 4px',
        margin: '0.5em auto',
        flexDirection: 'row',
        '&::placeholder': {
          color: SPORTIIZ_BLUE,
        },
        '&::error': {
          color: SPORTIIZ_RED,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '14px',
        height: '42px',
      },
      input: {
        fontSize: '14px',
        padding: '.375rem .75rem',
        height: '30px',
      },
      notchedOutline: {
        color: 'red',
        '& legend': {
          fontSize: '0.85em',
        },
      },
      adornedEnd: {
        paddingRight: '11px',
        color: SPORTIIZ_GREY,
        cursor: 'pointer',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 14px) scale(1)',
        backgroundColor: 'transparent',
      },
      shrink: {
        backgroundColor: `${SPORTIIZ_WHITE} !important`,
      },
    },
    MuiButton: {
      root: {
        fontSize: '13px',
        textTransform: 'none',
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 600,
      },
      outlined: {
        minWidth: '220px',
        margin: '22px 0 15px',
        padding: '.5rem .75rem',
        borderRadius: '45px',
      },
      contained: {
        minWidth: '220px',
        margin: '22px 0 15px',
        padding: '1em',
        borderRadius: '45px',
        color: SPORTIIZ_WHITE,
        boxShadow: 'none',
        backgroundColor: SPORTIIZ_BLUE,
        '&:hover': {
          backgroundColor: SPORTIIZ_BLUE_GRADIANT_LIGHT,
        },
        '&:disabled': {
          color: SPORTIIZ_PALE_BLUE,
        },
      },
    },
    MuiListItem: {
      root: {
        backgroundColor: SPORTIIZ_WHITE,
        color: SPORTIIZ_GREY,
        // borderRadius: '16px !important',
        '&$selected': {
          backgroundColor: SPORTIIZ_SELECTED_BACKGROUND,
          color: SPORTIIZ_WHITE,
          '&:hover': {
            backgroundColor: SPORTIIZ_HOVER_BACKGROUND,
          },
        },
      },
    },
    MuiTypography: {
      h1: {
        lineHeight: 1.3,
        fontWeight: 700,
        fontFamily: '"Open Sans", sans-serif',
        color: SPORTIIZ_BLUE_GRADIANT_DARK,
        fontSize: '2.4em',
        margin: '40px 0 15px',
        textAlign: 'start',
      },
    },
    MuiTable: {
      root: {
        minWidth: '700px',
        width: '100%',
        overflowX: 'auto',
      },
    },
    MuiTableBody: {
      root: {
        display: 'table-row-group',
        verticalAlign: 'middle',
        borderColor: 'inherit',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '13px',
        padding: '0.75rem',
        verticalAlign: 'middle',
        borderTop: `1px solid ${SPORTIIZ_GREY_LIGHT}`,
        display: 'table-cell',
        lineHeight: '1.125rem',
      },
      head: {
        fontWeight: 700,
        backgroundColor: SPORTIIZ_WHITE,
        borderTop: 0,
        color: SPORTIIZ_BLUE,
        verticalAlign: 'bottom',
        borderBottom: `2px solid ${SPORTIIZ_GREY_LIGHT}`,
      },
    },
    MuiTableHead: {
      root: {
        display: 'table-header-group',
        verticalAlign: 'middle',
        borderColor: 'inherit',
      },
    },
    MuiTableRow: {
      root: {
        display: 'table-row',
        verticalAlign: 'inherit',
        borderColor: 'inherit',
        '&:hover': {
          backgroundColor: SPORTIIZ_GREY_LIGHT,
          cursor: 'pointer',
        },
      },
      head: {},
    },
    MuiFormLabel: {
      root: {
        zIndex: 1,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '15px',
        padding: '35px 45px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        marginBottom: '40px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0!important',
      },
    },
    MuiDialogActions: {
      root: {
        display: 'flex',
        alignContent: 'flex-start',
        justifyContent: 'flex-end',
        width: '100%',
        gap: '12px',
        padding: '40px 0 0',
      },
    },
  },
});

function withTheme(Component) {
  function withTheme(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />
          <Component {...props} />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }

  return withTheme;
}

export default withTheme;
