import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import TrashIcon from '../icon/TrashIcon';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_RED,
  SPORTIIZ_WHITE,
} from '../../constants/colors';
import {
  MUTATION_REMOVE_TIMESLOTS,
  QUERY_ASSOCIATION,
} from '../../constants/graphQLQueries';
import PeopleIcon from '../icon/PeopleIcon';
import BookingListDialog from './BookingList/BookingListDialog';
import SortedTableCell from '../layout/table/SortedTableCell';
import TimeSlotDeleteConfirmationDialog from './TimeSlotDeleteConfirmationDialog';
import TimeSlotRow from './TimeSlotRow';

const useStyles = makeStyles((theme) => (
  {
    checkBox: {
      margin: '0px',
      padding: '0px',
    },
    removeButton: {
      margin: 0,
      width: 'auto',
      color: SPORTIIZ_WHITE,
    },
    table: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '100px',
      },
    },
    deleteSnackbar: {
      [theme.breakpoints.down('md')]: {
        marginRight: '90px',
      },
      [theme.breakpoints.down('sm')]: {
        bottom: '24px',
      },
    },
    deleteSnackbarContent: {
      background: SPORTIIZ_BLUE,
      color: SPORTIIZ_WHITE,
    },
    buttonProgress: {
      color: SPORTIIZ_RED,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }
));

function TransitionUp(props) {
  return <Grow {...props} />;
}

export default function TimeSlotsArray(props) {
  const {
    associationId,
    companyId,
    timeSlots,
    withCheckbox,
    limit,
    changeCallback,
    defaultOrder,
    defaultOrderBy,
    handleActivityButtonClick,
    redirection,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let timeSlotLimit = limit;
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [showTimeslotBookingId, setShowTimeslotBookingId] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuTimeSlotId, setMenuTimeSlotId] = useState('');
  const [menuTimeSlotBookingCount, setMenuTimeSlotBookingCount] = useState(0);
  useEffect(() => {
    if (defaultOrder !== order) {
      setOrder(defaultOrder);
    }
    // eslint-disable-next-line
  }, [defaultOrder]);

  const getSortedTimeSlots = useCallback((orderBy, order) => {
    if (orderBy === 'start') {
      return [...timeSlots].sort(
        (a, b) => {
          if (order === 'ASC') {
            return a.start - b.start;
          }
          return b.start - a.start;
        },
      );
    }
    if (orderBy === 'activity') {
      return [...timeSlots].sort(
        (a, b) => {
          if (order === 'ASC') {
            // eslint-disable-next-line no-nested-ternary
            return a.activity.name < b.activity.name
              ? 1
              : a.activity.name > b.activity.name
                ? -1
                : 0;
          }
          // eslint-disable-next-line no-nested-ternary
          return a.activity.name > b.activity.name
            ? 1
            : a.activity.name < b.activity.name
              ? -1
              : 0;
        },
      );
    }
    return [...timeSlots];
  }, [timeSlots]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [displayedTimeSlots, setDisplayedTimeSlots] = useState(getSortedTimeSlots('start', 'DESC'));
  const client = useApolloClient();
  const classes = useStyles();

  if (limit === undefined || limit === null) {
    timeSlotLimit = displayedTimeSlots.length;
  }
  const [state, setState] = useState({});
  const [allCheck, setAllCheck] = useState(false);
  const [checkNumber, setCheckNumber] = useState(0);

  useEffect(() => {
    setDisplayedTimeSlots(getSortedTimeSlots(orderBy, order));
  }, [getSortedTimeSlots, order, orderBy]);

  useEffect(() => {
    const a = {};
    timeSlots.forEach((ts) => {
      if (ts.bookingCount <= 0) {
        a[ts.id] = false;
      }
    });
    setState(a);
  }, [timeSlots]);

  const [mutationRemoveTimeSlot, { loading }] = useMutation(
    MUTATION_REMOVE_TIMESLOTS,
    {
      client,
      update,
    },
  );

  function update(cache, responseTimeSlots) {
    if (associationId === undefined) {
      return;
    }
    const association = {
      ...client.readQuery({
        query: QUERY_ASSOCIATION,
        variables: {
          id: associationId,
          start: 0,
        },
      }).association,
    };

    association.timeSlots = [...association.timeSlots];

    responseTimeSlots.data.removeTimeSlots.forEach((elem) => {
      const index = association.timeSlots.findIndex((ts) => elem === ts.id);
      if (index >= 0) {
        association.timeSlots.splice(index, 1);
      }
    });

    client.writeQuery({
      query: QUERY_ASSOCIATION,
      variables: {
        id: associationId,
        start: 0,
      },
      data: {
        association,
      },
    });
  }

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.checked,
    });
    if (event.target.checked) {
      setCheckNumber(checkNumber + 1);
    } else {
      setCheckNumber(checkNumber - 1);
    }
  };

  const handleSelectAll = () => {
    setAllCheck(!allCheck);
    const newState = {};
    let newNumberChecked = 0;
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in state) {
      newState[key] = !allCheck;
      if (!allCheck) {
        newNumberChecked += 1;
      }
    }
    setCheckNumber(newNumberChecked);
    setState(newState);
  };

  const handleClickRemove = () => {
    if (associationId === undefined) {
      return;
    }
    const ids = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in state) {
      if (state[key] === true) {
        ids.push(key);
      }
    }
    mutationRemoveTimeSlot({
      variables: {
        associationId,
        timeSlotsIds: ids,
      },
    }).then((result) => {
      onGraphQLSuccess(t(
        'pages.timeSlots.form.formRemoveSuccessMessage',
        { count: result.data.removeTimeSlots.length },
      ));
    }).catch((error) => {
      onGraphQLError(error);
    });
  };

  function onGraphQLSuccess(message) {
    if (message) {
      dispatch(dispatchInfo(message));
    }
    setCheckNumber(0);
    setAllCheck(false);
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  const handleCheckBooking = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    handleMenuClose();
    handleOpenBookingDialog(id);
  };

  const handleOpenBookingDialog = (id) => {
    setShowTimeslotBookingId(id);
  };

  const handleCloseBookingDialog = () => {
    setShowTimeslotBookingId('');
  };

  const handleMenuClick = (event, id, count) => {
    setMenuTimeSlotBookingCount(count);
    setMenuTimeSlotId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTimeSlotId('');
  };

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDeleteTimeSlot = () => {
    if (associationId === undefined) {
      return;
    }
    mutationRemoveTimeSlot({
      variables: {
        associationId,
        timeSlotsIds: [menuTimeSlotId],
      },
    }).then((result) => {
      setOpenDeleteDialog(false);
      setAnchorEl(null);
      setMenuTimeSlotId('');
      if (changeCallback !== undefined) {
        changeCallback();
      }
      onGraphQLSuccess(t(
        'pages.timeSlots.form.formRemoveSuccessMessage',
        { count: result.data.removeTimeSlots.length },
      ));
    }).catch((error) => {
      onGraphQLError(error);
    });
  };
  if (displayedTimeSlots.length === 0) {
    return (
      <div>
        <Typography variant={'subtitle1'}>
          {t('pages.timeSlots.emptyList')}
        </Typography>
      </div>
    );
  }
  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {withCheckbox
              && <TableCell align="left">
                <Checkbox
                  checked={allCheck}
                  className={classes.checkBox}
                  onChange={handleSelectAll}
                  value={'allCheck'}
                  color={'primary'}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  disableRipple={true}
                />
              </TableCell>}
            <SortedTableCell
              align="left"
              label={t('table.timeSlot.activity')}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              order={order}
              name={'activity'}
            />
            <SortedTableCell
              align="left"
              label={t('')}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              order={order}
              name={'start'}
            />
            <TableCell align="left">
              {t('table.timeSlot.hours')}
            </TableCell>
            <TableCell align="left">
              {t('table.timeSlot.entrants')}
            </TableCell>
            <TableCell align="left">
              {t('table.timeSlot.cancelledBookingCount')}
            </TableCell>
            <TableCell align="left">
              {t('table.timeSlot.status')}
            </TableCell>
            <TableCell align="left">
              {t('table.timeSlot.coach')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedTimeSlots.slice(0, timeSlotLimit).map((timeSlot) => (
            <TimeSlotRow
              key={timeSlot.id}
              onCheck={handleChange}
              withCheckbox={withCheckbox}
              timeSlot={timeSlot}
              handleMenuClick={handleMenuClick}
              checked={state[timeSlot.id] || false}
              activityOnClick={
                redirection
                  ? () => handleActivityButtonClick(
                    timeSlot.activity.association.id,
                    timeSlot.activity.id,
                  )
                  : null
              }
              showParticipants={handleOpenBookingDialog}
            />
          ))}
        </TableBody>
      </Table>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuTimeSlotId !== ''}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={(event) => handleCheckBooking(event, menuTimeSlotId)}>
          <ListItemIcon>
            <PeopleIcon
              color={SPORTIIZ_GREY_GREEN}
              width="22px"
              height="22px"
            />
          </ListItemIcon>
          <ListItemText>
            {t('pages.timeSlots.participants')}
          </ListItemText>
        </MenuItem>
        {withCheckbox && (
          <MenuItem onClick={handleDeleteDialogOpen} disabled={menuTimeSlotBookingCount > 0}>
            <ListItemIcon>
              <TrashIcon
                color={SPORTIIZ_GREY_GREEN}
                width="22px"
                height="22px"
              />
            </ListItemIcon>
            <ListItemText>
              {t('common.delete')}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <TimeSlotDeleteConfirmationDialog
        loading={loading}
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        handleValid={handleConfirmDeleteTimeSlot}
      />

      <Snackbar
        ContentProps={{ className: classes.deleteSnackbarContent }}
        className={classes.deleteSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={checkNumber > 0}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{t('pages.timeSlots.removeButton')}</span>}
        action={
          <Button
            className={classes.removeButton}
            onClick={handleClickRemove}
            disabled={loading}
          >
            <TrashIcon color={SPORTIIZ_WHITE} height="1.5em" width="1.5em" />
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        }
      />
      <BookingListDialog
        timeSlotId={showTimeslotBookingId}
        handleCloseBookingDialog={handleCloseBookingDialog}
        associationId={associationId}
        companyId={companyId}
      />
    </div>
  );
}

TimeSlotsArray.propTypes = {
  timeSlots: PropTypes.array.isRequired,
  withCheckbox: PropTypes.bool,
  associationId: PropTypes.string,
  companyId: PropTypes.string,
  limit: PropTypes.number,
  changeCallback: PropTypes.func,
  defaultOrder: PropTypes.string,
  defaultOrderBy: PropTypes.string,
  handleActivityButtonClick: PropTypes.func,
  redirection: PropTypes.bool,
};

TimeSlotsArray.defaultProps = {
  withCheckbox: true,
  redirection: false,
  timeSlots: [],
  defaultOrder: 'DESC',
  defaultOrderBy: 'start',
  associationId: null,
  companyId: null,
};
