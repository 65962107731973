import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { useApolloClient } from '@apollo/react-hooks';
import { SIGN_IN } from '../constants/routes';
import { userResetAction } from '../store/user/actions';
import { auth } from '../firebase';

const signOut = () => {
  auth.doSignOut();
};

export default function ErrorServer(props) {
  const { error } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const client = useApolloClient();

  console.log(error);

  function logOut() {
    dispatch(userResetAction());
    client.resetStore();
    signOut();
    history.push(SIGN_IN);
  }

  if (error.graphQLErrors.length > 0) {
    console.log(error.graphQLErrors[0]);
    if (error.graphQLErrors[0].message === 'Utilisateur non authentifié') {
      logOut();
    }
  }

  return (
    <header className="App-header">
      <Container>
        <p>Server Error</p>
      </Container>
    </header>
  );
}

ErrorServer.propTypes = {
  error: PropTypes.any.isRequired,
};
