import { useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { QUERY_INDIVIDUAL_USERS } from '../../constants/graphQLQueries';

function useIndividualUser() {

  const [users, setUsers] = useState([]);

  const client = useApolloClient();
  const {
    loading,
    error,
    data,
  } = useQuery(
    QUERY_INDIVIDUAL_USERS,
    { client },
  );
  useEffect(() => {
    if (data && data.individualUsers) {
      setUsers(data.individualUsers);
    }
  }, [data]);
  return {
    users,
    loading,
    error,
  };
}

export default useIndividualUser;
