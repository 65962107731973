import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SPORTIIZ_RED, SPORTIIZ_WHITE } from '../../constants/colors';
import PrivatiizIcon from '../icon/PrivatiizIcon';
import { LABEL_RADIUS } from '../../constants/style';

const useStyles = makeStyles(() => (
  {
    redChip: {
      color: SPORTIIZ_WHITE,
      backgroundColor: SPORTIIZ_RED,
      borderRadius: LABEL_RADIUS,
      fontWeight: 600,
      verticalAlign: 'bottom',
      boxShadow: `0px 1px 13px -6px ${SPORTIIZ_RED};`,
      padding: '2px 8px',
      marginLeft: '20px',
    },
    companyName: {
      fontStyle: 'normal',
      fontWeight: 700,
    },
    text: {
      marginLeft: '6px',
      display: 'inline-block',
    },
  }
));

const PrivatiizBadge = (props) => {
  const { companyName, marginLeft } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Paper className={classes.redChip} style={{ marginLeft }}>
      <PrivatiizIcon color={SPORTIIZ_WHITE} width="19px" height="19px" />
      {matches
        && companyName
        && <div className={classes.text}>
          Privatiiz {t('pages.activity.privatiizTo')} <em className={classes.companyName}>{companyName}</em>
        </div>
      }
    </Paper>
  );
};

PrivatiizBadge.propTypes = {
  companyName: PropTypes.string,
  marginLeft: PropTypes.string,
};
PrivatiizBadge.defaultProps = {
  companyName: null,
  marginLeft: '20px',
};

export default PrivatiizBadge;
