import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ErrorServer from '../../ErrorServer';
import detailPageStyles from '../../../styles/detailPage';
import withAuthorization from '../../../hoc/withAuthorization';
import { SPORTIIZ_BLUE, SPORTIIZ_RED } from '../../../constants/colors';
import Sidebar from '../../../components/layout/sidebar/Sidebar';
import NumbersBlueSection from '../../../components/layout/numbersBlueSection/NumbersBlueSection';
import Loading from '../../../components/layout/Loading';
import SimpleTimeSlotsArray from '../../../components/timeSlots/SimpleTimeSlotsArray';
import AngleCircleCharts from '../../../components/layout/charts/AngleCircleCharts';
import PeopleIcon from '../../../components/icon/PeopleIcon';
import HistogramCharts from '../../../components/layout/charts/HistogramCharts';
import CompanySubscriptionCard from '../../../components/companies/subscriptions/CompanySubscriptionCard';
import CompanySubscriptionFormDialog
  from '../../../components/companies/subscriptions/CompanySubscriptionFormDialog';
import useCompanyCharts from './UseCompanyCharts';
import useCompany from '../../../components/hooks/UseCompany';

export const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

export function CompanyPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);

  const {
    company,
    loading,
    error,
    refetch,
  } = useCompany(id);

  const {
    loading: chartsLoading,
    error: chartsError,
    previousRateCountData,
    nextRateCountData,
    fullRateCount,
    reducedRateCount,
    orderedRateChart,
    bookingCount,
    alreadyLoaded,
  } = useCompanyCharts(id);

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen() {
    setOpen(true);
  }

  if (error) {
    return <ErrorServer error={error} />;
  }

  const companyLoaded = company && company.id === id;
  return <div>
    <Sidebar />
    <Box className={detailPageClasses.box}>
      <Container maxWidth={false}>
        <Typography variant="h1">{company?.name ?? ''}</Typography>
      </Container>
      <Container maxWidth={false}>
        <Grid
          container
          spacing={
            matches
              ? 2
              : 4
          }
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ paddingBottom: '2em' }}
        >

          {chartsLoading && !alreadyLoaded && (
            <Grid item sm={12}>
              <Loading />
            </Grid>
          )}
          {alreadyLoaded && !chartsError && (
            <>

              <Grid item xl={4} md={6} xs={12}>
                <CompanySubscriptionCard
                  currentCompanySubscription={company.currentCompanySubscription}
                  openDialog={handleClickOpen}
                />
              </Grid>
              {company.satisfactionRatingPercent
                && <AngleCircleCharts
                  percent={Number((
                    company.satisfactionRatingPercent
                  ).toFixed(2))}
                  label={t('pages.company.charts.satisfactionRatingPercent')}
                  icon={<PeopleIcon color={SPORTIIZ_BLUE} />}
                  color={SPORTIIZ_RED}
                />
              }
              <HistogramCharts
                label={t('pages.company.charts.bookingCountHistogram')}
                series={bookingCount.map((elem) => elem.bookingCount)}
                color={SPORTIIZ_RED}
                categories={bookingCount.map((elem) => elem.date)}
              />
              <HistogramCharts
                title={t('pages.company.charts.RateCountHistogramTitle')}
                label={t('pages.company.charts.reducedRateCountHistogram')}
                series={reducedRateCount}
                color={SPORTIIZ_BLUE}
                secondaryLabel={t('pages.company.charts.fullRateCountHistogram')}
                secondarySeries={fullRateCount}
                secondaryColor={SPORTIIZ_RED}
                categories={orderedRateChart.map((elem) => elem.date)}
                fullWidth={true}
                moveNext={nextRateCountData}
                movePrevious={previousRateCountData}
              />
            </>
          )}
        </Grid>
      </Container>
      <NumbersBlueSection
        numbers={[
          {
            number: companyLoaded ? company.doneTimeSlotsCount : '-',
            text: t('pages.company.numbers.doneTimeSlots'),
          },
          {
            number: companyLoaded ? company.companyUsers?.length ?? '0' : '-',
            text: t('pages.company.numbers.users'),
          },
        ]}
      />

      <Box component="section" className={detailPageClasses.footerBox}>
        <Container maxWidth={false} className={detailPageClasses.footerContainer}>
          <Typography variant={'h6'}>
            {t('pages.company.lastTimeSlots')}
          </Typography>
        </Container>
        <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
          {loading && (
            <Loading />
          )}
          {!loading && company && (
            <SimpleTimeSlotsArray
              timeSlots={company.lastTimeSlots ?? []}
              withMenu
              companyId={company.id}
            />
          )}
        </Container>
      </Box>
    </Box>
    {!loading && hasSuperAdminRole && (
      <CompanySubscriptionFormDialog
        onClose={handleClose}
        open={open}
        company={company}
        refetch={refetch}
      />
    )}
  </div>;
}

export default withAuthorization()(CompanyPage);
