import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import detailPageStyles from '../../styles/detailPage';
import { Loading } from '../../components/layout/Loading';
import useCompanyTimeSlots from './UseCompanyTimeSlots';
import {
  TimeSlotPeriodProvider,
} from '../../components/contexts/TimeSlotPeriodContext';
import TimeSlotsPageContent from '../../components/timeSlots/TimeSlotsPageContent';
import withAuthorization from '../../hoc/withAuthorization';
import TimeSlotPeriodNav from '../../components/timeSlots/TimeSlotPeriodNav/TimeSlotPeriodNav';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

const CompanyTimeSlotsPage = () => {
  const { id } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const { t } = useTranslation();
  const {
    loading,
    timeSlots,
  } = useCompanyTimeSlots(id);
  return (
    <div>
      <TimeSlotPeriodProvider>
        <Sidebar />
        <Box className={detailPageClasses.box} component="section">
          <Container maxWidth={false}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h1">
                  {t('pages.companyTimeSlots.title')}
                </Typography>
              </Grid>
            </Grid>
          </Container>

          <TimeSlotPeriodNav />
          <Box component="section" className={detailPageClasses.footerBox}>
            <div className={detailPageClasses.footerContainer}></div>
            <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
              {loading && <Loading />}
              {!loading && (
                <TimeSlotsPageContent
                  displayButton={false}
                  timeSlots={timeSlots}
                  companyId={id}
                />
              )}
            </Container>
          </Box>
        </Box>
      </TimeSlotPeriodProvider>
    </div>
  );
};

export default withAuthorization()(CompanyTimeSlotsPage);
