import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Transition from '../../layout/TransitionAnimation';
import dialogFormStyle from '../../../styles/dialogForm';
import BookingList from './BookingList';
import AppDialogTitle from '../../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

const BookingListDialog = (props) => {
  const {
    timeSlotId,
    handleCloseBookingDialog,
    companyId,
    associationId,
    onlyValidated,
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const dialogTitle = t('pages.timeSlots.bookingList');

  return (
    <Dialog
      open={timeSlotId !== ''}
      fullScreen={matches}
      scroll="body"
      onClose={handleCloseBookingDialog}
      maxWidth="lg"
      keepMounted
      TransitionComponent={Transition}
      transitionDuration={matches
        ? 500
        : 750}
      aria-labelledby={dialogTitle}
    >
      <AppDialogTitle title={dialogTitle} handleClose={handleCloseBookingDialog}/>
      <DialogContent id="confirm-dialog-title">
        <BookingList
          timeSlotId={timeSlotId}
          associationId={associationId}
          companyId={companyId}
          onlyValidated={onlyValidated}
        />
      </DialogContent>
    </Dialog>
  );
};

BookingListDialog.propTypes = {
  timeSlotId: PropTypes.string.isRequired,
  companyId: PropTypes.string,
  associationId: PropTypes.string,
  onlyValidated: PropTypes.bool,
  handleCloseBookingDialog: PropTypes.func.isRequired,
};

BookingListDialog.defaultProps = {
  companyId: null,
  associationId: null,
  onlyValidated: false,
};

export default BookingListDialog;
