import React from 'react';
import { getI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { SPORTIIZ_PALE_BLUE, SPORTIIZ_RED } from '../../../constants/colors';
import { getDayAndHourFromTimestamp } from '../../../services/dateService';
import PaymentStatusChip from '../../timeSlots/PaymentStatusChip';
import { formatStringForTable } from '../../../services/stringService';

const useStyles = makeStyles(() => (
  {
    avatar: {
      border: `1px solid ${SPORTIIZ_PALE_BLUE}`,
      width: '30px',
      height: '30px',
      margin: '0 10px',
      fontSize: '1em',
    },
    avatarCell: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderTop: 'unset',
    },
    cell: {
      borderTop: 'unset',
    },
    lastCell: {
      borderTop: 'unset',
      width: '50px',
    },
    redText: {
      color: SPORTIIZ_RED,
    },
  }
));
const EventParticipantRow = (props) => {
  const { participant } = props;
  const classes = useStyles();
  const locale = getI18n().language.slice(0, 2);
  return (
    <TableRow key={participant.bookingId}>
      <TableCell align="left" className={classes.cell}>
        {formatStringForTable(participant.activityName)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Grid container wrap="nowrap" direction="row">
          <Grid item>
            {participant.userPicture !== null
              ? <Avatar
                className={classes.avatar}
                src={participant.userPicture}
                title="picture"
              />
              : (
                <Avatar className={classes.avatar}>
                  {`${
                    participant.userFirstname?.charAt(0).toUpperCase()
                  }${
                    participant.userLastname?.charAt(0).toUpperCase()
                  }`}
                </Avatar>
              )
            }
          </Grid>
          <Grid item>
            <span>{formatStringForTable(participant.userFullname)}</span>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {formatStringForTable(participant.userEmail)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {formatStringForTable(participant.companyName)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {formatStringForTable(getDayAndHourFromTimestamp(locale, participant.timeSlotDate))}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {formatStringForTable(getDayAndHourFromTimestamp(locale, participant.bookingDate))}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <span className={participant.bookingCancelledDate !== null ? classes.redText : ''}>
          {formatStringForTable(
            participant.bookingCancelledDate !== null
              ? getDayAndHourFromTimestamp(locale, participant.bookingCancelledDate)
              : '',
          )}
        </span>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <PaymentStatusChip
          status={participant.paymentStatus}
          stripeFailureCode={participant.stripeFailureCode}
        />
      </TableCell>
      <TableCell align="left" className={classes.lastCell} />
    </TableRow>
  );
};

EventParticipantRow.propTypes = {
  participant: PropTypes.object.isRequired,
};
export default EventParticipantRow;
