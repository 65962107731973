import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import withAuthorization from '../../hoc/withAuthorization';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import { QUERY_REWARDS } from '../../constants/graphQLQueries';
import Loading from '../../components/layout/Loading';
import superAdminPageStyles from '../../styles/superAdminPage';
import { SPORTIIZ_WHITE } from '../../constants/colors';
import PlusIcon from '../../components/icon/PlusIcon';
import { TYPE_FREE_TIME_SLOT, TYPE_PHYSICAL } from '../../constants/rewardTypes';
import RewardList from '../../components/rewards/RewardList';
import RewardFormDialog from '../../components/rewards/RewardFormDialog';

const useSuperAdminPageStyles = makeStyles((theme) => (superAdminPageStyles(theme)));

export function RewardsPage() {
  const { t } = useTranslation();
  const superAdminPageClasses = useSuperAdminPageStyles();
  const client = useApolloClient();
  const [subMenuSelected, setSubMenuSelected] = React.useState(TYPE_PHYSICAL);
  const [open, setOpen] = useState(false);
  const [reward, setReward] = useState(null);
  const [realRewards, setRealRewards] = React.useState([]);
  const [virtualRewards, setVirtualRewards] = React.useState([]);
  const [physicalRewardsCount, setPhysicalRewardsCount] = React.useState(0);
  const [virtualRewardsCount, setVirtualRewardsCount] = React.useState(0);

  const { loading, error, data } = useQuery(QUERY_REWARDS, {
    client,
    onCompleted: (data) => {
      const rewards = (data && data.rewards) ? data.rewards : [];
      updateRewards(rewards);
    },
  });

  function handleClickOpen() {
    setOpen(true);
  }

  function handleEdit(event, reward) {
    setReward(reward);
    setOpen(true);
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function handleClose() {
    setReward(null);
    setOpen(false);
  }

  function handleRealMenuClick() {
    setSubMenuSelected(TYPE_PHYSICAL);
  }

  function handleVirtualMenuClick() {
    setSubMenuSelected(TYPE_FREE_TIME_SLOT);
  }

  function update(cache, setReward) {
    const { rewards } = cache.readQuery({ query: QUERY_REWARDS });
    const rewardIndex = rewards.findIndex((assoc) =>
      assoc.id === setReward.data.setReward.id,
    );
    let newCache = rewards;
    if (rewardIndex !== undefined && rewardIndex !== -1) {
      newCache[rewardIndex] = setReward.data.setReward;
    } else {
      newCache = newCache.concat([setReward.data.setReward]);
    }
    cache.writeQuery({
      query: QUERY_REWARDS,
      data: { rewards: newCache },
    });
  }

  function updateRewards(rewards) {
    const newPhysicalReward = rewards.filter((r) => r.type === TYPE_PHYSICAL);
    const newVirtualReward = rewards.filter((r) => r.type !== TYPE_PHYSICAL);

    setRealRewards(newPhysicalReward);
    setVirtualRewards(newVirtualReward);

    setPhysicalRewardsCount(newPhysicalReward.length);
    setVirtualRewardsCount(newVirtualReward.length);
  }

  let content = (<Loading />);
  if (error) {
    content = (<ErrorServer error={error} />);
  }

  if ((!data || !data.rewards) && !loading) {
    content = (<Error404 />);
  } else if (!loading) {
    content = (
      <div>
        <Box component="div" display={subMenuSelected === TYPE_PHYSICAL ? 'block' : 'none'}>
          {
            physicalRewardsCount > 0
            && <RewardList rewards={realRewards} handleClickOpen={handleEdit} />
          }
          {
            physicalRewardsCount < 1
            && <Typography variant={'h6'}>{t('pages.rewards.noRealRewards')}</Typography>
          }
        </Box>
        <Box component="div" display={subMenuSelected !== TYPE_PHYSICAL ? 'block' : 'none'}>
          {
            virtualRewardsCount > 0
            && <RewardList rewards={virtualRewards} handleClickOpen={handleEdit} />
          }
          {
            virtualRewardsCount < 1
            && <Typography variant={'h6'}>{t('pages.rewards.noVirtualRewards')}</Typography>
          }
        </Box>
      </div>
    );
  }

  return (
    <Box className={superAdminPageClasses.boxSimple} component="section">
      <Container maxWidth={false} className={superAdminPageClasses.header}>
        <Grid container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
        >
          <Grid item>
            <Typography variant="h1">
              {t('pages.rewards.title')}
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item>
              <Button variant="contained" onClick={handleClickOpen}>
                {t('common.add')}
              </Button>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Fab
              color="primary"
              aria-label="add"
              className={superAdminPageClasses.fab}
              onClick={handleClickOpen}
            >
              <PlusIcon color={SPORTIIZ_WHITE} />
            </Fab>
          </Hidden>
        </Grid>
        <nav className={superAdminPageClasses.nav}>
          <ul className={superAdminPageClasses.ul}>
            <li className={superAdminPageClasses.li} onClick={handleRealMenuClick}>
              <a
                href="#app"
                className={superAdminPageClasses.link + (subMenuSelected === TYPE_PHYSICAL
                  ? ` ${superAdminPageClasses.linkActive}`
                  : '')
                }
              >
                {t('pages.rewards.realMenuTitle')} ({physicalRewardsCount})
              </a>
            </li>
            <li className={superAdminPageClasses.li} onClick={handleVirtualMenuClick}>
              <a href="#app"
                 className={superAdminPageClasses.link + (subMenuSelected !== TYPE_PHYSICAL
                   ? ` ${superAdminPageClasses.linkActive}`
                   : '')
                 }
              >
                {t('pages.rewards.virtualMenuTitle')} ({virtualRewardsCount})
              </a>
            </li>
          </ul>
        </nav>
      </Container>
      <Container maxWidth={false} className={superAdminPageClasses.content}>
        {content}
      </Container>
      <RewardFormDialog
        open={open}
        onClose={handleClose}
        update={update}
        reward={reward}
      />
    </Box>
  );
}

export default withAuthorization()(RewardsPage);
