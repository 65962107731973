import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTimeSlotPeriod } from '../../contexts/TimeSlotPeriodContext';
import BlueTabSection from '../../layout/BlueTabSection/BlueTabSection';
import BlueTab from '../../layout/BlueTabSection/BlueTab';

const TimeSlotPeriodNav = () => {
  const { t } = useTranslation();
  const {
    period,
    setPeriod,
  } = useTimeSlotPeriod();
  return (
    <BlueTabSection value={period} onChange={(event, newValue) => setPeriod(newValue)}>
      <BlueTab
        label={t('pages.timeSlots.nextMenuTitle')}
        value="Future"
      />
      <BlueTab
        label={t('pages.timeSlots.passedMenuTitle')}
        value="Past"
      />
      <BlueTab
        label={t('pages.timeSlots.allMenuTitle')}
        value="All"
      />
    </BlueTabSection>
  );
};

export default TimeSlotPeriodNav;
