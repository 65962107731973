import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Sidebar from '../../../components/layout/sidebar/Sidebar';
import PlusIcon from '../../../components/icon/PlusIcon';
import { SPORTIIZ_WHITE } from '../../../constants/colors';
import detailPageStyles from '../../../styles/detailPage';
import ErrorServer from '../../ErrorServer';
import { Loading } from '../../../components/layout/Loading';
import UserList from '../../../components/users/UserList';
import CompanyAdminFormDialog from '../../../components/companies/admins/CompanyAdminFormDialog';
import { useEditUser } from '../../../components/contexts/EditUserContext';
import useCompany from '../../../components/hooks/UseCompany';
import { MUTATION_REMOVE_COMPANY_USER } from '../../../constants/graphQLQueries';
import { dispatchInfo, handleError } from '../../../store/infos/actions';
import DeleteAdminDialog from '../../../components/companies/admins/DeleteAdminDialog';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

const CompanyAdminsContent = () => {
  const { id } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const { t } = useTranslation();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [adminToRemove, setAdminToRemove] = useState(null);

  const {
    loading,
    error,
    company,
    admins,
    refetch,
  } = useCompany(id);
  const { setEditUser } = useEditUser();

  const [mutationRemoveCompanyUser, { loading: mutationLoading }] = useMutation(
    MUTATION_REMOVE_COMPANY_USER,
    { client },
  );

  function handleClickOpen(event, admin) {
    event.preventDefault();
    if (admin !== undefined) {
      setEditUser(admin);
    } else {
      setEditUser({
        id: null,
        email: '',
        firstname: '',
        lastname: '',
        nickname: '',
        phone: '',
        picture: null,
      });
    }
  }

  function handleConfirmOpen(event, admin) {
    event.preventDefault();
    if (admin !== undefined) {
      setEditUser(admin);
    }
  }

  function handleClose() {
    setEditUser(null);
  }

  function handleOpenRemoveDialog(admin) {
    setAdminToRemove(admin);
    setOpenRemoveDialog(true);
  }

  function handleCloseRemoveDialog() {
    setOpenRemoveDialog(false);
    setTimeout(() => setAdminToRemove(null), 1000);
  }

  function handleRemoveAdmin() {
    mutationRemoveCompanyUser({
      variables: {
        companyId: id,
        userId: adminToRemove.id,
        role: 'admin',
      },
    }).then((result) => {
      if (result.data.removeCompanyUser) {
        refetch();
        dispatch(dispatchInfo(t('pages.companyAdministrators.deleteAdminSuccess')));
      } else {
        dispatch(handleError(t('pages.companyAdministrators.deleteAdminFail')));
      }
    }).catch((error) => {
      dispatch(handleError(error));
    }).finally(() => {
      handleCloseRemoveDialog();
    });
  }

  if (error) {
    return <ErrorServer error={error} />;
  }

  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        <Container maxWidth={false} className={detailPageClasses.header}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1">{t('pages.companyAdministrators.title')}</Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item>
                <Button
                  variant="contained" className={detailPageClasses.addButton}
                  onClick={handleClickOpen}
                >
                  {t('pages.companyAdministrators.addButton')}
                </Button>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Fab
                color="primary"
                aria-label="add"
                className={detailPageClasses.fab}
                onClick={handleClickOpen}
              >
                <PlusIcon color={SPORTIIZ_WHITE} />
              </Fab>
            </Hidden>
          </Grid>
        </Container>
        <Container maxWidth={false} className={detailPageClasses.content}>
          {loading && <Loading />}
          {!loading && (
            <UserList
              users={admins}
              handleClickOpen={handleClickOpen}
              handleConfirmOpen={handleConfirmOpen}
              emptyLabel={t('pages.companyAdministrators.emptyLabel')}
              openRemoveDialog={handleOpenRemoveDialog}
            />
          )}
        </Container>
        <CompanyAdminFormDialog
          onClose={handleClose}
          refetch={refetch}
          company={company}
        />
        <DeleteAdminDialog
          open={openRemoveDialog}
          onClose={handleCloseRemoveDialog}
          loading={mutationLoading}
          handleRemove={handleRemoveAdmin}
        />
      </Box>
    </div>
  );
};

export default CompanyAdminsContent;
