import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TimeSlotsArray from './TimeSlotsArray';
import { useTimeSlotPeriod } from '../contexts/TimeSlotPeriodContext';

const TimeSlotsPageContent = (props) => {
  const {
    associationId,
    companyId,
    timeSlots,
    displayButton,
  } = props;
  const { period } = useTimeSlotPeriod();

  const [displayedFutureTimeSlots, setDisplayedFutureTimeSlots] = useState([]);
  const [displayedPastTimeSlots, setDisplayedPastTimeSlots] = useState([]);

  useEffect(() => {
    const todayDate = new Date().getTime() / 1000;
    setDisplayedPastTimeSlots(timeSlots.filter((ts) => ts.end < todayDate));
    setDisplayedFutureTimeSlots(timeSlots.filter((ts) => ts.end >= todayDate));
  }, [timeSlots]);

  let displayedTimeSlots = [];
  if (period === 'Past') {
    displayedTimeSlots = displayedPastTimeSlots;
  } else if (period === 'Future') {
    displayedTimeSlots = displayedFutureTimeSlots;
  } else if (timeSlots) {
    displayedTimeSlots = timeSlots;
  }

  return (
      <TimeSlotsArray
        associationId={associationId}
        companyId={companyId}
        timeSlots={displayedTimeSlots}
        defaultOrder={period === 'Past'
          ? 'DESC'
          : 'ASC'}
        withCheckbox={displayButton}
      />
  );
};

TimeSlotsPageContent.propTypes = {
  associationId: PropTypes.string,
  companyId: PropTypes.string,
  timeSlots: PropTypes.array.isRequired,
  displayButton: PropTypes.bool.isRequired,
};
export default TimeSlotsPageContent;
