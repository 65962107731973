import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import detailPageStyles from '../../styles/detailPage';
import activityPaperStyles from '../../styles/activityPaper';
import { getShortDayFromTimestamp } from '../../services/dateService';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_RED,
} from '../../constants/colors';
import PrivatiizBadge from '../activities/PrivatiizBadge';
import IconNumberBlock from '../layout/IconNumberBlock';
import TimeSlotsArray from '../timeSlots/TimeSlotsArray';
import { ASSOCIATION_ACTIVITY, EVENT_EDIT } from '../../constants/routes';
import LockedEventDetails from './LockedEventDetails';
import PublicEventDetails from './PublicEventDetails';
import CancelEventDialog from './CancelEventDialog';

const useActivityPaperStyles = makeStyles(() => activityPaperStyles());
const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const useStyles = makeStyles((theme) => (
  {
    root: {
      lineHeight: 1.4,
      backgroundColor: SPORTIIZ_BLUE,
      display: 'block',
      position: 'relative',
    },
    header: {
      paddingBottom: '2rem',
    },
    subtitle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      padding: 0,
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        marginBottom: '5px',
      },
    },
    badgeContainer: {
      position: 'absolute',
      top: '30px',
      left: '20px',
    },
    icon: {
      margin: '0.3rem',
    },
    footerContainer: {
      textAlign: 'left',
      padding: '0rem 3rem',
    },
    cancel: {
      textTransform: 'uppercase',
      color: SPORTIIZ_RED,
    },
  }
));

const EventDetails = ({ event }) => {
  const detailPageClasses = useDetailPageStyles();
  const activityPaperClasses = useActivityPaperStyles();
  const {
    t,
    i18n,
  } = useTranslation();
  const locale = i18n.language.slice(0, 2);
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [openCancel, setOpenCancel] = useState(false);

  const sports = event.activities.map((a) => a.sport)
    .filter((value, index, self) => self.indexOf(value) === index);

  const timeslots = [];
  event.activities.forEach((a) => {
    a.timeSlots.forEach((ts) => {
      timeslots.push({
        ...ts,
        activity: {
          id: a.id,
          name: a.name,
          isPrivatiiz: a.isPrivatiiz,
          bookingCount: a.bookingCount,
          maximumEntrant: a.maximumEntrant,
          association: { id: a.association.id },
        },
      });
    });
  });

  const history = useHistory();

  const handleActivityButtonClick = (associationId, activityId) => {
    history.push(ASSOCIATION_ACTIVITY.replace(':id', associationId)
      .replace(':activityId', activityId));
  };

  const handleClickCancelEvent = (clickEvent) => {
    clickEvent.stopPropagation();
    clickEvent.preventDefault();
    setOpenCancel(true);
  };

  const editEvent = (clickEvent) => {
    clickEvent.stopPropagation();
    clickEvent.preventDefault();
    history.push(EVENT_EDIT.replace(':id', event.id));
  };

  return (
    <>
      <Container maxWidth={false}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item>
            <Typography variant="h1">
              {event.status === 'CANCELLED' && (
                <>
                  <span className={classes.cancel}>{t('common.cancelled')}</span>
                  {' - '}
                </>
              )}
              {event.name}
            </Typography>
          </Grid>
          {event.status === 'OK' && (
            <Hidden mdDown>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="flex-end"
                  spacing={matches
                    ? 2
                    : 4}
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Button variant="contained" onClick={editEvent}>
                      {t('common.edit')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClickCancelEvent}
                      className={detailPageClasses.headerOutlinedButton}
                    >
                      {t('common.cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          )}
        </Grid>
      </Container>

      <Container maxWidth={false}>
        <Grid
          container
          direction="row"
          spacing={matches
            ? 2
            : 4}
          className={classes.header}
        >
          <Grid item xs={12} md={6} lg={5} xl={4} style={{ position: 'relative' }}>
            <Paper
              className={activityPaperClasses.paper}
              style={
                {
                  backgroundImage: `url(${event.picture})`,
                  cursor: 'default',
                  boxShadow: 'none',
                }
              }
              elevation={0}
            />
          </Grid>
          <Grid
            item
            style={{
              textAlign: 'left',
              flexGrow: 1,
            }}
          >
            {event.isPrivatiiz
              && <Grid item className={classes.badgeContainer}>
                <Grid container alignItems="center">
                  <PrivatiizBadge />
                </Grid>
              </Grid>
            }
            <Typography variant="h6">
              {event.name}
            </Typography>
            <Typography variant="subtitle2">
              Du {getShortDayFromTimestamp(locale, event.start)} au {getShortDayFromTimestamp(
              locale,
              event.end,
            )}
            {event.area && `, ${t('common.in')} ${event.area.name}`}
            </Typography>
            <Typography variant="subtitle1">
              {event.visibility === 'LOCKED'
                ? (
                  <LockedEventDetails event={event} />
                )
                : (
                  <PublicEventDetails />
                )
              }
            </Typography>
            <Typography variant="subtitle2" component="p">
              <div dangerouslySetInnerHTML={{ __html: event.description }} />
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className={classes.root}>
        <Grid container justifyContent="center" direction="row" alignItems="center">
          {
            sports.length === 0 && (
              <Grid item xs={6} md={3}>
                <IconNumberBlock
                  picture=""
                  text=""
                />
              </Grid>
            )
          }
          {
            sports.slice(
              0,
              sports.length <= 4
                ? 4
                : 3,
            ).map((s) => (
              <Grid item xs={6} md={3} key={s.id}>
                <IconNumberBlock
                  picture={s.picture}
                  text={s.name}
                />
              </Grid>
            ))
          }
          {
            sports.length > 4
            && (
              <Grid item xs={6} md={3}>
                <IconNumberBlock
                  icon={
                    <Typography
                      variant={'subtitle1'}
                      style={{
                        fontSize: '30px',
                        color: SPORTIIZ_GREY_GREEN,
                      }}
                    >
                      +{sports.length - 3}
                    </Typography>
                  }
                  text={t('table.event.other', { count: sports.length - 3 })}
                />
              </Grid>
            )
          }
        </Grid>
      </div>

      <Box component="section" className={detailPageClasses.footerBox}>
        <Container maxWidth={false} className={detailPageClasses.footerContainer}>
          <Typography variant={'h6'}>
            {t('pages.events.program')}
          </Typography>
        </Container>
        <Container maxWidth={false} className={detailPageClasses.footerArrayContainer}>
          <TimeSlotsArray
            handleActivityButtonClick={handleActivityButtonClick}
            timeSlots={timeslots}
            limit={10}
            defaultOrder={'ASC'}
            withCheckbox={false}
            redirection={true}
          />
        </Container>
      </Box>
      <CancelEventDialog event={event} open={openCancel} setOpen={setOpenCancel} />
    </>
  );
};

EventDetails.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventDetails;
