import {
  USER_DOWNLOADED,
  USER_FAILED,
  USER_LOGIN,
  USER_PENDING,
  USER_RESET,
  USER_SENDING,
} from './types';
import {
  getAvatarLetter,
  isAssociationAdmin,
  isCompanyAdmin,
  isSuperAdmin,
} from '../../services/userService';

export const INITIAL_STATE = {
  error: null,
  pending: false,
  sending: false,
  authUser: null,
  hasSuperAdminRole: false,
  hasAssociationAdminRole: false,
  associationAdminIds: [],
  hasCompanyAdminRole: false,
  companyAdminIds: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_RESET:
      return INITIAL_STATE;
    case USER_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case USER_DOWNLOADED: {
      const newAuthUser = {
        ...state.authUser,
        ...action.payload,
        displayName: getAvatarLetter(action.payload),
      };
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        authUser: newAuthUser,
        hasSuperAdminRole: isSuperAdmin(newAuthUser),
        hasAssociationAdminRole: isAssociationAdmin(action.payload),
        associationAdminIds: action.payload.associationAdminIds || [],
        hasCompanyAdminRole: isCompanyAdmin(action.payload),
        companyAdminIds: action.payload.companyAdminIds || [],
      };
    }
    case USER_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case USER_LOGIN: {
      const newAuthUser = {
        ...state.authUser,
        ...action.payload,
      };
      return {
        ...state,
        authUser: newAuthUser,
        hasSuperAdminRole: isSuperAdmin(newAuthUser),
      };
    }
    case USER_SENDING:
      return {
        ...state,
        sending: true,
      };
    default:
      break;
  }
  return state;
}
