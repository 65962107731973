import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../../layout/TransitionAnimation';
import dialogFormStyle from '../../../styles/dialogForm';
import CompanyAdminForm from './CompanyAdminForm';
import { useEditUser } from '../../contexts/EditUserContext';
import AppDialogTitle from '../../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

export default function CompanyAdminFormDialog(props) {
  const {
    onClose,
    refetch,
    company,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [retry, setRetry] = useState(false);

  const { editUser } = useEditUser();

  const title = (!editUser || editUser.id === null)
    ? t('pages.associationAdministrators.dialogTitleAdd')
    : t('pages.associationAdministrators.dialogTitleEdit');

  return (
    <Dialog
      fullScreen={matches}
      open={editUser !== null}
      scroll='body'
      onClose={onClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches ? 500 : 750}
      aria-labelledby={title}
      keepMounted={retry}
    >
      <AppDialogTitle title={title} handleClose={onClose}/>
      <CompanyAdminForm
        handleClose={onClose}
        refetch={refetch}
        companyId={company.id}
        retry={retry}
        setRetry={setRetry}
      />
    </Dialog>
  );
}

CompanyAdminFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
