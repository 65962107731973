const USER_PENDING = 'user_pending';
const USER_FAILED = 'user_failed';
const USER_DOWNLOADED = 'user_downloaded';
const USER_RESET = 'user_reset';
const USER_LOGIN = 'user_login';
const USER_SENDING = 'user_sending';

export {
  USER_PENDING,
  USER_FAILED,
  USER_DOWNLOADED,
  USER_RESET,
  USER_LOGIN,
  USER_SENDING,
};
