import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import superAdminPageStyles from '../../../styles/superAdminPage';

const useSuperAdminPageStyles = makeStyles((theme) => (superAdminPageStyles(theme)));

function NavTab({ children }) {

    const superAdminPageClasses = useSuperAdminPageStyles();
    return (
        <nav className={superAdminPageClasses.nav}>
            <ul className={superAdminPageClasses.ul}>
                {children}
            </ul>
        </nav>
    );
}

export default NavTab;