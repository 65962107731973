import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const StarIcon = ({
  color,
  width,
  height,
  className,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height} className={className}>
    <g data-name="Layer 2" id="Eva-icons/star">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0985 20.842C17.2435 20.918 17.4025 20.955 17.5615 20.955C17.7695 20.955 17.9765 20.891 18.1495 20.763C18.4575 20.539 18.6105 20.16 18.5465 19.785L17.5745 14.157L21.6945 10.172C21.9695 9.907 22.0675 9.509 21.9505 9.146C21.8345 8.783 21.5205 8.519 21.1435 8.464L15.4425 7.636L12.8955 2.51C12.5575 1.83 11.4415 1.83 11.1035 2.51L8.55655 7.636L2.85555 8.464C2.47855 8.519 2.16455 8.783 2.04855 9.146C1.93155 9.509 2.02955 9.907 2.30455 10.172L6.42455 14.157L5.45255 19.785C5.38855 20.16 5.54155 20.539 5.84955 20.763C6.15455 20.987 6.56255 21.018 6.90055 20.842L11.9995 18.178L17.0985 20.842ZM12.4625 16.163C12.3175 16.088 12.1585 16.05 11.9995 16.05C11.8405 16.05 11.6815 16.088 11.5365 16.163L7.76755 18.133L8.48455 13.976C8.54155 13.65 8.43255 13.318 8.19455 13.087L5.15855 10.151L9.36155 9.539C9.68655 9.492 9.96755 9.289 10.1135 8.995L11.9995 5.201L13.8855 8.995C14.0315 9.289 14.3125 9.492 14.6375 9.539L18.8405 10.151L15.8045 13.087C15.5665 13.318 15.4575 13.65 15.5145 13.976L16.2315 18.133L12.4625 16.163Z"
        fill={color}
      />
    </g>
  </svg>
);

StarIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

StarIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
  className: '',
};
export default StarIcon;
