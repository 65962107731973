import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { LABEL_RADIUS } from '../../constants/style';

const useStyles = makeStyles(() => (
  {
    listChip: {
      width: '90%',
      borderRadius: LABEL_RADIUS,
      marginBottom: '1em',
    },
    chip: {
      borderRadius: LABEL_RADIUS,
      marginBottom: '1em',
    },
  }
));

export default function CategoryChip(props) {
  const { name, inList } = props;
  const classes = useStyles();
  return (<Chip
    size="small"
    className={inList ? classes.listChip : classes.chip}
    label={name}
    color="primary"
  />);
}

CategoryChip.propTypes = {
  name: PropTypes.string.isRequired,
  inList: PropTypes.bool,
};

CategoryChip.defaultProps = {
  inList: true,
};
