import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../../layout/TransitionAnimation';
import dialogFormStyle from '../../../styles/dialogForm';
import AssociationAdministratorForm from './AssociationAdministratorForm';
import { useEditUser } from '../../contexts/EditUserContext';
import AppDialogTitle from '../../layout/AppDialogTitle';

export const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

const AdminFormDialog = (props) => {
  const { handleClose, refetch, association } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { editUser } = useEditUser();

  const [retry, setRetry] = useState(false);

  const title = (!editUser || editUser.id === null)
    ? t('pages.associationAdministrators.dialogTitleAdd')
    : t('pages.associationAdministrators.dialogTitleEdit');

  return (
    <Dialog
      fullScreen={matches}
      open={editUser !== null}
      scroll='body'
      onClose={handleClose}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={matches ? 500 : 750}
      aria-labelledby={title}
      keepMounted={retry}
    >
      <AppDialogTitle title={title} handleClose={handleClose}/>
      <AssociationAdministratorForm
        handleClose={handleClose}
        refetch={refetch}
        associationId={association.id}
        retry={retry}
        setRetry={setRetry}
      />
    </Dialog>
  );
}

AdminFormDialog.propTypes = {
  refetch: PropTypes.func.isRequired,
  association: PropTypes.object.isRequired,
  handleClose: PropTypes.object.isRequired,
};

export default AdminFormDialog;
