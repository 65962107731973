import {
  SPORTIIZ_BLUE,
  SPORTIIZ_GREY_DARK,
  SPORTIIZ_GREY_LIGHT,
  SPORTIIZ_RED,
  SPORTIIZ_WHITE,
} from '../constants/colors';
import { LIST_ITEM_RADIUS } from '../constants/style';

export default function formInSmallDialogStyle(theme) {
  return ({
    buttonContainer: {
      margin: '15px',
    },
    button: {
      width: 'auto',
      padding: '1em',
      minWidth: '120px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '150px',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '125px',
      },
    },
    preview: {
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'unset',
    },
    uploadLabel: {
      width: '100%',
      height: '100%',
    },
    uploadButton: {
      position: 'relative',
      left: 'calc(50% - 20px)',
      top: '-20px',
      height: '40px',
      width: '40px',
      padding: '5px',
      zIndex: 10,
    },
    avatar: {
      width: '200px',
      height: '200px',
    },
    buttonProgress: {
      color: SPORTIIZ_RED,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    nav: {
      background: SPORTIIZ_WHITE,
      width: '100%',
      bottom: 0,
      zIndex: 98,
      textAlign: 'left',
      borderBottom: `1px solid ${SPORTIIZ_GREY_LIGHT}`,
      marginBottom: '1em',
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      display: 'inline-block',
      margin: '10px 0',
    },
    link: {
      display: 'block',
      color: SPORTIIZ_GREY_DARK,
      padding: '0 20px',
      [theme.breakpoints.down('md')]: {
        padding: '0 10px',
        letterSpacing: '0px',
      },
      '&:hover': {
        color: SPORTIIZ_BLUE,
        textDecoration: 'none',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: LIST_ITEM_RADIUS,
      },
    },
    linkActive: {
      color: SPORTIIZ_BLUE,
      position: 'relative',
      '&:after': {
        content: '\'\'',
        background: SPORTIIZ_RED,
        height: '3px',
        width: '80%',
        position: 'absolute',
        bottom: '-3px',
        left: '10%',
        borderRadius: '1.5px',
      },
    },
  });
}
