import React from 'react';
import PropTypes from 'prop-types';
import { getI18n, useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import PrivatiizIcon from '../icon/PrivatiizIcon';
import { SPORTIIZ_BLUE, SPORTIIZ_GREY_LIGHT, SPORTIIZ_RED } from '../../constants/colors';
import { formatStringForTable } from '../../services/stringService';
import { getDayFromTimestamp, getHourFromTimestamp } from '../../services/dateService';
import PeopleIcon from '../icon/PeopleIcon';
import TimeSlotStatusChip from './TimeSlotStatusChip';
import MoreVerticalIcon from '../icon/MoreVerticalIcon';

const useStyles = makeStyles(() => (
  {
    row: {
      cursor: 'unset',
    },
    chipCell: {
      padding: '0.65rem',
      width: '150px',
    },
    checkBox: {
      margin: '0px',
      padding: '0px',
    },
    isPrivatiizIcon: {
      marginRight: '6px',
      verticalAlign: 'middle',
    },
    showBookingCell: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
    showBookingButton: {
      padding: 0,
      paddingTop: 3,
      display: 'inline-block',
      margin: '0 5px 0 0',
      width: '30px',
      minWidth: '30px',
      color: SPORTIIZ_BLUE,
      verticalAlign: 'sub',
    },
    moreMenuIconCell: {
      padding: 0,
    },
    moreMenuIcon: {
      transform: 'rotate(0.25turn)',
      width: '24px',
      height: '24px',
    },
  }
));
const TimeSlotRow = (props) => {
  const {
    timeSlot,
    withCheckbox,
    checked,
    onCheck,
    activityOnClick,
    handleMenuClick,
    showParticipants,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const locale = getI18n().language.slice(0, 2);
  const canShowParticipants = timeSlot.bookingCount > 0 || timeSlot.cancelBookingCount > 0;
  return (

    <TableRow id={timeSlot.id} className={classes.row}>
      {withCheckbox && <TableCell align="left">
        {timeSlot.bookingCount <= 0 && (
          <Checkbox
            className={classes.checkBox}
            checked={checked || false}
            onChange={onCheck(timeSlot.id)}
            value={timeSlot.id}
            color={'primary'}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            disableRipple={true}
          />
        )}
      </TableCell>}
      <TableCell align="left" onClick={activityOnClick}>
        {timeSlot.activity.isPrivatiiz && (
          <span className={classes.isPrivatiizIcon}>
            <PrivatiizIcon color={SPORTIIZ_RED} verticalAlign={'unset'} />
          </span>
        )}
        {formatStringForTable(timeSlot.activity.name)}
        {timeSlot.activity.isPrivatiiz && timeSlot.activity.privatiizCompany && (
          <>
            {' '}{t('pages.activity.privatiizTo')}{' '}{timeSlot.activity.privatiizCompany.name}
          </>
        )}
      </TableCell>
      <TableCell align="left">
        {formatStringForTable(getDayFromTimestamp(locale, timeSlot.start))}
      </TableCell>
      <TableCell align="left">
        {getHourFromTimestamp(locale, timeSlot.start)}
        {' - '}
        {getHourFromTimestamp(locale, timeSlot.end)}
      </TableCell>
      <TableCell
        align="left"
        className={canShowParticipants
          ? classes.showBookingCell
          : ''}
        onClick={canShowParticipants
          ? () => showParticipants(timeSlot.id)
          : () => {
          }}
      >
        <span className={classes.showBookingButton}>
          <PeopleIcon
            color={(
              canShowParticipants
            )
              ? SPORTIIZ_BLUE
              : SPORTIIZ_GREY_LIGHT
            }
            width="22px"
            height="22px"
          />
        </span>
        {`${timeSlot.bookingCount}/${timeSlot.activity.maximumEntrant}`}
      </TableCell>
      <TableCell align="left">
        {formatStringForTable(
          timeSlot.cancelBookingCount > 0
            ? timeSlot.cancelBookingCount.toString()
            : '',
        )}
      </TableCell>
      <TableCell align="left" className={classes.chipCell}>
        <TimeSlotStatusChip status={timeSlot.status} />
      </TableCell>
      <TableCell align="left">
        <span>
          {formatStringForTable(timeSlot.coach
            ? `${timeSlot.coach.firstname} ${timeSlot.coach.lastname}`
            : '')
          }
        </span>
      </TableCell>
      <TableCell align="right" className={classes.moreMenuIconCell}>
        <IconButton
          onClick={(event) => handleMenuClick(event, timeSlot.id, timeSlot.bookingCount)}
        >
          <MoreVerticalIcon className={classes.moreMenuIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

TimeSlotRow.propTypes = {
  timeSlot: PropTypes.object.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  activityOnClick: PropTypes.func,
  showParticipants: PropTypes.func,
};

TimeSlotRow.defaultProps = {
  activityOnClick: null,
};
export default TimeSlotRow;
