import React, { useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import ErrorServer from '../ErrorServer';
import Error404 from '../Error404';
import withAuthorization from '../../hoc/withAuthorization';
import { SPORTIIZ_WHITE } from '../../constants/colors';
import listPageStyles from '../../styles/listPage';
import AssociationsArray from '../../components/associations/AssociationsArray';
import PlusIcon from '../../components/icon/PlusIcon';
import { QUERY_AREAS, QUERY_ASSOCIATIONS } from '../../constants/graphQLQueries';
import AssociationFormDialog from '../../components/associations/AssociationFormDialog';
import Loading from '../../components/layout/Loading';

const useListPageStyles = makeStyles((theme) => listPageStyles(theme));

export function AssociationsPage() {
  const { t } = useTranslation();
  const listPageClasses = useListPageStyles();
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const client = useApolloClient();
  const {
    loading: associationsLoading,
    error: associationsError,
    data: associationsData,
  } = useQuery(QUERY_ASSOCIATIONS, { client });

  const {
    loading: areasLoading,
    error: areasError,
    data: areasData,
  } = useQuery(
    QUERY_AREAS,
    { client },
  );
  const [open, setOpen] = useState(false);
  const [association, setAssociation] = useState(null);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleEdit(association) {
    setAssociation(association);
    setOpen(true);
  }

  function handleClose() {
    setAssociation(null);
    setOpen(false);
  }

  function update(cache, addAssociation) {
    const { associations } = cache.readQuery({ query: QUERY_ASSOCIATIONS });
    const assocIndex = associations.findIndex((assoc) => assoc.id
      === addAssociation.data.setAssociation.id);
    let newCache = associations;
    if (assocIndex !== undefined && assocIndex !== -1) {
      newCache[assocIndex] = addAssociation.data.setAssociation;
    } else {
      newCache = newCache.concat([addAssociation.data.setAssociation]);
    }
    cache.writeQuery({
      query: QUERY_ASSOCIATIONS,
      data: { associations: newCache },
    });
  }

  let content = (
    <Loading />
  );
  if (associationsError || areasError) {
    content = (
      <ErrorServer error={associationsError || areasError} />
    );
  }
  if ((
    !associationsData || !associationsData.associations
  ) && !associationsLoading && !areasLoading) {
    content = (
      <Error404 />
    );
  } else if (!associationsLoading && !areasLoading) {
    content = (
      <AssociationsArray
        associations={associationsData.associations}
        handleEdit={handleEdit}
      />
    );
  }

  return (
    <Box className={listPageClasses.box}>
      <Container maxWidth={'lg'}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h1" className={listPageClasses.title}>
              {t('pages.associations.title')}
            </Typography>
          </Grid>
          {hasSuperAdminRole && (
            <>
              <Hidden mdDown>
                <Grid item>
                  <Button
                    variant="contained"
                    className={listPageClasses.addButton}
                    onClick={handleClickOpen}
                  >
                    {t('pages.associations.addButton')}
                  </Button>
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <Fab
                  color="primary"
                  aria-label="add"
                  className={listPageClasses.fab}
                  onClick={handleClickOpen}
                >
                  <PlusIcon color={SPORTIIZ_WHITE} />
                </Fab>
              </Hidden>
            </>
          )}
        </Grid>
      </Container>
      <Container maxWidth={'lg'} className={listPageClasses.container}>
        {content}
      </Container>
      {hasSuperAdminRole && (
        <AssociationFormDialog
          open={open}
          onClose={handleClose}
          update={update}
          association={association}
          areas={areasData
            ? areasData.areas
            : []}
        />
      )}
    </Box>
  );
}

export default withAuthorization()(AssociationsPage);
