import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { SPORTIIZ_WHITE } from '../../../constants/colors';
import DashboardIcon from '../../icon/DashboardIcon';
import CoachesIcon from '../../icon/CoachesIcon';
import {
  ASSOCIATION,
  ASSOCIATION_ACTIVITIES,
  ASSOCIATION_ACTIVITIES_REGEX,
  ASSOCIATION_ADMINISTRATORS,
  ASSOCIATION_ADMINISTRATORS_REGEX,
  ASSOCIATION_COACHES,
  ASSOCIATION_COACHES_REGEX,
  ASSOCIATION_REGEX,
  ASSOCIATION_TIME_SLOTS,
  ASSOCIATION_TIME_SLOTS_REGEX,
  ASSOCIATION_BOOKINGS,
  ASSOCIATION_BOOKINGS_REGEX,
} from '../../../constants/routes';
import sidebarMenuStyle from '../../../styles/sidebarMenu';
import HomeIcon from '../../icon/HomeIcon';
import ClockIcon from '../../icon/ClockIcon';
import AdminIcon from '../../icon/AdminIcon';
import PeopleIcon from '../../icon/PeopleIcon';

const useSidebarMenuStyles = makeStyles((theme) => (sidebarMenuStyle(theme)));

export default function AssociationSidebarMenu(props) {
  const { id, loading } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const sidebarMenuClasses = useSidebarMenuStyles();

  function handleMenuItemClick(path) {
    if (!loading) {
      history.push(path.replace(':id', id));
    }
  }

  const dashBoardSelected = location.pathname.match(ASSOCIATION_REGEX) || [];
  const coachSelected = location.pathname.match(ASSOCIATION_COACHES_REGEX) || [];
  const administratorSelected = location.pathname.match(ASSOCIATION_ADMINISTRATORS_REGEX) || [];
  const activitySelected = location.pathname.match(ASSOCIATION_ACTIVITIES_REGEX) || [];
  const timeSlotsSelected = location.pathname.match(ASSOCIATION_TIME_SLOTS_REGEX) || [];
  const participantsSelected = location.pathname.match(ASSOCIATION_BOOKINGS_REGEX) || [];

  return (
    <List
      component="nav"
      aria-label="main mailbox folders"
      className={sidebarMenuClasses.list}
    >
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={dashBoardSelected.length > 0}
        onClick={() => handleMenuItemClick(ASSOCIATION)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <HomeIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary="Dashboard"
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={activitySelected.length > 0}
        onClick={() => handleMenuItemClick(ASSOCIATION_ACTIVITIES)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <DashboardIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.activities.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={coachSelected.length > 0}
        onClick={() => handleMenuItemClick(ASSOCIATION_COACHES)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <CoachesIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.associationCoaches.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={administratorSelected.length > 0}
        onClick={() => handleMenuItemClick(ASSOCIATION_ADMINISTRATORS)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <AdminIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.associationAdministrators.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={timeSlotsSelected.length > 0}
        onClick={() => handleMenuItemClick(ASSOCIATION_TIME_SLOTS)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <ClockIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.timeSlots.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
      <ListItem
        disableRipple={true}
        button
        className={sidebarMenuClasses.listItem}
        selected={participantsSelected.length > 0}
        onClick={() => handleMenuItemClick(ASSOCIATION_BOOKINGS)}
      >
        <ListItemIcon className={sidebarMenuClasses.listItemIcon}>
          <PeopleIcon width="24px" height="24px" color={SPORTIIZ_WHITE} />
        </ListItemIcon>
        <ListItemText
          primary={t('pages.associationBookings.title')}
          className={sidebarMenuClasses.listItemText}
          primaryTypographyProps={{ className: sidebarMenuClasses.listItemTextPrimary }}
        />
      </ListItem>
    </List>
  );
}

AssociationSidebarMenu.defaultProps = {
  loading: false,
};

AssociationSidebarMenu.propTypes = {
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};
