import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Hidden from '@material-ui/core/Hidden';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AssociationSidebarMenu from './AssociationSidebarMenu';
import CompanySidebarMenu from './CompanySidebarMenu';
import {
  SPORTIIZ_BLUE,
  SPORTIIZ_WHITE,
} from '../../../constants/colors';
import { LIST_ITEM_RADIUS, SIDEBAR_WIDTH } from '../../../constants/style';
import {
  ALL_ASSOCIATION_REGEX,
  ALL_COMPANY_REGEX, ALL_EVENT_REGEX,
  ASSOCIATION,
  COMPANY, EVENT,
} from '../../../constants/routes';
import {
  QUERY_ASSOCIATIONS,
  QUERY_COMPANIES,
  QUERY_EVENTS,
} from '../../../constants/graphQLQueries';
import EventSidebarMenu from './EventSidebarMenu';

const useStyles = makeStyles((theme) => (
  {
    box: {
      height: '100vh',
      width: SIDEBAR_WIDTH,
      top: 0,
      left: '70px',
      background: SPORTIIZ_BLUE,
      padding: '10px',
      zIndex: 99,
      display: 'block',
      position: 'fixed',
      [theme.breakpoints.down('md')]: {
        width: '50px',
        marginTop: '70px',
        padding: '5px',
        left: 0,
      },
    },
    list: {
      background: 'transparent',
      color: SPORTIIZ_WHITE,
      overflow: 'hidden',
    },
    listItem: {
      background: 'transparent',
      color: SPORTIIZ_WHITE,
      height: 'auto',
      borderRadius: LIST_ITEM_RADIUS,
      textAlign: 'left',
      padding: '15px 10px',
      lineHeight: '19.5px',
      fontSize: '13px',
      whiteSpace: 'nowrap',
      fontWeight: 400,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
    listItemText: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      flexDirection: 'row',
    },
    listItemTextPrimary: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 'calc(100% - 22px)',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: 2,
    },
    listItemTextSecondary: {
      width: '10px',
      color: SPORTIIZ_WHITE,
      top: '-4px',
    },
    menu: {
      height: 'auto',
      maxHeight: '350px',
      minWidth: '270px',
      overflowY: 'scroll',
      overflowx: 'hidden',
    },
    menuItem: {
      minWidth: '250px',
      fontSize: '13px',
      minHeight: '44px',
    },
  }
));

export default function Sidebar() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const classes = useStyles();
  const client = useApolloClient();

  const [anchorEl, setAnchorEl] = useState(null);

  const isCompanyPart = (
    location.pathname.match(ALL_COMPANY_REGEX) || []
  ).length > 0;
  const isAssociationPart = (
    location.pathname.match(ALL_ASSOCIATION_REGEX) || []
  ).length > 0;
  const isEventPart = (
    location.pathname.match(ALL_EVENT_REGEX) || []
  ).length > 0;

  let query;
  if (isCompanyPart) {
    query = QUERY_COMPANIES;
  } else if (isAssociationPart) {
    query = QUERY_ASSOCIATIONS;
  } else {
    query = QUERY_EVENTS;
  }
  const {
    loading,
    error,
    data,
  } = useQuery(query, {
    variables: {},
    client,
  });

  function handleMenuItemClick(id) {
    if (isCompanyPart) {
      history.push(COMPANY.replace(':id', id));
    } else if (isAssociationPart) {
      history.push(ASSOCIATION.replace(':id', id));
    } else if (isEventPart) {
      history.push(EVENT.replace(':id', id));
    }
    setAnchorEl(null);
  }

  function handleClickListItem(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  let list = [];
  let current = {
    id,
    name: '',
    picture: null,
  };
  if (error) {
    current = {
      id,
      name: 'Error',
      picture: null,
    };
    list = [current];
  } else if (data && data.companies) {
    list = data.companies;
    current = data.companies.find((elem) => elem.id === id);
  } else if (data && data.associations) {
    list = data.associations;
    current = data.associations.find((elem) => elem.id === id);
  } else if (data && data.events) {
    list = data.events;
    if (id) {
      current = data.events.find((elem) => elem.id === id);
    } else if (data.events.length > 0) {
      current = { ...data.events[0] };
    }
  }

  return (
    <Box className={classes.box}>
      <Hidden mdDown>
        {current && <List component="nav" aria-label="Association select" className={classes.list}>
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="when device is locked"
            onClick={handleClickListItem}
            className={classes.listItem}
          >

            <ListItemAvatar>
              {current.picture !== null
                ? <Avatar
                  alt={'img'}
                  src={current.picture}
                />
                : <Avatar>
                  {current.name ? current.name.slice(0, 1) : '-'}
                </Avatar>}
            </ListItemAvatar>
            <ListItemText
              primary={current.name ?? ''}
              secondary={<ArrowDropDown height="8px" width="8px" />}
              className={classes.listItemText}
              primaryTypographyProps={{ className: classes.listItemTextPrimary }}
              secondaryTypographyProps={{ className: classes.listItemTextSecondary }}
            />
          </ListItem>
        </List>}
        <Menu
          id="association-select"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleClose}
          className={classes.menu}
        >
          {list.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              selected={item.id === id}
              onClick={() => handleMenuItemClick(item.id)}
              className={classes.menuItem}
            >
              {item.name}
            </MenuItem>
          ))}
        </Menu>
      </Hidden>
      {isAssociationPart && <AssociationSidebarMenu id={id} loading={loading} />}
      {isCompanyPart && <CompanySidebarMenu id={id} loading={loading} />}
      {isEventPart && <EventSidebarMenu id={id} loading={loading} />}
    </Box>
  );
}
