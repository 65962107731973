import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SortedTableCell from '../../layout/table/SortedTableCell';
import { Loading } from '../../layout/Loading';
import useEventParticipants from './UseEventParticipants';
import useEventActivities from './UseEventActivities';
import EventParticipantRow from './EventParticipantRow';

const useStyles = makeStyles(() => (
  {
    table: {
      minWidth: '1400px',
      overflowX: 'auto',
    },
    lastCell: {
      width: '50px',
    },
    select: {
      textAlign: 'left',
    },
  }
));

const EventParticipantArray = ({ event }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [activityId, setActivityId] = useState('');
  const {
    loading,
    participants,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  } = useEventParticipants(event.id, search, activityId);
  const { activities } = useEventActivities(event.id);
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs={12} md={4} xl={3}>
          <TextField
            placeholder={t('pages.events.searchParticipants')}
            id="participants-search"
            name="participants-search"
            type="text"
            variant="outlined"
            onChange={(event) => setSearch(event.target.value)}
            margin="normal"
            value={search}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
          <FormControl variant="outlined" margin={'normal'} fullWidth className={classes.select}>
            <Select
              value={activityId}
              onChange={
                (event) => setActivityId(event.target.value)
              }
              input={
                <OutlinedInput
                  name="activity"
                  id="event-activityId"
                />
              }
              inputProps={{
                name: 'activity',
                id: 'event-activityId',
              }}
              displayEmpty
            >
              <MenuItem value="" key="all-activities">
                {t('pages.events.allActivities')}
              </MenuItem>
              {activities !== [] && activities.map((act) => (
                <MenuItem value={act.id} key={`activity-${act.id}`}>
                  {act.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <SortedTableCell
              align="left"
              setOrderBy={setOrderBy}
              setOrder={setOrder}
              order={order}
              orderBy={orderBy}
              name="activityName"
              label={t('table.participant.activity')}
            />
            <SortedTableCell
              align="left"
              setOrderBy={setOrderBy}
              setOrder={setOrder}
              order={order}
              orderBy={orderBy}
              name="userFullname"
              label={t('table.participant.participantName')}
            />
            <SortedTableCell
              align="left"
              setOrderBy={setOrderBy}
              setOrder={setOrder}
              order={order}
              orderBy={orderBy}
              name="userEmail"
              label={t('table.participant.email')}
            />
            <SortedTableCell
              align="left"
              setOrderBy={setOrderBy}
              setOrder={setOrder}
              order={order}
              orderBy={orderBy}
              name="companyName"
              label={t('table.participant.company')}
            />
            <TableCell
              align="left"
              name="timeSlot"
            >
              {t('table.participant.timeSlot')}
            </TableCell>
            <TableCell
              align="left"
              name="bookingDate"
            >
              {t('table.participant.bookingDate')}
            </TableCell>
            <TableCell
              align="left"
              name="paymentStatus"
            >
              {t('table.participant.cancelDate')}
            </TableCell>
            <TableCell
              align="left"
              name="paymentStatus"
            >
              {t('table.participant.paymentStatus')}
            </TableCell>
            <TableCell align="left" className={classes.lastCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {participants.map((participant) => (
            <EventParticipantRow key={participant.bookingId} participant={participant} />
          ))}
          {loading && (
            <TableRow>
              <TableCell colSpan={9} align="center">
                <Loading />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

EventParticipantArray.propTypes = {
  event: PropTypes.object.isRequired,
};
export default EventParticipantArray;
