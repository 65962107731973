import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import { Loading } from '../../layout/Loading';
import BookingListRow from './BookingListRow';
import useTimeSlotBooking from './UseTimeSlotBooking';

const useStyles = makeStyles((theme) => (
  {
    table: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '100px',
      },
      '& tr': {
        '& th:first-child': {
          paddingLeft: '24px',
        },
        '& td:first-child': {
          paddingLeft: '24px',
        },
      },
    },
  }
));

const BookingListDialog = ({
  timeSlotId,
  companyId,
  associationId,
  onlyValidated,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    bookings,
    loading,
    error,
  } = useTimeSlotBooking(
    timeSlotId,
    companyId,
    associationId,
    onlyValidated,
  );

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>
            {t('pages.companyMembers.form.firstname')}
          </TableCell>
          <TableCell>
            {t('pages.companyMembers.form.lastname')}
          </TableCell>
          <TableCell>
            {t('pages.companyMembers.form.email')}
          </TableCell>
          <TableCell>
            {t('pages.associationCoaches.form.phone')}
          </TableCell>
          <TableCell>
            {t('pages.timeSlots.company')}
          </TableCell>
          <TableCell>
            {t('pages.timeSlots.bookingDate')}
          </TableCell>
          <TableCell>
            {t('pages.timeSlots.cancelDate')}
          </TableCell>
          <TableCell>
            {t('pages.timeSlots.paymentStatus')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading
          && error === undefined
          && bookings.length > 0
          && bookings
            .filter((b) => b.type === 'DEFAULT')
            .sort((a, b) => {
              const da = new Date(a.createdAt * 1000);
              const db = new Date(b.createdAt * 1000);
              return db.getTime() - da.getTime();
            })
            .map((booking) => (
              <BookingListRow
                key={booking.id}
                bookings={bookings}
                booking={booking}
              />
            ))}
        {!loading
          && error === undefined
          && bookings.length === 0
          && (
            <TableRow>
              <TableCell align="center" colSpan="8">
                {t('pages.timeSlots.noBooking')}
              </TableCell>
            </TableRow>
          )}
        {loading && (
          <TableRow>
            <TableCell align="center" colSpan="8">
              <Loading />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

BookingListDialog.propTypes = {
  timeSlotId: PropTypes.string.isRequired,
  companyId: PropTypes.string,
  associationId: PropTypes.string,
  onlyValidated: PropTypes.bool,
};

BookingListDialog.defaultProps = {
  companyId: null,
  associationId: null,
  onlyValidated: false,
};

export default BookingListDialog;
