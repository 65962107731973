import {
  USER_DOWNLOADED,
  USER_FAILED,
  USER_LOGIN,
  USER_PENDING,
  USER_RESET,
  USER_SENDING,
} from './types';
// Action creators

export const userResetAction = () => ({
  type: USER_RESET,
});
export const userPendingAction = () => ({
  type: USER_PENDING,
});
export const userSendingAction = () => ({
  type: USER_SENDING,
});

export const userDownloadedAction = (user) => ({
  type: USER_DOWNLOADED,
  payload: user,
});

export const userLoginAction = (user) => ({
  type: USER_LOGIN,
  payload: user,
});

export const userFailedAction = (error) => ({
  type: USER_FAILED,
  payload: error,
});

// Actions

export function resetUser() {
  return (dispatch) => {
    dispatch(userResetAction());
  };
}
