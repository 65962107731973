import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { useTranslation } from 'react-i18next';
import TextClamp from 'react-string-clamp';
import Avatar from '@material-ui/core/Avatar';
import MessageIcon from '../icon/MessageIcon';
import { SPORTIIZ_BLUE, SPORTIIZ_GREY_GREEN, SPORTIIZ_PALE_BLUE } from '../../constants/colors';
import CheckCircleIcon from '../icon/CheckCircleIcon';
import { CARD_RADIUS } from '../../constants/style';

export const useStyles = makeStyles((theme) => (
  {
    card: {
      position: 'relative',
      height: '100%',
      top: 0,
      display: 'flex',
      border: 'none',
      borderRadius: CARD_RADIUS,
      overflow: 'visible',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.01), 0px 8px 10px 1px rgba(0,0,0,0.01), 0px 3px 14px 2px rgba(0,0,0,0.1)',
        '& $action': {
          display: 'block',
        },
      },
    },
    content: {
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 100,
      textAlign: 'left',
      padding: '24px',
      width: '1%',
      minHeight: '2em',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: 'calc(100% - 140px)',
      },
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 'calc(100% - 110px)',
      },
    },
    fab: {
      position: 'relative',
      top: 'calc(50% - 20px)',
      left: '-20px',
      fontSize: '1.5em',
      zIndex: 10,
      cursor: 'auto',
    },
    withPadding: {
      padding: '0 2rem',
    },
    iconContainer: {
      minWidth: '32px',
    },
    description: {
      fontWeight: 600,
      color: SPORTIIZ_BLUE,
    },
    neededPoints: {
      fontSize: '2em',
      fontWeight: 600,
      color: SPORTIIZ_BLUE,
    },
    avatar: {
      display: 'flex',
      border: `1px solid ${SPORTIIZ_PALE_BLUE}`,
      width: 110,
      height: 110,
      margin: 19,
      [theme.breakpoints.down('md')]: {
        width: 80,
        height: 80,
        margin: 12,
      },
      [theme.breakpoints.down('sm')]: {
        width: 70,
        height: 70,
        margin: 10,
      },
      [theme.breakpoints.down('xs')]: {
        width: 60,
        height: 60,
        margin: 10,
      },
    },
  }
));

export default function StepCard(props) {
  const { step, isLast } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <Fab
          component={'div'}
          color="primary"
          disableRipple={true}
          disableFocusRipple={true}
          aria-label="number"
          className={classes.fab}
        >
          {step.number}
        </Fab>
        <CardContent className={classes.content}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={4}>
              <List component="nav">
                <ListItem style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}>
                  <ListItemIcon className={classes.iconContainer}>
                    <CheckCircleIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className={classes.neededPoints}>
                      {step.neededPoints}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem style={{ paddingTop: 0 }}>
                  <ListItemText>
                    <Typography variant="body1" className={classes.withPadding} component={'div'}>
                      <TextClamp
                        text={t(
                          'pages.game.neededPoints',
                          {
                            count: (
                              isLast
                                ? 1
                                : (
                                  step.number + 1
                                )
                            ),
                          },
                        )}
                        lines={3}
                      />
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <List component="nav">
                <ListItem style={{ paddingBottom: 0 }}>
                  <ListItemIcon className={classes.iconContainer}>
                    <MessageIcon width="1.75em" height="1.75em" color={SPORTIIZ_GREY_GREEN} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className={classes.description}>
                      {t('pages.rewards.description')}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem style={{ paddingTop: 0 }}>
                  <ListItemText>
                    <Typography variant="body1" className={classes.withPadding} component={'div'}>
                      <TextClamp text={step.reward.description} lines={3} />
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={2}>
              {step.reward.picture !== null
                ? <Avatar
                  className={classes.avatar}
                  src={step.reward.picture}
                  title="picture"
                />
                : <Avatar className={classes.avatar}>
                  {step.reward.name.charAt(0)}
                </Avatar>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

StepCard.propTypes = {
  step: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
};
