import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import ErrorServer from '../../ErrorServer';
import Sidebar from '../../../components/layout/sidebar/Sidebar';
import detailPageStyles from '../../../styles/detailPage';
import {
  DEFAULT_USER,
  MUTATION_REMOVE_ASSOCIATION_USER,
} from '../../../constants/graphQLQueries';
import UserList from '../../../components/users/UserList';
import Loading from '../../../components/layout/Loading';
import PlusIcon from '../../../components/icon/PlusIcon';
import { SPORTIIZ_WHITE } from '../../../constants/colors';
import AdminFormDialog from '../../../components/associations/admins/AdminFormDialog';
import useAssociation from '../../../components/hooks/UseAssociation';
import { useEditUser } from '../../../components/contexts/EditUserContext';
import DeleteAdminDialog from '../../../components/associations/admins/DeleteAdminDialog';
import { dispatchInfo, handleError } from '../../../store/infos/actions';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

const AssociationCoachesContent = () => {
  const detailPageClasses = useDetailPageStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const { hasSuperAdminRole } = useSelector((state) => state.user);
  const { setEditUser } = useEditUser();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const {
    admins,
    loading,
    error,
    association,
    refetch,
  } = useAssociation(id);

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [adminToRemove, setAdminToRemove] = useState(null);

  const [mutationRemoveAssociationUser, { loading: mutationLoading }] = useMutation(
    MUTATION_REMOVE_ASSOCIATION_USER,
    { client },
  );

  function handleClickOpen(event, admin) {
    event.preventDefault();
    if (admin !== undefined) {
      setEditUser(admin);
    } else {
      setEditUser(DEFAULT_USER);
    }
  }

  function handleClose() {
    setEditUser(null);
  }

  function handleOpenRemoveDialog(admin) {
    setAdminToRemove(admin);
    setOpenRemoveDialog(true);
  }

  function handleCloseRemoveDialog() {
    setOpenRemoveDialog(false);
    setTimeout(() => setAdminToRemove(null), 1000);
  }

  if (error) {
    return <ErrorServer error={error} />;
  }

  function handleRemoveAdmin() {
    mutationRemoveAssociationUser({
      variables: {
        associationId: id,
        userId: adminToRemove.id,
        role: 'admin',
      },
    }).then((result) => {
      if (result.data.removeAssociationUser) {
        refetch();
        dispatch(dispatchInfo(t('pages.associationAdministrators.deleteAdminSuccess')));
      } else {
        dispatch(handleError(t('pages.associationAdministrators.deleteAdminFail')));
      }
    }).catch((error) => {
      dispatch(handleError(error));
    }).finally(() => {
      handleCloseRemoveDialog();
    });
  }

  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        <Container maxWidth={false} className={detailPageClasses.header}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1">{t('pages.associationAdministrators.title')}</Typography>
            </Grid>
            {hasSuperAdminRole && (
              <>
                <Hidden mdDown>
                  <Grid item>
                    <Button
                      variant="contained" className={detailPageClasses.addButton}
                      onClick={handleClickOpen}
                    >
                      {t('pages.associationAdministrators.addButton')}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp>
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={detailPageClasses.fab}
                    onClick={handleClickOpen}
                  >
                    <PlusIcon color={SPORTIIZ_WHITE} />
                  </Fab>
                </Hidden>
              </>
            )}
          </Grid>
        </Container>
        <Container maxWidth={false} className={detailPageClasses.content}>
          {loading && <Loading />}
          {!loading && <UserList
            key={'admin_list'}
            users={admins}
            emptyLabel={t('pages.associationAdministrators.emptyLabel')}
            hideMenu={!hasSuperAdminRole}
            openRemoveDialog={handleOpenRemoveDialog}
          />}
        </Container>
        {hasSuperAdminRole && (
          <>
            <AdminFormDialog
              handleClose={handleClose}
              refetch={refetch}
              association={association}
            />
            <DeleteAdminDialog
              open={openRemoveDialog}
              onClose={handleCloseRemoveDialog}
              loading={mutationLoading}
              handleRemove={handleRemoveAdmin}
            />
          </>
        )}
      </Box>
    </div>
  );
};

export default AssociationCoachesContent;
