import React from 'react';
import PropTypes from 'prop-types';
import { SPORTIIZ_BLUE } from '../../constants/colors';

const CoachesIcon = ({
  color,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g data-name="Layer 2">
      <path
        fill={color}
        d="M19 20.75l-2.31-9A5.94 5.94 0 0018 8 6 6 0 006 8a5.94 5.94 0 001.34 3.77L5 20.75a1 1 0 001.48 1.11l5.33-3.13 5.68 3.14A.91.91 0 0018 22a1 1 0 001-1.25zM12 4a4 4 0 11-4 4 4 4 0 014-4zm.31 12.71a1 1 0 00-1 0l-3.75 2.2L9 13.21a5.94 5.94 0 005.92 0L16.45 19z"
        data-name="award"
      />
    </g>
  </svg>
);

CoachesIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CoachesIcon.defaultProps = {
  color: SPORTIIZ_BLUE,
  width: '1em',
  height: '1em',
};
export default CoachesIcon;
