import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { dispatchInfo, handleError } from '../../../store/infos/actions';
import { validateEmail } from '../../../services/userService';
import AvatarPictureInput from '../../forms/AvatarPictureInput';
import { MUTATION_ASSOCIATION_COACH } from '../../../constants/graphQLQueries';
import dialogFormStyle from '../../../styles/dialogForm';
import { useEditUser } from '../../contexts/EditUserContext';

const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));

const INITIAL_VALUES = {
  id: '',
  associationId: null,
  coachId: null,
  email: '',
  emailError: false,
  emailErrorMessage: '',
  firstname: '',
  lastname: '',
  phone: '',
  phoneError: false,
  phoneErrorMessage: '',
  picture: null,
  pictureBlob: null,
};

const AssociationCoachForm = (props) => {
  const {
    handleClose,
    refetch,
    associationId,
    retry,
    setRetry,
  } = props;
  const dialogFormClasses = useDialogFormStyles();
  const { t } = useTranslation();
  const client = useApolloClient();

  const { editUser } = useEditUser();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    ...INITIAL_VALUES,
    associationId,
  });
  const [mutationCoach, { loading }] = useMutation(
    MUTATION_ASSOCIATION_COACH,
    {
      client,
      update,
      errorPolicy: 'ignore',
    },
  );

  function update(cache, user) {
    refetch();
  }

  useEffect(() => {
    if (editUser !== null && editUser.id !== null) {
      setValues(
        {
          ...INITIAL_VALUES,
          associationId,
          coachId: editUser.id,
          email: editUser.email,
          firstname: editUser.firstname,
          lastname: editUser.lastname,
          phone: editUser.phone,
          pictureBlob: editUser.picture || null,
        },
      );
    }
  }, [associationId, editUser]);

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
      [`${name}Error`]: false,
      [`${name}ErrorMessage`]: '',
    });
  };

  const handleChangeFile = (name) => (event) => {
    const blob = new Blob([event.target.files[0]], { type: 'image/png' });
    const blobUrl = URL.createObjectURL(blob);
    setValues({
      ...values,
      [name]: event.target.files[0],
      [`${name}Blob`]: blobUrl,
    });
  };

  function handleRetryClose() {
    setRetry(false);
  }

  function handleRetryAdd() {
    setRetry(false);
    submit(false);
  }

  const onSubmit = (event) => {
    let newValues = { ...values };
    let error = false;
    if (values.email === null || values.email === '') {
      newValues = {
        ...newValues,
        emailError: true,
        emailErrorMessage: t('pages.associationCoaches.form.formEmailRequired'),
      };
      error = true;
    }
    if (values.email !== null && values.email !== '' && !validateEmail(values.email)) {
      newValues = {
        ...newValues,
        emailError: true,
        emailErrorMessage: t('pages.associationCoaches.form.formInvalidEmail'),
      };
      error = true;
    }
    if (values.phone !== null && values.phone !== '' && values.phone.length < 10) {
      newValues = {
        ...newValues,
        phoneError: true,
        phoneErrorMessage: t('pages.associationCoaches.form.formInvalidPhone'),
      };
      error = true;
    }
    setValues(newValues);

    if (!error) {
      if (event) {
        event.preventDefault();
      }
      submit();
    }
  };

  function submit(force) {
    const vars = {
      associationId: values.associationId,
      coachId: values.coachId,
      email: values.email,
      lastname: values.lastname,
      firstname: values.firstname,
      phone: values.phone,
    };

    let hasUpload = false;
    if (values.picture) {
      vars.picture = values.picture;
      hasUpload = true;
    }
    let mutationForce = false;
    let successMessage = t('pages.associationCoaches.form.formAddSuccessMessage');
    if (editUser && editUser.id !== null) {
      mutationForce = true;
      successMessage = t('pages.associationCoaches.form.formEditSuccessMessage');
    } else if (force === undefined) {
      mutationForce = null;
    } else {
      mutationForce = force;
    }
    vars.force = mutationForce;

    mutationCoach({
      variables: vars,
      context: { hasUpload },
    }).then(() => {
      onGraphQLSuccess(successMessage);
    }).catch((error) => {
      onGraphQLError(error);
    });
  }

  function onGraphQLSuccess(message) {
    handleClose();
    setValues(INITIAL_VALUES);
    setRetry(false);
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
      || error.graphQLErrors[0].code !== 409
    ) {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    } else {
      setRetry(true);
      handleClose();
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <AvatarPictureInput
              pictureBlob={values.pictureBlob}
              handleChangeFile={handleChangeFile}
              id="coach-picture"
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <TextField
              label={t('pages.associationCoaches.form.email')}
              id="coach-email"
              type="text"
              variant="outlined"
              onChange={handleChange('email')}
              margin="normal"
              value={values.email}
              fullWidth={true}
              error={values.emailError}
              helperText={values.emailErrorMessage}
              disabled={editUser && editUser.id !== null && editUser.id !== ''}
            />
            <TextField
              label={t('pages.associationCoaches.form.firstname')}
              id="coach-firstname"
              type="text"
              variant="outlined"
              onChange={handleChange('firstname')}
              margin="normal"
              value={values.firstname}
              fullWidth={true}
            />
            <TextField
              label={t('pages.associationCoaches.form.lastname')}
              id="coach-lastname"
              type="text"
              variant="outlined"
              onChange={handleChange('lastname')}
              margin="normal"
              value={values.lastname}
              fullWidth={true}
            />
            <TextField
              label={t('pages.associationCoaches.form.phone')}
              id="coach-phone"
              pattern="^\d{10}$"
              type="text"
              variant="outlined"
              onChange={handleChange('phone')}
              margin="normal"
              value={values.phone}
              fullWidth={true}
              error={values.phoneError}
              helperText={values.phoneErrorMessage}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          onClick={handleClose}
          className={dialogFormClasses.button}
          disabled={loading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={dialogFormClasses.button}
          disabled={loading}
        >
          {values.coachId
            ? t('common.edit')
            : t('common.add')}
          {loading && <CircularProgress size={24} className={dialogFormClasses.buttonProgress} />}
        </Button>
      </DialogActions>

      <Dialog
        open={retry}
        onClose={handleClose}
        aria-labelledby="alert-retry-title"
        aria-describedby="alert-retry-description"
      >
        <DialogTitle id="alert-retry-title">
          {t('pages.associationCoaches.UserExistsDialogTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-retry-description">
            {t('pages.associationCoaches.UserExistsDialogDesc')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRetryClose}
            className={dialogFormClasses.button}
            variant="outlined"
            color="primary"
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={handleRetryAdd}
            className={dialogFormClasses.button}
            variant="contained"
            color="primary"
          >
            {t('common.link')}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

AssociationCoachForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  associationId: PropTypes.string.isRequired,
  retry: PropTypes.bool.isRequired,
  setRetry: PropTypes.func.isRequired,
};

export default AssociationCoachForm;
