import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { resetError, resetInfo } from '../../store/infos/actions';
import { SPORTIIZ_BLUE, SPORTIIZ_RED } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    info: {
      backgroundColor: SPORTIIZ_BLUE,
    },
    error: {
      backgroundColor: SPORTIIZ_RED,
    },
  }
));

export default function SnackBar() {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { error, info } = useSelector(state => state.infos);

  function handleErrorSnackbarClose() {
    dispatch(resetError());
  }

  function handleInfoSnackbarClose() {
    dispatch(resetInfo());
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={error !== null}
        message={error || ''}
        onClose={handleErrorSnackbarClose}
        ContentProps={{
          classes: {
            root: classes.error,
          },
        }}
        action={[
          <IconButton key="close_error" color="inherit" onClick={handleErrorSnackbarClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={info !== null}
        message={info || ''}
        autoHideDuration={4000}
        onClose={handleInfoSnackbarClose}
        ContentProps={{
          classes: {
            root: classes.info,
          },
        }}
        action={[
          <IconButton key="close_info" color="inherit" onClick={handleInfoSnackbarClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}
