import React from 'react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import 'react-quill/dist/quill.snow.css';
import store from './store';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import i18n from './services/i18n';
import API_ROOT from './constants/api';
import { firebase } from './firebase';

const httpLink = createUploadLink({ uri: API_ROOT });

const authLink = setContext(async (req, { headers, ...context }) => {
  if (firebase.auth.currentUser) {
    const token = await firebase.auth.currentUser.getIdToken(true);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
      ...context,
    };
  }
  return {};
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <App />
          </ApolloHooksProvider>
        </ApolloProvider>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
