import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import withAuthorization from '../../hoc/withAuthorization';
import ErrorServer from '../ErrorServer';
import { DEFAULT_ACTIVITY } from '../../constants/graphQLQueries';
import detailPageStyles from '../../styles/detailPage';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import ActivityDetails from '../../components/activities/ActivityDetails';
import Loading from '../../components/layout/Loading';
import ActivityPageContent from '../../components/activities/ActivityPageContent';
import useCompany from '../../components/hooks/UseCompany';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));

export function CompanyActivityPage() {
  const {
    id,
    activityId,
  } = useParams();
  const detailPageClasses = useDetailPageStyles();
  const {
    activities,
    loading,
    error,
  } = useCompany(id);

  if (error) {
    return <ErrorServer error={error} />;
  }

  const activity = activities.find((act) => act.id === activityId) ?? DEFAULT_ACTIVITY;

  return (
    <div>
      <Sidebar />
      <Box className={detailPageClasses.boxSimple} component="section">
        {loading
          && (
            <Container maxWidth={false} className={detailPageClasses.content}>
              <Loading />
            </Container>
          )}
        {!loading && activity !== undefined
          ? <>
            <ActivityPageContent activity={activity} />
            <ActivityDetails activity={activity} companyView={true} />
          </>
          : null
        }
      </Box>
    </div>
  );
}

export default withAuthorization()(CompanyActivityPage);
