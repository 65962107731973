import React from 'react';
import Chart from 'react-apexcharts';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CARD_RADIUS, LABEL_RADIUS } from '../../../constants/style';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      position: 'relative',
      borderRadius: CARD_RADIUS,
      height: '260px',
      [theme.breakpoints.down('sm')]: {
        height: '240px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '200px',
      },
    },
    chart: {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    divider: {
      position: 'absolute',
      borderRadius: LABEL_RADIUS,
      width: '3em',
      height: '3px',
      margin: '5px auto 10px',
      top: '70%',
      left: 'calc(50% - 1.5em)',
    },
    icon: {
      fontSize: '2rem',
      position: 'absolute',
      top: '30%',
      width: '100%',
    },
    percent: {
      position: 'absolute',
      fontSize: '3rem',
      top: '30%',
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    percentSign: {
      fontSize: '1rem',
      marginLeft: '3px',
    },
    label: {
      position: 'absolute',
      top: '80%',
      width: '100%',
    },
  };
});
export default function AngleCircleCharts(props) {
  const { percent, label, icon, color } = props;

  const classes = useStyles();

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  let chartHeight = '300px';
  if (matchesMD) {
    chartHeight = '260px';
  }
  if (matchesSM) {
    chartHeight = '240px';
  }
  if (matchesXS) {
    chartHeight = '200px';
  }
  const chartOptions = {
    chart: {
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -110,
        endAngle: 110,
        hollow: {
          margin: 5,
          size: '72%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: [color],
    labels: [''],
  };
  return (

    <Grid item xl={4} md={6} xs={12}>
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.chart}>
          <Typography variant={'h2'} className={classes.icon}>
            {icon}
          </Typography>
          <Typography variant={'h1'} className={classes.percent}>
            {percent}<span className={classes.percentSign}>%</span>
          </Typography>
          <Chart
            options={chartOptions}
            series={[percent]}
            type="radialBar"
            height={chartHeight}
          />
        </div>
        <Divider className={classes.divider} />
        <Typography variant="subtitle2" className={classes.label}>{label}</Typography>
      </Paper>
    </Grid>
  );
}

AngleCircleCharts.propTypes = {
  percent: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
};
