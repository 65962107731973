import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import { getDayFromString, getDayFromTimestamp } from '../../services/dateService';
import { formatStringForTable } from '../../services/stringService';

const useStyles = makeStyles(() => (
  {
    container: {
      width: '100%',
      overflow: 'scroll',
      scrollbarWidth: 'none',
      marginBottom: '2em',
    },
    table: {
      minWidth: 0,
      tableLayout: 'fixed',
    },
  }
));

export default function WaitingUserList(props) {
  const { waitingCompanyUsers } = props;
  const {
    t,
    i18n,
  } = useTranslation();
  const locale = i18n.language.slice(0, 2);
  const classes = useStyles();
  return (
    <Container maxWidth={false} className={classes.container}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {t('table.waitingCompanyUser.email')}
            </TableCell>
            <Hidden xsDown>
              <TableCell align="left">
                {t('table.waitingCompanyUser.lastname')}
              </TableCell>
            </Hidden>
            <Hidden xsDown>
              <TableCell align="left">
                {t('table.waitingCompanyUser.firstname')}
              </TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="left">
                {t('table.waitingCompanyUser.birthday')}
              </TableCell>
            </Hidden>
            <TableCell align="left">
              {t('table.waitingCompanyUser.source')}
            </TableCell>
            <Hidden smDown>
              <TableCell align="left">
                {t('table.waitingCompanyUser.createdAt')}
              </TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {waitingCompanyUsers.map((waitingCompanyUser) => (
            <TableRow key={waitingCompanyUser.id}>
              <TableCell align="left">
                {formatStringForTable(waitingCompanyUser.email)}
              </TableCell>
              <Hidden xsDown>
                <TableCell align="left">
                  {formatStringForTable(waitingCompanyUser.lastname)}
                </TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell align="left">
                  {formatStringForTable(waitingCompanyUser.firstname)}
                </TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell align="left">
                  {formatStringForTable(waitingCompanyUser.birthday
                    ? getDayFromString(locale, waitingCompanyUser.birthday)
                    : '')
                  }
                </TableCell>
              </Hidden>
              <TableCell align="left">
                {
                  formatStringForTable(waitingCompanyUser.source
                    ? waitingCompanyUser.source
                    : t('common.notSpecified'))
                }
              </TableCell>
              <Hidden smDown>
                <TableCell align="left">
                  {formatStringForTable(getDayFromTimestamp(
                    locale,
                    waitingCompanyUser.createdAt,
                  ))}
                </TableCell>
              </Hidden>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}

WaitingUserList.propTypes = {
  waitingCompanyUsers: PropTypes.array.isRequired,
};
