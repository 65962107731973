import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import StepCard from './StepCard';

const useStyles = makeStyles(() => (
  {
    gridRow: {
      width: '100%',
      margin: 'auto',
      paddingBottom: '76px',
    },
  }
));

const sortStep = (step1, step2) => {
  if (step1.number > step2.number) {
    return 1;
  }
  if (step2.number > step1.number) {
    return -1;
  }
  return 0;
};

export default function StepsList(props) {
  const { game } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const stepCount = game.steps.length;
  return (
    <Container>
      <Grid
        container
        justifyContent="flex-start"
        spacing={
          matches
            ? 2
            : 4
        }
        className={classes.gridRow}
      >
        {game.steps.sort(sortStep).map((step, i) => (
          <StepCard
            key={`step-${step.id}`}
            step={step}
            isLast={stepCount === i + 1}
          />
        ))}
      </Grid>
    </Container>
  );
}

StepsList.propTypes = {
  game: PropTypes.object.isRequired,
};
