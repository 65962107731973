import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getI18n, useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import PeopleIcon from '../icon/PeopleIcon';
import { getDayFromTimestamp, getHourFromTimestamp } from '../../services/dateService';
import { SPORTIIZ_GREY_GREEN } from '../../constants/colors';
import MoreVerticalIcon from '../icon/MoreVerticalIcon';
import BookingListDialog from './BookingList/BookingListDialog';

const useStyles = makeStyles((theme) => (
  {
    table: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '100px',
      },
    },
    moreMenuButton: {
      padding: 0,
    },
  }
));

function SimpleTimeSlotsArray(props) {
  const {
    timeSlots,
    withMenu,
    companyId,
    associationId,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showTimeslotBookingId, setShowTimeslotBookingId] = useState('');
  const [menuTimeSlotId, setMenuTimeSlotId] = useState('');

  const locale = getI18n().language.slice(0, 2);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTimeSlotId('');
  };
  const handleMenuClick = (event, id) => {
    setMenuTimeSlotId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleOpenBookingDialog = (id) => {
    setShowTimeslotBookingId(id);
  };
  const handleCheckBooking = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    handleMenuClose();
    handleOpenBookingDialog(id);
  };

  const handleCloseBookingDialog = () => {
    setShowTimeslotBookingId('');
  };

  if (timeSlots.length === 0) {
    return (
      <div>
        <Typography variant={'subtitle1'}>
          {t('pages.timeSlots.emptyList')}
        </Typography>
      </div>
    );
  }
  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {t('table.timeSlot.activity')}
            </TableCell>
            <TableCell align="left">
              {t('table.timeSlot.date')}
            </TableCell>
            <TableCell align="left">
              {t('table.timeSlot.hours')}
            </TableCell>
            <TableCell align="left">
              {t('table.timeSlot.entrants')}
            </TableCell>
            {withMenu && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {timeSlots.map((timeSlot) => (
            <TableRow key={timeSlot.id} id={timeSlot.id}>
              <TableCell align="left">
                {timeSlot.activity.name}
              </TableCell>
              <TableCell align="left">
                {getDayFromTimestamp(locale, timeSlot.start)}
              </TableCell>
              <TableCell align="left">
                {
                  getHourFromTimestamp(locale, timeSlot.start)
                } - {
                getHourFromTimestamp(locale, timeSlot.end)
              }
              </TableCell>
              <TableCell align="left">
                {`${timeSlot.bookingCount}/${timeSlot.activity.maximumEntrant}`}
              </TableCell>
              {withMenu && (
                <TableCell align="right">
                  <IconButton
                    onClick={
                      (event) => handleMenuClick(event, timeSlot.id)
                    }
                    className={classes.moreMenuButton}
                  >
                    <MoreVerticalIcon/>
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {withMenu && (
        <>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={menuTimeSlotId !== ''}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={(event) => handleCheckBooking(event, menuTimeSlotId)}>
              <ListItemIcon>
                <PeopleIcon
                  color={SPORTIIZ_GREY_GREEN}
                  width='22px'
                  height='22px'
                />
              </ListItemIcon>
              <ListItemText>
                {t('pages.timeSlots.participants')}
              </ListItemText>
            </MenuItem>
          </Menu>
          <BookingListDialog
            timeSlotId={showTimeslotBookingId}
            handleCloseBookingDialog={handleCloseBookingDialog}
            companyId={companyId}
            associationId={associationId}
          />
        </>
      )}
    </div>
  );
}

SimpleTimeSlotsArray.propTypes = {
  timeSlots: PropTypes.array.isRequired,
  withMenu: PropTypes.bool,
  companyId: PropTypes.string,
  associationId: PropTypes.string,
};

SimpleTimeSlotsArray.defaultProps = {
  withMenu: false,
  companyId: null,
  associationId: null,
};

export default SimpleTimeSlotsArray;
