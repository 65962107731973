import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Hidden from '@material-ui/core/Hidden';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { ASSOCIATION } from '../../constants/routes';
import MoreVerticalIcon from '../icon/MoreVerticalIcon';
import { SPORTIIZ_GREY } from '../../constants/colors';
import ArrayPopover from '../array/ArrayPopover';

const useStyles = makeStyles(() => (
  {
    table: {
      minWidth: 0,
    },
  }
));
export default function AssociationsArray(props) {
  const { associations, handleEdit } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [associationId, setAssociationId] = useState(null);

  const open = Boolean(anchorEl);

  function handleItemListMenuClick(event, id) {
    setAssociationId(id);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  }

  function handleItemListClick(id) {
    history.push(ASSOCIATION.replace(':id', id));
  }

  function handleClose(event) {
    setAnchorEl(null);
    setAssociationId(null);
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function handleEditClick(event) {
    setAnchorEl(null);
    event.stopPropagation();
    event.preventDefault();
    const assoc = associations.find((a) => {
      return a.id === associationId;
    });
    handleEdit(assoc);
  }

  function handleDetailsClick(event) {
    setAnchorEl(null);
    event.stopPropagation();
    event.preventDefault();
    history.push(ASSOCIATION.replace(':id', associationId));
  }

  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('table.association.name')}</TableCell>
            <Hidden xsDown>
              <TableCell align="left">{t('table.company.area')}</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="left">{t('table.association.slogan')}</TableCell>
            </Hidden>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {associations.map((association) => (
            <TableRow key={association.id} onClick={() => handleItemListClick(association.id)}>
              <TableCell align="left">
                {association.name}
              </TableCell>
              <Hidden xsDown>
                <TableCell align="left">
                  {
                    association.area
                      ? association.area.name
                      : t('common.notSpecified')
                  }
                </TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell align="left">
                  {association.slogan}
                </TableCell>
              </Hidden>
              <TableCell align="left">
                <IconButton
                  aria-label="more"
                  size="small"
                  onClick={(event) => handleItemListMenuClick(event, association.id)}
                >
                  <MoreVerticalIcon color={SPORTIIZ_GREY} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ArrayPopover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        items={[
          {
            action: handleDetailsClick,
            label: t('common.details'),
          },
          {
            action: handleEditClick,
            label: t('common.edit'),
          },
        ]}
      />
    </div>
  );
}

AssociationsArray.propTypes = {
  associations: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
};
