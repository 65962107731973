import React from 'react';
import PropTypes from 'prop-types';

const LogoIcon = ({
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 88 133">
    <title>{'icon/logo'}</title>
    <path
      d="M87.313 110.424c0-4.774-1.114-10.214-2.963-15.089-1.592-4.198-4.002-11.871-8.489-22.222-4.025-9.281-7.394-17.661-8.074-19.995-.938-3.218-1.624-6.17-1.624-9.463 0-10.128 5.981-18.949 14.24-23.557C72.639 8.013 59.088 0 43.657 0 19.546 0 0 19.545 0 43.655c0 16.548 9.208 30.944 22.778 38.348l-.055.103 52.049 28.395.011.064c4.952 2.896 8.281 8.266 8.281 14.417 0 3.019-.806 5.849-2.208 8.293a43.451 43.451 0 006.457-22.851"
      fill="#FC324C"
      fillRule="evenodd"
    />
  </svg>
);

LogoIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LogoIcon.defaultProps = {
  width: '1em',
  height: '1em',
};

export default LogoIcon;
