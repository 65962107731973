import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import dialogFormStyle from '../../../../styles/dialogForm';
import { MUTATION_SET_COMPANY_MEMBER } from '../../../../constants/graphQLQueries';
import { dispatchInfo, handleError } from '../../../../store/infos/actions';
import { isNotEmpty } from '../../../../services/stringService';
import {
  getBirthdayDateFromString,
  getBirthdayStringFromDate,
} from '../../../../services/dateService';

const useDialogFormStyles = makeStyles((theme) => dialogFormStyle(theme));
const CompanyMemberForm = (props) => {
  const {
    handleClose,
    update,
    member,
  } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const dialogFormClasses = useDialogFormStyles();

  const [firstname, setFirstname] = useState(member.firstname);
  const [lastname, setLastname] = useState(member.lastname);
  let birthdayDate = null;
  if (isNotEmpty(member.birthday)) {
    birthdayDate = getBirthdayDateFromString(member.birthday);
  }
  const [birthday, setBirthday] = useState(birthdayDate);
  const [birthdayError, setBirthdayError] = useState('');
  const [phone, setPhone] = useState(member.phone);
  const [phoneError, setPhoneError] = useState('');
  const [mutationMember, { loading }] = useMutation(
    MUTATION_SET_COMPANY_MEMBER,
    {
      client,
      update,
    },
  );
  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let error = false;
    if (birthday !== null) {
      const now = new Date();
      if (birthday.getFullYear() > now.getFullYear() - 18) {
        setBirthdayError('trop jeune');
        error = true;
      } else if (birthday.getFullYear() < now.getFullYear() - 100) {
        setBirthdayError('trop vieux');
        error = true;
      }
    }
    if (!error) {
      const vars = {
        companyId: id,
        userId: member.id,
        firstname,
        lastname,
        birthday: (
          birthday !== null
        ) ? getBirthdayStringFromDate(birthday) : null,
        phone,
      };
      mutationMember({ variables: vars })
        .then(() => {
          onGraphQLSuccess(t('pages.companyMembers.form.formEditSuccessMessage'));
        }).catch((error) => {
        onGraphQLError(error);
      });
    }
  };

  function onGraphQLSuccess(message) {
    handleClose();
    if (message) {
      dispatch(dispatchInfo(message));
    }
  }

  function onGraphQLError(error) {
    if (error.graphQLErrors === undefined
      || error.graphQLErrors.length === 0
    ) {
      dispatch(handleError(error));
    } else {
      console.log(error.graphQLErrors[0]);
      dispatch(handleError(error.graphQLErrors[0].message));
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <TextField
          label={t('pages.companyMembers.form.firstname')}
          id="member-firstname"
          type="text"
          variant="outlined"
          onChange={(event) => setFirstname(event.target.value)}
          margin="normal"
          value={firstname}
          fullWidth={true}
          required
        />
        <TextField
          label={t('pages.companyMembers.form.lastname')}
          id="member-lastname"
          type="text"
          variant="outlined"
          onChange={(event) => setLastname(event.target.value)}
          margin="normal"
          value={lastname}
          fullWidth={true}
          required
        />
        <DatePicker
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          fullWidth
          id="timeSlots-birthday"
          label={t('pages.companyMembers.form.birthdayLabel')}
          value={birthday}
          onChange={(date) => {
            setBirthday(date);
            setBirthdayError('');
          }}
          KeyboardButtonProps={{
            'aria-label': 'change birthday',
          }}
          error={birthdayError !== ''}
          helperText={birthdayError}
          okLabel={t('common.ok')}
          cancelLabel={t('common.cancel')}
        />
        <TextField
          label={t('pages.companyMembers.form.phone')}
          id="member-phone"
          type="text"
          variant="outlined"
          onChange={(event) => {
            setPhone(event.target.value);
            setPhoneError('');
          }}
          margin="normal"
          value={phone}
          fullWidth={true}
          error={phoneError !== ''}
          helperText={phoneError}
        />
        <TextField
          label={t('pages.companyMembers.form.email')}
          id="member-email"
          type="text"
          variant="outlined"
          onChange={() => {
          }}
          margin="normal"
          value={member.email}
          fullWidth={true}
          disabled
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          onClick={handleClose}
          className={dialogFormClasses.button}
          disabled={loading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={dialogFormClasses.button}
          disabled={loading}
        >
          {t('common.save')}
          {loading && <CircularProgress size={24} className={dialogFormClasses.buttonProgress} />}
        </Button>
      </DialogActions>
    </form>
  );
};
CompanyMemberForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
};
export default CompanyMemberForm;
