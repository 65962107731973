import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/layout/sidebar/Sidebar';
import detailPageStyles from '../../styles/detailPage';
import withAuthorization from '../../hoc/withAuthorization';
import { TimeSlotPeriodProvider } from '../../components/contexts/TimeSlotPeriodContext';
import TimeSlotPeriodNav from '../../components/timeSlots/TimeSlotPeriodNav/TimeSlotPeriodNav';
import AssociationBookingsContent from '../../components/associations/AssociationBookingsContent';

const useDetailPageStyles = makeStyles((theme) => detailPageStyles(theme));
const AssociationBookingsPage = () => {
  const detailPageClasses = useDetailPageStyles();
  const { t } = useTranslation();

  return (
    <div>
      <TimeSlotPeriodProvider>
        <Sidebar />
        <Box className={detailPageClasses.boxSimple} component="section">
          <Container maxWidth={false}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h1">
                  {t('pages.associationBookings.title')}
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <TimeSlotPeriodNav />
          <Box component="section" className={detailPageClasses.footerBox}>
            <div className={detailPageClasses.footerContainer}></div>
            <AssociationBookingsContent />
          </Box>
        </Box>
      </TimeSlotPeriodProvider>
    </div>
  );
};

export default withAuthorization()(AssociationBookingsPage);
