import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  SPORTIIZ_GREY,
  SPORTIIZ_GREY_GREEN,
  SPORTIIZ_RED,
  SPORTIIZ_WHITE,
} from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    chip: {
      width: '150px',
    },
  }
));

const TimeSlotStatusChip = (props) => {
  const { status } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  function getStatusBackgroundColor(status) {
    switch (status) {
      case 'waiting':
        return SPORTIIZ_GREY;
      case 'validated':
        return SPORTIIZ_GREY_GREEN;
      case 'confirmed':
        return SPORTIIZ_GREY_GREEN;
      case 'cancelledNotEnoughParticipants':
        return SPORTIIZ_RED;
      case 'cancelledByCoach':
        return SPORTIIZ_RED;
      case 'cancelledByEventCancellation':
        return SPORTIIZ_RED;
      case 'started':
        return SPORTIIZ_GREY_GREEN;
      case 'ended':
        return SPORTIIZ_GREY_GREEN;
      default:
        return SPORTIIZ_GREY;
    }
  }

  return <Chip
    label={t(`status.${status}`)}
    size="small"
    className={classes.chip}
    style={{
      backgroundColor: getStatusBackgroundColor(status),
      color: SPORTIIZ_WHITE,
    }}
  />;
};

TimeSlotStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};
export default TimeSlotStatusChip;
