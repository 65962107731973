export const HOME = '/';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const FIREBASE_EMAIL_ACTION_HANDLERS = '/email-action';

export const COMPANIES = '/companies';
export const COMPANY = `${COMPANIES}/:id`;
export const COMPANY_MEMBERS = `${COMPANY}/members`;
export const COMPANY_MEMBER = `${COMPANY_MEMBERS}/:memberId`;
export const COMPANY_ACTIVITIES = `${COMPANY}/activities`;
export const COMPANY_ACTIVITY = `${COMPANY_ACTIVITIES}/:activityId`;
export const COMPANY_ADMINISTRATORS = `${COMPANY}/administrators`;
export const COMPANY_TIMESLOTS = `${COMPANY}/timeslots`;

const all_company_regex_expr = /companies\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;
export const ALL_COMPANY_REGEX = new RegExp(all_company_regex_expr);
const company_regex_expr = /companies\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/g;
export const COMPANY_REGEX = new RegExp(company_regex_expr);
const company_members_regex_expr = /companies\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/members/g;
export const COMPANY_MEMBER_REGEX = new RegExp(company_members_regex_expr);
const company_activities_regex_expr = /companies\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/activities/g;
export const COMPANY_ACTIVITIES_REGEX = new RegExp(company_activities_regex_expr);
const company_administrators_regex_expr = /companies\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/administrators/g;
export const COMPANY_ADMINISTRATORS_REGEX = new RegExp(company_administrators_regex_expr);
const company_timeslots_regex_expr = /companies\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/timeslots/g;
export const COMPANY_TIMESLOTS_REGEX = new RegExp(company_timeslots_regex_expr);

export const REWARDS = '/rewards';

export const GAMES = '/games';
export const GAME = '/games/:type/:id';

export const SUBSCRIPTIONS = '/subscriptions';

export const ASSOCIATIONS = '/associations';
export const ASSOCIATION = `${ASSOCIATIONS}/:id`;
export const ASSOCIATION_COACHES = `${ASSOCIATION}/coaches`;
export const ASSOCIATION_ADMINISTRATORS = `${ASSOCIATION}/administrators`;
export const ASSOCIATION_ACTIVITIES = `${ASSOCIATION}/activities`;
export const ASSOCIATION_ACTIVITY = `${ASSOCIATION_ACTIVITIES}/:activityId`;
export const ASSOCIATION_ADD_ACTIVITY = `${ASSOCIATION_ACTIVITIES}/add`;
export const ASSOCIATION_EDIT_ACTIVITY = `${ASSOCIATION_ACTIVITIES}/:activityId/edit`;
export const ASSOCIATION_TIME_SLOTS = `${ASSOCIATION}/timeslots`;
export const ASSOCIATION_BOOKINGS = `${ASSOCIATION}/bookings`;

const all_association_regex_expr = /associations\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;
export const ALL_ASSOCIATION_REGEX = new RegExp(all_association_regex_expr);
const association_regex_expr = /associations\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/g;
export const ASSOCIATION_REGEX = new RegExp(association_regex_expr);
const association_administrators_regex_expr = /associations\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/administrators/g;
export const ASSOCIATION_ADMINISTRATORS_REGEX = new RegExp(association_administrators_regex_expr);
const association_coaches_regex_expr = /associations\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/coaches/g;
export const ASSOCIATION_COACHES_REGEX = new RegExp(association_coaches_regex_expr);
const association_activities_regex_expr = /associations\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/activities/g;
export const ASSOCIATION_ACTIVITIES_REGEX = new RegExp(association_activities_regex_expr);
const association_time_slots_regex_expr = /associations\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/timeslots/g;
export const ASSOCIATION_TIME_SLOTS_REGEX = new RegExp(association_time_slots_regex_expr);
const association_bookings_regex_expr = /associations\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/bookings/g;
export const ASSOCIATION_BOOKINGS_REGEX = new RegExp(association_bookings_regex_expr);

export const EVENTS = '/events';
export const EVENT = `${EVENTS}/:id`;
export const EVENT_PARTICIPANTS = `${EVENT}/participants`;

export const EVENT_ACTIVITIES = `${EVENT}/activities`;
export const EVENT_ACTIVITY = `${EVENT_ACTIVITIES}/:activityId`;
export const EVENT_EDIT = `${EVENT}/edit`;
export const EVENT_TIME_SLOTS = `${EVENT}/timeslots`;

const all_event_regex_expr = /events\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;
export const ALL_EVENT_REGEX = new RegExp(all_event_regex_expr);
const event_regex_expr = /events\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/g;
export const EVENT_REGEX = new RegExp(event_regex_expr);
const event_time_slots_regex_expr = /events\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/timeslots/g;
export const EVENT_TIME_SLOTS_REGEX = new RegExp(event_time_slots_regex_expr);
const event_activities_regex_expr = /events\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/activities/g;
export const EVENT_ACTIVITIES_REGEX = new RegExp(event_activities_regex_expr);
const event_participants_regex_expr = /events\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/participants/g;
export const EVENT_PARTICIPANTS_REGEX = new RegExp(event_participants_regex_expr);

export const INDIVIDUALS = '/individuals';
