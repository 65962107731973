import {
  SPORTIIZ_BACKGROUND, SPORTIIZ_BLUE,
  SPORTIIZ_GREY_DARK,
  SPORTIIZ_GREY_LIGHT,
  SPORTIIZ_WHITE,
} from '../constants/colors';
import { NAV_HEIGHT, SIDEBAR_WIDTH, BACKGROUND_RADIUS } from '../constants/style';

export default function detailPageStyles(theme) {
  return (
    {
      header: {
        [theme.breakpoints.up('lg')]: {
          borderBottom: `1px solid ${SPORTIIZ_GREY_LIGHT}`,
          position: 'fixed',
          backgroundColor: SPORTIIZ_WHITE,
          zIndex: 2,
          width: 'calc(100% - 340px)',
        },
      },
      content: {
        width: '100%',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        [theme.breakpoints.up('lg')]: {
          marginTop: '100px',
        },
      },
      pageTitle: {
        [theme.breakpoints.down('md')]: {
          padding: '8px',
        },
      },
      box: {
        position: 'absolute',
        width: `calc(100% - ${SIDEBAR_WIDTH} - ${NAV_HEIGHT})`,
        minHeight: '100%',
        top: 0,
        left: 0,
        margin: `0 0 0 calc(${SIDEBAR_WIDTH} + ${NAV_HEIGHT})`,
        backgroundColor: SPORTIIZ_BACKGROUND,
        scrollbarWidth: 'none',

        display: 'flex',
        flexDirection: 'column',
        gap: 0,

        [theme.breakpoints.down('md')]: {
          width: 'calc(100% - 50px)',
          margin: `${NAV_HEIGHT} 0 0 50px`,
        },
      },
      boxSimple: {
        position: 'absolute',
        width: `calc(100% - ${SIDEBAR_WIDTH} - ${NAV_HEIGHT})`,
        top: 0,
        left: 0,
        margin: `0 0 0 calc(${SIDEBAR_WIDTH} + ${NAV_HEIGHT})`,
        backgroundColor: SPORTIIZ_WHITE,
        scrollbarWidth: 'none',
        overflow: 'scroll',
        [theme.breakpoints.down('md')]: {
          width: 'calc(100% - 50px)',
          margin: `${NAV_HEIGHT} 0 0 50px`,
        },
      },
      footerBox: {
        backgroundColor: SPORTIIZ_BLUE,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        position: 'relative',
        padding: 0,
        flexGrow: 1,
      },
      footerContainer: {
        padding: '2em 2.5em 0',
        backgroundColor: SPORTIIZ_WHITE,
        borderTopRightRadius: BACKGROUND_RADIUS,
        borderTopLeftRadius: BACKGROUND_RADIUS,
        textAlign: 'left',
      },
      footerArrayContainer: {
        backgroundColor: SPORTIIZ_WHITE,
        padding: '0 2.5em 2em',
        width: '100%',
        overflow: 'scroll',
        scrollbarWidth: 'none',
        flexGrow: 1,
      },
      containerRow: {
        textAlign: 'left',
        color: SPORTIIZ_GREY_DARK,
      },
      fab: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        fontSize: '2em',
        zIndex: 10,
      },
      headerOutlinedButton: {
        padding: '1em',
        margin: '12px 0 15px',
        boxShadow: 'none',
        backgroundColor: SPORTIIZ_WHITE,
        border: `1px solid ${SPORTIIZ_GREY_LIGHT}`,
        color: SPORTIIZ_BLUE,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: SPORTIIZ_GREY_LIGHT,
        },
      },
    }
  );
}
