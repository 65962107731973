import { SPORTIIZ_WHITE } from '../constants/colors';
import { LIST_ITEM_RADIUS } from '../constants/style';

export default function sidebarMenuStyle(theme) {
  return ({
    list: {
      background: 'transparent',
      color: SPORTIIZ_WHITE,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    listItem: {
      background: 'transparent',
      color: SPORTIIZ_WHITE,
      height: 'auto',
      borderRadius: LIST_ITEM_RADIUS,
      textAlign: 'left',
      padding: '14px 16px',
      lineHeight: '19.5px',
      fontSize: '13px',
      whiteSpace: 'nowrap',
      fontWeight: 400,
      gap: '8px',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
      [theme.breakpoints.down('md')]: {
        padding: '10px 8px',
      },
    },
    listItemText: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      flexDirection: 'row',
    },
    listItemTextPrimary: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 'calc(100% - 22px)',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: 2,
    },
    listItemIcon: {
      justifyContent: 'center',
      minWidth: '42px',
      [theme.breakpoints.down('md')]: {
        minWidth: 0,
      },
    },
  });
}
