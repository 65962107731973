import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { useHistory } from 'react-router-dom';
import PrivatiizIcon from '../icon/PrivatiizIcon';
import { SPORTIIZ_OLD_GREY_LIGHT, SPORTIIZ_RED } from '../../constants/colors';
import { getShortDayFromTimestamp } from '../../services/dateService';
import { EVENT } from '../../constants/routes';

const useStyles = makeStyles(() => (
  {
    table: {
      minWidth: 0,
    },
    isPrivatiizIcon: {
      marginRight: '6px',
      verticalAlign: 'middle',
    },
    disabled: {
      backgroundColor: SPORTIIZ_OLD_GREY_LIGHT,
    },
  }
));

const EventsArray = ({ events }) => {
  const {
    t,
    i18n,
  } = useTranslation();
  const classes = useStyles();
  const locale = i18n.language.slice(0, 2);

  const history = useHistory();

  function handleClickNavigate(eventId) {
    history.push(EVENT.replace(':id', eventId));
  }

  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('table.event.name')}</TableCell>
            <TableCell align="left">{t('table.event.visibility')}</TableCell>
            <TableCell align="left">{t('table.event.start')}</TableCell>
            <TableCell align="left">{t('table.event.end')}</TableCell>
            <Hidden smDown>
              <TableCell align="left">{t('table.event.area')}</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
            <TableRow
              key={event.id}
              onClick={() => handleClickNavigate(event.id)}
              className={event.status === 'CANCELLED' ? classes.disabled : ''}
            >
              <TableCell align="left">
                {event.isPrivatiiz
                  && <span
                    className={classes.isPrivatiizIcon}
                  >
                      <PrivatiizIcon color={SPORTIIZ_RED} verticalAlign={'unset'} />
                    </span>}
                {event.name}
              </TableCell>
              <TableCell align="left">{t(`pages.events.visibilities.${event.visibility}`)}</TableCell>
              <TableCell align="left">{getShortDayFromTimestamp(locale, event.start)}</TableCell>
              <TableCell align="left">{getShortDayFromTimestamp(locale, event.end)}</TableCell>
              <Hidden smDown>
                <TableCell align="left">{event.area && event.area.name}</TableCell>
              </Hidden>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

EventsArray.propTypes = {
  events: PropTypes.array.isRequired,
};

export default EventsArray;
