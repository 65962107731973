import { SPORTIIZ_WHITE } from '../constants/colors';

export default function anonymousPageStyles(theme) {
  return ({
    gridContainer: {
      flexGrow: 1,
    },
    container: {
      height: '100vh',
      padding: 0,
      overflow: 'hidden',
      textAlign: 'center',
      zIndex: 1,
      backgroundColor: SPORTIIZ_WHITE,
      [theme.breakpoints.up('md')]: {
        position: 'fixed',
        top: 0,
        right: 0,
      },
      [theme.breakpoints.up('xl')]: {
        position: 'fixed',
        top: 0,
        right: 0,
      },
      [theme.breakpoints.down('sm')]: {
        margin: '6vh auto',
        position: 'relative',
      },
    },
    logo: {
      display: 'block',
      justifyContent: 'center',
      position: 'relative',
      top: 0,
      left: '-25px',
      zIndex: 0,
      margin: '12vh auto',
    },
    link: {
      verticalAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      padding: '2em',
    },
  });
}
