import { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { DEFAULT_COMPANY, QUERY_COMPANY } from '../../constants/graphQLQueries';

function useCompany(id) {
  const [company, setCompany] = useState(DEFAULT_COMPANY);
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [activities, setActivities] = useState([]);
  const [waitingCompanyUsers, setWaitingCompanyUsers] = useState([]);
  const [companySubscription, setCompanySubscription] = useState(null);
  const client = useApolloClient();
  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery(
    QUERY_COMPANY,
    {
      variables: { id },
      client,
    },
  );

  useEffect(() => {
    if (data && data.company) {
      setCompany(data.company);
      setUsers(
        data.company.companyUsers
          .filter((cu) => cu.roles)
          .map((cu) => cu.user),
      );
      setAdmins(
        data.company.companyUsers
          .filter((cu) => cu.roles.includes('admin'))
          .map((cu) => cu.user),
      );
      setWaitingCompanyUsers(
        data.company.waitingCompanyUsers.sort(
          (a, b) => (
            a.createdAt > b.createdAt
              ? -1
              : 1
          ),
        ),
      );
      if (data.company.currentCompanySubscription) {
        setCompanySubscription(data.company.currentCompanySubscription);
      }
      if (data.company.privatiizActivities) {
        setActivities(data.company.privatiizActivities);
      }
    }
  }, [data, loading]);

  return {
    loading,
    error,
    refetch,
    company,
    users,
    waitingCompanyUsers,
    companySubscription,
    admins,
    activities,
  };
}

export default useCompany;
