/* eslint-disable react/prop-types */
import React, { createContext, useCallback, useContext, useState } from 'react';

const TimeSlotPeriodContext = createContext({
  period: 'Future',
  setPeriod: () => {
  },
});

export const TimeSlotPeriodProvider = ({
  defaultPeriod = 'Future',
  children,
}) => {
  const [p, setP] = useState(defaultPeriod);
  const setPeriod = useCallback((newPeriod) => setP(newPeriod), []);
  return <TimeSlotPeriodContext.Provider
    value={{
      period: p,
      setPeriod,
    }}
  >
    {children}
  </TimeSlotPeriodContext.Provider>;
};

export const useTimeSlotPeriod = () => {
  const value = useContext(TimeSlotPeriodContext);
  if (value === null) {
    throw new Error(
      'You must encapsulate your components with <TimeSlotPeriodProvider> to use this hook',
    );
  }
  return value;
};
