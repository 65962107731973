import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import UserCard from './UserCard';

const useStyles = makeStyles(() => (
  {
    gridRow: {
      width: '100%',
      margin: 'auto',
      paddingBottom: '76px',
    },
  }
));

const UserList = (props) => {
  const {
    users,
    emptyLabel,
    hideMenu,
    openRemoveDialog,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Grid
        container
        justifyContent="flex-start"
        spacing={matches ? 2 : 4}
        className={classes.gridRow}
      >
        {users && users.length > 0 && users.map((elem) => (
          <UserCard
            user={elem}
            key={`usercard_${elem.id}`}
            hideMenu={hideMenu}
            openRemoveDialog={openRemoveDialog}
          />
        ))}
        {users && users.length === 0 && (
          <Typography variant={'subtitle1'}>{emptyLabel}</Typography>
        )}
      </Grid>
    </div>
  );
};

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  emptyLabel: PropTypes.string.isRequired,
  openRemoveDialog: PropTypes.func,
  hideMenu: PropTypes.bool,
};

UserList.defaultProps = {
  emptyLabel: 'No data to display',
  hideMenu: false,
};
export default UserList;
