export const SPORTIIZ_RED_LIGHT = '#EFC2C7';
export const SPORTIIZ_RED = '#FC324C';
export const SPORTIIZ_RED_DARK = '#DD2038';

export const SPORTIIZ_BLUE = '#212A4A';
export const SPORTIIZ_BLUE_GRADIANT_LIGHT = '#253952';
export const SPORTIIZ_BLUE_GRADIANT_DARK = '#252D4C';

export const SPORTIIZ_GREY_GREEN_LIGHT = '#CDD2BE';
export const SPORTIIZ_GREY_GREEN = '#B6BBA5';
export const SPORTIIZ_GREY_GREEN_DARK = '#F0F1EA';

export const SPORTIIZ_BACKGROUND = '#F6F7F9';

export const SPORTIIZ_GREY_LIGHT = '#D6DAE5';
export const SPORTIIZ_OLD_GREY_LIGHT = '#ECECEC';
export const SPORTIIZ_GREY = '#979BA7';
export const SPORTIIZ_GREY_DARK = '#8D909D';
export const SPORTIIZ_GREY_EXTRA_DARK = '#6C6E78';
export const SPORTIIZ_BLUE_GREY = '#8991B3';
export const SPORTIIZ_BLUE_GREY_DARK = '#73798C';
export const SPORTIIZ_BLUE_BACKGROUND = '#E9ECF3';
export const SPORTIIZ_WHITE = '#FFFFFF';

export const SPORTIIZ_LIGHT_PALE_BLUE = '#F3F5F9';
export const SPORTIIZ_PALE_BLUE = '#d6dae5';

export const SPORTIIZ_SELECTED_BACKGROUND = 'rgba(0,0,0,0.2)';
export const SPORTIIZ_HOVER_BACKGROUND = 'rgba(0,0,0,0.1)';
